import { Box, Button, createSvgIcon, IconButton, Snackbar, Tooltip } from "@material-ui/core";
import { Email } from "@material-ui/icons";
import { ArrowDownward, ArrowUpward, HorizontalRule } from "@mui/icons-material";
import { Alert } from "@mui/material";
import {
  DataGridPro,
  GridColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import { DateRange } from "@mui/x-date-pickers-pro";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import {
  AccountStateChange,
  AccountStateData,
  getAccountState,
  getAccountStateChange,
  getAccountStateExplainer,
} from "../../api/accountState";
import { AccountState } from "../../api/autopilotHelper";
import { InnerContainer } from "../../components/InnerContainer";
import { uppColourPalette } from "../../util/colours";
import { AccountStateDictionary, AccountStateGrid } from "./AccountStateGrid";
import { Truck } from "react-feather";

const getCorrectNotificationIcon = (notificationType: string) => {
  if (notificationType === "notification") {
    return <Email />;
  }
  if (notificationType === "pga") {
    return <Truck />;
  }
  return <Email />;
};

const formatDate = (date: any) => {
  const converted = DateTime.fromISO(date);

  if (converted.isValid) {
    return converted.toLocaleString(DateTime.DATETIME_SHORT);
  } else {
    return <HorizontalRule />;
  }
};

const formatEventType = (event: string) => {
  if (!event || event.length === 0) {
    return "";
  }

  const capitalized = event.charAt(0).toUpperCase() + event.slice(1);
  const spaced = capitalized.replace("_", " ");

  return spaced;
};

const columns: GridColDef[] = [
  {
    field: "date",
    type: "Date",
    headerName: "Date",
    renderCell: (params) => formatDate(params.value),
  },
  { field: "currentState", headerName: "Current State", width: 150 },
  { field: "previousState", headerName: "Previous State", width: 150 },
  {
    field: "accountStateChange",
    headerName: "Change",
    width: 100,
    renderCell: (params) => {
      const stateChange = params.value;
      if (stateChange === AccountStateChange.INCREASE) {
        return <ArrowUpward style={{ color: "green" }} />;
      }
      if (stateChange === AccountStateChange.DECREASE) {
        return <ArrowDownward style={{ color: "red" }} />;
      }
      return <HorizontalRule />;
    },
  },
  {
    field: "latestPGARun",
    headerName: "Latest PGA Run",
    width: 200,
    renderCell: (params) => formatDate(params.value),
  },
  {
    field: "lastTargetRoasUpdate",
    headerName: "Latest Target Roas Update",
    width: 200,
    renderCell: (params) => formatDate(params.value),
  },
  {
    field: "latestBudgetUpdate",
    headerName: "Latest Budget Update",
    width: 200,
    renderCell: (params) => formatDate(params.value),
  },
  { field: "event", headerName: "Event origin", valueFormatter: (params) => formatEventType(params?.value) },
  {
    field: "actionTaken",
    headerName: "Action taken",
    width: 100,
    renderCell: (params) => {
      const iconButton = getCorrectNotificationIcon(params.value);
      return params.value === "notification" ? (
        <Tooltip
          title={`Your account is currently hitting your ROAS goal ${
            params.row.targetRoas ? `of ${params.row.targetRoas * 100}%` : ""
          } but is limited by budget!

          Increase your budget via the Upp dashboard to make the most of this opportunity`}
        >
          <IconButton>{iconButton}</IconButton>
        </Tooltip>
      ) : (
        <Tooltip
          title={`PGA has been run for this account and the target roas has been updated.
        
        Performance: Interim Roas=${params.row.performanceInterimTargetRoas ?? "n/a"} 
                     Interim Budget=${params.row.performanceInterimTargetSpend ?? "n/a"}

        Visiblity:   Interim Roas=${params.row.visibilityInterimTargetRoas ?? "n/a"}
                     Interim Budget=${params.row.visibilityInterimTargetSpend ?? "n/a"}`}
        >
          <IconButton>{iconButton}</IconButton>
        </Tooltip>
      );
    },
  },
  { field: "reliableBuffer", headerName: "Reliable Buffer" },
  { field: "accountLag", headerName: "Account Lag" },
  { field: "sufficientDataIntervalInDays", headerName: "Data interval (days)" },
];

interface AccountStateInterface {
  tenantId: string;
}

export const AccountStateTable = ({ tenantId }: AccountStateInterface) => {
  const [isLoadingAccountState, setIsLoadingAccountState] = useState<boolean>(false);
  const [accountState, setAccountState] = useState<AccountStateData[]>([]);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const defaultFromDate = DateTime.now().minus({ months: 1 });
  const [dateRange] = useState<DateRange<DateTime>>([defaultFromDate, DateTime.now()]);
  const [currentAccountState, setCurrentAccountState] = useState<AccountState>();
  const [previousAccountState, setPreviousAccountState] = useState<AccountState>();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoadingAccountState(true);
      const from = dateRange[0]?.toISODate();
      const to = dateRange[1]?.toISODate();
      try {
        const accountStateResult = await getAccountState(tenantId, from, to);
        if (!accountStateResult) {
          return;
        }

        const formattedAccountState: AccountStateData[] = accountStateResult.map((state, index) => {
          return {
            ...state,
            id: index,
            currentState: state.currentState && AccountStateDictionary[state.currentState as AccountState],
            previousState: state.previousState && AccountStateDictionary[state.previousState as AccountState],
            accountStateChange: getAccountStateChange(
              state.previousState as AccountState,
              state.currentState as AccountState
            ),
          };
        });
        const accountStateExplainer = await getAccountStateExplainer();
        setCurrentAccountState(accountStateExplainer?.currentState as AccountState);
        setPreviousAccountState(formattedAccountState[0]?.previousState as AccountState);
        setAccountState(formattedAccountState);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoadingAccountState(false);
      }
    };
    fetchData();
  }, [tenantId, dateRange]);

  const GridContent = () => {
    if (isLoadingAccountState) {
      return <div>Loading...</div>;
    }

    const ExportIcon = createSvgIcon(
      <path d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z" />,
      "SaveAlt"
    );

    const CustomToolbar = () => {
      const exportProductsAsCSV = async () => {
        setIsLoadingAccountState(false);
      };
      return (
        <GridToolbarContainer>
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
          <GridToolbarDensitySelector />
          <Button color="secondary" size="small" startIcon={<ExportIcon />} onClick={() => exportProductsAsCSV()}>
            Export
          </Button>
        </GridToolbarContainer>
      );
    };

    return accountState ? (
      <DataGridPro
        rows={accountState}
        columns={columns}
        loading={isLoadingAccountState}
        pagination
        pageSizeOptions={[5, 50, 100]}
        initialState={{
          pagination: { paginationModel: { pageSize: 5 } },
        }}
        autoHeight
        style={{ overflowY: "auto" }}
        slots={{
          toolbar: CustomToolbar,
        }}
      />
    ) : (
      <div>No data</div>
    );
  };

  return (
    <>
      {currentAccountState && (
        <Box sx={{ margin: 10 }}>
          <AccountStateGrid currentAccountState={currentAccountState} previousAccountState={previousAccountState} />
        </Box>
      )}
      <h1
        style={{
          textAlign: "left",
          marginBottom: "2rem",
          fontWeight: 400,
          borderTop: `1px ${uppColourPalette.grey[300]} solid`,
          paddingTop: "2rem",
        }}
      >
        {`List of actions (email, PGA run) take for this account`}
      </h1>
      <InnerContainer>
        <GridContent />
      </InnerContainer>
      <Snackbar open={showAlert} autoHideDuration={3000} onClose={() => setShowAlert(false)}>
        <Alert severity={"error"} variant="filled"></Alert>
      </Snackbar>
    </>
  );
};
