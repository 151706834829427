import { http } from "../UppAuth";

interface Audit {
  _id: string;
  tenantId: string;
  createdOn: string;
  from: string;
  to: string;
  visible?: boolean;
}

export enum Categorisation {
  UNDER_SPEND = "under_spend",
  OVER_SPEND = "over_spend",
  ALL = "all",
}

export enum ChartRender {
  REVENUE = "revenue",
  SPEND = "cost",
}
export interface AuditProductGroupPerformance extends Audit {
  productGroupPerformanceAuditItems: ProductGroupPerformanceAuditItem[];
}

export interface ProductGroupPerformance extends Totals {
  groupId: string;
}
export interface Totals {
  impressions: number;
  clicks: number;
  cost: number;
  conversions: number;
  conversionValue: number;
  revenue: number;
  unitsSold: number;
}
export interface ProductGroupPerformanceAuditItem extends ProductGroupPerformance {
  costPercentile: number;
  unitsSoldPercentile: number;
  categorisation: Categorisation[];
  title: string;
}

export interface ProductGroupPerformance {
  groupId: string;
  impressions: number;
  clicks: number;
  cost: number;
  conversions: number;
  conversionValue: number;
  revenue: number;
  unitsSold: number;
}

export interface Share {
  unitsSold: number;
  unitsSoldShare: number;
  revenue: number;
  revenueShare: number;
  cost: number;
  costShare: number;
  percentageOfTotalInventory: number;
}

export interface AuditAccountPerformance extends Audit {
  totals: Totals;
  underSpend: Share;
  overSpend: Share;
  forecastUplift: number;
  timeSeries: {
    revenue: Array<number>;
    cost: Array<number>;
  };
  avgImpPerClick: number;
  productsAchievingAvgImp: {
    percentage: number;
    count: number;
  };
  avgConvPerClick: number;
  productsAchievingAvgConv: {
    percentage: number;
    count: number;
  };
}

export async function getAllAudits(): Promise<AuditAccountPerformance[]> {
  const result = await http.get<any>(`${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/audit`);

  if (result.status !== 200) {
    console.log("Failed to fetch audits");
  }
  return result.data;
}

export async function updateAuditVisibility(id: string, visible: boolean): Promise<AuditAccountPerformance> {
  const result = await http.patch<any>(`${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/audit/${id}/visible`, {
    visibility: visible,
  });
  return result.data;
}

export async function getProductGroupAudit(
  offset: number,
  limit: number,
  filter: Categorisation
): Promise<AuditProductGroupPerformance | undefined> {
  const offsetParam = offset ? `offset=${offset}&` : "";
  const limitParam = limit ? `limit=${limit}` : "";
  const filterParam = filter && filter !== "all" ? `filter=${filter}` : "";

  const result = await http.get(
    `${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/audit/productGroup?${offsetParam}&${limitParam}&${filterParam}`
  );
  if (result.status === 200) {
    return result.data;
  }

  return undefined;
}

export async function getAccountAudit(): Promise<AuditAccountPerformance | undefined> {
  const result = await http.get(`${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/audit/account`);
  if (result.status === 200) {
    return result.data;
  }

  return undefined;
}

export async function getGoogleAdsAudit(tenantId: string): Promise<any | undefined> {
  const result = await http.get(`${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/googleadwords/audit`);
  if (result.status === 200) {
    return result.data;
  }

  return undefined;
}
