import { useMutation } from "@tanstack/react-query";
import { http } from "../UppAuth";

// Tidy these up since we don't need them all
export interface User {
  email?: string | undefined;
  email_verified?: boolean | undefined;
  username?: string | undefined;
  phone_number?: string | undefined;
  phone_verified?: boolean | undefined;
  user_id?: string | undefined;
  _id?: string | undefined;
  created_at?: string | undefined;
  updated_at?: string | undefined;
  identities?: Identity[] | undefined;
  app_metadata?: AppMetadata | undefined;
  user_metadata?: UserMetadata | undefined;
  picture?: string | undefined;
  name?: string | undefined;
  nickname?: string | undefined;
  multifactor?: string[] | undefined;
  last_ip?: string | undefined;
  last_login?: string | undefined;
  last_password_reset?: string | undefined;
  logins_count?: number | undefined;
  blocked?: boolean | undefined;
  given_name?: string | undefined;
  family_name?: string | undefined;
}
export interface AppMetadata {
  [propName: string]: any;
}
export interface UserMetadata {
  [propName: string]: any;
}
export interface Identity {
  connection: string;
  user_id: string;
  provider: string;
  isSocial: boolean;
  access_token?: string | undefined;
  profileData?:
    | {
        email?: string | undefined;
        email_verified?: boolean | undefined;
        name?: string | undefined;
        phone_number?: string | undefined;
        phone_verified?: boolean | undefined;
        request_language?: string | undefined;
      }
    | undefined;
}

export interface UserRequest {
  email: string | undefined;
  name: string | undefined;
  tenantIds: string[] | undefined;
  sendShopifyConnectorEmail?: boolean;
  user_id?: string;
}

export interface UsersResponse {
  totalUsers: number;
  page: number;
  users: User[];
  nextPage: boolean;
}

export async function getUsers(page: number, tenantId?: string): Promise<UsersResponse> {
  const getByTenantParam = tenantId ? `&tenantId=${tenantId}` : "";

  const result = await http.get<UsersResponse>(
    `${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/users?page=${page}${getByTenantParam}`
  );

  if (result.status !== 200) {
    throw new Error("Unable to fetch users.");
  }

  return result.data;
}

export async function getUser(userId: string): Promise<User> {
  const result = await http.get<User>(`${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/users/${userId}`);

  if (result.status !== 200) {
    throw new Error("Unable to fetch user with id: " + userId);
  }

  return result.data;
}

export function useAddUser() {
  return useMutation(addUser);
}

async function addUser(user: UserRequest): Promise<User> {
  if (!user.email || !user.name || !user.tenantIds) throw new Error("Missing required parameters");

  const result = await http.post<UserRequest>(`${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/users`, user);

  if (result.status !== 201) {
    throw new Error("Unable to add user");
  }

  return result.data;
}

export function useUpdateUser() {
  return useMutation(updateUser);
}

async function updateUser(user: UserRequest): Promise<UserRequest> {
  if (!user.email || !user.name || !user.tenantIds || !user.user_id) throw new Error("Missing required parameters");

  const result = await http.patch<UserRequest>(
    `${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/users/${user.user_id}`,
    user
  );

  if (result.status !== 200) {
    throw new Error("Unable to update user.");
  }

  return user;
}

export function useDeleteUser() {
  return useMutation(deleteUser);
}

async function deleteUser(userId: string): Promise<void> {
  const result = await http.delete<UserRequest>(`${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/users/${userId}`);

  if (result.status !== 200) {
    throw new Error("Unable to delete user.");
  }

  return;
}
