import { Avatar, Box, Tooltip, Typography } from "@material-ui/core";
import { AlertColor } from "@mui/material/Alert";
import { useConfirm } from "material-ui-confirm";
import { Dispatch, SetStateAction } from "react";
import styled from "styled-components";
import { Connection, DatasourceEntityType, DatasourceStatus, deleteDatasource } from "../../api/datasources";
import { StyledButton } from "./Datasources";
import { StyledCloseButton } from "./SetupConnection";

const StyledSourceCard = styled.div<{ connection: string | undefined }>`
  position: relative;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
  border: 1px solid #bcbcbc;
  border-radius: 5px;
  height: 80px;
  width: 50%%;
  margin: 10px;
  cursor: pointer;

  &:last-of-type {
    margin-bottom: 0;
  }

  ${({ connection }) => `
    ::after {
      position: absolute;
      right: 0;
      top: 0;
      content: ' ';
      height: 100%;
      width: 6px;
      z-index: 1;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      ${(connection === DatasourceStatus.Pending && `background-color: grey;`) || ""}
      ${(connection === DatasourceStatus.Success && `background-color:  #8bc34a;`) || ""}
    }
  `}
`;
const ImgFrame = styled.div`
  display: flex !important;
  height: 80px !important;
  width: 80px !important;
  padding: 0 16px !important;
  align-items: center !important;
  justify-content: center !important;

  img {
    width: 100% !important;
    height: auto !important;
    object-fit: contain !important;
  }
`;

const SourceInfo = styled(Box)`
  display: flex !important;
  align-items: center !important;
`;

const ButtonContainer = styled.div`
  display: flex !important;
  justify-content: flex-end !important;
  align-items: center !important;
  padding-right: 16px !important;
`;

interface ConnectionCardProps {
  item: Connection;
  buttonValue?: string;
  buttonId?: string;
  buttonDisabled?: boolean;
  buttonDisabledHint?: string;
  onClick: () => void;
  onExpand?: (e: any) => void;
  status?: string;
  id: string;
  totalImported?: number | string;
  entityType?: DatasourceEntityType;
  setShowAlert?: Dispatch<SetStateAction<boolean>>;
  setAlertConfig?: Dispatch<
    SetStateAction<{
      message: string;
      severity: AlertColor;
    }>
  >;
}

export const ConnectionCard = ({
  item,
  onClick,
  onExpand,
  setAlertConfig,
  setShowAlert,
  id,
  buttonValue,
  buttonId,
  buttonDisabled,
  buttonDisabledHint,
  status,
  totalImported,
  entityType,
}: ConnectionCardProps) => {
  const confirm = useConfirm();

  const onDelete = () => {
    confirm({ title: `Confirm`, description: confirmMessage })
      .then(() => {
        deleteDatasource(id)
          .then((response) => {
            if (response === true) {
              setAlertConfig!({ message: "Success", severity: "success" });
            } else {
              setAlertConfig!({
                message: "Something went wrong!",
                severity: "error",
              });
            }
          })
          .finally(() => {
            setShowAlert!(true);
          });
      })
      .catch(() => {
        /* needed to handle 'cancel' gracefully */
      });
  };

  const confirmMessage = <Typography component={"span"}>Are you sure you want to delete this datasource?</Typography>;
  return (
    <StyledSourceCard className="source-card" connection={status} onClick={onExpand}>
      <SourceInfo>
        <ImgFrame>
          {item && item.logo ? (
            <img src={item.logo || undefined} alt={item.name} />
          ) : (
            <Avatar>{(item && item.name.slice(0, 1)) || "N/A"}</Avatar>
          )}
        </ImgFrame>
        {(item && item.name) || "N/A"}
      </SourceInfo>
      <SourceInfo id={`connections__${item.name.replace(" ", "")}Status`}>{status}</SourceInfo>
      <SourceInfo id={`connections__${item.name.replace(" ", "")}DocumentCount`} marginX={2}>
        {totalImported}
      </SourceInfo>
      <SourceInfo id={`connections__${item.name.replace(" ", "")}EntityType`}>{entityType}</SourceInfo>
      <ButtonContainer>
        <Tooltip title={buttonDisabledHint || ""}>
          <StyledButton
            variant="outlined"
            onClick={buttonValue !== "Delete" ? onClick : onDelete}
            id={buttonId}
            disabled={buttonDisabled}
            {...({ component: buttonDisabled ? "div" : undefined } as any)}
          >
            {buttonValue}
          </StyledButton>
        </Tooltip>
        {buttonValue !== "Connect" ? <StyledCloseButton onClick={onDelete}>X</StyledCloseButton> : ""}
      </ButtonContainer>
    </StyledSourceCard>
  );
};
