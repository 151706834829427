import { Dialog, DialogContent, DialogTitle, Divider, Grid, TextField } from "@material-ui/core";
import { CloseRounded } from "@material-ui/icons";
import { ChangeEvent, Dispatch, SetStateAction } from "react";
import { StyledButtonFilled } from "../../datasources/Datasources";
import { StyledCloseButton } from "../../datasources/SetupConnection";
import { StyledRow } from "../../tenantConfig/TenantConfig";
import { CommonPayload } from "@volo-ai/upp-temporal-utils";
import { StyledInputLabel } from "../AddJobDialog";

interface OpportunityProps {
  handleToggle: (prop: any) => (e: ChangeEvent<HTMLInputElement>) => void;
  jobPayload: CommonPayload;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  handleSubmit?: React.MouseEventHandler<HTMLButtonElement>;
  handleDataChange: (prop: any) => (e: ChangeEvent<HTMLInputElement>) => void;
}

export const OpportunitiesWorkflow = ({
  handleToggle,
  jobPayload,
  open,
  setOpen,
  handleSubmit,
  handleDataChange,
}: OpportunityProps) => {
  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>
        Opportunities Workflow
        <StyledCloseButton onClick={() => setOpen(false)}>
          <CloseRounded />
        </StyledCloseButton>
        <Grid container>
          <Grid item>
            <StyledInputLabel>Channel</StyledInputLabel>
            <TextField type="string" variant="outlined" onChange={handleDataChange("channel")} />
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <StyledRow style={{ marginTop: "20px" }}>
          <StyledButtonFilled onClick={handleSubmit}>Create</StyledButtonFilled>
        </StyledRow>
      </DialogContent>
    </Dialog>
  );
};
