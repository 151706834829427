import styled from "@emotion/styled";
import { Box, Switch } from "@material-ui/core";
import { DateRange } from "@mui/x-date-pickers-pro";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { SetStateAction, useEffect, useState } from "react";
import Chart from "react-google-charts";
import { getAccountLevelCampaignStats } from "../../api/campaignstats";
import { Heading } from "../../components/Heading";
import { GetCurrencySymbol } from "../../util/currency";

const DateRangePickerContainer = styled.div`
  width: 400px;
`;

const Container = styled.div`
  display: inline-block;
`;

const InnerContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SwitchContainer = styled.div`
  display: inline-block;
  margin-right: 20px;
`;

export const PerformanceMaxSplit = () => {
  const defaultFromDate = DateTime.now().minus({ months: 3 });
  const [dateRange, setDateRange] = useState<DateRange<DateTime>>([defaultFromDate, DateTime.now()]);
  const currencySymbol = GetCurrencySymbol();

  const { data, isLoading } = useQuery(
    ["users", dateRange[0], dateRange[1]],
    () =>
      getAccountLevelCampaignStats(
        dateRange[0]?.toJSDate() ?? defaultFromDate.toJSDate(),
        dateRange[1]?.toJSDate() ?? new Date()
      ),
    {
      enabled: true,
      keepPreviousData: true,
    }
  );

  const headers = ["Date", "Video", "Search", "Shopping"];

  const options = {
    isStacked: true,
    height: 300,
    legend: { position: "top", maxLines: 3 },
    vAxis: { minValue: 0 },
  };

  const [chartData, setChartData] = useState<(number | Date | undefined)[][]>([]);
  const [isActive, setIsActive] = useState("spend");

  useEffect(() => {
    if (data) {
      const chartData = data?.map((item) => {
        return [
          item.date,
          item?.pmaxSplit?.video.percentage,
          item?.pmaxSplit?.search.percentage,
          item?.pmaxSplit?.shopping.percentage,
        ];
      });
      // @ts-ignore
      chartData?.unshift(headers);
      setChartData(chartData);
    }
  }, [data]);

  function handleChange(dataType: string) {
    if (dataType === "roas") {
      const chartData = data?.map((item) => {
        return [
          item.date,
          item?.pmaxSplit?.video.roas! > 0 ? item.pmaxSplit?.video.roas : 0,
          item?.pmaxSplit?.search.roas! > 0 ? item.pmaxSplit?.search.roas : 0,
          item?.pmaxSplit?.shopping.roas! > 0 ? item.pmaxSplit?.shopping.roas : 0,
        ];
      });
      // @ts-ignore
      chartData?.unshift(headers);
      setChartData(chartData!);
      setIsActive("roas");
    } else if (dataType === "conversionValue") {
      const chartData = data?.map((item) => {
        return [
          item.date,
          item?.pmaxSplit?.video.conversionValue,
          item?.pmaxSplit?.search.conversionValue,
          item?.pmaxSplit?.shopping.conversionValue,
        ];
      });
      // @ts-ignore
      chartData?.unshift(headers);
      setChartData(chartData!);
      setIsActive("conversionValue");
    } else {
      const chartData = data?.map((item) => {
        return [
          item.date,
          item?.pmaxSplit?.video.percentage,
          item?.pmaxSplit?.search.percentage,
          item?.pmaxSplit?.shopping.percentage,
        ];
      });
      // @ts-ignore
      chartData?.unshift(headers);
      setChartData(chartData!);
      setIsActive("spend");
    }
  }

  const googleMicrosDivider = 1000000;

  const actualSpendSplit = data?.map((item) => {
    return {
      video: (item?.pmaxSplit?.video.cost ?? 0) / googleMicrosDivider,
      search: (item?.pmaxSplit?.search.cost ?? 0) / googleMicrosDivider,
      shopping: (item?.pmaxSplit?.shopping.cost ?? 0) / googleMicrosDivider,
    };
  });

  const videoTotal = actualSpendSplit?.reduce((acc, curr) => {
    return acc + curr.video;
  }, 0);
  const searchTotal = actualSpendSplit?.reduce((acc, curr) => {
    return acc + curr.search;
  }, 0);
  const shoppingTotal = actualSpendSplit?.reduce((acc, curr) => {
    return acc + curr.shopping;
  }, 0);

  const actualConversionValueSplit = data?.map((item) => {
    return {
      video: item?.pmaxSplit?.video.conversionValue ?? 0,
      search: item?.pmaxSplit?.search.conversionValue ?? 0,
      shopping: item?.pmaxSplit?.shopping.conversionValue ?? 0,
    };
  });

  const videoConversionValueTotal = actualConversionValueSplit?.reduce((acc, curr) => {
    return acc + curr.video;
  }, 0);
  const searchConversionValueTotal = actualConversionValueSplit?.reduce((acc, curr) => {
    return acc + curr.search;
  }, 0);
  const shoppingConversionValueTotal = actualConversionValueSplit?.reduce((acc, curr) => {
    return acc + curr.shopping;
  }, 0);

  const videoRoas = data?.map((item) => {
    return item?.pmaxSplit?.video.roas ?? 0;
  });
  const searchRoas = data?.map((item) => {
    return item?.pmaxSplit?.search.roas ?? 0;
  });
  const shoppingRoas = data?.map((item) => {
    return item?.pmaxSplit?.shopping.roas ?? 0;
  });

  const videoRoasAdded = videoRoas?.reduce((acc, curr) => {
    return acc + curr;
  }, 0);
  const searchRoasAdded = searchRoas?.reduce((acc, curr) => {
    return acc + curr;
  }, 0);
  const shoppingRoasAdded = shoppingRoas?.reduce((acc, curr) => {
    return acc + curr;
  }, 0);

  const videoRoasTotal = videoRoas && videoRoasAdded ? videoRoasAdded / videoRoas?.length : 0;
  const searchRoasTotal = searchRoas && searchRoasAdded ? searchRoasAdded / searchRoas?.length : 0;
  const shoppingRoasTotal = shoppingRoas && shoppingRoasAdded ? shoppingRoasAdded / shoppingRoas?.length : 0;

  // Check if there is any data for conversion value (hide the switch if there is no data for aka compatibile with old data)
  const hasExtraData = data?.some((item) => {
    return item?.pmaxSplit?.shopping.conversionValue;
  });

  return (
    <Box
      sx={{
        bgcolor: "white",
        padding: "2em",
        margin: "2em",
        borderRadius: "0.5%",
        border: "1px solid",
        borderColor: "#dbdbdb",
      }}
    >
      <div>
        <Heading>Performance Max Split</Heading>
        {/* <StyledRow> */}
        <DateRangePickerContainer>
          <DateRangePicker
            defaultValue={dateRange}
            value={dateRange}
            onChange={(newValue: SetStateAction<DateRange<DateTime>>) => {
              setDateRange(newValue);
            }}
          />{" "}
        </DateRangePickerContainer>
        {/* </StyledRow> */}

        {isLoading ? (
          <div>Loading...</div>
        ) : (
          <>
            <Chart chartType="AreaChart" width="100%" height="400px" data={chartData} options={options} />
            {hasExtraData && (
              <>
                <Container>
                  <SwitchContainer>
                    <Switch color="primary" checked={isActive === "spend"} onChange={() => handleChange("spend")} />
                    <p>Spend</p>
                  </SwitchContainer>
                  <SwitchContainer>
                    <Switch
                      color="primary"
                      checked={isActive === "conversionValue"}
                      onChange={() => handleChange("conversionValue")}
                    />
                    <p>Conversion Value</p>
                  </SwitchContainer>
                  <SwitchContainer>
                    <Switch color="primary" checked={isActive === "roas"} onChange={() => handleChange("roas")} />
                    <p>ROAS</p>
                  </SwitchContainer>
                </Container>

                <InnerContainer>
                  <div>
                    <h2>Shopping</h2>
                    <li>Ad Spend: {currencySymbol + shoppingTotal?.toFixed(2)}</li>
                    <li>Conversion Value: {currencySymbol + shoppingConversionValueTotal?.toFixed(2)}</li>
                    <li>ROAS: {(shoppingRoasTotal! * 100).toFixed(2) + "%"}</li>
                  </div>
                  <div>
                    <h2>Video</h2>
                    <li>Ad Spend: {currencySymbol + videoTotal?.toFixed(2)}</li>
                    <li>Conversion Value: {currencySymbol + videoConversionValueTotal?.toFixed(2)}</li>
                    <li>ROAS: {(videoRoasTotal! * 100).toFixed(2) + "%"}</li>
                  </div>
                  <div>
                    <h2>Search</h2>
                    <li>Ad Spend: {currencySymbol + searchTotal?.toFixed(2)}</li>
                    <li>Conversion Value: {currencySymbol + searchConversionValueTotal?.toFixed(2)}</li>
                    <li>ROAS: {(searchRoasTotal! * 100).toFixed(2) + "%"}</li>
                  </div>
                </InnerContainer>
              </>
            )}
          </>
        )}
      </div>
    </Box>
  );
};

// Left this here as it could be helpful for anyone modifying the map above in the future.
// The chart wants an array of arrays with headers on the first row, like so:
// const sampleChartData = [
//   ["Date", "Video", "Search", "Shopping"],
//   ["2023-01-01T00:00:00.000Z", 0.0284723263114684, 0.1334270379246002, 0.8381006357639313],
//   ["2023-01-02T00:00:00.000Z", 0.03074571997160648, 0.14534779033337406, 0.8239064896950195],
//   ["2023-01-03T00:00:00.000Z", 0.015161203200900192, 0.1214339655682908, 0.863404831230809],
//
// ];
