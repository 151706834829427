import { Autocomplete, Checkbox, Stack, TextField } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { Tenant } from "../../api/tenant";
import { useAddUser, User, UsersResponse } from "../../api/userManagement";
import { StyledButtonFilled } from "../datasources/Datasources";

interface FormProps {
  data: Tenant[];
  onClose: () => void;
  wizard?: boolean;
  onComplete?: () => void;
  tenantIds?: string[];
  page: number;
}

export function CreateUserForm({ data, onClose, wizard, onComplete, tenantIds, page }: FormProps) {
  const [selectedTenants, setSelectedTenants] = useState<Array<string>>(tenantIds || []);
  const [name, setName] = useState<string>();
  const [email, setEmail] = useState<string>("");
  const [sendShopifyConnectorEmail, setSendShopifyConnectorEmail] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const addUser = useAddUser();

  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    addUser.mutate(
      { email, name, tenantIds: selectedTenants, sendShopifyConnectorEmail },
      {
        onSuccess: (user: User) => {
          queryClient.setQueryData<UsersResponse>(["users", page], (oldData) => {
            if (oldData) {
              return {
                ...oldData!,
                users: [...oldData?.users!, user],
              };
            }
          });
          enqueueSnackbar(`Successfully created user`, {
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
          });
          if (!wizard) {
            onClose();
          } else {
            localStorage.setItem("waitingForCustomerShopify", "true");
            onComplete?.();
          }
        },
        onError: (error) => {
          if ((error as Error).message!.includes("409")) {
            enqueueSnackbar(`User already exists.`, {
              variant: "error",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
            });
            setSelectedTenants([]);
            setName("");
            setEmail("");
          } else {
            enqueueSnackbar(`${error}`, {
              variant: "error",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
            });
          }
          if (!wizard) {
            onClose();
          }
        },
      }
    );
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Stack spacing={3} sx={{ width: 450 }}>
          <TextField
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            label="Email"
            variant="outlined"
            required
          />
          <TextField
            type="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            label="Name"
            variant="outlined"
            required
          />
          {!tenantIds && (
            <Autocomplete
              multiple
              id="tenants"
              options={data as Tenant[]}
              value={data && data.filter((tenant) => selectedTenants.includes(tenant.id))}
              onChange={(e, newValue) => {
                const newIds = newValue.map((tenant) => tenant?.id);
                setSelectedTenants(newIds);
              }}
              getOptionLabel={(option: Tenant) => option.name}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Tenants" placeholder="Select tenants..." />
              )}
            />
          )}
          {!wizard ? (
            <StyledButtonFilled type="submit">Submit</StyledButtonFilled>
          ) : (
            <>
              <p>Click 'Next' to send email to customer</p>
              <p>
                Prompt User to add Shopify Connector
                <Checkbox
                  checked={sendShopifyConnectorEmail}
                  onChange={() => setSendShopifyConnectorEmail(!sendShopifyConnectorEmail)}
                />
              </p>
              <StyledButtonFilled onClick={handleSubmit}>Next</StyledButtonFilled>
            </>
          )}
        </Stack>
      </form>
    </>
  );
}
