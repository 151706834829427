import { Tooltip } from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { DateTime } from "luxon";
import { X } from "react-feather";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export function TableAgeCell(params: GridRenderCellParams<DateTime | null>) {
  return (
    <Container>
      {params.value !== null ? (
        <Tooltip title={params.value!.toLocaleString()}>
          <span>{params.value!.diffNow("days").toHuman({ listStyle: "narrow" })}</span>
        </Tooltip>
      ) : (
        <X color={red[600]} />
      )}
    </Container>
  );
}
