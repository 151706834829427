import { Box, Color, Grid } from "@material-ui/core";
import { green, orange, red } from "@material-ui/core/colors";
import { useEffect, useState } from "react";
import {
  Icon,
  Users as UsersIcon,
  Zap as ZapIcon,
  Target as TargetIcon,
  Compass as CompassIcon,
  AlertCircle as AlertCircleIcon,
} from "react-feather";
import styled from "styled-components";
import { OverviewDecoratedTenant, getAllTenantsWithConfigAndCampaignMetadata } from "../../api/tenant";
import { CountBox } from "./CountBox";
import { TenantTable } from "./TenantTable";

export const StyledDashboardContainer = styled.div`
  overflow: hidden;
  padding-top: 64px;
`;

export const StyledBox = styled(Box)`
  min-height: 100% !important;
  padding-top: 1em !important;
  margin: 2em !important;
`;

export const DatasourceConfiguration = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [tenants, setTenants] = useState<OverviewDecoratedTenant[]>([]);
  const [countBoxes, setCountBoxes] = useState<{ name: string; value: number; icon: Icon; colour: Color }[]>([]);

  useEffect(() => {
    async function fetchAllTenants() {
      setIsLoading(true);
      const allTenants = await getAllTenantsWithConfigAndCampaignMetadata();
      setTenants(allTenants);
      setIsLoading(false);
    }
    fetchAllTenants();
  }, []);

  useEffect(() => {
    setCountBoxes([
      {
        name: "LIVE CUSTOMERS",
        value: tenants.reduce((acc, tenant) => (tenant.live ? acc + 1 : acc), 0),
        icon: UsersIcon,
        colour: green,
      },
      // {
      //   name: "CUSTOMERS ON DASHBOARD",
      //   value: tenants.reduce((acc, tenant) => (tenant.config["liveDashboard"] === true ? acc + 1 : acc), 0),
      //   icon: MonitorIcon,
      //   colour: blue,
      // },
      {
        name: "LIVE CUSTOMERS NOT ON CAMPAIGN AUTOMATION",
        value: tenants.reduce((acc, tenant) => (tenant.live && !tenant.campaignAutomation ? acc + 1 : acc), 0),
        icon: AlertCircleIcon,
        colour: red,
      },
      {
        name: "LIVE CUSTOMERS ON CAMPAIGN AUTOMATION",
        value: tenants.reduce((acc, tenant) => (tenant.live && tenant.campaignAutomation ? acc + 1 : acc), 0),
        icon: ZapIcon,
        colour: green,
      },
      {
        name: "LIVE CUSTOMERS ON AUTOPILOT",
        value: tenants.reduce(
          (acc, tenant) =>
            tenant.live && tenant.campaignAutomation && !tenant.mpa && tenant.autopilot ? acc + 1 : acc,
          0
        ),
        icon: CompassIcon,
        colour: green,
      },
      {
        name: "LIVE CUSTOMERS ON MPA",
        value: tenants.reduce(
          (acc, tenant) => (tenant.live && tenant.campaignAutomation && tenant.mpa ? acc + 1 : acc),
          0
        ),
        icon: TargetIcon,
        colour: green,
      },
      {
        name: "LIVE CUSTOMERS NOT ON AUTOPILOT OR MPA",
        value: tenants.reduce(
          (acc, tenant) =>
            tenant.live && tenant.campaignAutomation && !tenant.mpa && !tenant.autopilot ? acc + 1 : acc,
          0
        ),
        icon: AlertCircleIcon,
        colour: orange,
      },
    ]);
  }, [tenants]);

  return (
    <StyledDashboardContainer>
      <StyledBox>
        <Grid container justifyContent="space-evenly" spacing={5}>
          <Grid container xs={12} spacing={5}>
            {countBoxes.map((box, idx) => {
              return (
                <Grid item xs={12} lg={4}>
                  {" "}
                  <CountBox name={box.name} value={box.value} icon={box.icon} colour={box.colour} key={idx} />
                </Grid>
              );
            })}
          </Grid>
          <Grid item xs={12}>
            <TenantTable tenants={tenants} isLoading={isLoading} />
          </Grid>
        </Grid>
      </StyledBox>
    </StyledDashboardContainer>
  );
};

export default DatasourceConfiguration;
