type ColourShades = {
  50: string;
  100: string;
  200: string;
  300: string;
  400: string;
  500: string;
  600: string;
  700: string;
  800: string;
  900: string;
};

type UppColourPalette = {
  lightGreen: ColourShades; // DBS Green-01
  lime: ColourShades; // DBS Green-02
  darkGreen: ColourShades; // DBS Green-03
  coolGrey: ColourShades; // DBS Off White
  griege: ColourShades; // DBS Greige
  grey: ColourShades; // DBS Grey
  navy: ColourShades; // DBS Navy
  purple: ColourShades; // DBS Purple
  coral: ColourShades; // DBS Coral
};

export const uppColourPalette: UppColourPalette = {
  lightGreen: {
    50: "#eefff3",
    100: "#dcffe6",
    200: "#b9fecd",
    300: "#96feb4",
    400: "#73FD9B",
    500: "#50FD82",
    600: "#40CA68",
    700: "#30984E",
    800: "#206534",
    900: "#10331A",
  },
  lime: {
    50: "#ebfef0",
    100: "#d7fce1",
    200: "#aff9c3",
    300: "#86f6a5",
    400: "#5ef387",
    500: "#36F069",
    600: "#2bc054",
    700: "#20903f",
    800: "#16602a",
    900: "#0b3015",
  },
  darkGreen: {
    50: "#e6f6f1",
    100: "#ccede4",
    200: "#99dcc8",
    300: "#4dc19f",
    400: "#33b991",
    500: "#00A776",
    600: "#00865e",
    700: "#006447",
    800: "#00432f",
    900: "#002118",
  },
  coolGrey: {
    50: "#F6F6F9",
    100: "#dddde0",
    200: "#c5c5c7",
    300: "#acacae",
    400: "#949495",
    500: "#7b7b7d",
    600: "#626264",
    700: "#4a4a4b",
    800: "#313132",
    900: "#191919",
  },
  griege: {
    50: "#fbfbfa",
    100: "#f7f7f5",
    200: "#f0eeeb",
    300: "#e8e6e0",
    400: "#e1ddd6",
    500: "#D9D5CC",
    600: "#aeaaa3",
    700: "#82807a",
    800: "#575552",
    900: "#2b2b29",
  },
  grey: {
    50: "#fafafb",
    100: "#f5f5f7",
    200: "#ecebee",
    300: "#e2e1e6",
    400: "#d9d7dd",
    500: "#CFCDD5",
    600: "#a6a4aa",
    700: "#7c7b80",
    800: "#535255",
    900: "#29292b",
  },
  navy: {
    50: "#ecebee",
    100: "#d9d7dd",
    200: "#b2b0bb",
    300: "#8c8898",
    400: "#656176",
    500: "#3F3954",
    600: "#39334c",
    700: "#322e43",
    800: "#262232",
    900: "#191722",
  },
  purple: {
    50: "#faf8ff",
    100: "#f4f1ff",
    200: "#e9e3fe",
    300: "#ded5fe",
    400: "#d3c7fd",
    500: "#C8B9FD",
    600: "#a094ca",
    700: "#786f98",
    800: "#504a65",
    900: "#282533",
  },
  coral: {
    50: "#fdf2f2",
    100: "#fce6e5",
    200: "#f8cccb",
    300: "#f5b3b1",
    400: "#f19997",
    500: "#EE807D",
    600: "#be6664",
    700: "#8f4d4b",
    800: "#5f3332",
    900: "#301a19",
  },
};
