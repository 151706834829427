import styled from "styled-components";
import { uppColourPalette } from "../util/colours";

const StyledButton = styled.button`
  cursor: pointer;
  background: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  transition: all ease 0.25s;
  color: #ffffff;
  border: none;
  &:hover {
    background: ${uppColourPalette.navy[800]};
  }
  &:disabled {
    cursor: default;
    &:hover {
      background: none;
    }
  }
`;

const FooterButton = ({ children, onClick, disabled }: any) => {
  return (
    <StyledButton disabled={disabled} onClick={onClick}>
      {children}
    </StyledButton>
  );
};

export default FooterButton;
