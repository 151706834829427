import { MenuItem, TextField } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

type Props = {
  name: string;
  data?: any;
  value: any;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const StyledTextField = styled(TextField)`
  .MuiInputBase-root {
    width: 12em;
  }
  .MuiOutlinedInput-input {
    padding: 12px 14px;
  }
`;

export const DropdownConfig = ({ name, data, value, handleChange }: Props) => {
  return (
    <div>
      <StyledTextField variant="outlined" select value={value} onChange={handleChange}>
        {data.map((option: any, index: number) => (
          <MenuItem key={index} value={option.value || option}>
            {option.key || option}
          </MenuItem>
        ))}
      </StyledTextField>
    </div>
  );
};

export default DropdownConfig;
