import { useState, useEffect } from "react";
import { FeatureFlags } from "../util/FeatureFlags";
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import ChartTooltip from "./ChartTooltip";
import { DateTime } from "luxon";

const ResponsiveWrapper = ({ children }: any) => {
  return (
    <div style={{ width: "100%", height: "100%", position: "relative", overflowX: "auto", overflowY: "hidden" }}>
      <div
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          top: 0,
          left: 0,
        }}
      >
        {children}
      </div>
    </div>
  );
};
export enum ChartKeys {
  Spend = "actualSpend",
  TargetSpend = "targetSpend",
  Roas = "roas",
  TargetRoas = "targetRoas",
  ConversionValue = "conversionValue",
  Cpa = "actualCpa",
  TargetCpa = "targetCpa",
}

export const dateFormatter = (epoch: number) => {
  const date = DateTime.fromSeconds(epoch || 0);
  return date.toFormat("MMM d");
};

function determineLargestDataPoint(data: Array<any>, item1: string, item2: string): string {
  const item1Max = arrayMax(data, item1);
  const item2Max = arrayMax(data, item2);
  return item1Max > item2Max ? item1 : item2;
}

export function getDataPointForAxis(data: Array<any>, key: string): string {
  if (key === ChartKeys.ConversionValue) {
    return ChartKeys.ConversionValue;
  }
  let item1 = "";
  let item2 = "";
  if (key === ChartKeys.Cpa) {
    item1 = ChartKeys.Cpa;
    item2 = ChartKeys.TargetCpa;
  } else if (key === ChartKeys.Roas) {
    item1 = ChartKeys.Roas;
    item2 = ChartKeys.TargetRoas;
  } else {
    item1 = ChartKeys.Spend;
    item2 = ChartKeys.TargetSpend;
  }
  return determineLargestDataPoint(data, item1, item2);
}

function arrayMax(arr: any, field: any) {
  return arr.reduce(
    (accumulator: any, item: any) => (accumulator = accumulator > item[field] ? accumulator : item[field]),
    0
  );
}

const Chart = (props: any) => {
  const featureFlags = FeatureFlags();
  const renderCpa = featureFlags.cpa;
  const [valueOneKey, setValueOneKey] = useState<string>(renderCpa ? ChartKeys.Cpa : ChartKeys.Roas);
  const [valueTwoKey, setValueTwoKey] = useState<string>(ChartKeys.Spend);
  const [leftAxis, setLeftAxis] = useState<string>(renderCpa ? ChartKeys.Cpa : ChartKeys.Roas);
  const [rightAxis, setRightAxis] = useState<string>(ChartKeys.Spend);
  const { data } = props;

  useEffect(() => {
    if (data.length) {
      const filters = ["date", "targetRoas", "targetSpend"];

      const filteredDataKeys = Object.keys(data[0]).filter((item: any) => {
        return !filters.includes(item);
      });

      setValueOneKey(filteredDataKeys[0]);
      setValueTwoKey(filteredDataKeys[1]);
      setLeftAxis(getDataPointForAxis(data, valueOneKey));
      setRightAxis(getDataPointForAxis(data, valueTwoKey));
    }
  }, [data, valueOneKey, valueTwoKey]);

  /* Chart Variables Visible */
  const { visibleOne } = props;
  const { visibleTwo } = props;

  const renderRoasTarget = () => {
    if (visibleOne && (valueOneKey || valueTwoKey === ChartKeys.Roas)) {
      return (
        <Area
          yAxisId="left"
          dataKey={ChartKeys.TargetRoas}
          stroke="#7B77EE"
          strokeWidth={1}
          strokeDasharray="5 5"
          fillOpacity={0}
        />
      );
    }
  };

  const renderCpaTarget = () => {
    if (visibleOne && (valueOneKey || valueTwoKey === ChartKeys.Cpa)) {
      return (
        <Area
          yAxisId="left"
          dataKey={ChartKeys.TargetCpa}
          stroke="#7B77EE"
          strokeWidth={1}
          strokeDasharray="5 5"
          fillOpacity={0}
        />
      );
    }
  };

  const renderSpendTarget = () => {
    if (visibleTwo && (valueOneKey || valueTwoKey === ChartKeys.Spend)) {
      return (
        <Area
          yAxisId="right"
          dataKey={ChartKeys.TargetSpend}
          stroke="#1BCBA0"
          strokeWidth={1}
          strokeDasharray="5 5"
          fillOpacity={0}
        />
      );
    }
  };

  const customTooltip = <ChartTooltip keyRender={true} left={visibleOne} right={visibleTwo} />;

  return (
    <ResponsiveWrapper>
      <ResponsiveContainer width="99%">
        <AreaChart
          data={data.map((item: { date: any }) => {
            return { ...item, date: new Date(item.date).getTime() / 1000 };
          })}
          margin={{ top: 20, right: -20, bottom: 30, left: -20 }}
        >
          <defs>
            <linearGradient id="color" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="#7B77EE" stopOpacity={0.4} />
              <stop offset="75%" stopColor="#7B77EE" stopOpacity={0.4} />
            </linearGradient>
            <linearGradient id="color2" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="#1BCBA0" stopOpacity={0.4} />
              <stop offset="75%" stopColor="#1BCBA0" stopOpacity={0.4} />
            </linearGradient>
          </defs>
          <XAxis
            allowDataOverflow={false}
            dataKey="date"
            fontSize={10}
            orientation="bottom"
            axisLine={false}
            tickLine={false}
            tickFormatter={dateFormatter}
            interval={0}
            scale="time"
            type="number"
            domain={["dataMin", "dataMax"]}
            tick={CustomTick}
          />
          <YAxis yAxisId="left" dataKey={leftAxis} fontSize={10} axisLine={false} tickLine={false} tickCount={8} />
          <YAxis
            yAxisId="right"
            orientation="right"
            dataKey={rightAxis}
            fontSize={10}
            axisLine={false}
            tickLine={false}
            tickCount={8}
          />

          {visibleOne && <Area yAxisId="left" dataKey={valueOneKey} stroke="#7B77EE" fill="url(#color)" />}

          {/* ROAS/CPA Target Line */}

          {renderCpa ? renderCpaTarget() : renderRoasTarget()}

          {visibleTwo && <Area yAxisId="right" dataKey={valueTwoKey} stroke="#1BCBA0" fill="url(#color2)" />}

          {/* Spend Target Line */}

          {renderSpendTarget()}

          <Tooltip content={customTooltip} />
          <CartesianGrid opacity={0.2} horizontal={false} />
        </AreaChart>
      </ResponsiveContainer>
    </ResponsiveWrapper>
  );
};

export const CustomTick = ({ x, y, payload }: any) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text fontSize={10} x={-4} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-90)">
        {dateFormatter(payload.value)}
      </text>
    </g>
  );
};

export default Chart;
