import { useState } from "react";
import styled from "styled-components";
import Tippy from "@tippyjs/react";
import CircularProgress from "@mui/material/CircularProgress";
import { uppColourPalette } from "../../../util/colours";
import { VisibilityOutlined } from "@material-ui/icons";
import Alert from "@mui/material/Alert";
import { cognismMigrationToHubspot } from "../../../api/marketing";
import { IntegratorResponse } from "../models/IntegratorResponse";

const breakpoints = {
  mobile: "480px",
  tablet: "768px",
  desktop: "1024px",
};

const Container = styled.div`
  width: 500px;
  margin: 50px auto;

  @media (max-width: ${breakpoints.mobile}) {
    margin: 40px auto;
  }
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: white;
  font-size: 16px;

  @media (max-width: ${breakpoints.mobile}) {
    margin-bottom: 5px;
  }
`;

const SubmitButton = styled.button`
  width: 200px;
  height: 40px;
  border-radius: 4px;
  padding: 8px 24px;
  background: ${uppColourPalette.navy[500]};
  color: white;
  margin-bottom: 20px;
  &:hover {
    cursor: pointer;
    filter: drop-shadow(0 0 2px ${uppColourPalette.navy[500]});
  }

  &:disabled {
    background: ${uppColourPalette.coolGrey[100]};
    pointer-events: none;
  }
`;

const SmallStyledButton = styled(SubmitButton)`
  width: 40%;
  margin: 0 auto;
  margin: 10px 0;

  @media (max-width: ${breakpoints.mobile}) {
    width: 100%;
  }
`;

const AboutButton = styled.button`
  position: fixed;
  right: 20px;
  bottom: 20px;
  background-color: #3f3954;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  padding: 10px 15px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  right: 10px;
  top: 5px;
  border: none;
  font-size: 20px;
  cursor: pointer;
`;
const TooltipContentStyled = styled.div`
  background-color: black;
  color: white;
  width: 300px;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 5px 10px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 10px 10px 0 10px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }
`;
export const WhatPill = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
  width: fit-content;
  padding: 4px 8px;
  border: 1px solid ${uppColourPalette.navy[500]};
  border-radius: 24px;
`;

function MigrationForm() {
  const [tech, setTech] = useState("");
  const [revenueFrom, setRevenueFrom] = useState("");
  const [revenueTo, setRevenueTo] = useState("");
  const [country, setCountry] = useState("");
  const [limit, setLimit] = useState("");
  const [status, setStatus] = useState("");
  const [migratedCount, setMigratedCount] = useState({} as IntegratorResponse);
  const [isRevenueFromInvalid, setIsRevenueFromInvalid] = useState(false);
  const [isRevenueToInvalid, setIsRevenueToInvalid] = useState(false);
  const [isTechEmpty, setIsTechEmpty] = useState(false);
  const [errorToManyRequests] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    if (!tech) {
      return setIsTechEmpty(true);
    } else {
      setIsTechEmpty(false);
    }

    try {
      const queryParams = new URLSearchParams({
        tech,
        revenueFrom,
        revenueTo,
        country,
        limit,
      });

      setIsLoading(true);

      const response = await cognismMigrationToHubspot(queryParams);

      setIsLoading(false);

      setMigratedCount(response?.integratorResponse || ({} as IntegratorResponse));

      setStatus("COMPLETE");
    } catch (error: any) {
      setIsLoading(false);
      console.error("Error migrating companies:", error);
      setStatus("ERROR");
    }
  };

  const Loading = () => <CircularProgress style={{ alignSelf: "center" }} />;

  const Submit = () => {
    return (
      <SubmitButton style={{ alignSelf: "center" }} onClick={handleSubmit}>
        Submit
      </SubmitButton>
    );
  };

  const renderAlerts = () => {
    if (status === "COMPLETE") {
      if (migratedCount.error === undefined) {
        return (
          <>
            <Alert severity="success">
              <b>Companies created:</b>
              {migratedCount.numCompaniesCreated}.
            </Alert>
            <Alert severity="success">
              <b>Companies updated:</b> {migratedCount.numCompaniesUpdated}.
            </Alert>
            <Alert severity="success">
              <b>Contacts created: </b>
              {migratedCount.numContactsCreated}.
            </Alert>
            <Alert severity="success">
              <b>Contacts updated:</b> {migratedCount.numContactsUpdated}.
            </Alert>
            <p>Check your HubSpot dashboard:</p>
            <a
              href={window.__RUNTIME_CONFIG__.REACT_APP_HUBSPOT_DASHBOARD_URL}
              target="_blank"
              rel="noopener noreferrer"
            >
              <SmallStyledButton>Go to HubSpot</SmallStyledButton>
            </a>
          </>
        );
      } else {
        return <Alert severity="info">You have no new companies to be created!</Alert>;
      }
    } else if (status === "ERROR" && !errorToManyRequests) {
      return <Alert severity="error">Internal Server Error</Alert>;
    } else if (errorToManyRequests) {
      return (
        <Alert severity="error">You reached your rate limit on Cognism. Please contact Cognism help center.</Alert>
      );
    }
    return null;
  };

  return (
    <Container style={{ display: "flex", flexDirection: "column" }}>
      <h1>Cognism Companies Migration</h1>

      <div
        style={{
          top: "50px",
          display: "flex",
          justifyContent: "flex-end",
          gap: "8px",
        }}
      >
        <Tippy
          content={
            <TooltipContentStyled>
              In this field, you need to enter the technologies, example: Magento, Shopify...
            </TooltipContentStyled>
          }
        >
          <WhatPill>
            What&apos;s This?
            <VisibilityOutlined fontSize="small" />
          </WhatPill>
        </Tippy>
      </div>
      <StyledInput
        value={tech}
        onChange={(e) => {
          setIsTechEmpty(e.target.value.trim() === "");
          setTech(e.target.value);
        }}
        placeholder="Tech* eg. Magento"
      />

      {isTechEmpty && <Alert severity="error">Please enter with a technology.</Alert>}

      <StyledInput
        value={revenueFrom}
        onChange={(e) => {
          const containsNonNumber = /[^\d]/.test(e.target.value);
          setIsRevenueFromInvalid(containsNonNumber);
          if (!containsNonNumber) {
            setRevenueFrom(e.target.value);
          }
        }}
        placeholder="Minimum Annual Company Revenue*"
      />

      {isRevenueFromInvalid && <Alert severity="error">Please enter only numbers in the Revenue From field.</Alert>}

      <StyledInput
        value={revenueTo}
        onChange={(e) => {
          const containsNonNumber = /[^\d]/.test(e.target.value);
          setIsRevenueToInvalid(containsNonNumber);
          if (!containsNonNumber) {
            setRevenueTo(e.target.value);
          }
        }}
        placeholder="Maximum Annual Company Revenue*"
      />

      {isRevenueToInvalid && <Alert severity="error">Please enter only numbers in the Revenue To field.</Alert>}

      <StyledInput value={country} onChange={(e) => setCountry(e.target.value)} placeholder="Country(optional)" />
      <StyledInput
        value={limit}
        onChange={(e) => setLimit(e.target.value)}
        placeholder="How many companies do you want?"
      />
      <Tippy
        content={
          <>
            <CloseButton onClick={() => setIsPopupOpen(false)}>x</CloseButton>
            <h2>Cognism Migration Tool</h2>
            <p>
              This tool lets you migrate company data based on specific criteria like technology, revenue, and country.
              After submission, results are processed and integrated into your HubSpot system.
            </p>
          </>
        }
        visible={isPopupOpen}
        onClickOutside={() => setIsPopupOpen(false)}
      >
        <AboutButton onClick={() => setIsPopupOpen(true)}>About Migration</AboutButton>
      </Tippy>
      {isLoading ? <Loading /> : <Submit />}

      {renderAlerts()}
    </Container>
  );
}

export default MigrationForm;
