import { Grid, InputLabel, TextField, Typography } from "@material-ui/core";
import { ChangeEvent } from "react";
import SwitchConfig from "../../tenantConfig/SwitchConfig";
import { StyledInputLabel } from "../AddJobDialog";
import { AuditConfiguration, CommonPayload, DownloadWorkflowToRun } from "@volo-ai/upp-temporal-utils";

export interface WorkflowProps {
  handleToggle: (prop: any) => (e: ChangeEvent<HTMLInputElement>) => void;
  jobPayload: CommonPayload;
  handleDataChange: (prop: any) => (e: ChangeEvent<HTMLInputElement>) => void;
  handleSubmit?: React.MouseEventHandler<HTMLButtonElement>;
}

export const Workflow = ({ handleToggle, jobPayload, handleDataChange }: WorkflowProps) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <InputLabel>Campaign</InputLabel>
        <SwitchConfig
          onChange={handleToggle("workflowToRun.campaign")}
          checked={(jobPayload.workflowToRun as DownloadWorkflowToRun)?.campaign}
        ></SwitchConfig>
        <InputLabel>Reconciliation</InputLabel>
        <SwitchConfig
          onChange={handleToggle("workflowToRun.reconciliation")}
          checked={(jobPayload.workflowToRun as DownloadWorkflowToRun)?.reconciliation}
        ></SwitchConfig>
        <InputLabel>Automated Mapping</InputLabel>
        <SwitchConfig
          onChange={handleToggle("workflowToRun.automatedMapping")}
          checked={(jobPayload.workflowToRun as DownloadWorkflowToRun)?.automatedMapping}
        ></SwitchConfig>
        <InputLabel>Compute Audit</InputLabel>
        <SwitchConfig
          onChange={handleToggle("workflowToRun.audit")}
          checked={(jobPayload.workflowToRun as DownloadWorkflowToRun)?.audit}
        ></SwitchConfig>
        {(jobPayload.workflowToRun as DownloadWorkflowToRun)?.audit === true && (
          <>
            <StyledInputLabel>Date From (Optional)</StyledInputLabel>
            <TextField
              type="date"
              variant="outlined"
              onChange={handleDataChange("configuration.audit.from")}
            ></TextField>
            <StyledInputLabel>Date To (Optional)</StyledInputLabel>
            <TextField type="date" variant="outlined" onChange={handleDataChange("configuration.audit.to")}></TextField>
            <InputLabel>BySku</InputLabel>
            <SwitchConfig
              onChange={handleToggle("configuration.audit.bySku")}
              checked={(jobPayload.configuration as AuditConfiguration)?.bySku}
            ></SwitchConfig>
          </>
        )}
        <InputLabel>Product Flagger</InputLabel>
        <SwitchConfig
          onChange={handleToggle("workflowToRun.productFlagger")}
          checked={(jobPayload.workflowToRun as DownloadWorkflowToRun)?.productFlagger}
        ></SwitchConfig>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h5">Performance:</Typography>
        <InputLabel>Account</InputLabel>
        <SwitchConfig
          onChange={handleToggle("workflowToRun.performance.account")}
          checked={(jobPayload.workflowToRun as DownloadWorkflowToRun)?.performance?.account}
        ></SwitchConfig>
        {(jobPayload.workflowToRun as DownloadWorkflowToRun)?.performance?.account && (
          <>
            <StyledInputLabel>Date From (Optional)</StyledInputLabel>
            <TextField
              type="date"
              variant="outlined"
              onChange={handleDataChange("configuration.performance.account.from")}
            ></TextField>
            <StyledInputLabel>Date To (Optional)</StyledInputLabel>
            <TextField
              type="date"
              variant="outlined"
              onChange={handleDataChange("configuration.performance.account.to")}
            ></TextField>
          </>
        )}
        <InputLabel style={{ paddingTop: "10px" }}>SKU</InputLabel>
        <SwitchConfig
          onChange={handleToggle("workflowToRun.performance.product")}
          checked={(jobPayload.workflowToRun as DownloadWorkflowToRun)?.performance?.product}
        ></SwitchConfig>
        {(jobPayload.workflowToRun as DownloadWorkflowToRun)?.performance?.product && (
          <>
            <StyledInputLabel>Date From (Optional)</StyledInputLabel>
            <TextField
              type="date"
              variant="outlined"
              onChange={handleDataChange("configuration.performance.product.from")}
            ></TextField>
            <StyledInputLabel>Date To (Optional)</StyledInputLabel>
            <TextField
              type="date"
              variant="outlined"
              onChange={handleDataChange("configuration.performance.product.to")}
            ></TextField>
            <StyledInputLabel>Batch Size in Days</StyledInputLabel>
            <TextField
              type="number"
              variant="outlined"
              onChange={handleDataChange("configuration.performance.product.batchSize")}
            ></TextField>
          </>
        )}
        <InputLabel style={{ paddingTop: "10px" }}>Campaign</InputLabel>
        <SwitchConfig
          onChange={handleToggle("workflowToRun.performance.campaign")}
          checked={(jobPayload.workflowToRun as DownloadWorkflowToRun)?.performance?.campaign}
        ></SwitchConfig>
        {(jobPayload.workflowToRun as DownloadWorkflowToRun)?.performance?.campaign === true && (
          <>
            <StyledInputLabel>Date From (Optional)</StyledInputLabel>
            <TextField
              type="date"
              variant="outlined"
              onChange={handleDataChange("configuration.performance.campaign.from")}
            ></TextField>
            <StyledInputLabel>Date To (Optional)</StyledInputLabel>
            <TextField
              type="date"
              variant="outlined"
              onChange={handleDataChange("configuration.performance.campaign.to")}
            ></TextField>
          </>
        )}
        <InputLabel style={{ paddingTop: "10px" }}>Category</InputLabel>
        <SwitchConfig
          onChange={handleToggle("workflowToRun.performance.category")}
          checked={(jobPayload.workflowToRun as DownloadWorkflowToRun)?.performance?.category}
        ></SwitchConfig>
        {(jobPayload.workflowToRun as DownloadWorkflowToRun)?.performance?.category === true && (
          <>
            <StyledInputLabel>Date From (Optional)</StyledInputLabel>
            <TextField
              type="date"
              variant="outlined"
              onChange={handleDataChange("configuration.performance.category.from")}
            ></TextField>
            <StyledInputLabel>Date To (Optional)</StyledInputLabel>
            <TextField
              type="date"
              variant="outlined"
              onChange={handleDataChange("configuration.performance.category.to")}
            ></TextField>
          </>
        )}
        <InputLabel style={{ paddingTop: "10px" }}>Category Sku Mapping</InputLabel>
        <SwitchConfig
          onChange={handleToggle("workflowToRun.performance.categorySkuMapping")}
          checked={(jobPayload.workflowToRun as DownloadWorkflowToRun)?.performance?.categorySkuMapping}
        ></SwitchConfig>
        {(jobPayload.workflowToRun as DownloadWorkflowToRun)?.performance?.categorySkuMapping === true && (
          <>
            <StyledInputLabel>Date From (Optional)</StyledInputLabel>
            <TextField
              type="date"
              variant="outlined"
              onChange={handleDataChange("configuration.performance.categorySkuMapping.from")}
            ></TextField>
            <StyledInputLabel>Date To (Optional)</StyledInputLabel>
            <TextField
              type="date"
              variant="outlined"
              onChange={handleDataChange("configuration.performance.categorySkuMapping.to")}
            ></TextField>
          </>
        )}
      </Grid>
    </Grid>
  );
};
