import styled from "@emotion/styled";
import { Box, Divider } from "@material-ui/core";
import { Heading } from "../../components/Heading";
import { useGoogleAdsDirectAudit } from "../../hooks/useAuditHealth";
import { CampaignItem } from "../campaign/CampaignItem";

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin: 1em;
`;

const AuditCampaigns = () => {
  const tenantId = window.USER_INFO.tenantId;
  let { data, error, isLoading } = useGoogleAdsDirectAudit(tenantId);

  return (
    <Box
      sx={{
        bgcolor: "white",
        padding: "2em",
        margin: "2em",
        borderRadius: "0.5%",
        border: "1px solid",
        borderColor: "#dbdbdb",
      }}
    >
      <Heading>Audit Campaigns</Heading>
      <Divider />
      {isLoading && <div>Loading...</div>}
      <CardContainer>
        {data?.campaigns?.map((campaign: any) => (
          <CampaignItem
            key={campaign.id}
            name={campaign.name}
            value={{
              budget: campaign.budget,
              targetRoas: campaign.targetRoas,
              campaignId: campaign.campaignId,
            }}
          />
        ))}
      </CardContainer>
      {error && <div>Error: Unable to get campaigns ({error.message})</div>}
    </Box>
  );
};

export default AuditCampaigns;
