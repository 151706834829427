import {
  Box,
  Modal,
  styled as MuiStyled,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import React, { useState } from "react";
import { useChatHistory } from "../../hooks/useChatHistory";
import { StyledDashboardContainer } from "../dashboard/Dashboard";
import { ModalContent } from "./ChatModal";

const StyledTableCell = MuiStyled(TableCell)(({ theme }) => ({
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
  maxWidth: "150px",
}));

const StyledHeaderCell = MuiStyled(TableCell)(({ theme }) => ({
  fontWeight: "bold",
  letterSpacing: "0.5px",
}));

const StyledTableRow = MuiStyled(TableRow)({
  cursor: "pointer",
});

export interface Message {
  id?: string;
  createdAt?: Date;
  content: string;
  role: "user" | "assistant";
  feedback?: string;
}

const ChatbotHistory = () => {
  const [open, setOpen] = useState(false);
  const [selectedMessages, setSelectedMessages] = useState<Message[]>([]);
  const { data, isSuccess } = useChatHistory(window.TENANT_ID);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleRowClick = (messages: Message[]) => {
    setSelectedMessages(messages);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows
  // The table will always have the same height, independent of the number of rows
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, (data?.length || 0) - page * rowsPerPage);

  const sortedData =
    isSuccess && data
      ? [...data].sort((a, b) => {
          const dateA = a.createdAt ? new Date(a.createdAt).getTime() : 0;
          const dateB = b.createdAt ? new Date(b.createdAt).getTime() : 0;
          return dateB - dateA;
        })
      : [];

  return (
    <StyledDashboardContainer>
      <Box
        sx={{
          bgcolor: "white",
          padding: "2em",
          margin: "2em",
          borderRadius: "0.5%",
          border: "1px solid",
          borderColor: "#dbdbdb",
        }}
      >
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledHeaderCell>Date</StyledHeaderCell>
                <StyledHeaderCell>User Email</StyledHeaderCell>
                <StyledHeaderCell>Content</StyledHeaderCell>
                <StyledHeaderCell>Message Count</StyledHeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isSuccess &&
                sortedData.length > 0 &&
                (rowsPerPage > 0
                  ? sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  : sortedData
                ).map((element, index) => (
                  <StyledTableRow key={index} hover onClick={() => handleRowClick(element.messages)}>
                    <StyledTableCell>{new Date(element.createdAt).toLocaleDateString()}</StyledTableCell>
                    <StyledTableCell>{element.userEmail}</StyledTableCell>
                    <StyledTableCell title={element.messages[0]?.content || "No content"}>
                      {element.messages[0]?.content || "No content"}
                    </StyledTableCell>
                    <StyledTableCell>{element.messages.length}</StyledTableCell>
                  </StyledTableRow>
                ))}
              {isSuccess && sortedData.length === 0 && (
                <TableRow style={{ height: 53 }}>
                  <StyledTableCell colSpan={4} align="center">
                    There are no conversations from this user.
                  </StyledTableCell>
                </TableRow>
              )}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <StyledTableCell colSpan={4} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={100}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <Modal open={open} onClose={handleClose}>
          <ModalContent messages={selectedMessages} handleClose={handleClose} />
        </Modal>
      </Box>
    </StyledDashboardContainer>
  );
};

export default ChatbotHistory;
