import { Box, Divider } from "@material-ui/core";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { clearCollections, CollectionStatsResponse, getCollectionStats } from "../../api/platformadmin";
import { StyledDashboardContainer } from "../dashboard/Dashboard";
import { StyledButton } from "../datasources/Datasources";
import { ConfirmationDialog } from "./ConfirmationDialog";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

export const GridWrapper = styled.div`
  height: 700px;
`;

const columnDefs: GridColDef[] = [
  {
    headerName: "Context",
    field: "context",
    width: 150,
  },
  { headerName: "Service", field: "service", width: 200 },
  { headerName: "Name", field: "name", width: 200 },
  { headerName: "Count", field: "count", width: 100 },
];

const Heading = styled.div`
  display: flex;
  align-items: center;
  margin: 0 -16px 10px -16px;
  font-size: 2em;
  padding: 0 10px 10px 10px;
  button {
    margin-left: auto;
  }
`;

export function DataManagement() {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedCollections, setSelectedCollections] = useState<any[]>([]);
  const [collections, setCollections] = useState<CollectionStatsResponse>([]);

  useEffect(() => {
    async function fetchCollections() {
      const collections = await getCollectionStats();
      setCollections(collections);
    }
    fetchCollections();
  }, []);

  const openDialog = () => setIsDialogOpen(true);
  const closeDialog = () => setIsDialogOpen(false);

  async function deleteFromCollections() {
    await clearCollections(selectedCollections);
    setCollections(collections?.filter((d) => selectedCollections.includes(d.name) === false));
    setSelectedCollections([]);
    closeDialog();
  }

  return (
    <>
      <StyledDashboardContainer>
        <Box
          sx={{
            bgcolor: "white",
            padding: "2em",
            margin: "2em",
            borderRadius: "0.5%",
            border: "1px solid",
            borderColor: "#dbdbdb",
          }}
        >
          <Heading>
            Data Management
            <StyledButton
              variant="outlined"
              disabled={selectedCollections.length === 0} // collections.loading ||
              onClick={openDialog}
            >
              Delete Data
            </StyledButton>
          </Heading>
          <Divider />

          <GridWrapper>
            <DataGrid
              rows={collections}
              getRowId={(collection) => collection.name}
              columns={columnDefs}
              checkboxSelection
              disableSelectionOnClick
              onSelectionModelChange={(data) => setSelectedCollections(data)}
            />
          </GridWrapper>
        </Box>
      </StyledDashboardContainer>
      <ConfirmationDialog
        open={isDialogOpen}
        totalRecords={
          collections
            ?.filter((d) => selectedCollections.includes(d.name))
            .reduce((acc: number, cur) => acc + cur.count, 0) ?? 0
        }
        totalCollections={selectedCollections.length}
        onClose={closeDialog}
        onConfirm={deleteFromCollections}
        disableButtons={false}
      />
    </>
  );
}

export default DataManagement;
