import { Box, Divider, InputLabel } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import styled from "styled-components";
import { getUppManagedCampaigns } from "../../api/campaign";
import {
  ShadowCampaign,
  getMostRecentCampaignAutomation,
  triggerCampaignAutomation,
} from "../../api/campaignAutomation";
import { ManagedCampaign } from "../../api/datasources";
import { TenantConfig, getTenantConfig, updateTenantConfig } from "../../api/tenant";
import { Heading } from "../../components/Heading";
import { InnerContainer } from "../../components/InnerContainer";
import { StyledButtonFilled } from "../../components/StyledButton";
import { isBackOfficeError } from "../../types/errors";
import { StyledDashboardContainer } from "../dashboard/Dashboard";
import SwitchConfig from "../tenantConfig/SwitchConfig";
import { CampaignChecker } from "./CampaignChecker";
import { MPAPerformance } from "./mpa/MPAPerformance";
import { MPAProductsFieldsMapping } from "./mpa/MPAProductsFieldsMapping";
import { MPASettings } from "./mpa/MPASettings";
import { NumberOfCampaigns } from "./NumberOfCampaigns";
import { OptimalNumberOfClusters } from "./OptimalNumberOfClusters";
import { MPAHeathIndicators } from "./mpa/MPAHeathIndicators";
import { MPAProductsPerformance } from "./mpa/MPAProductsPerformance";

export const StyledUnorderedList = styled.ul`
  text-align: start;
`;
export const StyledChart = styled(Chart)`
  margin: 1em;
`;

export const StyledRow = styled.div`
  display: inline-flex;
  width: 100%;
  margin-bottom: 10px;
  .MuiFormLabel-root {
    margin-top: 10px !important;
    margin: 5px;
  }
`;

export const CampaignAutomation = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [managedCampaigns, setManagedCampaigns] = useState<ManagedCampaign[]>([]);

  const [isDryRun, setDryRun] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [estimatedRoas, setEstimatedRoas] = useState<number>();
  const [totalBudget, setTotalBudget] = useState<number>();
  const [targetSpend, setTargetSpend] = useState<number>();
  const [targetROAS, setTargetROAS] = useState<number>();

  const [tenantId, setTenantId] = useState<string>("");
  const [tenantConfig, setTenantConfig] = useState<TenantConfig>({});

  const [isMPAEnabled, setIsMPAEnabled] = useState<boolean>(tenantConfig.intakeMargin_enabled as boolean);
  const [sankeyData, setSankeyData] = useState<Array<Array<string | number>>>();

  // MPA
  const [loadingUpdateIntakeMarginEnabled, setLoadingUpdateIntakeMarginEnabled] = useState<boolean>(false);

  const updateMPAEnabled = async (value: boolean) => {
    if (value !== undefined || value !== null) {
      setLoadingUpdateIntakeMarginEnabled(true);
      await updateTenantConfig(tenantId, [{ key: "intakeMargin_enabled", value: value }]);
      setIsMPAEnabled(value);
      setLoadingUpdateIntakeMarginEnabled(false);
    }
  };
  // MPA

  const triggerModelUpdate = async () => {
    setIsLoading(true);
    const triggerUpdate = await triggerCampaignAutomation(isDryRun);
    setIsLoading(false);

    enqueueSnackbar(triggerUpdate.message, {
      variant: triggerUpdate.success ? "success" : "error",
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
    });

    if (triggerUpdate.success && triggerUpdate.estimatedROAS) {
      setEstimatedRoas(triggerUpdate.estimatedROAS!);
      setTotalBudget(triggerUpdate.totalBudget!);
      setTargetSpend(triggerUpdate.targetSpend!);
      setTargetROAS(triggerUpdate.targetROAS!);
    }
  };

  async function getManagedCampaigns() {
    const managedCampaigns = await getUppManagedCampaigns();
    setManagedCampaigns(managedCampaigns);
  }

  useEffect(() => {
    setTenantId(window?.USER_INFO?.tenantId);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (tenantId) {
        const config = await getTenantConfig(tenantId);
        setTenantConfig(config);
        await buildPlot(enqueueSnackbar, setSankeyData);
        await getManagedCampaigns();
      }
    };
    fetchData();
  }, [enqueueSnackbar, tenantId]);

  useEffect(() => {
    setIsMPAEnabled(tenantConfig.intakeMargin_enabled as boolean);
  }, [tenantConfig]);

  return (
    <StyledDashboardContainer>
      <Box
        sx={{
          bgcolor: "white",
          padding: "2em",
          margin: "2em",
          borderRadius: "0.5%",
          border: "1px solid",
          borderColor: "#dbdbdb",
        }}
      >
        <Heading>Campaign Automation</Heading>

        <NumberOfCampaigns tenantConfig={tenantConfig} managedCampaigns={managedCampaigns} />

        <Divider />
        {sankeyData && (
          <StyledChart chartType="Sankey" data={[...sankeyData]} width="80%" height="300px" legendToggle />
        )}

        <InnerContainer>
          {(!!estimatedRoas || !!totalBudget) && (
            <StyledRow>
              <StyledUnorderedList>
                <li>
                  <strong>Estimated Roas:</strong> {estimatedRoas}
                </li>
                <li>
                  <strong>Total Budget:</strong> {totalBudget}
                </li>
                <li>Target Spend: {targetSpend}</li>
                <li>Target ROAS: {targetROAS}</li>
              </StyledUnorderedList>
            </StyledRow>
          )}
          <StyledRow>
            <SwitchConfig onChange={() => setDryRun(!isDryRun)} checked={isDryRun} />
            <InputLabel>Dry Run? (It won't update google campaigns)</InputLabel>
          </StyledRow>

          <StyledButtonFilled disabled={isLoading} onClick={() => triggerModelUpdate()}>
            Trigger Campaign Automation
          </StyledButtonFilled>
        </InnerContainer>
      </Box>

      <CampaignChecker />

      <OptimalNumberOfClusters />

      <MPASettings
        tenantConfig={tenantConfig as any}
        intakeMarginEnabled={isMPAEnabled}
        loadingUpdateIntakeMarginEnabled={loadingUpdateIntakeMarginEnabled}
        updateMPAEnabled={updateMPAEnabled}
      />
      <MPAHeathIndicators />
      {isMPAEnabled && <MPAProductsPerformance />}
      {isMPAEnabled && <MPAPerformance />}
      <MPAProductsFieldsMapping />
    </StyledDashboardContainer>
  );
};

export default CampaignAutomation;

async function buildPlot(enqueueSnackbar: (message: string, body: any) => void, setSankeyData: (body: any) => void) {
  const result = await getMostRecentCampaignAutomation();
  if (isBackOfficeError(result)) {
    enqueueSnackbar(result.message, {
      variant: "warning",
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
    });
  } else {
    setSankeyData(buildSankeyData(result.campaigns ?? []));
  }
}

function buildSankeyData(proposedCampaigns: Array<ShadowCampaign>): Array<Array<string | number>> {
  let sankeyData: Array<Array<string | number>> = [["From", "To", "Budget", "Target Roas"]];

  const weighting = proposedCampaigns.reduce(
    (agg, cur) => {
      if (cur.campaignName.startsWith("kmeans")) {
        agg.roas = agg.roas + cur.budget;
      } else {
        agg.visibility = agg.visibility + cur.budget;
      }
      return agg;
    },
    { visibility: 0, roas: 0 }
  );

  sankeyData.push(["AllProducts", "Visibility", weighting.visibility, 0]);

  sankeyData.push(["AllProducts", "ROAS", weighting.roas, 0]);

  sankeyData.push(
    ...proposedCampaigns
      .reverse()
      .map((plan) => [
        `${plan.campaignName.startsWith("kmeans") ? "ROAS" : "Visibility"}`,
        `${plan.campaignName} (${plan.exclusionList ? "Everything - " : ""}${plan.itemIds?.length} Products) `,
        plan.budget,
        plan.targetRoas ? plan.targetRoas : 0,
      ])
  );

  // const example = [
  //   ["From", "To", "Weight"],
  //   ["AllProducts", "Visibility", 3],
  //   ["AllProducts", "ROAS", 9],
  //   ["ROAS", "Cluster 1 (5 Products)", 3],
  //   ["ROAS", "Cluster 2 (10 Products)", 3],
  //   ["ROAS", "Cluster 3 (50 Products)", 3],
  //   ["Visibility", "NoConvNoOrder (5000 Products)", 2],
  //   ["Visibility", "OrderNoConv (400 Products)", 1],
  // ];

  return sankeyData;
}
