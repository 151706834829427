import { IconButton, Tooltip } from "@material-ui/core";
import { QuestionMarkOutlined } from "@mui/icons-material";

interface MarginModellingTooltipInterface {
  text: string;
}

export const MarginModellingTooltip = ({ text }: MarginModellingTooltipInterface) => {
  return (
    <Tooltip title={<h2 style={{ color: "lightblue" }}>{text}</h2>}>
      <IconButton>
        <QuestionMarkOutlined />
      </IconButton>
    </Tooltip>
  );
};
