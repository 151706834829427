import { http } from "../UppAuth";

export async function getAccountLevelCampaignStats(from: Date, to: Date): Promise<Array<CampaignStatsAccount>> {
  const result = await http.get<Array<CampaignStatsAccount>>(
    `${
      window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL
    }/campaignstats/account?fromDate=${from.toISOString()}&toDate=${to.toISOString()}`
  );

  if (result.status !== 200) {
    throw new Error("Unable to fetch account level stats.");
  }

  return result.data;
}

export interface CampaignStatsAccount {
  tenantId: string;
  date: Date;
  clicks: number;
  conversions: number;
  impressions: number;
  cost: number;
  conversionValue: number;
  pmaxSplit?: PmaxSplit;
}

export interface PmaxSplitItem {
  cost: number;
  percentage: number;
  conversionValue?: number;
  roas?: number;
}

export interface PmaxSplit {
  video: PmaxSplitItem;
  search: PmaxSplitItem;
  shopping: PmaxSplitItem;
}
