import Box from "@mui/material/Box";
import { DataGridPro, GridColDef, GridToolbarContainer } from "@mui/x-data-grid-pro";
import { AccountState } from "../../api/autopilotHelper";

const columns: GridColDef[] = [
  { field: "spend", headerName: "Spend/ROAS", width: 200 },
  {
    field: "belowTargetRoas",
    headerName: "Below Target Roas",
    width: 200,
  },
  { field: "onTargetRoas", headerName: "On Target Roas", width: 200 },
  { field: "aboveTargetRoas", headerName: "Above Target Roas", width: 200 },
];

interface AccountStateGridInterface {
  currentAccountState?: AccountState;
  previousAccountState?: AccountState;
}

interface AccountStateGridRow {
  id: string;
  spend: string;
  belowTargetRoas: string;
  onTargetRoas: string;
  aboveTargetRoas: string;
}

export const AccountStateDictionary: { [key in AccountState]: string } = {
  [AccountState.ONE]: "1",
  [AccountState.TWO]: "2",
  [AccountState.THREE]: "3",
  [AccountState.FOUR]: "4",
  [AccountState.FIVE]: "5",
  [AccountState.SIX]: "6",
  [AccountState.SEVEN]: "7",
  [AccountState.EIGHT]: "8",
  [AccountState.NINE]: "9",
};

const getAccountStateGridCellValue = (
  state: AccountState,
  currentAccountState?: AccountState,
  previousAccountState?: AccountState
) => {
  if (state === currentAccountState) {
    return `${AccountStateDictionary[state]} - Current`;
  }
  if (state === previousAccountState) {
    return `${AccountStateDictionary[state]} - Previous`;
  }
  return AccountStateDictionary[state];
};

export const AccountStateGrid = ({ currentAccountState, previousAccountState }: AccountStateGridInterface) => {
  const accountState: AccountStateGridRow[] = [
    {
      id: "64610e2f4526f850e0a4e878",
      spend: "Above spend",
      belowTargetRoas: getAccountStateGridCellValue(AccountState.SEVEN, currentAccountState, previousAccountState),
      onTargetRoas: getAccountStateGridCellValue(AccountState.EIGHT, currentAccountState, previousAccountState),
      aboveTargetRoas: getAccountStateGridCellValue(AccountState.NINE, currentAccountState, previousAccountState),
    },
    {
      id: "645f52ba4526f8290ca4e86a",
      spend: "On spend",
      belowTargetRoas: getAccountStateGridCellValue(AccountState.FOUR, currentAccountState, previousAccountState),
      onTargetRoas: getAccountStateGridCellValue(AccountState.FIVE, currentAccountState, previousAccountState),
      aboveTargetRoas: getAccountStateGridCellValue(AccountState.SIX, currentAccountState, previousAccountState),
    },
    {
      id: "645e6b2f4526f85aa8a4e859",
      spend: "Below spend",
      belowTargetRoas: getAccountStateGridCellValue(AccountState.ONE, currentAccountState, previousAccountState),
      onTargetRoas: getAccountStateGridCellValue(AccountState.TWO, currentAccountState, previousAccountState),
      aboveTargetRoas: getAccountStateGridCellValue(AccountState.THREE, currentAccountState, previousAccountState),
    },
  ];

  const GridContent = () => {
    const CustomToolbar = () => {
      return <GridToolbarContainer />;
    };

    return (
      <DataGridPro
        rows={accountState}
        columns={columns}
        autoHeight
        hideFooter={true}
        slots={{
          toolbar: CustomToolbar,
        }}
        getCellClassName={(params) => {
          if (
            (params.value as string).match(`${AccountStateDictionary[AccountState.FIVE]}`) ||
            (params.value as string).match(`${AccountStateDictionary[AccountState.SIX]}`) ||
            (params.value as string).match(`${AccountStateDictionary[AccountState.EIGHT]}`) ||
            (params.value as string).match(`${AccountStateDictionary[AccountState.NINE]}`)
          ) {
            return "excellentState";
          }
          if (
            (params.value as string).match(`${AccountStateDictionary[AccountState.TWO]}`) ||
            (params.value as string).match(`${AccountStateDictionary[AccountState.THREE]}`)
          ) {
            return "goodState";
          }
          if (
            (params.value as string).match(`${AccountStateDictionary[AccountState.ONE]}`) ||
            (params.value as string).match(`${AccountStateDictionary[AccountState.FOUR]}`) ||
            (params.value as string).match(`${AccountStateDictionary[AccountState.SEVEN]}`)
          ) {
            return "badState";
          }

          return "";
        }}
      />
    );
  };

  return (
    <>
      <Box
        sx={
          {
            margin: "5%",
            "& .badState": {
              backgroundColor: "#FF7276",
              color: "#fff",
              border: 2,
              fontSize: 20,
              borderColor: "black",
            },
            "& .goodState": {
              backgroundColor: "#ff9100",
              color: "#fff",
              border: 2,
              fontSize: 20,
              borderColor: "black",
              textAlign: "center",
            },
            "& .excellentState": {
              backgroundColor: "#00A776",
              color: "#fff",
              border: 2,
              fontSize: 20,
              borderColor: "black",
            },
          } as any
        }
      >
        <GridContent />
      </Box>
    </>
  );
};
