import styled from "styled-components";
import { Typography } from "@material-ui/core";

const StyledHeader = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr 0.5fr;
  border-bottom: 1px solid #e4e4e4;
  padding: 16px 0 8px;
  margin-bottom: 10px;
  font-size: 12px;
`;

type Props = {
  values: string[];
};

export const Headers = ({ values }: Props) => {
  return (
    <StyledHeader>
      {values &&
        values.map((item) => (
          <Typography variant="body2" key={item}>
            {item}
          </Typography>
        ))}
    </StyledHeader>
  );
};

export default Headers;
