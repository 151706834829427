import { InputLabel, makeStyles } from "@material-ui/core";
import NotchedOutline from "@material-ui/core/OutlinedInput/NotchedOutline";
import { useState, useRef, useEffect } from "react";
import ReactDOM from "react-dom";

interface DivProps {
  label: string;
  children: any;
  colour?: string;
}

export const OutlinedDiv = ({ label, children, colour }: DivProps) => {
  const useStyles = makeStyles({
    root: {
      position: "relative",
      marginTop: "10px",
      marginBottom: "20px",
    },
    contentWrapper: {
      position: "relative",
    },
    content: {
      padding: "18.5px 14px",
      textAlign: "left",
    },
    inputLabel: {
      position: "absolute",
      color: "#4054b4",
      left: 0,
      top: 0,
      transform: "translate(0, 24px) scale(1)",
    },
    notchedOutline: {
      borderRadius: "5px",
      borderColor: `${colour}`,
    },
  });

  const classes = useStyles();
  const [labelWidth, setLabelWidth] = useState(0);
  const labelRef = useRef(null);

  useEffect(() => {
    const labelNode = ReactDOM.findDOMNode(labelRef.current) as HTMLElement;
    setLabelWidth(labelNode != null ? labelNode.offsetWidth : 0);
  }, [label]);
  return (
    <div className={classes.root}>
      <InputLabel className={classes.inputLabel} ref={labelRef} variant="outlined" shrink>
        {label}
      </InputLabel>
      <div className={classes.contentWrapper}>
        <div className={classes.content}>
          {children}
          <NotchedOutline notched labelWidth={labelWidth} className={classes.notchedOutline} />
        </div>
      </div>
    </div>
  );
};
