import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { StyledDashboardContainer } from "../../dashboard/Dashboard";
import { SearchForm } from "../SearchForm";
import CognismMigration from "./CognismMigration";
import styled from "@emotion/styled";
import { uppColourPalette } from "../../../util/colours";
import { Tabs as MuiTabs } from "@material-ui/core";

const StyledTab = styled(Tab)({
  "&.Mui-selected": {
    color: uppColourPalette.darkGreen[500],
  },
});

const Tabs = styled(MuiTabs)(({ theme }) => ({
  "& .MuiTabs-indicator": {
    backgroundColor: uppColourPalette.darkGreen[500],
  },
}));

export default function LabTabs() {
  const [value, setValue] = React.useState("1");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <StyledDashboardContainer>
      <div className="col-6 tab p-5">
        <Box sx={{ width: "100%", typography: "body1" }}>
          <Tabs
            TabIndicatorProps={{
              hidden: true,
            }}
            orientation="vertical"
          >
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList onChange={handleChange} aria-label="lab API tabs example" centered>
                  <StyledTab label="Migrate" value="1" />
                  <StyledTab label="Search Companies" value="2" />
                </TabList>
              </Box>

              <TabPanel value="1">
                <CognismMigration />
              </TabPanel>
              <TabPanel value="2">
                <SearchForm />
              </TabPanel>
            </TabContext>
          </Tabs>
        </Box>
      </div>
    </StyledDashboardContainer>
  );
}
