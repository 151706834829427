import { DateTime, Duration } from "luxon";

export const formatDate = (input: string) => {
  const date = DateTime.fromISO(input);
  const legible = date.toLocaleString(DateTime.DATE_MED);
  return legible;
};

export function dateMinusDays(days: number) {
  return DateTime.now()
    .startOf("day")
    .minus(Duration.fromISO(`P${days}D`))
    .toJSDate();
}

export function dateTimeZoneAdjusted(date: Date): string {
  return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
}

export function getCompareDateRange(dateRange: { startDate: Date; endDate: Date }): { startDate: Date; endDate: Date } {
  const startDate = DateTime.fromJSDate(dateRange.startDate);
  const endDate = DateTime.fromJSDate(dateRange.endDate);
  const numberOfDaysInDateRange: Duration = endDate.diff(startDate, ["days"]);

  const compareStartDate = startDate.minus({ days: numberOfDaysInDateRange.days + 1 }).toJSDate();
  const compareEndDate = endDate.minus({ days: numberOfDaysInDateRange.days + 1 }).toJSDate();
  return { startDate: compareStartDate, endDate: compareEndDate };
}
