import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const ComparisonChart = ({ tenantId, xData, yData }: { tenantId: string; xData?: any; yData?: any }) => {
  const conversionValueData = yData.map((data: any) => data.accountMetrics ?? 0);
  const conversionValueTimeData = yData.map((data: any) => data.hourlyMetrics ?? 0);

  return (
    <>
      <HighchartsReact
        highcharts={Highcharts}
        containerProps={{
          style: { height: "100%", marginTop: "2rem", padding: "1rem ", border: "1px solid rgb(219, 219, 219)" },
        }}
        options={{
          chart: {
            type: "spline",
          },
          title: {
            text: tenantId,
          },
          xAxis: {
            categories: xData,
            accessibility: {
              description: "Date",
            },
          },
          series: [
            {
              name: "Conversion value",
              data: conversionValueData,
            },
            {
              name: "Conversion Val. by date",
              data: conversionValueTimeData,
            },
          ],
        }}
      />
    </>
  );
};

export default ComparisonChart;
