import { Box, Dialog, DialogContent, DialogTitle, Divider, Tab, Typography } from "@material-ui/core";
import { CloseRounded } from "@material-ui/icons";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import React, { useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";
import { getActiveJob, getJobHistory } from "../../api/jobscheduler";
import { StyledCloseButton } from "../datasources/SetupConnection";
import { StepCard } from "./StepCard";
import { Headers } from "../datasources/Headers";
import { StyledButton } from "../datasources/Datasources";

interface JobDetailProps {
  isOpen: boolean;
  onClose: () => void;
  data: any | undefined;
}
export const JobDetails = ({ isOpen, onClose, data }: JobDetailProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [jobDetails, setJobDetails] = useState<any>();
  const [jobHistory, setJobHistory] = useState<any>();
  const [tabValue, setTabValue] = useState<string>("0");
  const [showResults, setShowResults] = useState<boolean>(false);

  const handleClose = () => {
    setTabValue("0");
    onClose();
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, newTabValue: any) => {
    setTabValue(newTabValue);
  };

  useEffect(() => {
    async function getData() {
      setLoading(true);
      const job = await getJobHistory(data.jobName);
      setJobHistory(job);
      const details = await getActiveJob(data.jobName);
      setJobDetails(details);
      setLoading(false);
    }
    getData();
  }, [data]);

  return (
    <TabContext value={tabValue}>
      <Dialog open={isOpen} maxWidth="md" fullWidth onClose={handleClose}>
        <DialogTitle>
          Job Information
          <StyledCloseButton onClick={handleClose}>
            <CloseRounded />
          </StyledCloseButton>
        </DialogTitle>
        <TabList onChange={handleTabChange}>
          <Tab label="Job Details" value="0" />
          <Tab disabled={jobHistory && jobHistory.length === 0} label="Job History" value="1" />
        </TabList>

        <Divider />
        {loading ? (
          <DialogContent>
            <Box paddingY={8} display="flex" flexDirection="column">
              <Box display="flex" justifyContent="center">
                <ClipLoader />
              </Box>

              <Typography variant="h6" align="center">
                Loading
              </Typography>
            </Box>
          </DialogContent>
        ) : (
          <div>
            <TabPanel value="0">
              <pre>{JSON.stringify(jobDetails, null, 2)}</pre>
            </TabPanel>
            <TabPanel value="1">
              {!showResults ? (
                <>
                  <Headers values={["Latest Update", " ", "Status", "", "Results"]} />
                  {jobHistory &&
                    Object.entries(jobHistory).map((steps: any) => {
                      return steps.slice(1).map((step: any, idx: number) => {
                        return (
                          <StepCard
                            status={step.status}
                            update={step.latestUpdate}
                            latestUpdateTime={step.latestUpdateTime}
                            results={step.results}
                            onClick={() => {
                              setShowResults(true);
                            }}
                          />
                        );
                      });
                    })}
                </>
              ) : (
                <div>
                  {jobHistory &&
                    Object.entries(jobHistory)
                      .slice(-1)
                      .map((steps: any) => {
                        return steps.slice(1).map((step: any, idx: number) => {
                          return (
                            step.results &&
                            Object.entries(step.results).map((result) => (
                              <>
                                <pre>{result[0] + ":"}</pre>
                                <Typography>{JSON.stringify(result[1]) + ""}</Typography>
                              </>
                            ))
                          );
                        });
                      })}
                  <br />
                  <StyledButton
                    onClick={() => {
                      setShowResults(false);
                    }}
                  >
                    Go Back
                  </StyledButton>
                </div>
              )}
            </TabPanel>
          </div>
        )}
      </Dialog>
    </TabContext>
  );
};
