import { useAuth0 } from "@auth0/auth0-react";
import styled from "@emotion/styled";
import { createContext, useState } from "react";
import { ClipLoader } from "react-spinners";
import Card from "../../components/Card";
import { useAllTenantsWithConfig } from "../../hooks/useTenants";
import { CreateTenant } from "../dashboard/CreateTenant";
import DatasourceWizard from "./DatasourceWizard";
import { MappingWizard } from "./MappingWizard";
import { TargetsAndCurrencyWizard } from "./TargetsAndCurrencyWizard";
import { UsersWizard } from "./UsersWizard";
import { DataDownloadWizard } from "./DataDownloadWizard";
import { WizardSummary } from "./WizardSummary";
import { WizardContextProps } from "./WizardProvider";

const Wrapper = styled.div`
  padding: 88px 24px 24px 24px;
`;

export const Footer = styled.div`
  padding: 24px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export enum Steps {
  CREATE_TENANT = 0,
  DATASOURCE = 1,
  MAPPING = 2,
  TARGETS = 3,
  DATA_DOWNLOAD = 4,
  USERS = 5,
  COMPLETE = 6,
}
export const WizardContext = createContext<WizardContextProps>({ wizardState: undefined, updateWizardState: () => {} });

export const OnboardingWizard = () => {
  const [step, setStep] = useState<number>(
    localStorage.getItem("onboardingStep") ? Number(localStorage.getItem("onboardingStep")) : 0
  );
  const [datasourcesComplete, setDatasourcesComplete] = useState<boolean>(false);
  const tenants = useAllTenantsWithConfig().data;
  const [newTenant, setNewTenant] = useState<string>(localStorage.getItem("onboardingTenant") || "");
  const { loginWithRedirect } = useAuth0();

  const newTenantId = (data: string | string[]) => {
    if (typeof data !== "string" && data.length > 1) {
      setNewTenant(data[0] as string);
      localStorage.setItem("previousTenant", window.TENANT_ID);
      localStorage.setItem("onboardingTenant", data[0] as string);
      localStorage.setItem("multiRegion", data.toLocaleString());
    } else {
      setNewTenant(data as string);
      localStorage.setItem("previousTenant", window.TENANT_ID);
      localStorage.setItem("onboardingTenant", data as string);
    }
  };

  const redirect = (type: string) => {
    const scope = `tenant:${localStorage.getItem(type === "new" ? "onboardingTenant" : "previousTenant")}`;
    loginWithRedirect({
      scope,
      redirectUri: window.location.href,
    });
  };

  return (
    <Wrapper>
      <Card>
        {step === Steps.CREATE_TENANT && (
          <CreateTenant
            setStep={setStep}
            existingTenantIds={tenants?.map((t) => t.id) || []}
            createdTenant={newTenantId}
            redirect={redirect}
            wizard={true}
          />
        )}
        {step === Steps.DATASOURCE && (
          <>
            {window.TENANT_ID === localStorage.getItem("previousTenant") ? (
              <ClipLoader />
            ) : (
              <>
                {localStorage.getItem("multiRegion") && <h1>{newTenant}</h1>}
                <DatasourceWizard
                  setStep={setStep}
                  datasourcesComplete={datasourcesComplete}
                  redirect={redirect}
                  newTenant={newTenant}
                  setDatasourcesComplete={setDatasourcesComplete}
                />
              </>
            )}
            {
              // TODO: Stop 'next' button from showing until datasources are complete
              // TODO: Properly validate Google Datasource. If Oauth doesn't work returning a refresh token, it should fail with a friendly error message
            }
          </>
        )}
        {step === Steps.MAPPING && (
          <>
            <MappingWizard setStep={setStep} setDatasourcesComplete={setDatasourcesComplete} />
          </>
        )}
        {step === Steps.TARGETS && (
          <>
            <TargetsAndCurrencyWizard setStep={setStep} />
          </>
        )}
        {step === Steps.DATA_DOWNLOAD && (
          <>
            <DataDownloadWizard setStep={setStep} />
          </>
        )}
        {step === Steps.USERS && (
          <>
            <UsersWizard setStep={setStep} />
          </>
        )}
        {step === Steps.COMPLETE && (
          <>
            <WizardSummary setStep={setStep} />
          </>
        )}
      </Card>
    </Wrapper>
  );
};

export default OnboardingWizard;
