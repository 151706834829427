import { http } from "../UppAuth";
import { PerformaceInsightsReport, PerformanceInsightsReportMetadata } from "../hooks/useVisibilityPerformance";

export interface ProductsPerformance {
  date: string;
  impressions: number;
  actualSpend: number;
  roas: number;
  unitsSold: number;
  revenue: number;
}

export interface MPAProductPerformance {
  _id: string;
  sku: string;
  title: string;
  price: number;
  nonManagedCost: number;
  impressions: number;
  clicks: number;
  cost: number;
  conversions: number;
  conversionValue: number;
  revenue: number;
  unitsSold: number;
  targetRoas: number;
  roas: number;
}

export async function getMPAProductsPerformance(from: string, to: string): Promise<MPAProductPerformance[]> {
  const result = await http.get(
    `${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/performance/mpa-products?from=${from}&to=${to}`
  );

  if (result.status !== 200) {
    console.log("Failed to fetch products performance");
  }

  return result.data;
}

export interface AccountPerformanceTrend {
  index: string;
  before: number;
  after: number;
  difference: number;
  percentageDifference: number;
}

export async function getAccountPerformanceTrend(): Promise<Array<AccountPerformanceTrend>> {
  const result = await http.get(`${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/mpa-reporting/trend`);

  if (result.status !== 200) {
    console.log("Failed to fetch account performance");
  }

  return result.data;
}

export async function hasMappedPerformanceData(): Promise<boolean> {
  const result = await http.get(`${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/performance/hasMappedData`);
  return result.data;
}

export async function getAllVisibilityPerformanceReports(): Promise<Array<PerformanceInsightsReportMetadata>> {
  const result = await http.get(`${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/report/visibility-performance`);
  return result.data;
}

export async function getVisibilityPerformanceReportById(id: string): Promise<PerformaceInsightsReport> {
  const result = await http.get(`${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/report/visibility-performance/${id}`);
  return result.data;
}

export interface InfluentialProduct {
  sku: string;
  title: string;
  isDeleted: boolean;
  isBlocked: boolean;
  unlisted: boolean;
  stock: number;
  contribution: number;
  rank: number;
}

export async function getInfluentialProducts(
  limit: number,
  skip: number,
  field: string
): Promise<InfluentialProduct[]> {
  const result = await http.get(
    `${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/performance/influential-products?field=${field}&limit=${limit}&skip=${skip}`
  );
  if (result.data.influentialProducts.length > 0) {
    let rank = 1;
    return result.data.influentialProducts.map((ip: InfluentialProduct) => {
      return { ...ip, rank: rank++ };
    });
  }
  return [];
}

export interface InfluentialProductCounts {
  cost: number;
  conversions: number;
  impressions: number;
}

export async function getInfluentialProductsCount(): Promise<InfluentialProductCounts> {
  const [costResult, conversionsResult, impressionsResult] = await Promise.all([
    http.get(`${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/performance/influential-products/count?field=cost`),
    http.get(`${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/performance/influential-products/count?field=conversions`),
    http.get(`${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/performance/influential-products/count?field=impressions`),
  ]);

  return {
    cost: costResult.data.count ?? 0,
    conversions: conversionsResult.data.count ?? 0,
    impressions: impressionsResult.data.count ?? 0,
  };
}
