import { useQuery } from "@tanstack/react-query";
import {
  getAllTenants,
  getAllTenantsWithConfigAndCampaignMetadata,
  getTenantConfig,
  OverviewDecoratedTenant,
  Tenant,
  TenantConfig,
} from "../api/tenant";

export function useAllTenantsWithConfig() {
  return useQuery<OverviewDecoratedTenant[], Error>(["allTenantsWithConfig"], () =>
    getAllTenantsWithConfigAndCampaignMetadata()
  );
}

export function useAllTenants() {
  return useQuery<Tenant[], Error>(["allTenants"], () => getAllTenants());
}

export function useTenantConfig(tenantId: string) {
  return useQuery<TenantConfig, Error>(["tenantConfig"], () => getTenantConfig(tenantId));
}
