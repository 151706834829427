import { Avatar, Card, CardContent, Color, Grid, Typography } from "@material-ui/core";
import { Icon } from "react-feather";
import styled from "styled-components";

export const CountBox = (props: { name: string; value: number; icon: Icon; colour: Color }) => {
  // TODO: This should not be declared within the function
  // Read: https://github.com/styled-components/styled-components/issues/3117
  const StyledIcon = styled(props.icon)`
    size: 20;
  `;

  const StyledAvatar = styled(Avatar)`
    background-color: ${props.colour[600]} !important;
    height: 56;
    width: 56;
    aligncontent: left;
  `;

  const StyledCard = styled(Card)`
    height: 100% !important;
  `;

  return (
    <StyledCard>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <StyledAvatar>
              <StyledIcon />
            </StyledAvatar>
          </Grid>
          <Grid item xs={6}>
            <Typography color="textPrimary" variant="h4" align="right">
              {props.value}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography color="textSecondary" align="left">
              {props.name}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </StyledCard>
  );
};
