import { useQuery } from "@tanstack/react-query";
import { getAllVisibilityPerformanceReports, getVisibilityPerformanceReportById } from "../api/performance";
import { DateConfiguration } from "@volo-ai/upp-temporal-utils";

export function useAllVisibilityPerformanceReports() {
  return useQuery<PerformanceInsightsReportMetadata[], Error>(["allVisibilityPerformanceReports"], () =>
    getAllVisibilityPerformanceReports()
  );
}

export function useVisibilityPerformanceReportById(id: string) {
  return useQuery<PerformaceInsightsReport, Error>(["visibilityPerformanceReportById", id], () =>
    getVisibilityPerformanceReportById(id)
  );
}

export interface PerformanceInsightsReportMetadata {
  _id: string;
  tenantId: string;
  createdOn: Date;
  targetDate: DateConfiguration;
  comparisonDate: DateConfiguration;
}

export interface PerformaceInsightsReport {
  _id: string;
  tenantId: string;
  createdOn: Date;
  targetDate: DateConfiguration;
  comparisonDate: DateConfiguration;
  end: Date;
  allTested: {
    skus: string[];
    totalProductsTested: number;
    totalSpend: number;
  };
  successfullyTested: DataReportInterface;
  stillInTesting: DataReportInterface;
}

export interface DataReportInterface {
  conversions?: number;
  roas?: number;
  skus: string[];
  totalConversionValue?: number;
  totalProductsTested: number;
  totalSpend: number;
  revenue?: number;
  clicks: number;
  impressions: number;
}
