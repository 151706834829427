import { http } from "../UppAuth";

// const mockCompanies = [
//   {
//     id: 1,
//     name: "Adidas",
//   },
// ];

export async function getCompany(companyName: string) {
  const result = await http.get<any>(
    `${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/marketing-leads/searchTarget/${companyName}`
  );
  if (result.status !== 200) {
    console.log("Failed to add company");
    return "Failed to add company";
  }
  return result.data;
  // return mockCompanies;
}
export async function redeemCompany(id: string) {
  const result = await http.get<any>(`${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/marketing-leads/search/${id}`);
  if (result.status !== 200) {
    console.log("Failed to redeem company");
    return "Failed to redeem company";
  }
  return result.data;

  // return {
  //   id: 1,
  //   name: "Adidas",
  //   webDomain: "hello",
  //   linkedinUrl: "hello LinkedIn",
  //   website: "hello website",
  // };
}

export async function addCompany(id: string) {
  const result = await http.post<any>(`${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/marketing-leads/createCompany`, {
    id,
  });

  if (result.status !== 200) {
    throw new Error("Failed to add company");
  }

  return result.data;
}
