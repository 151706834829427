import { Dialog, DialogContent, DialogTitle, Divider, Box } from "@material-ui/core";
import { CloseRounded } from "@material-ui/icons";
import { useAllTenants } from "../../hooks/useTenants";
import { StyledCloseButton } from "../datasources/SetupConnection";
import { CreateUserForm } from "./CreateUserForm";

interface Props {
  onClose: () => void;
  isOpen: boolean;
  wizard?: boolean;
  onComplete?: () => void;
  tenantIds?: string[];
  page: number;
}
export function CreateUserModal({ onClose, isOpen, wizard, onComplete, tenantIds, page }: Props) {
  const tenantQuery = useAllTenants();

  const handleClose = () => {
    onClose();
  };

  return (
    <>
      {!wizard ? (
        <Dialog open={isOpen} maxWidth="sm" fullWidth onClose={onClose}>
          <DialogTitle>
            Add User
            <StyledCloseButton onClick={handleClose}>
              <CloseRounded />
            </StyledCloseButton>
          </DialogTitle>
          <Divider />

          <Box
            sx={{
              padding: "1em",
              margin: "2em",
            }}
          >
            <DialogContent>
              <CreateUserForm data={tenantQuery.data || []} onClose={onClose} tenantIds={tenantIds} page={page} />
            </DialogContent>
          </Box>
        </Dialog>
      ) : (
        <CreateUserForm
          data={tenantQuery.data || []}
          onClose={onClose}
          wizard={true}
          onComplete={onComplete}
          page={page}
        />
      )}
    </>
  );
}
