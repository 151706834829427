import { http } from "../UppAuth";

export interface ProductBlockerResult {
  error?: string;
  message?: string;
}

export async function blockUnblockProducts(
  identifierType: string,
  identifiers: string[],
  path: string
): Promise<ProductBlockerResult> {
  const result = await http.post<any>(
    `${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/product-flagger/${path}`,
    {
      identifierType,
      identifiers,
      field: "isBlocked",
    },
    {
      validateStatus: function (status) {
        return true;
      },
    }
  );
  if (result.status === 202) {
    return { message: `Block list has been uploaded successfully.` };
  }
  return { error: `Failed to upload block list error=${result.data.error ?? result.status}` };
}
