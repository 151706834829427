import styled from "@emotion/styled";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { Dispatch, SetStateAction, useContext, useState } from "react";
import { useDeleteUser, getUsers, UsersResponse } from "../../api/userManagement";
import Button from "../../components/Wizards/Button";
import { ConfirmationModal } from "../userManagement/ConfirmationModal";
import { CreateUserModal } from "../userManagement/CreateUserModal";
import { EditUserWrapper } from "../userManagement/EditUserWrapper";
import { StyledDelete, StyledEdit } from "../userManagement/UserManagement";
import { Footer, Steps, WizardContext } from "./OnboardingWizard";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const GridWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`;

const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
`;

export const UsersWizard = ({ setStep }: { setStep: Dispatch<SetStateAction<number>> }) => {
  const [page, setPage] = useState(1);
  const [email, setEmail] = useState<string>("");
  const [createUserModal, setCreateUserModal] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editUserId, setEditUserId] = useState<string>("");
  const [deleteUserModal, setDeleteUserModal] = useState(false);
  const deleteUser = useDeleteUser();
  const queryClient = useQueryClient();
  const { updateWizardState } = useContext(WizardContext);
  const { data, isLoading } = useQuery(["users", page], () =>
    getUsers(page, localStorage.getItem("onboardingTenant")!)
  );

  const handleEditUserClick = (userId: string) => {
    setEditUserId(userId);
    setEditModalOpen(true);
  };
  const handleDeleteUserClick = (userId: string, email: string) => {
    setEditUserId(userId);
    setEmail(email);
    setDeleteUserModal(true);
  };

  const { enqueueSnackbar } = useSnackbar();

  const handleDeleteUser = (userId: string) => {
    deleteUser.mutate(userId, {
      onSuccess: () => {
        queryClient.setQueryData<UsersResponse>(["users", data?.page || 1], (oldData) => {
          return {
            ...oldData!,
            totalUsers: (oldData?.totalUsers! - 1) | 0,
            users: oldData?.users.filter((user) => user.user_id !== userId) || [],
          };
        });
        enqueueSnackbar(`Successfully deleted user!`, {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        });
      },
      onError: (error) => {
        enqueueSnackbar(`Failed to delete user : ${(error as Error).message}`, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        });
      },
    });
    setDeleteUserModal(false);
  };

  const columns: GridColDef[] = [
    { headerName: "Email", field: "email", flex: 1 },
    { headerName: "Name", field: "name", flex: 1 },
    {
      headerName: "",
      field: "edit",
      renderCell: (params) => editCell(params.id as string),
      width: 75,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      headerName: "",
      field: "delete",
      renderCell: (params) => deleteCell(params.id as string, params.row.email as string),
      width: 75,
      disableColumnMenu: true,
      sortable: false,
    },
  ];

  const editCell = (id: string) => {
    return <StyledEdit onClick={() => handleEditUserClick(id)}>Edit</StyledEdit>;
  };
  const deleteCell = (id: string, email: string) => {
    return <StyledDelete onClick={() => handleDeleteUserClick(id, email)}>Delete</StyledDelete>;
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const backPage = () => {
    localStorage.setItem("onboardingStep", String(4));
    setStep(Steps.DATA_DOWNLOAD);
  };
  const nextPage = () => {
    updateWizardState({ users: data && data.users });
    localStorage.setItem("onboardingStep", String(6));

    setStep(Steps.COMPLETE);
  };

  if (data) {
    return (
      <Container>
        <Heading>
          User Management
          <Button styling="Submit" onClick={() => setCreateUserModal(true)}>
            Add User
          </Button>
        </Heading>

        <GridWrapper>
          <DataGrid
            sx={{
              "& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator": {
                display: "none",
              },
              height: "500px",
            }}
            paginationMode={"server"}
            rowCount={data.totalUsers}
            onPageChange={(page) => setPage(page + 1)}
            rows={data.users}
            columns={columns}
            getRowId={(row) => row.user_id}
            disableSelectionOnClick
            rowsPerPageOptions={[100]}
          />
        </GridWrapper>
        <CreateUserModal
          isOpen={createUserModal}
          tenantIds={[localStorage.getItem("onboardingTenant")!]}
          onClose={() => {
            setCreateUserModal(false);
          }}
          page={page}
        ></CreateUserModal>
        <EditUserWrapper
          isOpen={editModalOpen}
          userId={editUserId}
          onClose={() => {
            setEditModalOpen(false);
          }}
          page={page}
        />
        <ConfirmationModal
          open={deleteUserModal}
          userEmail={email}
          onClose={() => {
            setDeleteUserModal(false);
          }}
          onConfirm={() => {
            handleDeleteUser(editUserId);
          }}
          disableButtons={false}
        />
        <Footer>
          <Button onClick={backPage}>Back</Button>
          <Button onClick={nextPage}>Next</Button>
        </Footer>
      </Container>
    );
  } else {
    return <div>Something went wrong</div>;
  }
};
