import { JSONSchema7 } from "json-schema";
import { useEffect, useState } from "react";
import { Form } from "react-final-form";
import {
  Connection,
  createDatasource,
  DatasourceEntityType,
  getAvailableConnectionDetails,
} from "../../api/datasources";
import { ConnectionForm } from "./ConnectionForm";
import { useSnackbar } from "notistack";
import { Box, Button, DialogActions, DialogContent, Typography } from "@material-ui/core";
import { ClipLoader } from "react-spinners";
import { get } from "lodash";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { flatten } from "flat";

// TODO: potentially move to using react-hook-form instead of react-final-form??
interface ConfigureConnectionProps {
  system: Connection;
  entityType: DatasourceEntityType;
  onFinish: (connectionId: string) => void;
  onBack: () => void;
  wizard?: boolean;
}

export const ConfigureConnection = ({ system, entityType, onFinish, onBack, wizard }: ConfigureConnectionProps) => {
  const [schema, setSchema] = useState<JSONSchema7>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    if (!system) {
      return;
    }
    async function fetchDetails() {
      setIsLoading(true);
      const data = await getAvailableConnectionDetails(system.systemId);
      setSchema(data);
      setIsLoading(false);
    }

    fetchDetails();
  }, [system]);

  async function autoCreateConnection() {
    try {
      if (system?.systemId === "shopify") {
        return;
      }

      const results = await createDatasource({
        entityType: entityType!,
        partnerSystemId: system!.systemId!,
        channel: system.channel ?? "",
        integrationDetails: {},
      });
      onFinish(results.id);

      enqueueSnackbar("Your connection has been created.", {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    } catch {
      enqueueSnackbar("There was an error trying to add your connection, please try again.", {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    }
  }

  if (isLoading) {
    return (
      <DialogContent>
        <Box margin={2}>
          <ClipLoader />
        </Box>
      </DialogContent>
    );
  }

  if (schema && schema!.required!.length === 0) {
    return (
      <>
        <DialogContent>
          <Box padding={14} display="flex" flexDirection="column">
            <Typography variant="h6" align="center">
              Upp already has all the details required to connect to {system!.name}. Just submit below to create this
              connection.
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onBack} id="connections__back">
            Back
          </Button>

          <Button id="connections__connect" color="primary" onClick={autoCreateConnection}>
            Submit
          </Button>
        </DialogActions>
      </>
    );
  }

  return (
    <Form
      mutators={{
        setGoogleAuthCode: ({ args, state, utils }) => {
          // utils.changeValue(state, 'googleAuthCode', () => args[0]);
        },
      }}
      onSubmit={async (values: Record<string, any>) => {
        const messageId = enqueueSnackbar("Creating connection...", {
          persist: true,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        });

        const fields: any = {};

        for (const key of Object.keys(schema!.properties!)) {
          const value = get(values.connection, `${key}`);

          if (value) {
            fields[key] = value;
          }
        }

        const connectionFields: any = flatten(values.connection || {});
        const integrationParams =
          system!.authType !== "oauth2"
            ? fields
            : {
                ...connectionFields,
                "oauth.authorization.code": values.googleAuthCode,
                "oauth.callback.url": "postmessage",
              };

        try {
          const results = await createDatasource({
            entityType: entityType!,
            partnerSystemId: system!.systemId!,
            channel: system.channel ?? "",
            integrationDetails: integrationParams,
          });

          onFinish(results.data.id);
          enqueueSnackbar("Your connection has been created.", {
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
          });
        } catch {
          enqueueSnackbar("There was an error trying to add your connection, please try again.", {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
          });
        }

        closeSnackbar(messageId!);
      }}
    >
      {({ handleSubmit }) => (
        <GoogleOAuthProvider clientId={window.__RUNTIME_CONFIG__.REACT_APP_GOOGLE_AUTH_CLIENT_ID}>
          <ConnectionForm schema={schema!} system={system!} onSubmit={handleSubmit} onBack={onBack} wizard={wizard} />
        </GoogleOAuthProvider>
      )}
    </Form>
  );
};

export default ConfigureConnection;
