import { http } from "../UppAuth";
import { AccountState } from "./autopilotHelper";

export interface AccountStateData {
  _id: string;
  tenantId: string;
  parentJobName: string;
  date: string;
  previousState?: AccountState | string;
  currentState?: AccountState | string;
  accountStateChange?: AccountStateChange;
  event?: string;
  actionTaken?: string;
  budget?: null;
  targetSpend?: number;
  reliableBuffer?: number;
  sufficientDataIntervalInDays?: number;
  accountLag?: number;
  latestPGARun?: string;
  latestTargetRoasUpdate?: string;
  latestBudgetUpdate?: string;
}

export enum AccountStateRanks {
  AWFUL = 0,
  BAD = 1,
  GOOD = 2,
  GREAT = 3,
  EXCELLENT = 4,
}

export const AccountStateRankings = {
  [AccountState.SEVEN]: AccountStateRanks.AWFUL,
  [AccountState.FOUR]: AccountStateRanks.BAD,
  [AccountState.ONE]: AccountStateRanks.BAD,
  [AccountState.EIGHT]: AccountStateRanks.GREAT,
  [AccountState.NINE]: AccountStateRanks.GREAT,
  [AccountState.FIVE]: AccountStateRanks.EXCELLENT,
  [AccountState.SIX]: AccountStateRanks.GREAT,
  [AccountState.TWO]: AccountStateRanks.GOOD,
  [AccountState.THREE]: AccountStateRanks.GOOD,
};

export enum AccountStateChange {
  SAME = "Same",
  INCREASE = "Increase",
  DECREASE = "Decrease",
}

export const getAccountStateChange = (
  previousState?: AccountState,
  currentState?: AccountState
): AccountStateChange => {
  if (previousState && currentState && AccountStateRankings[previousState] < AccountStateRankings[currentState]) {
    return AccountStateChange.INCREASE;
  }
  if (previousState && currentState && AccountStateRankings[previousState] > AccountStateRankings[currentState]) {
    return AccountStateChange.DECREASE;
  }
  return AccountStateChange.SAME;
};

export async function getAccountState(tenantId: string, from?: string, to?: string): Promise<AccountStateData[]> {
  const url = new URL(`${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/autopilot-helper/accounts/${tenantId}`);
  if (from) {
    url.searchParams.append("from", from);
  }
  if (to) {
    url.searchParams.append("to", to);
  }
  const accountStateResult = await http.get<any>(url.href);

  return accountStateResult.data?.stats || [];
}

export async function getAllAccountsStateActionsHistory(
  from?: string,
  to?: string,
  actionType?: string
): Promise<AccountStateData[]> {
  const url = new URL(`${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/autopilot-helper/all-accounts-actions-history`);
  if (from) {
    url.searchParams.append("from", from);
  }
  if (to) {
    url.searchParams.append("to", to);
  }

  if (actionType) {
    url.searchParams.append("actionType", actionType);
  }
  const accountStateResult = await http.get<any>(url.href);

  return accountStateResult.data || [];
}

export interface AccountStateExplainer {
  currentLag: number;
  currentState: AccountState;
  sufficientDataIntervalInDays: number;
  queryInterval: string;
  dailyAccountPerformance: {
    tenantId: string;
    date: string;
    accountState: string;
    cost: number;
    conversionValue: number;
    roas: number;
    accountLag?: number;
    splitAccountStateReport?: any;
  }[];
}

export async function getAccountStateExplainer(): Promise<AccountStateExplainer | null> {
  let url = `${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/performance/account-state`;
  const accountStateExplainer = await http.get<any>(url);

  return accountStateExplainer.data || null;
}

export interface DailyAccountPerformance {
  tenantId: string;
  date: string;
  accountState: string;
  accountLag: number;
  cost: number;
  conversionValue: number;
  roas: number;
}

export interface AccountsState {
  tenantId: string;
  currentState: AccountState;
}

export async function getAccountsState(): Promise<AccountsState[]> {
  let url = `${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/performance/accounts`;
  const accountStateResult = await http.get<any>(url);

  return accountStateResult.data ?? [];
}
