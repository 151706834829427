import styled from "@emotion/styled";
import {
  Accordion,
  AccordionSummary,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { CloseRounded } from "@material-ui/icons";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { CommonPayload, DownloadConfiguration, DownloadWorkflowToRun } from "@volo-ai/upp-temporal-utils";
import { ChangeEvent, Dispatch, SetStateAction } from "react";
import { Path } from "../../../api/jobscheduler";
import { StyledButtonFilled } from "../../datasources/Datasources";
import { StyledCloseButton } from "../../datasources/SetupConnection";
import SwitchConfig from "../../tenantConfig/SwitchConfig";
import { StyledInputLabel } from "../AddJobDialog";
import { Workflow } from "./Workflow";

export interface AddNewJobProps {
  handleToggle: (prop: any) => (e: ChangeEvent<HTMLInputElement>) => void;
  jobPayload: CommonPayload;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  handleDataChange: (prop: any) => (e: ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: (path: Path) => void;
}

export const StyledRow = styled.div`
  display: inline-flex;
  width: 100%;
  margin: 10px;
`;

const Pane = styled.div`
  padding: 10px;
`;

export const DownloadWorkflow = ({
  handleToggle,
  jobPayload,
  open,
  setOpen,
  handleDataChange,
  handleSubmit,
}: AddNewJobProps) => {
  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>
        Download Workflow
        <StyledCloseButton onClick={() => setOpen(false)}>
          <CloseRounded />
        </StyledCloseButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            Order Enabled
            <SwitchConfig
              onChange={handleToggle("workflowToRun.order")}
              checked={(jobPayload.workflowToRun as DownloadWorkflowToRun).order}
            />
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="Order">
                <Typography>Order</Typography>
              </AccordionSummary>
              <Pane>
                <StyledInputLabel>Initial Download</StyledInputLabel>
                <SwitchConfig
                  onChange={handleToggle("configuration.order.initialDownload")}
                  checked={(jobPayload.configuration as DownloadConfiguration)?.order?.initialDownload}
                />
                <StyledInputLabel>Initial Download Length (Months)</StyledInputLabel>
                <TextField
                  type="number"
                  variant="outlined"
                  value={
                    (jobPayload.configuration as DownloadConfiguration)?.order?.initialDownloadLengthInMonths as number
                  }
                  onChange={handleDataChange("configuration.order.initialDownloadLengthInMonths")}
                />
                <StyledInputLabel>Daily Download</StyledInputLabel>
                <SwitchConfig
                  onChange={handleToggle("configuration.order.dailyDownload")}
                  checked={(jobPayload.configuration as DownloadConfiguration)?.order?.dailyDownload}
                />
                <StyledInputLabel>Date From (Optional)</StyledInputLabel>
                <TextField type="date" variant="outlined" onChange={handleDataChange("configuration.order.from")} />
                <StyledInputLabel>Date To (Optional)</StyledInputLabel>
                <TextField type="date" variant="outlined" onChange={handleDataChange("configuration.order.to")} />
                <StyledInputLabel>Batch Size in Days (Optional)</StyledInputLabel>
                <TextField
                  type="number"
                  variant="outlined"
                  onChange={handleDataChange("configuration.order.batchSize")}
                />
                <StyledInputLabel>Use Alternate Order Download</StyledInputLabel>
                <SwitchConfig
                  onChange={handleToggle("configuration.order.alternateOrdersDownload")}
                  checked={(jobPayload.configuration as DownloadConfiguration)?.order?.alternateOrdersDownload}
                />
                <StyledInputLabel>Tenant with Orders Datasource</StyledInputLabel>
                <TextField
                  type="string"
                  variant="outlined"
                  onChange={handleDataChange("configuration.order.ordersTenantId")}
                />
                <StyledInputLabel>SKU Mapping Field</StyledInputLabel>
                <TextField
                  type="string"
                  variant="outlined"
                  onChange={handleDataChange("configuration.order.productSkuMappingField")}
                />
              </Pane>
            </Accordion>
          </Grid>
          <Grid item xs={6}>
            Cost Enabled
            <SwitchConfig
              onChange={(e) => {
                handleToggle("workflowToRun.cost")(e);
                handleToggle("workflowToRun.costAccount")(e);
              }}
              checked={(jobPayload.workflowToRun as DownloadWorkflowToRun).cost}
            />
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="cost">
                <Typography>Cost</Typography>
              </AccordionSummary>
              <Pane>
                <StyledInputLabel>Account Level Ad Data</StyledInputLabel>
                <SwitchConfig
                  onChange={handleToggle("workflowToRun.costAccount")}
                  checked={(jobPayload.workflowToRun as DownloadWorkflowToRun)?.costAccount}
                />

                <StyledInputLabel>Campaign Level Ad Data</StyledInputLabel>
                <SwitchConfig
                  onChange={handleToggle("workflowToRun.costCampaign")}
                  checked={(jobPayload.workflowToRun as DownloadWorkflowToRun)?.costCampaign}
                />
                <StyledInputLabel>Initial Download</StyledInputLabel>
                <SwitchConfig
                  onChange={handleToggle("configuration.cost.initialDownload")}
                  checked={(jobPayload.configuration as DownloadConfiguration)?.cost?.initialDownload}
                />
                <StyledInputLabel>Initial Download Length (Months)</StyledInputLabel>
                <TextField
                  type="number"
                  variant="outlined"
                  value={
                    (jobPayload?.configuration as DownloadConfiguration)?.cost?.initialDownloadLengthInMonths as number
                  }
                  onChange={handleDataChange("configuration.cost.initialDownloadLengthInMonths")}
                />
                <StyledInputLabel>Batch Size (Days)</StyledInputLabel>
                <TextField
                  type="number"
                  variant="outlined"
                  value={(jobPayload?.configuration as DownloadConfiguration)?.cost?.batchSize as number}
                  onChange={handleDataChange("configuration.cost.batchSize")}
                />
                <StyledInputLabel>Auto Update (Days)</StyledInputLabel>
                <TextField
                  type="number"
                  variant="outlined"
                  value={(jobPayload.configuration as DownloadConfiguration)?.cost?.autoUpdateLength as number}
                  onChange={handleDataChange("configuration.cost.autoUpdateLength")}
                />
                <StyledInputLabel>Date From (Optional)</StyledInputLabel>
                <TextField type="date" variant="outlined" onChange={handleDataChange("configuration.cost.from")} />
                <StyledInputLabel>Date To (Optional)</StyledInputLabel>
                <TextField type="date" variant="outlined" onChange={handleDataChange("configuration.cost.to")} />
                <StyledInputLabel>Intraday Cost</StyledInputLabel>
                <SwitchConfig
                  onChange={handleToggle("configuration.cost.intraDay")}
                  checked={(jobPayload.configuration as DownloadConfiguration)?.cost?.intraDay}
                />
              </Pane>
            </Accordion>
          </Grid>
          <Grid item xs={12}>
            Product Enabled
            <SwitchConfig
              onChange={handleToggle("workflowToRun.product")}
              checked={(jobPayload.workflowToRun as DownloadWorkflowToRun).product}
            />
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="product">
                <Typography>Product</Typography>
              </AccordionSummary>
              <Pane>
                <Workflow handleToggle={handleToggle} jobPayload={jobPayload} handleDataChange={handleDataChange} />
              </Pane>
            </Accordion>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <StyledInputLabel>Channel</StyledInputLabel>
            <TextField type="string" variant="outlined" onChange={handleDataChange("channel")} />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <StyledInputLabel>Run Campaign Automation</StyledInputLabel>
            <SwitchConfig
              onChange={handleToggle("workflowToRun.campaignAutomation")}
              checked={(jobPayload.workflowToRun as DownloadWorkflowToRun)?.campaignAutomation}
            />
          </Grid>
        </Grid>
        <StyledRow style={{ marginTop: "20px" }}>
          <StyledButtonFilled onClick={() => handleSubmit("downloads")}>Create</StyledButtonFilled>
        </StyledRow>
        <StyledRow style={{ marginTop: "20px" }}>
          <StyledButtonFilled onClick={() => handleSubmit("auto-create-download")}>
            Create Daily Download
          </StyledButtonFilled>
        </StyledRow>
      </DialogContent>
    </Dialog>
  );
};
