import { Accordion, AccordionDetails, AccordionSummary, Box } from "@material-ui/core";

import { Heading } from "../../components/Heading";
import {
  PerformanceInsightsReportMetadata,
  useAllVisibilityPerformanceReports,
  useVisibilityPerformanceReportById,
} from "../../hooks/useVisibilityPerformance";
import styled from "@emotion/styled";
import { uppColourPalette } from "../../util/colours";
import { useState } from "react";
import { GetCurrencySymbol } from "../../util/currency";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DateTime } from "luxon";

const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
`;

const StyledButton = styled.button`
width: 100%;
max-width: 200px;
padding: 8px 16px;
transition: all ease 0.25s;
border: none;
margin: 0.5em;
background: ${uppColourPalette.coolGrey[50]};
font-family: Aeonik Pro;
font-size: 1em;
border-radius: 4px;
border: 1px solid ${uppColourPalette.coolGrey[100]};
&:hover {
  filter: drop-shadow(0 0 2px ${uppColourPalette.coolGrey[500]});

  `;

const StyledAccordion = styled(Accordion)`
  width: 50%;
  margin-left: auto;
  margin-right: auto;

  &.MuiAccordion-root.Mui-expanded {
    margin: 0 auto;
  }
`;

export const VisibilityPerformanceReports = () => {
  const [currentReportId, setCurrentReportId] = useState<string>("");
  const {
    data: report,
    isSuccess: reportSuccess,
    isLoading: reportLoading,
  } = useVisibilityPerformanceReportById(currentReportId);
  const { data: allReports, isLoading, isError } = useAllVisibilityPerformanceReports();
  const [expanded, setExpanded] = useState<string | false>(false);
  const currency = GetCurrencySymbol();

  const handleExpand = (section: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? section : false);
  };

  async function handleIdChange(id: string) {
    setCurrentReportId(id);
  }

  // write a function that takes in an array of skus and returns a comma separated string of skus, rmeoving the last comma from the end

  const skuString = (skus: string[]) => {
    let skuString = "";
    skus.forEach((sku) => {
      skuString += sku + ", ";
    });
    return skuString.slice(0, -2);
  };

  return (
    <Box
      sx={{
        bgcolor: "white",
        padding: "2em",
        margin: "2em",
        borderRadius: "0.5%",
        border: "1px solid",
        borderColor: "#dbdbdb",
      }}
    >
      {isError && <p>There was an error loading the reports</p>}
      {isLoading && <p>Loading...</p>}
      <div>
        <Heading>Visibility Performance Reports</Heading>

        {allReports && allReports.length > 0 && (
          <ButtonContainer>
            {allReports.map((report: PerformanceInsightsReportMetadata) => {
              return (
                <StyledButton onClick={() => handleIdChange(report._id)}>
                  {DateTime.fromJSDate(new Date(report.comparisonDate.from!)).monthLong}
                  {" - "} {DateTime.fromJSDate(new Date(report.targetDate.from!)).monthLong}
                </StyledButton>
              );
            })}
          </ButtonContainer>
        )}
      </div>
      {currentReportId && !reportLoading && reportSuccess && report !== undefined && (
        <div>
          <>
            <p>Target Start Date: {new Date(report!.targetDate.from!).toISOString().substring(0, 10)}</p>
            <p>Target End Date: {new Date(report!.targetDate.to!).toISOString().substring(0, 10)}</p>
            <p>Comparison Start Date: {new Date(report!.comparisonDate.from!).toISOString().substring(0, 10)}</p>
            <p>Comparison End Date: {new Date(report!.comparisonDate.to!!).toISOString().substring(0, 10)}</p>
            <h2>All Tested</h2>
            <p>Total Spend: {` ${currency}${report.allTested.totalSpend ? report!.allTested.totalSpend : 0}`} </p>
            <p>Total Products Tested: {report!.allTested.totalProductsTested!} </p>
            <StyledAccordion expanded={expanded === "all"} onChange={handleExpand("all")}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>All SKUs</AccordionSummary>
              <AccordionDetails>
                <p style={{ marginLeft: "auto", marginRight: "auto" }}>{skuString(report.allTested.skus)}</p>
              </AccordionDetails>
            </StyledAccordion>
            <h2>Successfully Tested</h2>
            <p>Total Spend: {`${currency}${report!.successfullyTested.totalSpend!.toFixed(2)}`} </p>
            <p>
              Total Conversion Value: {`${currency}${report!.successfullyTested.totalConversionValue!.toFixed(2) || 0}`}
            </p>
            <p>ROAS: {report!.successfullyTested.roas!.toFixed(2) || 0}</p>
            <p>Conversions: {report!.successfullyTested.conversions! || 0}</p>
            <p>Revenue: {`${currency}${report!.successfullyTested.revenue!.toFixed(2) || 0}`}</p>
            <p>Clicks: {report.successfullyTested.clicks}</p>
            <p>Impressions: {report.successfullyTested.impressions}</p>
            <p>Total Products Tested: {report!.successfullyTested.totalProductsTested!} </p>
            <StyledAccordion expanded={expanded === "successful"} onChange={handleExpand("successful")}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>Succesfully Tested SKUs</AccordionSummary>
              <AccordionDetails>
                <p style={{ marginLeft: "auto", marginRight: "auto" }}>{skuString(report.successfullyTested.skus)}</p>
              </AccordionDetails>
            </StyledAccordion>
            <h2>Still in Testing</h2>
            <p>Total Spend: {`${currency}${report!.stillInTesting.totalSpend!.toFixed(2)}`} </p>
            <p>
              Total Conversion Value: {`${currency}${report!.stillInTesting.totalConversionValue!.toFixed(2) || 0}`}
            </p>
            <p>ROAS: {report!.stillInTesting.roas!.toFixed(2) || 0}</p>
            <p>Conversions: {report!.stillInTesting.conversions! || 0}</p>
            <p>Revenue: {`${currency}${report!.stillInTesting.revenue!.toFixed(2) || 0}`}</p>
            <p>Clicks: {report.stillInTesting.clicks}</p>
            <p>Impressions: {report.stillInTesting.impressions}</p>
            <p>Total Products Tested: {report!.stillInTesting.totalProductsTested! || 0} </p>
            <StyledAccordion expanded={expanded === "unsuccessful"} onChange={handleExpand("unsuccessful")}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>Still In Testing SKUs</AccordionSummary>
              <AccordionDetails>
                <p style={{ marginLeft: "auto", marginRight: "auto" }}>{skuString(report.stillInTesting.skus)}</p>
              </AccordionDetails>
            </StyledAccordion>{" "}
          </>
        </div>
      )}
    </Box>
  );
};
