import { Box, Snackbar } from "@material-ui/core";
import { Alert } from "@mui/material";
import { DataGridPro, GridColDef, GridToolbar } from "@mui/x-data-grid-pro";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { getMPAProductsPerformance, MPAProductPerformance } from "../../../api/performance";
import { Heading } from "../../../components/Heading";
import { InnerContainer } from "../../../components/InnerContainer";

const columns: GridColDef[] = [
  { field: "sku", headerName: "SKU", width: 150 },
  { field: "title", headerName: "Title", width: 250 },
  {
    field: "roas",
    headerName: "Roas",
    renderCell: (params) => (params.value ? Number(params.value * 100).toFixed(0) + "%" : ""),
  },
  { field: "price", headerName: "Price" },
  { field: "nonManagedCost", headerName: "Non Managed Cost" },
  { field: "impressions", headerName: "Impressions" },
  { field: "cost", headerName: "Cost" },
  { field: "clicks", headerName: "Clicks" },
  { field: "conversions", headerName: "Conversions" },
  { field: "conversionValue", headerName: "Conversion Value" },
  { field: "revenue", headerName: "Revenue" },
  { field: "unitsSold", headerName: "Units Sold" },
  { field: "targetRoas", headerName: "Target Roas" },
];

const MAXIMUM_PRODUCTS_FOR_EXPORT = 200000;

export const MPAProductsPerformance = () => {
  const [isLoadingProducts, setIsLoadingProducts] = useState<boolean>(false);

  const startInterval = DateTime.now().setZone("utc").minus({ days: 30 }).startOf("day");
  const endInterval = DateTime.now().setZone("utc").minus({ days: 1 }).endOf("day");

  const [products, setProducts] = useState<MPAProductPerformance[]>([]);
  const [showAlert, setShowAlert] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoadingProducts(true);
      try {
        const results = await getMPAProductsPerformance(startInterval.toISO(), endInterval.toISO());
        setProducts(results || []);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoadingProducts(false);
      }
    };
    fetchData();
  }, []);

  const GridContent = () => {
    if (isLoadingProducts) {
      return <div>Loading...</div>;
    }
    if (products.length === 0) {
      return <div>No Products available - Is there something wrong with the connectors?</div>;
    }

    return (
      <DataGridPro
        rows={products}
        columns={columns}
        loading={isLoadingProducts}
        getRowId={(product: MPAProductPerformance) => product.sku}
        autoHeight
        slots={{
          toolbar: GridToolbar,
        }}
        pagination
        initialState={{
          pagination: { paginationModel: { pageSize: 5 } },
        }}
        style={{ overflowY: "auto" }}
        pageSizeOptions={[10, 20, 50]}
      />
    );
  };

  return (
    <Box
      sx={{
        bgcolor: "white",
        padding: "2em",
        margin: "2em",
        borderRadius: "0.5%",
        border: "1px solid",
        borderColor: "#dbdbdb",
      }}
    >
      <Heading>{`MPA Products with conversion | ${startInterval.toISODate()} - ${endInterval.toISODate()}`}</Heading>
      <InnerContainer>
        <GridContent />
      </InnerContainer>
      <Snackbar open={showAlert} autoHideDuration={3000} onClose={() => setShowAlert(false)}>
        <Alert severity={"error"} variant="filled">
          {`Cannot export ${products.length} products. Maximum is ${MAXIMUM_PRODUCTS_FOR_EXPORT} Contact Giraffe team`}
        </Alert>
      </Snackbar>
    </Box>
  );
};
