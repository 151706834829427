import { Box, CircularProgress } from "@material-ui/core";
import { StyledDashboardContainer } from "../dashboard/Dashboard";
import { Heading } from "../../components/Heading";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import NotificationBar from "../../components/NotificationBar";
import { DateTime, Interval } from "luxon";
import { SetStateAction, useEffect, useState } from "react";
import { DateRange } from "@mui/x-date-pickers-pro";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import { HorizontalRule, CheckCircle, Cancel } from "@mui/icons-material";
import { ChangeLogItem, getAllChangelogItems } from "../../api/changelog";
import { ChangeLogType } from "../changelog/Changelog";
import { AccountStateData, getAllAccountsStateActionsHistory } from "../../api/accountState";
import { useSearchParams } from "react-router-dom";
import { DateContainer, MetricsContainer } from "./StateActionsEmailMetricsStyledComponents";
import { ActionMetricItem } from "./StateActionsEmailMetricsTypes";

const formatDate = (date: string) => {
  const converted = DateTime.fromISO(date);

  if (converted.isValid) {
    return converted.toLocaleString(DateTime.DATETIME_SHORT);
  } else {
    return <HorizontalRule />;
  }
};

export const StateActionsEmailMetricsScreen = () => {
  const [isLoadingAccountState, setIsLoadingAccountState] = useState<boolean>(true);
  const defaultFromDate = DateTime.now().minus({ months: 3 });
  const [dateRange, setDateRange] = useState<DateRange<DateTime>>([defaultFromDate, DateTime.now()]);

  const [changeLogs, setChangeLogs] = useState<ChangeLogItem[] | null>(null);
  const [accountHistoryActions, setAccountHistoryActions] = useState<AccountStateData[] | null>(null);

  const [searchParams] = useSearchParams();
  const tenantIdParam = searchParams.get("tenantId");
  const [tenantId, setTenantId] = useState<string | null>(tenantIdParam);
  const [actionsData, setActionsData] = useState<ActionMetricItem[]>([]);

  useEffect(() => {
    if (!tenantIdParam) {
      return setTenantId(window?.USER_INFO?.tenantId);
    }
  }, [tenantIdParam]);

  useEffect(() => {
    async function fetchData() {
      let changeLogs: ChangeLogItem[] = [];
      let accountStateHistoryActions: AccountStateData[] = [];

      if (dateRange[0]?.isValid && dateRange[1]?.isValid && tenantId) {
        changeLogs = await getAllChangelogItems(dateRange[0].toJSDate(), dateRange[1].toJSDate(), ChangeLogType.ALL);

        accountStateHistoryActions = await getAllAccountsStateActionsHistory(
          dateRange[0].toISO(),
          dateRange[1].toISO(),
          "notification"
        );
      }

      setChangeLogs(changeLogs);
      setAccountHistoryActions(accountStateHistoryActions);
    }
    setIsLoadingAccountState(true);
    fetchData();
  }, [dateRange, tenantId]);

  useEffect(() => {
    if (changeLogs && changeLogs.length > 0 && accountHistoryActions && accountHistoryActions.length > 0) {
      const actionsData = generateActionsData(changeLogs, accountHistoryActions);

      setActionsData(actionsData);
      setIsLoadingAccountState(false);
    }
  }, [changeLogs, accountHistoryActions]);

  const columns: GridColDef[] = [
    { field: "tenantId", type: "string", headerName: "Tenant ID", width: 200 },
    {
      field: "date",
      type: "Date",
      headerName: "Date",
      renderCell: (params) => formatDate(params.value),
      width: 200,
    },
    {
      field: "actionTaken",
      type: "boolean",
      headerName: "Action Taken",
      width: 200,
      renderCell: (params) =>
        params.value ? <CheckCircle style={{ fill: "#00e676" }} /> : <Cancel style={{ fill: "#ff1744" }} />,
    },
    {
      field: "actionTakenDate",
      type: "Date",
      headerName: "Date action taken",
      renderCell: (params) => formatDate(params.value),
      width: 200,
    },
    {
      field: "actionTakenType",
      type: "string",
      headerName: "Action taken",
      renderCell: (params) => params.value,
      width: 200,
    },
  ];

  return (
    <StyledDashboardContainer>
      <NotificationBar
        title="Note"
        copy="Metrics in this screen are extrapolated from records where some assumptions have been drawn between user activity and emails sent out. Whilst we have taken care to ensure these assumptions are as accurate as possible, they are not guaranteed to be 100% accurate as user action may be independent of email sending."
        type="info"
      />

      <Box
        sx={{
          bgcolor: "white",
          padding: "2em",
          margin: "2em",
          borderRadius: "0.5%",
          border: "1px solid",
          borderColor: "#dbdbdb",
        }}
      >
        <Heading>Account State Actions Emails Metrics</Heading>
        <DateContainer>
          <h2>Date range:</h2>
          <DateRangePicker
            defaultValue={dateRange}
            value={dateRange}
            onChange={(newValue: SetStateAction<DateRange<DateTime>>) => {
              setDateRange(newValue);
            }}
          />
        </DateContainer>
        <MetricsContainer>
          <div>
            <h3>Emails sent</h3>
            {isLoadingAccountState ? <CircularProgress size="3.5rem" /> : <span>{actionsData.length}</span>}
          </div>
          <div>
            <h3>Actions taken</h3>
            {isLoadingAccountState ? (
              <CircularProgress size="3.5rem" />
            ) : (
              <>
                <span>{actionsData.length ? actionsData.reduce((a, b) => a + (b.actionTaken ? 1 : 0), 0) : 0}</span>
                <span style={{ display: "block", fontSize: "0.75rem", marginTop: "0.5rem" }}>
                  (
                  {actionsData.length
                    ? (
                        (actionsData.reduce((a, b) => a + (b.actionTaken ? 1 : 0), 0) / actionsData.length) *
                        100
                      ).toFixed(3)
                    : 0}
                  %)
                </span>
              </>
            )}
          </div>
        </MetricsContainer>

        <DataGridPro
          rows={actionsData}
          columns={columns}
          loading={isLoadingAccountState}
          pagination
          autoHeight
          getRowId={(row) => row._id}
          style={{ overflowY: "auto" }}
        />
      </Box>
    </StyledDashboardContainer>
  );
};

const generateActionsData = (changeLogs: any, accountHistoryActions: any) => {
  let processedData: any[] = accountHistoryActions.map((item: any, index: number) => {
    const interval = Interval.fromDateTimes(DateTime.fromISO(item.date), DateTime.fromISO(item.date).plus({ days: 3 }));
    const foundAction = changeLogs.find(
      (log: ChangeLogItem) => log.tenantId === item.tenantId && interval.contains(DateTime.fromISO(log.date))
    );

    return {
      _id: `${item.id}_${index}_${item.tenantId}}`,
      tenantId: item.tenantId,
      date: item.date,
      actionTaken: foundAction ? true : false,
      actionTakenDate: foundAction ? foundAction.date : null,
      actionTakenType: foundAction ? foundAction.changeType : "N/A",
    };
  });
  return processedData;
};

export default StateActionsEmailMetricsScreen;
