import { http } from "../UppAuth";

export enum AccountState {
  ONE = "one_below_roas_below_spend",
  TWO = "two_met_roas_below_spend",
  THREE = "three_above_roas_below_spend",
  FOUR = "four_below_roas_met_spend",
  FIVE = "five_met_roas_met_spend",
  SIX = "six_above_roas_met_spend",
  SEVEN = "seven_below_roas_above_spend",
  EIGHT = "eight_met_roas_above_spend",
  NINE = "nine_above_roas_above_spend",
}
