import { Box, Link } from "@material-ui/core";

interface CampaignMonitoringInterface {
  tenantId?: string | null;
}

export const CampaignMonitoringErrorBox = ({ tenantId }: CampaignMonitoringInterface) => {
  if (!tenantId)
    return (
      <Box style={{ margin: 10 }}>
        {`Please define a tenantId in the URL. Or reselect from the `}
        <Link href={"/AccountsOverview"} target="_blank" rel="noreferrer">
          {`Accounts Overview`}
        </Link>
        {` page`}.
      </Box>
    );
  return <Box style={{ margin: 10 }}> {`No campaigns found for account for date range`}</Box>;
};
