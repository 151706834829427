import styled from "@emotion/styled";
import { Check } from "@material-ui/icons";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CommonPayload } from "@volo-ai/upp-temporal-utils";
import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { createJob } from "../../api/jobscheduler";
import { publishSlackNotification } from "../../api/platformadmin";
import Button from "../../components/Wizards/Button";
import { useAllJobs } from "../../hooks/useJobScheduler";
import { useMappedPerformance } from "../../hooks/usePerformance";
import { useTenantConfig } from "../../hooks/useTenants";
import { getCurrencySymbol } from "../../services/utils";
import { uppColourPalette } from "../../util/colours";
import { CheckContainer } from "./DatasourceWizard";
import { Footer, Steps, WizardContext } from "./OnboardingWizard";
import { Completion } from "./DataDownloadWizard";

const Heading = styled.div`
  display: flex;
  padding: 16px 0;
  align-items: center;
`;

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 24px;
`;

const Header = styled.div`
  background: ${uppColourPalette.coolGrey[100]};
  padding: 16px;
  text-align: left;
`;
const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid ${uppColourPalette.coolGrey[100]};
`;

const CompletionRow = styled.div`
  display: flex;
`;

const Column = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const WizardSummary = ({ setStep }: { setStep: Dispatch<SetStateAction<number>> }) => {
  const { data, isSuccess } = useAllJobs();
  const { wizardState, updateWizardState } = useContext(WizardContext);
  const [isJobsSuccess, setIsJobsSuccess] = useState<boolean | undefined>(undefined);
  const [isPerformanceSuccess, setIsPerformanceSuccess] = useState<boolean | undefined>(undefined);
  const [slackSent, setSlackSent] = useState<boolean>(false);
  const performanceData = useMappedPerformance().data!;
  const [scheduledJobCount, setScheduledJobCount] = useState<number>(0);

  const { data: tenantConfig, isSuccess: isTenantSuccess } = useTenantConfig(
    localStorage.getItem("onboardingTenant") as string
  );

  const queryClient = useQueryClient();

  const checkSuccess = () => {
    if (isSuccess && data?.length > 0) {
      let expiredCount = 0;
      let scheduledCount = 0;
      if (data.filter((job: any) => job.status === "expired").length > 0) {
        expiredCount = data.filter((job: any) => job.status === "expired").length;
      }
      if (data.filter((job: any) => job.status === "scheduled").length > 0) {
        scheduledCount = data.filter((job: any) => job.status === "scheduled").length;
      }

      if (expiredCount === 1 && scheduledCount === 2) {
        setIsJobsSuccess(true);
      } else {
        setIsJobsSuccess(false);
      }
      updateWizardState({
        jobs: { initial: data[0].status === "expired", tradepack: scheduledCount === 2, nightly: scheduledCount === 2 },
      });
    }
    if (performanceData) {
      setIsPerformanceSuccess(performanceData);
    }
  };

  const jobMutation = useMutation(
    async () => {
      console.log("Setting jobs...");
      if (
        isSuccess &&
        data.length === 1 &&
        data[0].status === "expired" &&
        data.filter((job: any) => job.status === "scheduled").length === 0
      ) {
        await createDailyDownloadJob();
        await createTradepackJob();
        setScheduledJobCount(scheduledJobCount + 2);
      }
    },

    {
      onSuccess: () => {
        queryClient.invalidateQueries(["allJobs"]);
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  useEffect(() => {
    if (isSuccess && scheduledJobCount === 0) {
      jobMutation.mutateAsync();
    }

    checkSuccess();
    console.log(wizardState!.slackSent);

    if (isJobsSuccess && slackSent === false && isTenantSuccess && wizardState && wizardState.slackSent !== true) {
      setSlackSent(true);
      notifySlackOfCompletion(
        window.TENANT_ID,
        isJobsSuccess === true && isPerformanceSuccess === true ? "success" : "failure"
      );
    }
    // eslint-disable-next-line
  }, [
    data,
    isSuccess,
    isJobsSuccess,
    isPerformanceSuccess,
    slackSent,
    performanceData,
    isTenantSuccess,
    scheduledJobCount,
  ]);

  return (
    <>
      <Heading>Summary</Heading>
      <Content>
        <Column>
          <Header>Connected Data Source</Header>
          <Row>
            <div>Google Combined</div>
            <CheckContainer active={wizardState?.datasource.googleCombined}>
              <Check fontSize="small" />
            </CheckContainer>
          </Row>
          <Row>
            <div>Orders</div>
            <div>{wizardState?.datasource.order}</div>
          </Row>
          <Header>Mapping Products</Header>
          <Row>
            <div>Field</div>
            <div>{wizardState?.mapping!}</div>
          </Row>
          <Header>Scheduled Jobs</Header>
          <Row>
            Initial Data Download
            <CheckContainer active={wizardState?.jobs.initial}>
              <Check fontSize="small" />
            </CheckContainer>
          </Row>
          <Row>
            Weekly Tradepack
            <CheckContainer active={wizardState?.jobs.tradepack}>
              <Check fontSize="small" />
            </CheckContainer>
          </Row>
          <Row>
            Nightly Update
            <CheckContainer active={wizardState?.jobs.nightly}>
              <Check fontSize="small" />
            </CheckContainer>
          </Row>
        </Column>
        <Column>
          <Header>Slack</Header>
          <Row>
            <div>Channel</div>
            <div>{wizardState?.slackChannel}</div>
          </Row>
          <Header>Users</Header>
          {wizardState && wizardState.users.length > 0 ? (
            wizardState?.users.map((item: any) => {
              return (
                <Row>
                  <div>{item.username}</div>
                  <div>{item.email}</div>
                </Row>
              );
            })
          ) : (
            <Row>
              <div>No users added</div>
            </Row>
          )}
        </Column>
        <Column>
          <Header>Targets</Header>
          <Row>
            <div>ROAS</div>
            <div>{wizardState?.targetRoas}%</div>
          </Row>
          <Row>
            <div>Spend</div>
            <div>
              {getCurrencySymbol(wizardState?.currency)}
              {wizardState?.targetSpend}
            </div>
          </Row>
        </Column>
      </Content>
      <CompletionRow>
        <Completion status="completed">
          <div>
            Next Steps: The slack channel {wizardState?.slackChannel} has been notified that the account is now
            onboarded. A member of engineering staff will setup automated campaign management.
          </div>
          <div>Any questions / concerns: Ask on {wizardState?.slackChannel}</div>
        </Completion>
      </CompletionRow>

      <Footer>
        <Button
          onClick={() => {
            localStorage.setItem("onboardingStep", String(5));
            setStep(Steps.USERS);
          }}
        >
          Back
        </Button>
      </Footer>
    </>
  );

  async function notifySlackOfCompletion(tenantId: string, status: "success" | "failure") {
    updateWizardState({ slackSent: true });
    const attachments =
      status === "success"
        ? [
            {
              title: "Onboarding Wizard Success :white_check_mark:",
              text: `Successfully completed onboarding wizard for tenant ${tenantId}\n Mapping: ${
                isPerformanceSuccess === true ? "Success" : "Failure"
              }\n Jobs: ${isJobsSuccess === true ? "Success" : "Failure"}`,
              fallback: `[${tenantId}] successfully completed onboarding wizard`,
            },
          ]
        : [
            {
              title: "Onboarding Wizard Failure :anger_face:",
              text: `Could not complete onboarding wizard for tenant ${tenantId}\n Mapping: ${
                isPerformanceSuccess === true ? "Success" : "Failure"
              }\n Jobs: ${isJobsSuccess === true ? "Success" : "Failure"}`,
              fallback: `[${tenantId}] unsuccessfully completed onboarding wizard`,
            },
          ];

    await publishSlackNotification({
      channel: tenantConfig ? (tenantConfig.customerSlackChannel as string) : "customer-ops",
      attachments: attachments,
    });
  }
};

async function createDailyDownloadJob() {
  const payload: CommonPayload = {
    cronSchedule: "auto",
    tenantId: window.TENANT_ID,
    configuration: {},
    workflowToRun: {
      order: true,
      product: true,
      cost: true,
      costAccount: true,
      performance: {
        account: true,
        product: true,
      },
    },
  };

  createJob(payload, "downloads");
}

async function createTradepackJob() {
  const payload: CommonPayload = {
    cronSchedule: "0 7 * * MON",
    tenantId: window.TENANT_ID,
    configuration: {
      productsOnPromotion: true,
      productTrending: true,
    },
    workflowToRun: {
      tradePack: true,
    },
  };

  createJob(payload, "trade-pack");
}
