import getSymbolFromCurrency from "currency-symbol-map";
import { useTenantConfig } from "../hooks/useTenants";

//Looks a bit weird, but needs to be a react component to use useStore()
export const GetCurrencySymbol = () => {
  const defaultCurrency = "£";
  const data = useTenantConfig(window.TENANT_ID).data;
  const currencySymbol = getSymbolFromCurrency(data?.currency as string) || defaultCurrency;
  return addWhiteSpace(currencySymbol);
};

function addWhiteSpace(symbol: string): string {
  return new RegExp("[A-Za-z]").test(symbol) ? `${symbol} ` : symbol;
}
