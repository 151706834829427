import { Dialog, DialogContent, DialogTitle, Divider, Grid, TextField } from "@material-ui/core";
import { CloseRounded } from "@material-ui/icons";
import { ChangeEvent, Dispatch, SetStateAction } from "react";
import { StyledButtonFilled } from "../../datasources/Datasources";
import { StyledCloseButton } from "../../datasources/SetupConnection";
import SwitchConfig from "../../tenantConfig/SwitchConfig";
import { StyledRow } from "../../tenantConfig/TenantConfig";
import { StyledInputLabel } from "../AddJobDialog";
import {
  CampaignAutomationConfiguration,
  CampaignAutomationWorkflowToRun,
  CommonPayload,
} from "@volo-ai/upp-temporal-utils";

interface CampaignAutomationProps {
  handleToggle: (prop: any) => (e: ChangeEvent<HTMLInputElement>) => void;
  jobPayload: CommonPayload;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  handleSubmit?: React.MouseEventHandler<HTMLButtonElement>;
  handleDataChange: (prop: any) => (e: ChangeEvent<HTMLInputElement>) => void;
}

export const CampaignAutomationWorkflow = ({
  handleToggle,
  jobPayload,
  open,
  setOpen,
  handleSubmit,
  handleDataChange,
}: CampaignAutomationProps) => {
  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>
        Campaign Automation Workflow
        <StyledCloseButton onClick={() => setOpen(false)}>
          <CloseRounded />
        </StyledCloseButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container>
          <Grid item>
            <StyledInputLabel>PGA</StyledInputLabel>
            <SwitchConfig
              onChange={handleToggle("workflowToRun.pga")}
              checked={(jobPayload.workflowToRun as CampaignAutomationWorkflowToRun)?.pga}
            ></SwitchConfig>
          </Grid>
          <Grid item>
            <StyledInputLabel>Dry Run</StyledInputLabel>
            <SwitchConfig
              onChange={handleToggle("configuration.isDryRun")}
              checked={(jobPayload.configuration as CampaignAutomationConfiguration)?.isDryRun}
            ></SwitchConfig>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <StyledInputLabel>Update Google Campaigns Only Without Running PGA</StyledInputLabel>
            <SwitchConfig
              onChange={handleToggle("configuration.updateOnly")}
              checked={(jobPayload.configuration as CampaignAutomationConfiguration)?.updateOnly}
            ></SwitchConfig>
            <StyledInputLabel>Target Spend</StyledInputLabel>
            <SwitchConfig
              onChange={handleToggle("workflowToRun.spend")}
              checked={(jobPayload.workflowToRun as CampaignAutomationWorkflowToRun)?.spend}
            ></SwitchConfig>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <StyledInputLabel>Channel</StyledInputLabel>
            <TextField type="string" variant="outlined" onChange={handleDataChange("channel")} />
          </Grid>
        </Grid>
        <StyledRow style={{ marginTop: "20px" }}>
          <StyledButtonFilled onClick={handleSubmit}>Create</StyledButtonFilled>
        </StyledRow>
      </DialogContent>
    </Dialog>
  );
};
