import { CommonPayload } from "@volo-ai/upp-temporal-utils";
import { http } from "../UppAuth";
import { DateTime } from "luxon";

export enum JobStatus {
  Scheduled = "scheduled",
  Running = "running",
  Completed = "completed",
  Failed = "failed",
  Expired = "expired",
  Unknown = "unknown",
}

export interface ScheduledJob {
  tenantId: string;
  jobName: string;
  workflowId: string;
  taskQueue: string;
  status: JobStatus;
  jobType: string;
  payload: CommonPayload;
  active: boolean;
  nextInvocation?: Date;
  createdOn: Date;
  updateOn?: Date;
  jobStarted?: Date;
  latestUpdateTime?: Date;
  results?: {
    wasSuccessful: boolean;
    message: string;
    results: any;
  };
}

export type Path =
  | "trade-pack"
  | "downloads"
  | "auto-create-download"
  | "campaign-automation"
  | "mpa-account"
  | "mpa-sku"
  | "monitoring"
  | "visibility-performance-report"
  | "opportunities"
  | "search-trends";

export async function createJob(payload: CommonPayload, path: Path): Promise<any> {
  try {
    if (payload.channel) {
      payload.channel = payload.channel.toLowerCase();
    }
    return await http.post<any>(
      `${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/job-scheduler/${path}/schedule`,
      {
        payload,
      },
      { validateStatus: () => true }
    );
  } catch (e) {
    return { data: { wasSuccessful: false, message: (e as Error).message } };
  }
}

export async function getAllJobs(): Promise<any> {
  const result = await http.get<any>(`${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/job-scheduler/jobs`);
  if (result.status !== 200) {
    console.log("Failed to fetch create schedule");
  }
  return result.data;
}

export async function getActiveJob(jobName: string): Promise<any> {
  const result = await http.get<any>(
    `${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/job-scheduler/jobs?active=true&jobName=${jobName}`
  );
  if (result.status !== 200) {
    console.log("Failed to fetch active jobs");
  }
  return result.data;
}

export async function getJobHistory(jobName: string): Promise<ScheduledJob> {
  const result = await http.get<any>(
    `${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/job-scheduler/jobs/history?jobName=${jobName}`
  );
  if (result.status !== 200) {
    console.log("Failed to fetch job history");
  }
  return result.data;
}

export async function deleteJob(jobName: string): Promise<boolean> {
  const result = await http.delete<any>(`${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/job-scheduler/job/${jobName}`);
  if (result.status !== 202) {
    console.log("Failed to delete job");
    return false;
  }
  return true;
}

export async function getAllScheduledJobs(): Promise<SummaryScheduledJob[]> {
  const result = await http.get<any>(`${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/job-scheduler/jobs/all-scheduled`);
  if (result.status !== 200) {
    console.log("Failed to fetch all scheduled jobs");
  }
  const jobs: SummaryScheduledJob[] = [];
  for (const job of result.data.jobs) {
    const history = await getLatestHistory(job.jobName);
    jobs.push({
      tenantId: job.tenantId,
      jobName: job.jobName,
      status: job.status,
      cronSchedule: job.payload.cronSchedule,
      jobType: job.jobType,
      latestUpdate: history?.latestUpdate,
      latestUpdateTime: history?.latestUpdateTime,
      nextInvocation: job.nextInvocation,
    });
  }
  return jobs;
}

export async function getLatestHistory(jobName: string): Promise<any> {
  const result = await http.get<any>(
    `${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/job-scheduler/job/${jobName}/latest-history`
  );
  if (result.status !== 200) {
    console.log("Failed to fetch latest job history");
  }
  return result.data.history;
}

export interface TenantsWithRbaScheduled {
  tenants: string[];
}
export interface SummaryScheduledJob {
  tenantId: string;
  jobName: string;
  cronSchedule: string;
  status: string;
  jobType: string;
  latestUpdate: string;
  latestUpdateTime: DateTime;
  nextInvocation: DateTime;
}
