import { ReactNode, useState } from "react";
import { User } from "../../api/userManagement";
import { WizardContext } from "./OnboardingWizard";

export enum OrderSource {
  GOOGLE_ANALYTICS = "googleanalytics",
  MAGENTO = "magento",
  SHOPIFY = "shopify",
}

export interface WizardState {
  slackChannel?: string;
  targetRoas: number;
  targetSpend: number;
  datasource: {
    googleCombined: boolean;
    order?: OrderSource;
  };
  mapping: string;
  currency: string;
  users: User[];
  jobs: {
    nightly: boolean;
    tradepack: boolean;
    initial: boolean;
  };
  slackSent: boolean;
}

export interface WizardContextProps {
  wizardState?: WizardState;
  updateWizardState: (newState: Partial<WizardState>) => void;
}

export function WizardProvider(props: { children: ReactNode }) {
  const [wizardState, setWizardState] = useState<WizardState>({
    slackChannel: undefined,
    targetRoas: 0,
    targetSpend: 0,
    datasource: {
      googleCombined: false,
      order: undefined,
    },
    mapping: "",
    currency: "",
    users: [],
    jobs: {
      nightly: false,
      tradepack: false,
      initial: false,
    },
    slackSent: false,
  });

  const updateWizardState = (newState: Partial<WizardState>) => setWizardState({ ...wizardState, ...newState });

  const wizardContext = {
    wizardState,
    updateWizardState,
  };

  return <WizardContext.Provider value={wizardContext}>{props.children}</WizardContext.Provider>;
}
