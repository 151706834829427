import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import styled from "styled-components";
import { Comparison } from "../../api/health";
import AuditHealthTableCell from "./AuditHealthTableCell";

const GridWrapper = styled.div`
  height: 500px;
`;

function AuditHealthReportTable({
  accountLevelHealthReport,
  isLoading,
}: {
  accountLevelHealthReport: Comparison[];
  isLoading: boolean;
}): JSX.Element {
  const percentageRenderer = (params: GridRenderCellParams<number>) => (
    <AuditHealthTableCell percentageDifference={params.value as number} />
  );

  const columns: GridColDef[] = [
    { headerName: "Metric", field: "metric", width: 250 },
    { headerName: "Audit Value", field: "auditValue", width: 250 },
    { headerName: "External Value", field: "externalValue", width: 250 },
    { headerName: "Difference", field: "externalValuePercentDifference", width: 250, renderCell: percentageRenderer },
    { headerName: "External Value source", field: "externalValueSource", width: 250 },
  ];

  return (
    <GridWrapper>
      <DataGrid loading={isLoading} rows={accountLevelHealthReport} columns={columns} getRowId={(row) => row.metric} />
    </GridWrapper>
  );
}

export default AuditHealthReportTable;
