import styled from "@emotion/styled";
import { ProductGroupPerformanceAuditItem } from "../../api/audit";
import { GetCurrencySymbol } from "../../util/currency";
import { numberFormat } from "../../util/textUtil";

const StyledRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  border-bottom: 1px solid;
`;

const StyledHeader = styled.div`
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  padding: 0 8px;
  display: grid;
  background: pink;
  font-family: Jost;
  color: white;
`;

const StyledFooter = styled.div`
  justify-content: center;
  gap: 12px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  color: white;
  font-family: Jost;
  background: pink;
  box-shadow: 0 -2px 2px rgba(123, 119, 238, 0.1);
  border-radius: 0 0 4px 4px;
`;

const StyledWrapper = styled.div`
  display: grid;
  grid-template-rows: 60px 390px 60px;
  @media screen and (min-width: 1800px) {
    grid-template-rows: 60px 850px 60px;
  }
`;

export const RowWrapper = styled.div`
  overflow: auto;
  max-height: 100%;
`;

export const Cell = styled.div`
  display: flex;
  padding: 8px;
  align-items: center;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: small;
`;

export const CellContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 2px;
  padding: 8px;
  width: 100%;
`;

const headers = [
  "groupId",
  "impressions",
  "clicks",
  "cost",
  "conversions",
  "conversionValue",
  "revenue",
  "unitsSold",
  "costPercentile",
  "unitsSoldPercentile",
  "categorisation",
];

export const formatValue = (key: string, value: string | number) => {
  switch (key) {
    case "percentage":
    case "costPercentile":
    case "unitsSoldPercentile":
      return numberFormat((value as number) * 100, "%");
    case "unitsSoldShare":
    case "revenueShare":
    case "costShare":
    case "percentageOfTotalInventory":
    case "percentProductsAchievingAvgImp":
    case "percentProductsAchievingAvgConv":
      return numberFormat(value as number, "%");
    case "monetary":
    case "cost":
    case "conversionValue":
    case "revenue":
      return numberFormat(value as number, GetCurrencySymbol());
    case "plainNumber":
    case "impressions":
    case "conversions":
    case "unitsSold":
    case "clicks":
    case "productsAchievingAvgImp":
    case "productsAchievingAvgConv":
    case "avgImpPerClick":
    case "avgConvPerClick":
      return numberFormat(value as number, "");
    default:
      return value;
  }
};

const AuditProductTable = (props: { auditItems: ProductGroupPerformanceAuditItem[]; children: any }) => {
  return (
    <StyledWrapper>
      <StyledHeader>
        {headers.map((key) => (
          <Cell key={key}>
            <CellContent>{key}</CellContent>
          </Cell>
        ))}
      </StyledHeader>
      <RowWrapper>
        {props.auditItems.map((auditItem: ProductGroupPerformanceAuditItem) => (
          <StyledRow key={props.auditItems.indexOf(auditItem)}>
            {Object.keys(auditItem).map((key) => (
              <Cell key={key}>
                <CellContent>{formatValue(key, (auditItem as any)[key])}</CellContent>
              </Cell>
            ))}
          </StyledRow>
        ))}
      </RowWrapper>
      <StyledFooter>{props.children}</StyledFooter>
    </StyledWrapper>
  );
};

export default AuditProductTable;
