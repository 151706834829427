import styled from "@emotion/styled";
import { ArrowForward, ArrowBack, Check } from "@material-ui/icons";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CommonPayload } from "@volo-ai/upp-temporal-utils";
import { AnimatePresence, motion } from "framer-motion";
import { DateTime } from "luxon";
import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";
import { createJob } from "../../api/jobscheduler";
import { updateTenantConfig } from "../../api/tenant";
import Button from "../../components/Wizards/Button";
import { useAllJobs, useJobHistory } from "../../hooks/useJobScheduler";
import { uppColourPalette } from "../../util/colours";
import { CheckContainer } from "./DatasourceWizard";
import { Footer, Steps, WizardContext } from "./OnboardingWizard";

const Heading = styled.div`
  display: flex;
  padding: 16px 0;
`;

const Row = styled.div`
  gap: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SlideContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

const DotContainer = styled.div`
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
`;

type DotProps = {
  status: string;
  active: boolean;
};

const Dot = styled.div<DotProps>`
  display: inline-block;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: ${(props) => (props.active ? `2px solid white` : "none")};
  box-shadow: ${(props) =>
    props.active
      ? `0 2px 3px rgba(63, 57, 84, .4)
  `
      : "none"};
  background: ${(props) =>
    props.status === "completed" ? uppColourPalette.darkGreen[400] : uppColourPalette.coolGrey[200]};
  &:hover {
    cursor: pointer;
  }
`;

type CompletionProps = {
  status: string;
};

export const Completion = styled.div<CompletionProps>`
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  font-size: 16px;
  border-radius: 4px;
  color: ${(props) => (props.status === "completed" ? "white" : "black")};
  background: ${(props) => (props.status === "completed" ? uppColourPalette.darkGreen[500] : "none")};
  margin-bottom: 24px;
`;

type ArrowContainerProps = {
  onClick: () => void;
};

const ArrowContainer = styled.div<ArrowContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background: ${uppColourPalette.navy[500]};
  color: white;
  &: hover {
    cursor: pointer;
  }
`;

const variants = {
  enter: (direction: number) => {
    return {
      x: direction > 0 ? -200 : 200,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction: number) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 200 : -200,
      opacity: 0,
    };
  },
};

export const DataDownloadWizard = ({ setStep }: { setStep: Dispatch<SetStateAction<number>> }) => {
  const [slide, setSlide] = useState(0);
  const [direction] = useState(0);
  const { updateWizardState } = useContext(WizardContext);
  const { data } = useAllJobs();
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const { data: jobHistory, isSuccess: jobHistoryIsSuccess } = useJobHistory(
    data && data.length ? data[0].jobName : undefined
  );
  const queryClient = useQueryClient();

  const downloadFinished = data?.length > 0 && data?.[0].status === "expired";

  const jobMutation = useMutation(
    () => {
      setIsDownloading(true);
      return createDownloadJob();
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["allJobs"]);
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  const goBackward = () => {
    if (slide !== 0) {
      return setSlide(slide - 1);
    }
  };

  const goForward = () => {
    if (slide !== jobHistory.length - 1) {
      return setSlide(slide + 1);
    }
  };

  const isScheduledJobs = data?.length > 0 && data?.[0].status === "scheduled";
  const isExpiredJobs = data?.length > 0 && data?.[0].status === "expired";
  const isJobRunning = data?.length > 0 && data?.[0].status === "running";

  useEffect(() => {
    if (downloadFinished) {
      updateTenantConfig(localStorage.getItem("onboardingTenant") as string, [{ key: "accountStatus", value: "Live" }]);
      updateWizardState({ jobs: { initial: true, tradepack: false, nightly: false } });
    }
    if (
      (!isDownloading && data?.length === 0) ||
      (!isDownloading && isScheduledJobs && !isJobRunning) ||
      (!isDownloading && !isExpiredJobs && !isJobRunning)
    ) {
      jobMutation.mutate();
    }
  }, []);

  const nextPage = () => {
    localStorage.setItem("onboardingStep", String(5));
    setStep(Steps.USERS);
  };
  const backPage = () => {
    localStorage.setItem("onboardingStep", String(3));
    setStep(Steps.TARGETS);
  };
  return (
    <>
      <Heading>Data Download</Heading>
      <>
        <Row style={{ flexGrow: 2 }}>
          <AnimatePresence initial={true} custom={direction} mode="popLayout">
            <motion.div
              layout="position"
              key={slide}
              custom={direction}
              variants={variants}
              initial="enter"
              animate="center"
              draggable={false}
              exit="exit"
              transition={{
                x: { type: "spring", stiffness: 100, damping: 30 },
                opacity: { duration: 0.25 },
              }}
            >
              <div>
                {jobHistoryIsSuccess && jobHistory.length !== 0 ? (
                  <SlideContainer>
                    <div>{jobHistory[slide].step}.</div>
                    <div>{jobHistory[slide].latestUpdate}</div>
                    <>
                      {jobHistory[slide].status === "running" ? (
                        <ClipLoader />
                      ) : (
                        <CheckContainer active={true}>
                          <Check fontSize="small" />
                        </CheckContainer>
                      )}
                    </>
                  </SlideContainer>
                ) : (
                  <ClipLoader />
                )}
              </div>
            </motion.div>
          </AnimatePresence>
        </Row>
        <Row>
          {downloadFinished && <Completion status="completed">Download Complete! Click Next to continue</Completion>}
        </Row>
        <Row>
          <ArrowContainer onClick={goBackward}>
            <ArrowBack fontSize="small" />
          </ArrowContainer>
          Click to Navigate
          <ArrowContainer onClick={goForward}>
            <ArrowForward fontSize="small" />
          </ArrowContainer>
        </Row>
        <Row style={{ padding: "16px 0" }}>
          {jobHistoryIsSuccess &&
            jobHistory.map((item: any) => {
              return (
                <DotContainer onClick={() => setSlide(item.step)}>
                  <div>{item.step}</div>
                  <Dot active={slide === item.step} status={item.status} key={item.latestUpdate} />
                </DotContainer>
              );
            })}
        </Row>
      </>

      <Footer>
        <Button onClick={backPage}>Back</Button>
        <Button disabled={!downloadFinished} onClick={nextPage}>
          Next
        </Button>
      </Footer>
    </>
  );
};

async function createDownloadJob() {
  const fromDate = DateTime.now().minus({ days: 31 }).toISODate();
  const toDate = DateTime.now().minus({ days: 1 }).toISODate();
  const payload: CommonPayload = {
    cronSchedule: "* * * * *",
    tenantId: window.TENANT_ID,
    configuration: {
      product: {
        from: fromDate,
        to: toDate,
      },
      order: {
        from: fromDate,
        to: toDate,
      },
      cost: {
        from: fromDate,
        to: toDate,
      },
      performance: {
        account: {
          from: fromDate,
          to: toDate,
        },
        product: {
          from: fromDate,
          to: toDate,
        },
      },
    },
    workflowToRun: {
      order: true,
      product: true,
      cost: true,
      costAccount: true,
      performance: {
        account: true,
        product: true,
      },
    },
  };

  createJob(payload, "downloads");
}
