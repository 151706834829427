import { Button, Dialog, DialogTitle } from "@material-ui/core";
import { CloseRounded } from "@material-ui/icons";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { getSkuMapping } from "../../api/datasources";

export const StyledCloseButton = styled(Button)`
  border-radius: 5px !important;
  float: right !important;
  color: #9e9e9e !important;
`;
interface SetupProps {
  isOpen: boolean;
  onClose: () => void;
}

export const SkuMapping = ({ isOpen, onClose }: SetupProps) => {
  const [skuMapping, setSkuMapping] = useState<{ productMapping: string }>({ productMapping: "" });

  useEffect(() => {
    getMapping();
    // eslint-disable-next-line
  }, [skuMapping]);

  async function getMapping() {
    const skuMappingResult = await getSkuMapping();
    setSkuMapping(skuMappingResult);
  }

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog open={isOpen} maxWidth="md" fullWidth onClose={handleClose}>
      <DialogTitle>
        Suggested SKU Mapping
        <StyledCloseButton onClick={handleClose}>
          <CloseRounded />
        </StyledCloseButton>
      </DialogTitle>
      {skuMapping.productMapping === "" && <>Unable to find sku mapping</>}
      {skuMapping.productMapping !== "" && <>Suggested Mapping: {skuMapping.productMapping}</>}
    </Dialog>
  );
};

export default SkuMapping;
