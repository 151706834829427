import { useQuery } from "@tanstack/react-query";
import { Connection, Datasource, getAllDatasources, getAvailableConnections } from "../api/datasources";

export function useAllConnections() {
  return useQuery<Connection[], Error>(["connections"], () => getAvailableConnections());
}

export function useCurrentDatasources() {
  return useQuery<Datasource[], Error>(["currentDatasources"], () => getAllDatasources());
}
