import { Box, CircularProgress } from "@material-ui/core";
import { Heading } from "../../components/Heading";
import { StyledDashboardContainer } from "../dashboard/Dashboard";
import { useEffect, useState } from "react";
import { Tenant, getAllLiveTenants } from "../../api/tenant";
import AutopilotTenantsOverviewTable from "./AutopilotTenantsOverviewTable";
import {
  CampaignAutomationStrategy,
  CampaignAutomationStrategyPlaybookItem,
  getCampaignAutomationStrategies,
  getCampaignAutomationStrategiesPlaybook,
} from "../../api/campaignAutomation";
import _ from "lodash";

const AutopilotState = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [tenants, setTenants] = useState<Tenant[]>([]);
  const [campaignStrategies, setCampaignStrategies] = useState<CampaignAutomationStrategy[]>([]);
  const [campaignStrategiesPlaybook, setCampaignStrategiesPlaybook] = useState<
    CampaignAutomationStrategyPlaybookItem[]
  >([]);
  const [filteredStrategies, setFilteredStrategies] = useState<CampaignAutomationStrategy[]>([]);

  const fetchCampaignStrategies = async () => {
    setIsLoading(true);
    const allStrategies = await getCampaignAutomationStrategies();
    setCampaignStrategies(allStrategies);
    setIsLoading(false);
  };

  const fetchAutopilotStrategiesPlaybook = async () => {
    setIsLoading(true);
    const allStrategies = await getCampaignAutomationStrategiesPlaybook();
    setCampaignStrategiesPlaybook(allStrategies);
    setIsLoading(false);
  };

  useEffect(() => {
    async function fetchAllTenants() {
      setIsLoading(true);
      const allTenants = await getAllLiveTenants();
      setTenants(allTenants);
      setIsLoading(false);
    }
    fetchAllTenants();
  }, []);

  useEffect(() => {
    fetchCampaignStrategies();
    fetchAutopilotStrategiesPlaybook();
  }, []);

  useEffect(() => {
    const filteredAutopilotTenants = filterAutopilotTenants(tenants);
    const filteredStrategies = campaignStrategies.filter((strategy) =>
      filteredAutopilotTenants.find((tenant) => tenant.id === strategy.tenantId)
    );

    setFilteredStrategies(_.orderBy(filteredStrategies, "filteredStrategies"));
  }, [tenants, campaignStrategies]);

  useEffect(() => {
    if (tenants.length > 0) {
      setIsLoading(false);
    }
  }, [tenants]);

  return (
    <StyledDashboardContainer>
      <Box
        sx={{
          bgcolor: "white",
          padding: "2em",
          margin: "2em",
          marginBottom: "0",
          borderRadius: "0.5%",
          border: "1px solid",
          borderColor: "#dbdbdb",
        }}
      >
        <Heading>Accounts Autopilot State</Heading>
        <div style={{ textAlign: "left" }}>
          <p>
            Total accounts on autopilot:{" "}
            {isLoading ? (
              <CircularProgress style={{ marginLeft: "1rem" }} size="1rem" />
            ) : (
              <span style={{ fontWeight: "bold" }}>{computeAccountsOnAutopilot(tenants)}</span>
            )}
          </p>
        </div>
        <div style={{ height: "1px", width: "95%", backgroundColor: "#ccc", margin: "2rem 2.5%" }}></div>
        <Heading>Autopilot Overview</Heading>
        <AutopilotTenantsOverviewTable
          fetchCampaignStrategies={fetchCampaignStrategies}
          campaignStrategiesPlaybook={campaignStrategiesPlaybook}
          strategies={filteredStrategies}
          isLoading={isLoading}
        />
      </Box>
    </StyledDashboardContainer>
  );
};

function removeDuplicateTenants(tenants: Tenant[]): Tenant[] {
  const regex = /-[a-z]{2}(?=[^-]*$)/;
  const regexArray = tenants.map((tenant) => {
    return {
      ...tenant,
      id: tenant.id.replace(regex, ""),
    };
  });

  const ids = new Set();
  return regexArray.filter((tenant) => {
    if (ids.has(tenant.id)) {
      return false;
    }
    ids.add(tenant.id);
    return true;
  });
}

const computeAccountsOnAutopilot = (tenants: Tenant[]) => {
  const noDuplicatedTenants = removeDuplicateTenants(tenants);

  const tenantsCount = noDuplicatedTenants.length;
  const teantsOnAutopilot = noDuplicatedTenants.filter(
    (tenant) =>
      tenant.config["automation.enabled"] &&
      tenant.config?.autopilot_enabled !== false &&
      tenant.config?.intakeMargin_enabled !== true
  ).length;

  return `${teantsOnAutopilot}/${tenantsCount}`;
};

const filterAutopilotTenants = (tenants: Tenant[]) => {
  return tenants.filter(
    (tenant) =>
      tenant.config["automation.enabled"] &&
      tenant.config?.autopilot_enabled !== false &&
      tenant.config?.intakeMargin_enabled !== true
  );
};

export default AutopilotState;
