import React from "react";
import { OutlinedDiv } from "../../components/OutlinedDiv";
import { LinkItem } from "./UsefulLinks";

type Props = {
  links: Array<LinkItem>;
  title: string;
};

export const LinkTray = ({ links, title }: Props) => {
  return (
    <OutlinedDiv label={title}>
      {links.map((link: LinkItem) => {
        return (
          <p>
            <a href={link.url} target="_blank" rel="noreferrer">
              {link.title}
            </a>
          </p>
        );
      })}
    </OutlinedDiv>
  );
};
