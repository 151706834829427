import React from "react";
import { createRoot } from "react-dom/client";

import { Auth0Provider } from "@auth0/auth0-react";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { UppAuth } from "./UppAuth";

declare global {
  interface Window {
    APP_CONFIG: typeof appConfig;
    APP_VERSION: string;
    USER_INFO: any;
    TENANT_ID: any;
    dataLayer: any[];
  }
}
export const appConfig = {
  svcUrl: window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL,
  keycloak: {
    url: window.__RUNTIME_CONFIG__.REACT_APP_AUTH_URL,
    client: window.__RUNTIME_CONFIG__.REACT_APP_AUTH_CLIENT,
  },
  googleAuth: {
    scope: window.__RUNTIME_CONFIG__.REACT_APP_GOOGLE_AUTH_SCOPE,
    clientId: window.__RUNTIME_CONFIG__.REACT_APP_GOOGLE_AUTH_CLIENT_ID,
  },
  auth0: {
    url: new URL(window.__RUNTIME_CONFIG__.REACT_APP_AUTH0_URL!).host,
    clientId: window.__RUNTIME_CONFIG__.REACT_APP_AUTH0_CLIENT,
  },
  audience: window.__RUNTIME_CONFIG__.REACT_APP_API_AUDIENCE,
};

let container: any = document.querySelector("#root");

const root = createRoot(container!);
root.render(
  <Auth0Provider
    domain={appConfig.auth0.url}
    clientId={appConfig.auth0.clientId}
    redirectUri={window.location.origin}
    audience={appConfig.audience}
  >
    <BrowserRouter>
      <UppAuth />
    </BrowserRouter>
  </Auth0Provider>
);

reportWebVitals();
