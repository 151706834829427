import { useSnackbar } from "notistack";
import React, { Dispatch, SetStateAction } from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import { uppColourPalette } from "../util/colours";

const getColor = (props: any) => {
  if (props.isDragAccept) {
    return "#00e676";
  }
  if (props.isDragReject) {
    return "#ff1744";
  }
  if (props.isDragActive) {
    return "#2196f3";
  }
  return "#eeeeee";
};

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: ${uppColourPalette.grey[900]};
  color: #bdbdbd;
  font-family: Jost;
  outline: none;
  transition: border 0.24s ease-in-out;
  cursor: pointer;
`;

const Dropzone = (props: { file?: File; setFiles: Dispatch<SetStateAction<File[]>>; uploadedList: string[] }) => {
  const { enqueueSnackbar } = useSnackbar();

  const onDrop = (acceptedFiles: Array<any>) => {
    if (acceptedFiles.length === 1) {
      props.setFiles(acceptedFiles);
    } else {
      enqueueSnackbar("Please select a single file only", {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    }
  };

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    onDrop,
  });

  return (
    <Container {...getRootProps({ isDragActive, isDragAccept, isDragReject })}>
      <input {...getInputProps()} />
      <p>
        {props.file && props.uploadedList.length
          ? `${props.file.name} ready for upload!`
          : "Click or Drag a Line Delimited File To Get Started!"}
      </p>
    </Container>
  );
};

export default Dropzone;
