import { Box, Dialog, DialogTitle, Divider, FormControlLabel, Switch, Toolbar, Typography } from "@material-ui/core";
import { TextField } from "mui-rff";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Field, Form, useForm, useFormState } from "react-final-form";
import { createTenant } from "../../api/platformadmin";
import { kebabCase } from "../../util/textUtil";
import { Steps } from "../onboardingWizard/OnboardingWizard";
import styled from "@emotion/styled";
import { uppColourPalette } from "../../util/colours";
import Button from "../../components/Wizards/Button";

const Heading = styled.div`
  padding: 16px 0;
  text-align: left;
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 16px 0;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-self: center;
  width: 400px;
  padding: 16px;
  border-radius: 4px;
  border: 1px solid ${uppColourPalette.coolGrey[100]};
`;

interface CreateTenantProps {
  existingTenantIds: string[];
  createdTenant?: (data: string | string[]) => void;
  setStep?: Dispatch<SetStateAction<number>>;
  redirect?: (key: string) => void;
  wizard?: boolean;
  onClose?: () => void;
  isOpen?: boolean;
}

export const CreateTenant = ({
  existingTenantIds,
  createdTenant,
  setStep,
  redirect,
  wizard,
  onClose,
  isOpen,
}: CreateTenantProps) => {
  const [multiTenant, setMultiTenant] = useState<boolean>(false);

  const tenantMessage = multiTenant ? "New or Existing Account Name " : "Account Name";

  const handleClose = () => {
    onClose && onClose();
  };

  const TextFieldAdapter = ({ input, meta, ...rest }: any) => (
    <TextField {...input} {...rest} errorText={meta.touched ? meta.error : ""} margin="dense" variant="outlined" />
  );

  const SwitchAdapter = ({ input: { onChange, value }, option }: any) => (
    <FormControlLabel
      label={option.name}
      control={
        <Switch
          color="primary"
          checked={value}
          onChange={(e, v) => {
            onChange(v);
            setMultiTenant(v);
          }}
        />
      }
    />
  );

  const createMultiTenants = (data: any) => {
    const tenantRegions = data.regions.split(/[ ,]+/);

    tenantRegions.forEach((region: any) => {
      if (!existingTenantIds.includes(data.tenantId + "-" + region.toLowerCase())) {
        createTenant({ tenantId: data.tenantId + "-" + region.toLowerCase(), name: data.name + " " + region });
      }
    });
  };

  const getMultiTenantList = (data: any) => {
    const tenantArray: string[] = [];
    const tenantRegions = data.regions.split(/[ ,]+/);

    tenantRegions.forEach((region: any) => {
      if (!existingTenantIds.includes(data.tenantId + "-" + region.toLowerCase())) {
        tenantArray.push(data.tenantId + "-" + region.toLowerCase());
      }
    });
    return tenantArray;
  };

  async function createNewTenant(data: any) {
    createTenant(data);
  }

  const updateStep = () => {
    if (setStep && redirect) {
      setStep(Steps.DATASOURCE);
      redirect("new");
      localStorage.setItem("onboardingStep", String(1));
    }
  };

  if (wizard) {
    return (
      <>
        <Form
          onSubmit={async (data: any) => {
            if (data.multi) {
              createdTenant?.(getMultiTenantList(data) as string[]);
              createMultiTenants(data);
              updateStep();
            } else {
              createdTenant?.(data.tenantId as string);
              await createNewTenant(data);
              updateStep();
            }
          }}
          validate={(data) => {
            const errors: any = {};
            for (const key of Object.keys(data)) {
              const value = data[key];
              if (!value || value === "") {
                errors[key] = "Required";
              }
              if (key === "tenantId" && existingTenantIds.includes(value) && !multiTenant) {
                errors[key] = "Already exists";
              }
            }
            return errors;
          }}
          render={({ handleSubmit, submitting }) => (
            <>
              <Heading>Enter Tenant Details</Heading>
              <StyledForm onSubmit={handleSubmit}>
                <SlugifyTenantId />
                <Field name="name" component={TextFieldAdapter} label={tenantMessage} required />
                <Field name="tenantId" component={TextFieldAdapter} label="Account Id" disabled required />
                <Field name="multi" value={multiTenant} option={{ name: "Multi Tenant?" }} component={SwitchAdapter} />
                {multiTenant ? (
                  <Field name="regions" component={TextFieldAdapter} label="Regions (comma separated)" />
                ) : (
                  ""
                )}
              </StyledForm>
              <Footer>
                <Button styling="Submit" disabled={submitting} onClick={handleSubmit}>
                  Submit
                </Button>
              </Footer>
            </>
          )}
        />
      </>
    );
  } else {
    return (
      <Dialog open={isOpen!} maxWidth="md" fullWidth onClose={handleClose}>
        <DialogTitle> Create Tenant</DialogTitle>

        <Divider />
        <Box marginY={2} marginX={2}>
          <Form
            onSubmit={async (data: any) => {
              if (data.multi) {
                createMultiTenants(data);
              } else {
                await createTenant(data);
              }

              handleClose();
            }}
            validate={(data) => {
              const errors: any = {};
              for (const key of Object.keys(data)) {
                const value = data[key];
                if (!value || value === "") {
                  errors[key] = "Required";
                }
                if (key === "tenantId" && existingTenantIds.includes(value) && !multiTenant) {
                  errors[key] = "Already exists";
                }
              }
              return errors;
            }}
            render={({ handleSubmit, submitting }) => (
              <form onSubmit={handleSubmit}>
                <Typography variant="body1">Enter details for the new Tenant / User below.</Typography>

                <SlugifyTenantId />
                <Field name="name" component={TextFieldAdapter} label={tenantMessage} required />
                <Field name="tenantId" component={TextFieldAdapter} label="Account Id" disabled required />
                <Field name="multi" value={multiTenant} option={{ name: "Multi Tenant?" }} component={SwitchAdapter} />
                {multiTenant ? (
                  <Field name="regions" component={TextFieldAdapter} label="Regions (comma separated)" />
                ) : (
                  ""
                )}

                <Toolbar>
                  <Button styling="Submit" disabled={submitting} onClick={handleSubmit}>
                    Submit
                  </Button>
                  <Button onClick={handleClose}>Cancel</Button>
                </Toolbar>
              </form>
            )}
          />
        </Box>
      </Dialog>
    );
  }
};

const SlugifyTenantId = () => {
  const state = useFormState();
  const form = useForm();

  useEffect(() => {
    if (state.values.name !== undefined) {
      form.change("tenantId", kebabCase(state.values.name.replace(/[^a-zA-Z0-9 ]+/g, "").replace(/\s+/g, "-")));
    } else {
      form.change("tenantId", "");
    }
  }, [state.values.name, form]);

  return null;
};
