import { AxiosError, AxiosResponse } from "axios";
import { BackOfficeError, BackOfficeErrorCode, isBackOfficeError } from "../types/errors";
import { http } from "../UppAuth";
import { createJob } from "./jobscheduler";
import { CommonPayload } from "@volo-ai/upp-temporal-utils";

interface AutomateCampaignResponse {
  code?: string;
  estimatedROAS?: number;
  message?: string;
  targetROAS?: number;
  targetSpend?: number;
  totalBudget?: number;
}

export interface CheckingResults {
  accountTargetSpendGood: boolean;
  accountTargetRoasGood: boolean;
  campaignsGood: boolean | string;
  message: string;
}

export async function triggerCampaignAutomation(
  dryRun: boolean
): Promise<{ success: boolean } & AutomateCampaignResponse> {
  if (dryRun) {
    const result = await http.post<any>(
      `${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/campaign-automation/automate-campaigns${
        dryRun ? "-dry-run" : ""
      }`,
      {}
    );

    if (result.status !== 200) {
      return {
        ...result.data,
        success: false,
        message: result.data.message ?? "Something went wrong while calling Campaign Automation",
      };
    }

    return { ...result.data, success: result.data?.code === "OK" };
  } else {
    const payload: CommonPayload = {
      tenantId: "TODO",
      cronSchedule: "* * * * *",
      configuration: {},
      workflowToRun: {
        pga: true,
        rba: false,
        spend: false,
      },
    };
    const result = await createJob(payload, "campaign-automation");
    if (result.status === 200) {
      return { success: true, message: `Scheduled campaign automation.` };
    } else {
      return { success: false, message: `Failed to schedule campaign automation` };
    }
  }
}

export async function triggerGoogleAdsCheck(): Promise<{ success: boolean } & CheckingResults> {
  const result = await http.get<any>(`${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/campaign-checker/check`, {});

  if (result.status >= 300) {
    return {
      ...result.data,
      success: false,
      message: result.data.message ?? "Something went wrong while calling Campaign Checker",
    };
  }

  return { ...result.data, success: true };
}

export async function getMostRecentCampaignAutomation(): Promise<CampaignAutomation | BackOfficeError> {
  try {
    const result = await http.get<CampaignAutomation | BackOfficeError>(
      `${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/campaign-automation/plans`,
      {}
    );

    return result.data;
  } catch (errorResponse) {
    const error = errorResponse as AxiosResponse;
    if (error.status === 404) {
      return error.data as BackOfficeError;
    }
    return {
      message: "Unexpected error with Campaign Automation Service",
      errorCode: BackOfficeErrorCode.Unexpected,
    };
  }
}

export interface UppPoint {
  x: number;
  y: number;
}

export interface ClustersResponse {
  tenantId: string;
  date: string;
  n_clusters: number;
}

export async function getNumberClustersAPI(): Promise<ClustersResponse | BackOfficeError> {
  try {
    const result = await http.get<ClustersResponse | BackOfficeError>(
      `${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/campaign-automation/clusters`,
      {}
    );

    return result.data;
  } catch (errorResponse) {
    const error = errorResponse as AxiosError;
    const errorData = error?.response?.data;
    if (isBackOfficeError(errorData)) {
      return errorData;
    }
    return {
      message: "Unexpected error with Campaign Automation Service",
      errorCode: BackOfficeErrorCode.Unexpected,
    };
  }
}

export interface CampaignAutomationStrategy {
  tenantId: string;
  name: string;
  description: string;
  date: string;
  expiryDate?: string;
}

export interface CampaignAutomationStrategyPlaybookItem {
  name: string;
  description: string;
  category: string;
}

export interface CampaignAutomationStrategyPlaybookItem {
  name: string;
  description: string;
  category: string;
}

export async function getCampaignAutomationStrategies(): Promise<CampaignAutomationStrategy[]> {
  const result = await http.get<CampaignAutomationStrategy[]>(
    `${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/campaign-automation/get-autopilot-strategies`,
    {}
  );

  if (result.status !== 200) {
    console.log("Failed to fetch Campaign Automation Strategies");
    throw new Error("Failed to fetch Campaign Automation Strategies");
  }

  return result.data;
}

export async function getCampaignAutomationStrategiesPlaybook(): Promise<CampaignAutomationStrategyPlaybookItem[]> {
  const result = await http.get<CampaignAutomationStrategyPlaybookItem[]>(
    `${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/campaign-automation/get-autopilot-playbook`,
    {}
  );

  if (result.status !== 200) {
    console.log("Failed to fetch Campaign Automation Strategies Playbook");
    throw new Error("Failed to fetch Campaign Automation Strategies Playbook");
  }

  return result.data;
}

export async function updateCampaignAutomationStrategyVisiblityBudget(
  tenantId: string,
  visibilityShare: number
): Promise<boolean> {
  const result = await http.post<number>(
    `${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/campaign-automation/update-autopilot-visibility-share`,
    { tenantId: tenantId, visibilityShare: visibilityShare }
  );

  if (result.status !== 200) {
    console.log("Failed to update Campaign Automation Strategy Visibility Budget");
    throw new Error("Failed to delete Campaign Automation Strategy Visibility Budget");
  }

  return true;
}

export async function updateCampaignAutomationStrategy(
  tenantId: string,
  strategy: CampaignAutomationStrategyPlaybookItem
): Promise<boolean> {
  const result = await http.post<number>(
    `${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/campaign-automation/replace-autopilot-strategy`,
    { tenantId: tenantId, strategyName: strategy.name }
  );

  if (result.status !== 200) {
    console.log("Failed to delete Campaign Automation Strategies");
    throw new Error("Failed to delete Campaign Automation Strategies");
  }

  return true;
}

export async function closeCampaignAutomationStrategy(tenantId: number): Promise<boolean> {
  const result = await http.post<number>(
    `${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/campaign-automation/close-autopilot-strategy/${tenantId}`,
    {}
  );

  if (result.status !== 200) {
    console.log("Failed to close Campaign Automation Strategies");
    throw new Error("Failed to close Campaign Automation Strategies");
  }

  return true;
}

export interface ShadowCampaign {
  budget: number;
  campaignId: number;
  campaignName: string;
  campaignUpdateId: string;
  exclusionList: boolean;
  itemIds: string[];
  planId: string;
  targetRoas: number;
  tenantId: string;
}

export interface CampaignAutomation {
  dryRun: boolean;
  date: Date;
  campaigns: ShadowCampaign[];
}

export interface ProductMoves {
  source: string[];
  source_labels: string[];
  target: string[];
  target_labels: string[];
  value: string[];
  label: string[];
  colour: string[];
  message?: string;
}

export async function getProductMoves({
  tenantId,
  from,
  to,
}: {
  tenantId: string;
  from: string;
  to: string;
}): Promise<ProductMoves> {
  const productMovesUrl = `${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/campaign-automation/sankey?tenantId=${tenantId}&from=${from}&to=${to}`;
  const result = await http.get<ProductMoves>(productMovesUrl, {});
  if (result.status !== 200) {
    return {
      source: [],
      source_labels: [],
      target: [],
      target_labels: [],
      value: [],
      colour: [],
      label: [],
    };
  }

  return result.data;
}
