import { Typography } from "@material-ui/core";
import { useEffect, useRef } from "react";
import styled from "styled-components";
import { uppColourPalette } from "../../util/colours";

const CloseButton = styled.button`
  position: absolute;
  float: right;
  right: 0;
  top: 0;
  background: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0.5rem;
`;

const ModalContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 16px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 600px;
`;

const MessageContainer = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  margin-bottom: 8px;
  padding-right: 16px;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const User = styled.span`
  color: ${uppColourPalette.darkGreen[500]};
`;
const Title = styled.span`
  & > p {
    font-weight: bold;
    color: black;
  }
`;
const StyledTypography = styled(Typography)`
  font-weight: bold;
  color: #000000;
`;

const Feedback = styled.span`
  color: ${uppColourPalette.darkGreen[500]};
  font-weight: bold;
`;

const MessageContainerScrollable = styled.div`
  overflow-y: auto;
  max-height: 300px;
`;

export interface Message {
  id?: string;
  createdAt?: Date;
  content: string;
  role: "user" | "assistant";
  feedback?: string;
}
interface ModalContentProps {
  messages: Message[];
  handleClose: () => void;
}

const getRole = (role: string) => {
  switch (role) {
    case "user":
      return "User";
    case "assistant":
      return "Assistant";
    default:
      return "System";
  }
};
const MessageDisplay = ({ message }: { message: Message }) => {
  return (
    <>
      <User>{getRole(message.role)}: </User>
      <span>{message.content}</span>
    </>
  );
};

const ErrorMessage = () => (
  <MessageContainer>
    <Typography>Error: Message content unavailable</Typography>
  </MessageContainer>
);

export const ModalContent: React.FC<ModalContentProps> = ({ messages, handleClose }) => {
  const scrollToLastMessageRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scrollToLastMessageRef.current) {
      scrollToLastMessageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  return (
    <ModalContainer>
      <ModalHeader>
        <Title>
          <StyledTypography>Conversation History:</StyledTypography>
        </Title>
      </ModalHeader>
      <br></br>
      <CloseButton onClick={handleClose}>x</CloseButton>
      <MessageContainerScrollable>
        {messages.map((message, index) => {
          if (!message || !message.content) {
            return (
              <MessageContainer key={index}>
                <User>
                  <ErrorMessage key={index} />
                </User>
              </MessageContainer>
            );
          }
          return (
            <MessageContainer key={index}>
              <Typography>
                <MessageDisplay key={message.id || index} message={message} />
              </Typography>
              {message.feedback && <Feedback>User Feedback: </Feedback>}
              {message.feedback}
            </MessageContainer>
          );
        })}
      </MessageContainerScrollable>
    </ModalContainer>
  );
};
