import { Dialog, DialogContent, DialogTitle, Divider, InputLabel } from "@material-ui/core";
import { CloseRounded } from "@material-ui/icons";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { blockUnblockProducts } from "../../api/productBlocker";
import Dropzone from "../../components/Dropzone";
import { StyledRow } from "../campaignAutomation/CampaignAutomation";
import { StyledButtonFilled } from "../datasources/Datasources";
import { StyledCloseButton } from "../datasources/SetupConnection";
import DropdownConfig from "../tenantConfig/DropdownConfig";
import SwitchConfig from "../tenantConfig/SwitchConfig";
import { GridWrapper } from "./BlockedProducts";

interface AddJobProps {
  isOpen: boolean;
  onClose: () => void;
}

const columnDefs: GridColDef[] = [{ headerName: "Identifier", field: "value", width: 400 }];

interface Line {
  value: string;
}

export const UploadListDialog = ({ isOpen, onClose }: AddJobProps) => {
  const [files, setFiles] = useState<File[]>([]);
  const [uploadedList, setUploadedList] = useState<Line[]>([]);
  const [blocked, setBlocked] = useState<boolean>(true);
  const [identifierType, setIdentifierType] = useState<string>("");
  const [uploadDisabled, setUploadDisabled] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const reader = new FileReader();
    reader.onload = () => {
      const fileAsBinaryString = reader.result as string;
      const lines = fileAsBinaryString?.split("\n");
      setUploadedList(
        lines.map((line) => {
          return { value: line };
        })
      );
    };
    if (files.length) {
      reader.readAsText(files[0]);
    } else {
      setUploadedList([]);
    }
  }, [files]);

  const handleClose = () => {
    onClose();
  };

  async function upload() {
    setUploadDisabled(true);
    const result = await blockUnblockProducts(
      identifierType,
      uploadedList.map((e) => e.value),
      blocked ? "flag" : "unflag"
    );
    enqueueSnackbar(result.message ?? result.error, {
      variant: result.message ? "success" : "error",
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
    });
    if (!result.error) {
      setUploadedList([]);
    }
    setUploadDisabled(false);
  }

  return (
    <Dialog open={isOpen} maxWidth="sm" fullWidth onClose={handleClose}>
      <DialogTitle>
        Upload Block List
        <StyledCloseButton onClick={handleClose}>
          <CloseRounded />
        </StyledCloseButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <StyledRow>
          <InputLabel>Block</InputLabel>
          <SwitchConfig
            onChange={(e) => {
              setBlocked(e.target.checked);
            }}
            checked={blocked}
          ></SwitchConfig>
          <InputLabel>Identifier Type</InputLabel>
          <DropdownConfig
            name={"identifierType"}
            data={["sku", "mpn", "itemId", "groupId"]}
            value={identifierType!}
            handleChange={(e) => {
              setIdentifierType(e.target.value);
            }}
          />
        </StyledRow>
        <Divider />
        {uploadedList.length === 0 ? (
          <>
            <Dropzone setFiles={setFiles} file={files[0]} uploadedList={[]} />
          </>
        ) : (
          <>
            <GridWrapper>
              <DataGrid
                rows={uploadedList}
                columns={columnDefs}
                getRowId={(line) => line.value}
                disableSelectionOnClick={true}
              />
            </GridWrapper>
            <Divider />
            <StyledRow>
              <StyledButtonFilled disabled={uploadDisabled} onClick={() => upload()}>
                Upload
              </StyledButtonFilled>
            </StyledRow>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default UploadListDialog;
