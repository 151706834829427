import { useEffect, useState } from "react";
import styled from "styled-components";
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { AuditAccountPerformance } from "../../api/audit";
import Card from "../../components/Card";
import { DateTime } from "luxon";
import ChartTooltip from "../../components/ChartTooltip";
import { CustomTick, dateFormatter } from "../../components/Chart";
import { uppColourPalette } from "../../util/colours";

const ChartWrapper = styled(Card)`
  height: 400px;
  overflow: hidden;
  background: white;
  border: none;
`;

const ChartHeader = styled.div`
  background: ${uppColourPalette.darkGreen[500]};
  color: white;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  gap: 16px;
`;

const AuditChart = (props: { chartRender: "revenue" | "cost"; auditAccountPerformance: AuditAccountPerformance }) => {
  const [chartData, setChartData] = useState<Array<{ value: number; date: number }>>([]);

  useEffect(() => {
    const data = [];
    for (let i = 0; i < props.auditAccountPerformance.timeSeries[props.chartRender].length; i++) {
      data.push({
        value: props.auditAccountPerformance.timeSeries[props.chartRender][i],
        date: DateTime.fromISO(props.auditAccountPerformance.from).plus({ days: i }).toJSDate().getTime() / 1000,
      });
      setChartData(data);
    }
  }, [props.chartRender, props.auditAccountPerformance, props.auditAccountPerformance.from]);

  const renderTitle = () => {
    if (props.chartRender === "revenue") {
      return "Historic Revenue";
    } else {
      return "Historic Spend";
    }
  };

  return (
    <ChartWrapper>
      <ChartHeader>
        <div>{renderTitle()}</div>
      </ChartHeader>
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart data={chartData} margin={{ top: 30, right: 40, bottom: 75, left: -16 }}>
          <defs>
            <linearGradient id="color" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="#7B77EE" stopOpacity={0.4} />
              <stop offset="75%" stopColor="#7B77EE" stopOpacity={0.4} />
            </linearGradient>
          </defs>
          <CartesianGrid opacity={0.2} horizontal={false} />
          <XAxis
            allowDataOverflow={false}
            dataKey="date"
            fontSize={10}
            orientation="bottom"
            axisLine={false}
            tick={CustomTick}
            tickLine={false}
            tickFormatter={dateFormatter}
            interval={0}
            scale="time"
            type="number"
            domain={["dataMin", "dataMax"]}
          />
          <YAxis yAxisId="left" dataKey="value" fontSize={10} axisLine={false} tickLine={false} tickCount={8} />
          <Tooltip content={<ChartTooltip />} />
          <Area yAxisId="left" dataKey="value" stroke="#7B77EE" fill="url(#color)" />
        </AreaChart>
      </ResponsiveContainer>
    </ChartWrapper>
  );
};

export default AuditChart;
