import { DateTime } from "luxon";
import _ from "lodash";
import { http } from "../UppAuth";

import { Tenant } from "./tenant";

interface ConsolidatedData {
  date: string;
  cost: number;
  clicks: number;
  impressions: number;
  conversions: number;
  conversionValue: number;
  conversionValueByConversionDate?: number;
}

interface ConsolidatedHourlyAccountData {
  date: string;
  accountMetrics: ConsolidatedData;
  hourlyMetrics: ConsolidatedData;
}

function consolidateHourlyData(hourlyData: HourlyAccountPerformance[]): ConsolidatedData[] {
  const grouped = _.groupBy(hourlyData, "date");

  const consolidated = Object.keys(grouped).map((dateGroup) => {
    const returnData = {
      date: dateGroup,
      cost: grouped[dateGroup].reduce((a, b) => a + b.cost, 0),
      clicks: grouped[dateGroup].reduce((a, b) => a + b.clicks, 0),
      impressions: grouped[dateGroup].reduce((a, b) => a + b.impressions, 0),
      conversions: grouped[dateGroup].reduce((a, b) => a + b.conversions, 0),
      conversionValue: grouped[dateGroup].reduce((a, b) => a + b.conversionsValue, 0),
      conversionValueByConversionDate: grouped[dateGroup].reduce((a, b) => a + b.conversionsValueByConversionDate, 0),
    };

    return returnData;
  });

  return consolidated;
}

function consolidateHourlyAccountData(
  hourlyData: ConsolidatedData[],
  accountData: AccountPerformance[]
): ConsolidatedHourlyAccountData[] {
  const consolidatedData = hourlyData.map((hourData) => {
    const consolidated = {
      date: hourData.date,
      hourlyMetrics: hourData,
      accountMetrics: {} as ConsolidatedData,
    };

    const matchedAccountMetrics = accountData.find(
      (data) => DateTime.fromISO(data.date as string).toISODate() === DateTime.fromISO(hourData.date).toISODate()
    );

    if (matchedAccountMetrics) {
      consolidated.accountMetrics = cleanAccountData(matchedAccountMetrics as AccountPerformance);
    }
    return consolidated;
  });

  return consolidatedData;
}

function cleanAccountData(accountData: AccountPerformance): ConsolidatedData {
  const consolidatedAccountData = {
    date: accountData.date as string,
    cost: accountData.actualSpend,
    clicks: accountData.clicks,
    impressions: accountData.impressions,
    conversions: accountData.conversions,
    conversionValue: accountData.conversionValue,
  };

  return consolidatedAccountData;
}

interface IndividualAccountConversionData {
  tenantId: string;
  data: ConsolidatedHourlyAccountData[];
}

export async function getAccountsConversionDataSummary(tenants: Tenant[]): Promise<IndividualAccountConversionData[]> {
  let summaries = [];
  for (const tenant of tenants) {
    const tenantSummary = await getIndividualAccountConversionData(tenant.id);
    summaries.push(tenantSummary);
  }

  return summaries;
}

export async function getIndividualAccountConversionData(tenantId: string): Promise<IndividualAccountConversionData> {
  const fromDate = DateTime.now().minus({ months: 6 }).startOf("day").toISODate();
  const toDate = DateTime.now().startOf("day").toISODate();

  const hourlyCampaignDataRes = await http.get<HourlyAccountPerformance[]>(
    `${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/campaignstats/account/hourly?tenantId=${tenantId}&fromDate=${fromDate}&toDate=${toDate}`
  );

  const accountPerformanceDataRes = await http.get<any[]>(
    `${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/performance/account?tenantId=${tenantId}&from=${fromDate}&to=${toDate}`
  );

  const consolidatedHourlyData = consolidateHourlyData(hourlyCampaignDataRes.data);
  const consolidatedHourlyAccountData = consolidateHourlyAccountData(
    consolidatedHourlyData,
    accountPerformanceDataRes.data
  );

  const sorted = _.sortBy(consolidatedHourlyAccountData, ["date", "asc"]);

  return {
    tenantId,
    data: sorted,
  };
}

interface PmaxSplit {
  video: PmaxSplitItem;
  search: PmaxSplitItem;
  shopping: PmaxSplitItem;
}

interface PmaxSplitItem {
  cost: number;
  percentage: number;
}

interface IndividualCampaignPerformanceReport {
  all: string | null;
  split: {
    campaignId?: string;
    state: string | null;
  }[];
}

interface CampaignPerformanceReport {
  visibility: IndividualCampaignPerformanceReport;
  performance: IndividualCampaignPerformanceReport;
}

interface MpaNumberOfInvalidProducts {
  productsMissingTargetRoas: number;
  productsMissingCogs: number;
}

interface HourlyAccountPerformance {
  tenantId?: string;
  date: string;
  hour: number;
  impressions: number;
  clicks: number;
  conversions: number;
  cost: number;
  conversionsValue: number;
  conversionsValueByConversionDate: number;
}

enum AccountState {
  ONE = "one_below_roas_below_spend",
  TWO = "two_met_roas_below_spend",
  THREE = "three_above_roas_below_spend",
  FOUR = "four_below_roas_met_spend",
  FIVE = "five_met_roas_met_spend",
  SIX = "six_above_roas_met_spend",
  SEVEN = "seven_below_roas_above_spend",
  EIGHT = "eight_met_roas_above_spend",
  NINE = "nine_above_roas_above_spend",
}

interface AccountPerformance {
  tenantId: string;
  date: Date | string;
  targetRoas: number;
  roas: number;
  actualSpend: number;
  targetSpend: number;
  impressions: number;
  clicks: number;
  clickThroughRate: number;
  conversions: number;
  conversionRate: number;
  averageOrderValue: number;
  totalOrderValue: number;
  conversionValue: number;
  percentZeroSpendProducts: number;
  actualCpa: number;
  targetCpa: number;
  pmaxSplit: PmaxSplit | undefined;
  accountState?: AccountState | null | string;
  accountLag?: number | null;
  splitAccountStateReport?: CampaignPerformanceReport | null;
  mpaNumberOfInvalidProducts: MpaNumberOfInvalidProducts;
}
