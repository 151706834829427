import NumberFormat from "react-number-format";
import { GetCurrencySymbol } from "./currency";

export function kebabCase(string: string): string {
  return string
    .replace(/([a-z])([A-Z])/g, "$1-$2")
    .replace(/\s+/g, "-")
    .toLowerCase();
}
export function numberFormat(value: number, format: string) {
  return format === GetCurrencySymbol() ? (
    <NumberFormat displayType={"text"} value={value} thousandSeparator prefix={format} decimalScale={2} />
  ) : (
    <NumberFormat displayType={"text"} value={value} thousandSeparator suffix={format} decimalScale={2} />
  );
}
