import { DataGrid, GridColDef, GridComparatorFn, gridNumberComparator, GridRenderCellParams } from "@mui/x-data-grid";

import { AccountOverview } from "../../api/accountsoverview";
import TablePercentageCell from "../../components/Table/TablePercentageCell";
import { calculatePercentageIncrease } from "../../util/percentageUtil";
import { GridWrapper } from "../dataManagement/DataManagement";

type Props = {
  accounts: AccountOverview[];
  isLoading: boolean;
};

type OverviewPairParams = {
  comparison: number;
  target: number;
};

function OverviewTable({ accounts, isLoading }: Props): JSX.Element {
  const percentageRenderer = (params: GridRenderCellParams<OverviewPairParams>) => (
    <TablePercentageCell comparison={params.value!.comparison} target={params.value!.target} />
  );

  // Custom comparator is needed as cell values are objects (OverviewPariParams) which breaks sorting
  const percentageComparator: GridComparatorFn = (v1, v2, param1, param2) => {
    const v1Percent = calculatePercentageIncrease(
      (v1 as OverviewPairParams).comparison,
      (v1 as OverviewPairParams).target
    );
    const v2Percent = calculatePercentageIncrease(
      (v2 as OverviewPairParams).comparison,
      (v2 as OverviewPairParams).target
    );

    // Once we have some sensible values, we can fallback to a built-in comparator
    return gridNumberComparator(v1Percent, v2Percent, param1, param2);
  };

  const columns: GridColDef[] = [
    // { headerName: "Monitoring", field: "monitoring", width: 100, renderCell: monitoringButtonRender }, //FIXME: Uncomment when monitoring is ready
    { headerName: "Customer", field: "name", width: 200 },
    { headerName: "Account ID", field: "tenantId", width: 150 },
    { headerName: "Target ROAS", field: "targetRoas", width: 125 },
    { headerName: "Target Spend", field: "targetSpend", width: 125 },
    {
      headerName: "ROAS",
      field: "roas",
      width: 160,
      renderCell: percentageRenderer,
      sortComparator: percentageComparator,
    },
    {
      headerName: "Spend",
      field: "actualSpend",
      width: 160,
      renderCell: percentageRenderer,
      sortComparator: percentageComparator,
    },
    {
      headerName: "Impressions",
      field: "impressions",
      width: 160,
      renderCell: percentageRenderer,
      sortComparator: percentageComparator,
    },
    {
      headerName: "Clicks",
      field: "clicks",
      width: 160,
      renderCell: percentageRenderer,
      sortComparator: percentageComparator,
    },
    {
      headerName: "CTR",
      field: "clickThroughRate",
      width: 160,
      renderCell: percentageRenderer,
      sortComparator: percentageComparator,
    },
    {
      headerName: "Conversion Volume",
      field: "conversions",
      width: 160,
      renderCell: percentageRenderer,
      sortComparator: percentageComparator,
    },
    {
      headerName: "Conversion Value",
      field: "conversionValue",
      width: 160,
      renderCell: percentageRenderer,
      sortComparator: percentageComparator,
    },
    {
      headerName: "Avg Order",
      field: "averageOrderValue",
      width: 160,
      renderCell: percentageRenderer,
      sortComparator: percentageComparator,
    },
    {
      headerName: "CPA",
      field: "actualCpa",
      width: 160,
      renderCell: percentageRenderer,
      sortComparator: percentageComparator,
    },
  ];

  return (
    <GridWrapper>
      <DataGrid rows={accounts} columns={columns} loading={isLoading} getRowId={(row) => row.tenantId} />
    </GridWrapper>
  );
}

export default OverviewTable;
