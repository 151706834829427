import { Box } from "@material-ui/core";
import {
  DataGridPro,
  GridColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import { DateTime, Interval } from "luxon";
import { useEffect, useState } from "react";
import { AccountStateExplainer, getAccountStateExplainer } from "../../api/accountState";
import { InnerContainer } from "../../components/InnerContainer";
import { uppColourPalette } from "../../util/colours";
import { AccountStateDictionary } from "./AccountStateGrid";
import { AccountState } from "../../api/autopilotHelper";

const columns: GridColDef[] = [
  {
    field: "date",
    type: "Date",
    headerName: "Date",
    width: 200,
    valueFormatter: (params) => DateTime.fromISO(params.value).toLocaleString(DateTime.DATETIME_SHORT),
  },
  { field: "accountLag", headerName: "Account Lag", width: 200 },
  { field: "cost", headerName: "Cost", width: 200, valueFormatter: (params) => params.value.toFixed(2) },
  {
    field: "conversionValue",
    headerName: "Conversion Value",
    width: 200,
    valueFormatter: (params) => params.value.toFixed(2),
  },
  { field: "roas", headerName: "ROAS", width: 200, valueFormatter: (params) => (params.value * 100).toFixed(0) + "%" },
  {
    field: "targetRoas",
    headerName: "Target ROAS",
    width: 200,
    valueFormatter: (params) => (params.value * 100).toFixed(0) + "%",
  },
  {
    field: "targetSpend",
    headerName: "Target Spend",
    width: 200,
    valueFormatter: (params) => params.value.toFixed(2),
  },
  {
    field: "splitAccountStateReport",
    headerName: "Account State Split",
    width: 200,
    renderCell: (params) => {
      if (!params.value || !params.value.visibility) {
        return "N/A";
      }

      return (
        <p style={{ margin: 0, textAlign: "left", fontSize: "0.85rem" }}>
          Visibility:{AccountStateDictionary[params.value.visibility.all as AccountState]} <br />
          Performance:{AccountStateDictionary[params.value.performance.all as AccountState]}
        </p>
      );
    },
  },
  {
    field: "accountState",
    headerName: "Account State",
    width: 200,
    valueFormatter: (params) => AccountStateDictionary[params.value as AccountState],
  },
];

interface AccountStateInterface {
  tenantId: string;
}

export const AccountStateExplainerView = ({ tenantId }: AccountStateInterface) => {
  const [isLoadingAccountStatusData, setIsLoadingAccountStatusData] = useState<boolean>(false);
  const [accountStateExplainer, setAccountStateExplainer] = useState<AccountStateExplainer>();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoadingAccountStatusData(true);

      try {
        const accountState = await getAccountStateExplainer();
        if (!accountState) {
          return;
        }
        setAccountStateExplainer(accountState);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoadingAccountStatusData(false);
      }
    };
    fetchData();
  }, [tenantId]);

  const GridContent = () => {
    if (isLoadingAccountStatusData) {
      return <div>Loading...</div>;
    }

    const CustomToolbar = () => {
      return (
        <GridToolbarContainer>
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
        </GridToolbarContainer>
      );
    };

    return (
      <DataGridPro
        rows={accountStateExplainer?.dailyAccountPerformance || []}
        columns={columns}
        loading={isLoadingAccountStatusData}
        getRowId={(row) => row.date}
        pagination
        autoHeight
        style={{ overflowY: "auto" }}
        slots={{
          toolbar: CustomToolbar,
        }}
        getCellClassName={(params) => {
          try {
            const dateString = params.id as string;
            const date = DateTime.fromISO(dateString);
            const interval = Interval.fromISO(accountStateExplainer?.queryInterval!);

            if (interval.contains(date)) {
              return "dataRangeUsed";
            }

            return "delay";
          } catch (error) {
            console.log(error);
            return "";
          }
        }}
      />
    );
  };

  return (
    <>
      <Box
        sx={
          {
            margin: "5% 0%",
            "& .delay": {
              backgroundColor: uppColourPalette.coolGrey[100],
              color: "#000",
              border: 2,
              fontSize: 20,
              borderColor: "black",
            },
            "& .dataRangeUsed": {
              backgroundColor: uppColourPalette.lime[200],
              color: "#000",
              border: 2,
              fontSize: 20,
              borderColor: "black",
            },
            "& h1, & h2": {
              fontWeight: 400,
              textAlign: "left",
            },
            "& h2": {
              fontSize: "1.25rem",
            },
            "& h2 span": {
              fontSize: "1rem",
            },
          } as any
        }
      >
        <h1
          style={{
            borderTop: `1px ${uppColourPalette.grey[300]} solid`,
            paddingTop: "2rem",
          }}
        >
          Account State: {AccountStateDictionary[accountStateExplainer?.currentState!]}
        </h1>
        <h2>
          Current lag: <br />{" "}
          <span>{accountStateExplainer?.currentLag} days (date interval not used for calculation)</span>
        </h2>
        <h2>
          Query interval: <br /> <span>{accountStateExplainer?.queryInterval}</span>
        </h2>
        <h2 style={{ marginBottom: "2rem" }}>
          Sufficient data interval in days: <br />{" "}
          <span>{accountStateExplainer?.sufficientDataIntervalInDays} (date interval used for to define state)</span>
        </h2>
        <InnerContainer>
          <GridContent />
        </InnerContainer>

        <div
          style={{
            textAlign: "left",
            marginTop: "10px",
            display: "flex",
            flexDirection: "column",
            alignContent: "middle",
          }}
        >
          <div>
            <div
              style={{
                display: "inline-block",
                marginRight: "10px",
                width: "20px",
                height: "20px",
                backgroundColor: uppColourPalette.coolGrey[100],
              }}
            ></div>
            <span>Data not suitable for calculation (missing conversion attribution)</span>
          </div>
          <div>
            <div
              style={{
                display: "inline-block",
                marginRight: "10px",
                width: "20px",
                height: "20px",
                backgroundColor: uppColourPalette.lime[200],
              }}
            ></div>
            <span>Data used for calculation</span>
          </div>
        </div>
      </Box>
    </>
  );
};
