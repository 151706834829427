import { createTheme } from "@mui/material/styles";
import { uppColourPalette } from "./colours";

export const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          textTransform: "none",
          borderRadius: "12.5px",
          padding: "0.75em 1em",
        },
      },
    },
  },
  palette: {
    background: { default: uppColourPalette.coolGrey[50] },
    primary: {
      main: uppColourPalette.lightGreen[500],
    },
    secondary: {
      main: uppColourPalette.navy[500],
    },
  },
});
export { uppColourPalette };
