import { ManagedCampaign } from "../../api/datasources";
import { TenantConfig } from "../../api/tenant";
import { OutlinedDiv } from "../../components/OutlinedDiv";

interface NumberOfCampaignsInterface {
  tenantConfig: TenantConfig;
  managedCampaigns: ManagedCampaign[];
}

const countCampaignsUsedByPGA = (managedCampaigns: ManagedCampaign[]) =>
  managedCampaigns.reduce((sum, current) => {
    if (current.campaignName.indexOf("TRO") > -1) {
      return sum + 1;
    }
    return sum;
  }, 0);

export const NumberOfCampaigns = ({ tenantConfig, managedCampaigns }: NumberOfCampaignsInterface) => {
  let numberCampaignsRequired = tenantConfig ? parseInt(String(tenantConfig["automation.numberClusters"])) + 1 : 0;
  if (!!tenantConfig.automation_addOrdersVisibilityCampaign) {
    numberCampaignsRequired++;
  }

  const numberOfUppCampaigns = countCampaignsUsedByPGA(managedCampaigns);

  return (
    <OutlinedDiv label="PGA configuration">
      {!!numberOfUppCampaigns ? (
        <p>Current number campaigns in Google: {numberOfUppCampaigns}</p>
      ) : (
        <p>! No target ROAS campaigns available</p>
      )}
      {!!numberCampaignsRequired && <p>Required number of campaigns for PGA: {numberCampaignsRequired}</p>}
    </OutlinedDiv>
  );
};
