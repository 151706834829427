import { http } from "../UppAuth";

export async function getProducts({
  pageSize,
  skip,
  sample,
  groupId,
  skus,
  isDeleted,
  isBlocked,
  isWatched,
  isSale,
  externalId,
  mpns,
  itemGroupIds,
  inStock,
  itemIds,
  invalidCostOfGoods,
  invalidTargetContributionMargin,
  isTargetRoasSet,
  isTargetRoasPositive,
  isProblematic,
  identifiersOnly,
}: {
  pageSize?: number;
  skip?: number;
  sample?: boolean;
  groupId?: string[];
  skus?: string[];
  isDeleted?: boolean;
  isBlocked?: boolean;
  isWatched?: boolean;
  isSale?: boolean;
  externalId?: string;
  mpns?: string[];
  itemGroupIds?: string[];
  inStock?: boolean;
  itemIds?: string[];
  invalidCostOfGoods?: boolean;
  invalidTargetContributionMargin?: boolean;
  isTargetRoasSet?: boolean;
  isTargetRoasPositive?: boolean;
  isProblematic?: boolean;
  identifiersOnly?: boolean;
}): Promise<{ products: Product[]; count: number }> {
  let url = `${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/products?`;
  const DEFAULT_PAGE_SIZE = 50000;
  url += `pageSize=${pageSize ?? DEFAULT_PAGE_SIZE}&`;
  if (skip) {
    url += `skip=${skip}&`;
  }
  if (sample !== undefined) {
    url += `sample=${sample}&`;
  }
  if (groupId) {
    url += `groupId=${groupId}&`;
  }
  if (skus) {
    url += `skus=${skus}&`;
  }
  if (isDeleted !== undefined) {
    url += `isDeleted=${isDeleted}&`;
  }
  if (isBlocked !== undefined) {
    url += `isBlocked=${isBlocked}&`;
  }
  if (isWatched !== undefined) {
    url += `isWatched=${isWatched}&`;
  }
  if (isSale !== undefined) {
    url += `isSale=${isSale}&`;
  }
  if (externalId) {
    url += `externalId=${externalId}&`;
  }
  if (mpns) {
    url += `mpns=${mpns}&`;
  }
  if (itemGroupIds) {
    url += `itemGroupIds=${itemGroupIds}&`;
  }
  if (inStock) {
    url += `inStock=${inStock}&`;
  }
  if (itemIds) {
    url += `itemIds=${itemIds}&`;
  }
  if (invalidCostOfGoods !== undefined) {
    url += `invalidCostOfGoods=${invalidCostOfGoods}&`;
  }
  if (invalidTargetContributionMargin !== undefined) {
    url += `invalidTargetContributionMargin=${invalidTargetContributionMargin}&`;
  }
  if (isTargetRoasSet !== undefined) {
    url += `isTargetRoasSet=${isTargetRoasSet}&`;
  }
  if (isTargetRoasPositive !== undefined) {
    url += `isTargetRoasPositive=${isTargetRoasPositive}&`;
  }
  if (isProblematic !== undefined) {
    url += `isProblematic=${isProblematic}&`;
  }
  if (identifiersOnly !== undefined) {
    url += `identifiersOnly=${identifiersOnly}&`;
  }
  const result = await http.get<any>(url);
  if (result.status !== 200) {
    console.log(`Unable to fetch products status=${result.status}`);
    return { products: [], count: 0 };
  }
  return { products: result.data, count: parseInt(result.headers["resource-count"]) };
}

export async function getProductStats(): Promise<{ discountedProductCount: number; normalProductCount: number }> {
  const discountedProductsResults = await http.get<any>(
    `${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/products?isSale=true`
  );

  if (discountedProductsResults.status !== 200) {
    console.log("Unable to fetch discounted product count.");
  }

  const normalProductsResults = await http.get<any>(
    `${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/products?isSale=false`
  );

  if (normalProductsResults.status !== 200) {
    console.log("Unable to fetch normal product count.");
  }

  return {
    discountedProductCount:
      discountedProductsResults.status !== 200 ? 0 : parseInt(discountedProductsResults.headers["resource-count"]),
    normalProductCount:
      normalProductsResults.status !== 200 ? 0 : parseInt(normalProductsResults.headers["resource-count"]),
  };
}

export async function getBlockedProducts(): Promise<Product[]> {
  const result = await http.get<any>(
    `${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/products?isBlocked=true&pageSize=50000`
  );
  if (result.status !== 200) {
    console.log(`Unable to fetch blocked products status=${result.status}`);
    return [];
  }
  return result.data;
}

export interface MPAHealthIndicators {
  totalProducts: number;
  productsMissingCogs: number;
  productsMissingTargetContributionMargin: number;
  productsInvalidTargetRoas: number;
  combinedIssues: number;
}

export interface CustomLabelExclusionOptions {
  customLabel0?: string[];
  customLabel1?: string[];
  customLabel2?: string[];
  customLabel3?: string[];
  customLabel4?: string[];
}

export async function getMPAHealth(): Promise<MPAHealthIndicators | null> {
  const result = await http.get<any>(`${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/products/mpa-health`);
  if (result.status !== 200) {
    console.log(`Unable to fetch blocked products status=${result.status}`);
    return null;
  }
  return result.data;
}

export async function getCustomLabelExclusionOptions(): Promise<CustomLabelExclusionOptions | {}> {
  let returnData = {} as CustomLabelExclusionOptions;

  for (let i = 0; i < 5; i++) {
    const result = await http.get<any>(`${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/products/custom-label/${i}`);

    if (result.status === 200) {
      if (result.data.values.length > 0) {
        returnData[`customLabel${i}` as keyof CustomLabelExclusionOptions] = result.data.values;
      }
    }
  }

  return returnData;
}

export interface Product {
  sku: string;
  itemId: string;
  title: string;
}
