import { FormControl, MenuItem, Select } from "@material-ui/core";
import Box from "@mui/material/Box";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import { useEffect, useState } from "react";
import { AccountsState, getAccountsState } from "../../api/accountState";
import { AccountState } from "../../api/autopilotHelper";
import { Heading } from "../../components/Heading";
import { AccountStateDictionary } from "../accountState/AccountStateGrid";
import { StyledDashboardContainer } from "../dashboard/Dashboard";
import { AccountsStateTable } from "./AccountsStateTable";

const columns: GridColDef[] = [
  { field: "spend", headerName: "Spend/ROAS", width: 200 },
  {
    field: "belowTargetRoas",
    headerName: "Below Target Roas",
    width: 300,
    align: "center",
  },
  { field: "onTargetRoas", headerName: "On Target Roas", width: 300, align: "center" },
  { field: "aboveTargetRoas", headerName: "Above Target Roas", width: 300, align: "center" },
];

interface AccountStateGridRow {
  id: string;
  spend: string;
  belowTargetRoas: string;
  onTargetRoas: string;
  aboveTargetRoas: string;
}

export const AccountsStateGrid = () => {
  const [isLoadingAccountsState, setIsLoadingAccountsState] = useState<boolean>(false);

  const [accountsState, setAccountsState] = useState<AccountsState[]>([]);
  const [accountsStateRows, setAccountsStateRows] = useState<AccountStateGridRow[]>([]);

  const [listAccountState, setListAccountState] = useState<AccountState[]>();

  const [selectedState, setSelectedState] = useState<string>();

  const handleStateChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedState(event.target.value as AccountState);
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoadingAccountsState(true);
      try {
        const accountsState = await getAccountsState();
        setAccountsState(accountsState);
        setSelectedState(accountsState?.[0]?.currentState || AccountState.FIVE);

        setListAccountState([
          ...new Set(
            accountsState
              .map((t) => t.currentState)
              .sort((a, b) => Number(AccountStateDictionary[a]) - Number(AccountStateDictionary[b]))
          ),
        ]);

        const stateCount: { [key: string]: number } = {};

        accountsState.forEach((tenant) => {
          if (tenant.currentState && !stateCount[tenant.currentState]) {
            return (stateCount[tenant.currentState] = 1);
          }
          if (tenant.currentState) {
            return (stateCount[tenant.currentState] += 1);
          }
        });

        const getAccountStateRow = (accountState: AccountState) => {
          if (!stateCount?.[accountState]) {
            return `${AccountStateDictionary[accountState]}: -`;
          }

          const count = stateCount[accountState];
          const percentage = Math.round((100 * count) / accountsState.length);
          return `${AccountStateDictionary[accountState]}: ${percentage}%`;
        };

        const accountsStateRows: AccountStateGridRow[] = [
          {
            id: "64610e2f4526f850e0a4e878",
            spend: "Above spend",
            belowTargetRoas: getAccountStateRow(AccountState.SEVEN),
            onTargetRoas: getAccountStateRow(AccountState.EIGHT),
            aboveTargetRoas: getAccountStateRow(AccountState.NINE),
          },
          {
            id: "645f52ba4526f8290ca4e86a",
            spend: "On spend",
            belowTargetRoas: getAccountStateRow(AccountState.FOUR),
            onTargetRoas: getAccountStateRow(AccountState.FIVE),
            aboveTargetRoas: getAccountStateRow(AccountState.SIX),
          },
          {
            id: "645e6b2f4526f85aa8a4e859",
            spend: "Below spend",
            belowTargetRoas: getAccountStateRow(AccountState.ONE),
            onTargetRoas: getAccountStateRow(AccountState.TWO),
            aboveTargetRoas: getAccountStateRow(AccountState.THREE),
          },
        ];

        setAccountsStateRows(accountsStateRows);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoadingAccountsState(false);
      }
    };
    fetchData();
  }, []);

  const GridContent = () => {
    return (
      <DataGridPro
        loading={isLoadingAccountsState}
        rows={accountsStateRows}
        columns={columns}
        autoHeight
        hideFooter={true}
        getCellClassName={(params) => {
          if (
            (params.value as string).match(`${AccountStateDictionary[AccountState.FIVE]}:`) ||
            (params.value as string).match(`${AccountStateDictionary[AccountState.SIX]}:`) ||
            (params.value as string).match(`${AccountStateDictionary[AccountState.EIGHT]}:`) ||
            (params.value as string).match(`${AccountStateDictionary[AccountState.NINE]}:`)
          ) {
            return "excellentState";
          }
          if (
            (params.value as string).match(`${AccountStateDictionary[AccountState.TWO]}:`) ||
            (params.value as string).match(`${AccountStateDictionary[AccountState.THREE]}:`)
          ) {
            return "goodState";
          }
          if (
            (params.value as string).match(`${AccountStateDictionary[AccountState.ONE]}:`) ||
            (params.value as string).match(`${AccountStateDictionary[AccountState.FOUR]}:`) ||
            (params.value as string).match(`${AccountStateDictionary[AccountState.SEVEN]}:`)
          ) {
            return "badState";
          }

          return "";
        }}
      />
    );
  };

  return (
    <StyledDashboardContainer>
      <Box
        sx={{
          bgcolor: "white",
          padding: "2em",
          marginTop: "2em",
          marginLeft: "2em",
          marginRight: "2em",
          borderRadius: "0.5%",
          border: "1px solid",
          borderColor: "#dbdbdb",
          "& .awfulState": {
            backgroundColor: "#FF7276",
            color: "#fff",
            border: 2,
            fontSize: 20,
            borderColor: "black",
          },
          "& .badState": {
            backgroundColor: "#FF7276",
            color: "#fff",
            border: 2,
            fontSize: 20,
            borderColor: "black",
          },
          "& .goodState": {
            backgroundColor: "#ff9100",
            color: "#fff",
            border: 2,
            fontSize: 20,
            borderColor: "black",
            textAlign: "center",
          },
          "& .excellentState": {
            backgroundColor: "#00A776",
            color: "#fff",
            border: 2,
            fontSize: 20,
            borderColor: "black",
          },
        }}
      >
        <Heading>Accounts State</Heading>
        <GridContent />
        <Box style={{ margin: "1em" }}>
          {listAccountState && (
            <FormControl>
              <Select
                labelId="account-state-selector-label"
                id="account-state-selected"
                value={selectedState}
                label="Chose Account State"
                onChange={handleStateChange}
              >
                {listAccountState.map((state, index) => {
                  return (
                    <MenuItem key={index} value={state as AccountState}>
                      {`State: ${AccountStateDictionary[state as AccountState]}`}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          )}

          {selectedState && (
            <Box style={{ marginLeft: 0, marginRight: 0, marginTop: "1em" }}>
              <AccountsStateTable
                tenants={accountsState.filter((tenant) => tenant.currentState === selectedState)}
                currentState={selectedState as AccountState}
              />
            </Box>
          )}
        </Box>
      </Box>
    </StyledDashboardContainer>
  );
};
