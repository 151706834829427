import { Box } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useSearchParams } from "react-router-dom";
import {
  CampaignAutomationStrategy,
  ShadowCampaign,
  getCampaignAutomationStrategies,
  getMostRecentCampaignAutomation,
} from "../../api/campaignAutomation";
import { TenantConfig, getTenantConfig } from "../../api/tenant";
import { Heading } from "../../components/Heading";
import NotificationBar from "../../components/NotificationBar";
import { isBackOfficeError } from "../../types/errors";
import { StyledChart } from "../campaignAutomation/CampaignAutomation";
import { StyledDashboardContainer } from "../dashboard/Dashboard";
import { AccountStateExplainerView } from "./AccountStateExplainer";
import { AccountStateTable } from "./AccountStateTable";

export const AccountStateScreen = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams] = useSearchParams();
  const tenantIdParam = searchParams.get("tenantId");
  const [tenantId, setTenantId] = useState<string | null>(tenantIdParam);
  const [tenantConfig, setTenantConfig] = useState<TenantConfig>({});
  const [isMPAEnabled, setIsMPAEnabled] = useState<boolean>(tenantConfig.intakeMargin_enabled as boolean);
  const [autopilotStrategies, setAutopilotStrategies] = useState<CampaignAutomationStrategy[]>([]);
  const [sankeyData, setSankeyData] = useState<Array<Array<string | number>>>();

  useEffect(() => {
    const fetchData = async () => {
      if (tenantId) {
        const config = await getTenantConfig(tenantId);
        setTenantConfig(config);
        let strategies = await getCampaignAutomationStrategies();
        strategies = strategies.filter((s) => s.tenantId === tenantId);
        setAutopilotStrategies(strategies);
      }
    };
    fetchData();
  }, [tenantId]);

  useEffect(() => {
    setIsMPAEnabled(tenantConfig.intakeMargin_enabled as boolean);
  }, [tenantConfig]);

  useEffect(() => {
    if (!tenantIdParam) {
      return setTenantId(window?.USER_INFO?.tenantId);
    }
  }, [tenantIdParam]);

  useEffect(() => {
    const fetchData = async () => {
      if (tenantId) {
        const config = await getTenantConfig(tenantId);
        setTenantConfig(config);
        await buildPlot(enqueueSnackbar, setSankeyData);
      }
    };
    fetchData();
  }, [enqueueSnackbar, tenantId]);

  return (
    <StyledDashboardContainer>
      {isMPAEnabled && (
        <NotificationBar
          title="WARNING"
          copy="Tenant is using MPA: account state and supporting data may be incorrect or missing"
          type="error"
        />
      )}

      <Box
        sx={{
          bgcolor: "white",
          padding: "2em",
          margin: "2em",
          borderRadius: "0.5%",
          border: "1px solid",
          borderColor: "#dbdbdb",
        }}
      >
        <Heading>Account State | {tenantId}</Heading>
        <p
          style={{
            textAlign: "left",
            marginLeft: 0,
            color: `${
              tenantConfig?.autopilot_enabled !== false && tenantConfig?.intakeMargin_enabled !== true
                ? "#00A776"
                : "#ff1744"
            }`,
          }}
        >
          {tenantConfig?.autopilot_enabled !== false && tenantConfig?.intakeMargin_enabled !== true
            ? "Autopilot enabled"
            : "Autopilot disabled"}
        </p>
        <p
          style={{
            textAlign: "left",
            marginLeft: 0,
          }}
        >
          Strategy Name:{" "}
          <b>
            {tenantConfig?.autopilot_enabled !== false && tenantConfig?.intakeMargin_enabled !== true
              ? autopilotStrategies.map((s) => s.name)
              : "N/A"}
          </b>
        </p>
        <p
          style={{
            textAlign: "left",
            marginLeft: 0,
          }}
        >
          Strategy Description:{" "}
          <b>
            {tenantConfig?.autopilot_enabled !== false && tenantConfig?.intakeMargin_enabled !== true
              ? autopilotStrategies.map((s) => s.description)
              : "N/A"}
          </b>
        </p>
        <p
          style={{
            textAlign: "left",
            marginLeft: 0,
          }}
        >
          Strategy Start:{" "}
          <b>
            {tenantConfig?.autopilot_enabled !== false && tenantConfig?.intakeMargin_enabled !== true
              ? autopilotStrategies.map((s) => s.date)
              : "N/A"}
          </b>
        </p>
        <p
          style={{
            textAlign: "left",
            marginLeft: 0,
          }}
        >
          Strategy Expiry:{" "}
          <b>
            {tenantConfig?.autopilot_enabled !== false && tenantConfig?.intakeMargin_enabled !== true
              ? autopilotStrategies.map((s) => s.expiryDate || "No expiry currently set")
              : "N/A"}
          </b>
        </p>

        {tenantId && <AccountStateTable tenantId={tenantId} />}
        {sankeyData && (
          <StyledChart chartType="Sankey" data={[...sankeyData]} width="80%" height="300px" legendToggle />
        )}
        {tenantId && <AccountStateExplainerView tenantId={tenantId} />}
      </Box>
    </StyledDashboardContainer>
  );
};

export default AccountStateScreen;

async function buildPlot(enqueueSnackbar: (message: string, body: any) => void, setSankeyData: (body: any) => void) {
  const result = await getMostRecentCampaignAutomation();
  if (isBackOfficeError(result)) {
    enqueueSnackbar(result.message, {
      variant: "warning",
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
    });
  } else {
    setSankeyData(buildSankeyData(result.campaigns ?? []));
  }
}

function buildSankeyData(proposedCampaigns: Array<ShadowCampaign>): Array<Array<string | number>> {
  let sankeyData: Array<Array<string | number>> = [["From", "To", "Budget", "Target Roas"]];

  const weighting = proposedCampaigns.reduce(
    (agg, cur) => {
      if (cur.campaignName.startsWith("kmeans")) {
        agg.roas = agg.roas + cur.budget;
      } else {
        agg.visibility = agg.visibility + cur.budget;
      }
      return agg;
    },
    { visibility: 0, roas: 0 }
  );

  sankeyData.push(["AllProducts", "Visibility", weighting.visibility, 0]);

  sankeyData.push(["AllProducts", "ROAS", weighting.roas, 0]);

  sankeyData.push(
    ...proposedCampaigns
      .reverse()
      .map((plan) => [
        `${plan.campaignName.startsWith("kmeans") ? "ROAS" : "Visibility"}`,
        `${plan.campaignName} (${plan.exclusionList ? "Everything - " : ""}${plan.itemIds?.length} Products) `,
        plan.budget,
        plan.targetRoas ? plan.targetRoas : 0,
      ])
  );

  // const example = [
  //   ["From", "To", "Weight"],
  //   ["AllProducts", "Visibility", 3],
  //   ["AllProducts", "ROAS", 9],
  //   ["ROAS", "Cluster 1 (5 Products)", 3],
  //   ["ROAS", "Cluster 2 (10 Products)", 3],
  //   ["ROAS", "Cluster 3 (50 Products)", 3],
  //   ["Visibility", "NoConvNoOrder (5000 Products)", 2],
  //   ["Visibility", "OrderNoConv (400 Products)", 1],
  // ];

  return sankeyData;
}
