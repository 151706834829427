import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import styled from "styled-components";
import InputIcon from "@material-ui/icons/Input";
import { IconButton } from "@material-ui/core";

const LogoutIcon = styled(IconButton)`
  background-color: white;
  margin-left: auto !important;
  color: black;
  padding: 5px 15px;
  border-radius: 5px;
  outline: 0;
  margin: 10px 0px;
  cursor: pointer;
  transition: ease background-color 250ms;
  &:hover {
    background-color: white;
  }
  &:disabled {
    cursor: default;
    opacity: 0.7;
  }
`;

const LogoutButton = () => {
  const { logout } = useAuth0();

  return (
    <LogoutIcon
      color="inherit"
      onClick={() => {
        logout({ returnTo: window.location.origin });
      }}
    >
      <InputIcon />
    </LogoutIcon>
  );
};

export default LogoutButton;
