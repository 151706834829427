import { Button } from "@material-ui/core";
import styled from "styled-components";

export const StyledButtonFilled = styled(Button)`
  position: relative;
  padding: 6px 16px !important;
  color: white !important;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%);
  font-weight: 500 !important;
  background-color: #4054b4 !important;
  border-radius: 4px !important;
  z-index: 150;

  &:disabled {
    border: 1px solid #bfbfbf !important;
    color: #bfbfbf !important;
  }
`;

export const StyledButton = styled(StyledButtonFilled)`
  position: absolute;
  background-color: #fff !important;
  color: black !important;
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
  box-shadow: none;
`;
