import styled from "styled-components";

const formatter = Intl.NumberFormat("en-GB", {
    signDisplay: "exceptZero",
    maximumFractionDigits: 1,
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Percentage = styled.div`
  font-size: 1.25em;
`;

function AuditHealthTableCell(props: { percentageDifference: number }): JSX.Element {
    return (
        <Container>
            <Percentage style={{ color: props.percentageDifference > 0 ? "green" : "red" }}>{formatter.format(props.percentageDifference)}%</Percentage>
        </Container>
    );
}

export default AuditHealthTableCell;
