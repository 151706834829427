import { Box, Color, Grid, Typography } from "@material-ui/core";
import { green, orange } from "@material-ui/core/colors";
import { useConfirm } from "material-ui-confirm";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Clock, Icon, Loader } from "react-feather";
import { ClipLoader } from "react-spinners";
import { deleteJob, getAllJobs } from "../../api/jobscheduler";
import { CountBox } from "../dashboard/CountBox";
import { StyledBox, StyledDashboardContainer } from "../dashboard/Dashboard";
import { RightStyledButton } from "../dashboard/TenantTable";
import { Heading } from "../datasources/Datasources";
import { Headers } from "../datasources/Headers";
import { AddJobDialog } from "./AddJobDialog";
import { JobCard } from "./JobCard";
import { JobDetails } from "./JobDetails";

export const JobScheduler = () => {
  const [countBoxes, setCountBoxes] = useState<{ name: string; value: number; icon: Icon; colour: Color }[]>([]);
  const [jobs, setJobs] = useState([]);
  const [addNewJobModalOpen, setAddNewJobModalOpen] = useState<boolean>(false);
  const [expandJob, setExpandJob] = useState<boolean>(false);
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();
  const confirmMessage = <Typography component={"span"}>Are you sure you want to delete this job?</Typography>;

  useEffect(() => {
    async function fetchAllJobs() {
      const allJobs = await getAllJobs();
      setJobs(allJobs);
    }
    fetchAllJobs();
  }, [addNewJobModalOpen, loading]);

  useEffect(() => {
    setCountBoxes([
      {
        name: "Active Jobs",
        value: jobs.reduce((acc, job) => (job["status"] === "running" ? acc + 1 : acc), 0),
        icon: Loader,
        colour: green,
      },
      {
        name: "Scheduled Jobs",
        value: jobs.reduce((acc, job) => (job["status"] === "scheduled" ? acc + 1 : acc), 0),
        icon: Clock,
        colour: orange,
      },
    ]);
  }, [jobs]);

  const onDelete = (id: string) => {
    confirm({ title: `Confirm`, description: confirmMessage }).then(() => {
      deleteJob(id).then((response) => {
        setLoading(true);
        if (response === true) {
          enqueueSnackbar(`Job (${id}) has been deleted successfully`, {
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
          });
          setLoading(false);
        } else {
          enqueueSnackbar(`Job (${id}) could not be deleted`, {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
          });
        }
      });
    });
  };

  return (
    <div>
      <StyledDashboardContainer>
        <StyledBox
          sx={{
            padding: "2em",
            margin: "2em",
          }}
        >
          <Grid container justifyContent="space-evenly" spacing={5}>
            <Grid container xs={12} spacing={5}>
              {countBoxes.map((box, idx) => {
                return (
                  <Grid item xs={12} lg={4}>
                    {" "}
                    <CountBox name={box.name} value={box.value} icon={box.icon} colour={box.colour} key={idx} />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </StyledBox>
        <Grid item>
          <Box
            sx={{
              bgcolor: "white",
              padding: "2em",
              margin: "2em",
              borderRadius: "0.5%",
              border: "1px solid",
              borderColor: "#dbdbdb",
            }}
          >
            <Heading>
              <h1>Job Scheduler</h1>
              <RightStyledButton onClick={() => setAddNewJobModalOpen(true)}>Add New Job</RightStyledButton>
            </Heading>
            {loading ? (
              <ClipLoader />
            ) : jobs.length > 0 ? (
              <div>
                <Headers values={["Job Name", "", "Status", "Next Invocation"]} />
                {jobs.map(function (j, idx) {
                  return (
                    <JobCard
                      id={j["jobName"]}
                      status={j["status"]}
                      nextInvocation={j["nextInvocation"]}
                      onClick={() => onDelete(j["jobName"])}
                      onExpand={(e) => {
                        if (e.target.className !== "MuiButton-label") {
                          setData(j);
                          setExpandJob(true);
                        }
                      }}
                    />
                  );
                })}
              </div>
            ) : (
              <Box padding={8} display="flex" alignItems="center" flexDirection="column">
                <Typography align="center">You don't have any jobs scheduled right now.</Typography>
              </Box>
            )}
          </Box>
        </Grid>
        <AddJobDialog isOpen={addNewJobModalOpen} setIsOpen={setAddNewJobModalOpen} />
        {expandJob && (
          <JobDetails
            isOpen={true}
            onClose={() => {
              setExpandJob(false);
            }}
            data={data}
          />
        )}
      </StyledDashboardContainer>
    </div>
  );
};

export default JobScheduler;
