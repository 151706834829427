import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchIcon from "@mui/icons-material/Search";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import React, { ChangeEvent, useState } from "react";
import styled from "styled-components";
import { addCompany, getCompany, redeemCompany } from "../../api/targetCompany";
import Button from "../../components/Wizards/Button";

const breakpoints = {
  mobile: "480px",
  tablet: "768px",
  desktop: "1024px",
};

const Container = styled.div`
  width: 900px;
  margin: 50px auto;

  @media (max-width: ${breakpoints.mobile}) {
    margin: 40px auto;
  }
`;

const StyledInput = styled.input`
  width: 70%;
  padding: 12px;
  margin-top: 5px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-sizing: border-box;
  background-color: white;
  font-size: 16px;

  @media (max-width: ${breakpoints.mobile}) {
    margin-bottom: 5px;
  }
 }
`;

export const SearchForm = () => {
  const [text, setText] = useState<string>("");
  const [companies, setCompanies] = useState<any[]>([]);
  const [companyDetails, setCompanyDetails] = useState<any>({});
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const Loading = () => {
    return <div>Loading...</div>;
  };

  const searchText = async (text: string) => {
    try {
      setIsLoading(true);
      const res = await getCompany(text);
      setIsLoading(false);

      if (res.length > 0) {
        setCompanies(res);
      } else {
        alert("No company found");
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  const handleCompanyDetails = async (id: string) => {
    console.log("handleCompanyDetails: ", id);
    const res = await redeemCompany(id);
    console.log("Redeem res: ", res);
    setCompanyDetails(res);
  };

  const handleSubimit = (e: any): void => {
    e.preventDefault();
    setSubmitting(true);
    searchText(text);

    setSubmitting(false);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setText(e.target.value);
  };

  const handleChange = (panel: string, id: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    handleCompanyDetails(id);
    setExpanded(isExpanded ? panel : false);
  };

  async function addCompanyToHubspot(id: string) {
    setSubmitting(true);

    try {
      const res = await addCompany(id);
      if (res.status === "no_contacts") {
        alert("No contacts found for this company");
      } else {
        alert("Company successfully added to Hubspot");
      }
    } catch (e) {
      alert("Company couldn't be added to Hubspot. Please try again later");
    }

    setSubmitting(false);
  }
  return (
    <Container>
      <form onSubmit={handleSubimit}>
        <StyledInput
          type="text"
          placeholder="eg. Ocado"
          className="py-1 px-2 rounded-l-lg"
          onChange={handleInputChange}
        />
        <IconButton type="submit" className="bg-blue-800 py-1 px-2 rounded-r-lg text-white">
          <SearchIcon style={{ fill: "blue" }} />
        </IconButton>
      </form>

      {companies.map((company) => (
        <Accordion
          key={company.id + "accordion"}
          sx={{ textAlign: "left" }}
          expanded={expanded === "panel" + company.id}
          onChange={handleChange("panel" + company.id, company.id)}
        >
          <div key={company.id}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${company.id}bh-content`}
              id={`panel${company.id}bh-header`}
            >
              <Typography sx={{ width: "33%", flexShrink: 0 }}>{company.name}</Typography>

              <Typography sx={{ color: "text.secondary", marginLeft: "auto" }}>Read more</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                {companyDetails.id === company.id && (
                  <div key={companyDetails.id}>
                    <p>
                      <b>Web domain:</b> {companyDetails.webDomain}
                    </p>
                    <p>
                      <b>Linkedin:</b> {companyDetails.linkedinUrl}
                    </p>
                    <p>
                      <b>Website:</b> {companyDetails.website}
                    </p>

                    <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
                      <Button
                        styling="Submit"
                        disabled={submitting}
                        onClick={() => {
                          addCompanyToHubspot(company.id);
                        }}
                      >
                        Add
                      </Button>
                    </div>
                  </div>
                )}
              </Typography>
            </AccordionDetails>
          </div>
        </Accordion>
      ))}
      {isLoading && <Loading />}
      {submitting && <Loading />}
    </Container>
  );
};
