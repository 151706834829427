import { Box, DialogContent, TextField, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";
import {
  Connection,
  Datasource,
  DatasourceEntityType,
  getAllDatasources,
  getAvailableConnections,
} from "../../api/datasources";
import { ConnectionCard } from "./ConnectionCard";

interface ListAvailableConnectionsProps {
  onClick: (item: Connection, entityType: DatasourceEntityType) => void;
}
export const ListAvailableConnections = ({ onClick }: ListAvailableConnectionsProps) => {
  const [value, updateValue] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [availableConnections, setAvailableConnections] = useState<Connection[]>([]);
  const [datasources, setDatasources] = useState<Datasource[]>([]);

  useEffect(() => {
    async function fetchAllData() {
      setIsLoading(true);
      const ac = await getAvailableConnections();
      setAvailableConnections(ac);
      const ds = await getAllDatasources();
      setDatasources(ds);
      setIsLoading(false);
    }
    fetchAllData();
  }, []);

  return (
    <>
      {isLoading ? (
        <DialogContent>
          <Box paddingY={8} display="flex" flexDirection="column" justifyContent="center">
            <ClipLoader />
            <br />
            <Typography variant="h6" align="center">
              Loading
            </Typography>
          </Box>
        </DialogContent>
      ) : (
        <DialogContent>
          <Typography variant="button" component="label">
            Search:{" "}
          </Typography>
          <TextField
            id="connections__availableConnectionsSearch"
            variant="outlined"
            value={value}
            onChange={(event) => updateValue(event.target.value)}
            size="small"
            hiddenLabel
            placeholder="Search..."
          />

          <Box p={2}>
            {availableConnections
              .filter((item) => item.name.toLowerCase().includes(value.toLowerCase().trim()))
              .map((item) => {
                return (
                  <React.Fragment key={item.systemId!}>
                    {item.entityTypes.map((type) => {
                      const disabled = Boolean(
                        datasources &&
                          datasources.find((item) => item.entityType === type && item.entityType !== "PRODUCT")
                      );
                      const disabledHint = disabled ? "You already have a connection for this entity type" : undefined;

                      return (
                        <ConnectionCard
                          key={type}
                          id={`configure[${item.systemId}]__action`}
                          item={item}
                          entityType={type!}
                          buttonValue="Connect"
                          buttonId={`connect__${item.name.replace(/\s+/g, "")}`}
                          onClick={() => {
                            onClick(item, type);
                          }}
                          status=""
                          buttonDisabled={disabled}
                          buttonDisabledHint={disabledHint}
                        />
                      );
                    })}
                  </React.Fragment>
                );
              })}
          </Box>
        </DialogContent>
      )}
    </>
  );
};
