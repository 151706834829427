import { http } from "../UppAuth";

export async function checkOrderHealth(tenantId: string, dateRange: number) {
  const result = await http.get<any>(
    `${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/health/account/${tenantId}/audit/${dateRange}`
  );

  if (result.status !== 200) {
    console.log("Failed to get order health");
  }

  return result;
}

export async function getMarginAndCostReport(dateRange: number, groupBy: string) {
  if (groupBy) {
    return await http.get<any>(
      `${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/campaign-recommendation-report/margin-and-cost/?intervalInDays=${dateRange}&groupBy=${groupBy}`,
      {
        responseType: "blob",
      }
    );
  } else {
    return await http.get<any>(
      `${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/campaign-recommendation-report/margin-and-cost/?intervalInDays=${dateRange}`,
      {
        responseType: "blob",
      }
    );
  }
}
