import { Dialog, DialogContent, DialogTitle, Divider, InputLabel } from "@material-ui/core";
import { CloseRounded } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import React, { Dispatch, SetStateAction, useState } from "react";
import styled from "styled-components";
import { createJob, Path } from "../../api/jobscheduler";
import { StyledButton } from "../datasources/Datasources";
import { StyledCloseButton } from "../datasources/SetupConnection";
import { StyledRow } from "../tenantConfig/TenantConfig";
import { DownloadWorkflow } from "./steps/DownloadWorkflow";
import { Schedule } from "./steps/Schedule";
import { TradePackWorkflow } from "./steps/TradePackWorkflow";
import {
  CampaignAutomationConfiguration,
  CampaignAutomationWorkflowToRun,
  CommonPayload,
} from "@volo-ai/upp-temporal-utils";
import { CampaignAutomationWorkflow } from "./steps/CampaignAutomationWorkflow";
import { MpaAccountWorkflow } from "./steps/MpaAccountWorkflow";
import { MonitoringWorkflow } from "./steps/MonitoringWorkflow";
import { VisibilityPerformanceReportWorkflow } from "./steps/VisibilityPerformanceReportWorkflow";
import { MpaSkuWorkflow } from "./steps/MpaSkuWorkflow";
import { OpportunitiesWorkflow } from "./steps/OpportunitiesWorkflow";
import { SearchTrendsWorkflow } from "./steps/SearchTrendsWorkflow";

interface AddJobProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export const StyledInputLabel = styled(InputLabel)`
  margin-bottom: 10px;
  margin-top: 5px;
`;

export const AddJobDialog = ({ isOpen, setIsOpen }: AddJobProps) => {
  const [tradePackModalOpen, setTradePackModalOpen] = useState<boolean>(false);
  const [downloadModalOpen, setDownloadModalOpen] = useState<boolean>(false);
  const [campaignAutomationModalOpen, setCampaignAutomationModalOpen] = useState<boolean>(false);
  const [opportunitiesModalOpen, setOpportunitiesModalOpen] = useState<boolean>(false);
  const [mpaAccountModalOpen, setMpaAccountModalOpen] = useState<boolean>(false);
  const [mpaSkuModalOpen, setMpaSkuModalOpen] = useState<boolean>(false);
  const [monitoringModalOpen, setMonitoringModalOpen] = useState<boolean>(false);
  const [searchTrendsModalOpen, setSearchTrendsModalOpen] = useState<boolean>(false);
  const [visibilityPerformanceModalOpen, setVisibilityPerformanceModalOpen] = useState<boolean>(false);
  const [jobPayload, setJobPayload] = useState<CommonPayload>({
    tenantId: "",
    cronSchedule: "* * * * *",
    workflowToRun: {},
    configuration: {},
  });

  const { enqueueSnackbar } = useSnackbar();

  const ALL_DOWNLOADS_PAYLOAD = {
    workflowToRun: {
      product: true,
      order: true,
      reconciliation: true,
      cost: true,
      campaign: true,
      costAccount: true,
      costCampaign: true,
      performance: {
        account: true,
        campaign: true,
        category: true,
        product: true,
      },
      campaignAutomation: true,
    },
    configuration: {
      performance: {
        product: {
          batchSize: 2,
        },
      },
      cost: {
        batchSize: 2,
      },
    },
  };

  const handleSubmit = (path: Path) => {
    let payload = jobPayload;
    let finalPath = path;
    if (path === "auto-create-download") {
      finalPath = "downloads";
      payload = {
        ...payload,
        ...ALL_DOWNLOADS_PAYLOAD,
      };
    }
    createJob(payload, finalPath)
      .then((response) => {
        if (response.data.wasSuccessful === true) {
          enqueueSnackbar(`${response.data.message}`, {
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
          });
        } else {
          enqueueSnackbar(`${response.data.message}`, {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
          });
        }
      })
      .finally(() => {
        setIsOpen(false);
        setTradePackModalOpen(false);
        setDownloadModalOpen(false);
      });
  };

  const onOpenVisibilityPerformanceModal = (e: any) => {
    setVisibilityPerformanceModalOpen(e);
    if (e === true) {
      setJobPayload({
        tenantId: "",
        cronSchedule: "* * * * *",
        workflowToRun: {},
        configuration: {
          visibilityPerformanceReport: {
            dateRange: {
              to: "",
              from: "",
            },
            comparisonDateRange: {
              to: "",
              from: "",
            },
          },
        },
      });
    } else {
      setJobPayload({
        tenantId: "",
        cronSchedule: "* * * * *",
        workflowToRun: {},
        configuration: {},
      });
    }
  };
  const handleDataChange = (prop: any) => (e: React.ChangeEvent<HTMLInputElement>) => {
    let value: any;

    if (e.target.type === "number") {
      value = +e.target.value;
    }

    if (e.target.type === "text" || e.target.type === "date") {
      value = e.target.value;
    }

    setJobPayload((prevState: any) => {
      const newState = { ...prevState };
      set(newState, prop, value);
      return newState;
    });
  };

  const handleToggle = (prop: any) => (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.checked;

    setJobPayload((prevState: any) => {
      const newState = { ...prevState };
      set(newState, prop, value);
      return newState;
    });
  };

  function set(obj: any, path: any, value: any) {
    var schema = obj;
    var pList = path.split(".");
    var len = pList.length;
    for (var i = 0; i < len - 1; i++) {
      var elem = pList[i];
      if (!schema[elem]) schema[elem] = {};
      schema = schema[elem];
    }

    schema[pList[len - 1]] = value;
  }
  return (
    <Dialog open={isOpen} maxWidth="sm" fullWidth onClose={() => setIsOpen(false)}>
      <DialogTitle>
        Add New Job
        <StyledCloseButton onClick={() => setIsOpen(false)}>
          <CloseRounded />
        </StyledCloseButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Schedule handleToggle={handleToggle} jobPayload={jobPayload} handleDataChange={handleDataChange} />
        <StyledRow>
          <StyledButton onClick={() => setDownloadModalOpen(true)}>Download</StyledButton>
        </StyledRow>
        <StyledRow>
          <StyledButton onClick={() => setTradePackModalOpen(true)}>Trade Pack</StyledButton>
        </StyledRow>
        <StyledRow>
          <StyledButton onClick={() => setOpportunitiesModalOpen(true)}>Opportunities</StyledButton>
        </StyledRow>
        <StyledRow>
          <StyledButton onClick={() => setCampaignAutomationModalOpen(true)}>Campaign Automation</StyledButton>
        </StyledRow>
        <StyledRow>
          <StyledButton onClick={() => setMpaAccountModalOpen(true)}>Mpa Account</StyledButton>
        </StyledRow>
        <StyledRow>
          <StyledButton onClick={() => setMpaSkuModalOpen(true)}>Mpa Sku</StyledButton>
        </StyledRow>
        <StyledRow>
          <StyledButton onClick={() => setMonitoringModalOpen(true)}>Monitoring</StyledButton>
        </StyledRow>
        <StyledRow>
          <StyledButton onClick={() => setSearchTrendsModalOpen(true)}>Search Trends</StyledButton>
        </StyledRow>
        <StyledRow>
          <StyledButton onClick={() => onOpenVisibilityPerformanceModal(true)}>
            Visibility Performance Report
          </StyledButton>
        </StyledRow>
      </DialogContent>
      <DownloadWorkflow
        handleToggle={handleToggle}
        jobPayload={jobPayload}
        open={downloadModalOpen}
        setOpen={setDownloadModalOpen}
        handleDataChange={handleDataChange}
        handleSubmit={(path) => {
          console.log(`handle submit ${path}`);
          handleSubmit(path);
          setJobPayload({ tenantId: "", cronSchedule: "* * * * *", workflowToRun: {}, configuration: {} });
        }}
      />
      <TradePackWorkflow
        handleToggle={handleToggle}
        jobPayload={{ ...jobPayload, workflowToRun: { tradePack: true } }}
        open={tradePackModalOpen}
        setOpen={setTradePackModalOpen}
        handleSubmit={() => {
          handleSubmit("trade-pack");
          setJobPayload({ tenantId: "", cronSchedule: "* * * * *", workflowToRun: {}, configuration: {} });
        }}
        handleDataChange={handleDataChange}
      />
      <OpportunitiesWorkflow
        handleToggle={handleToggle}
        jobPayload={jobPayload}
        open={opportunitiesModalOpen}
        setOpen={setOpportunitiesModalOpen}
        handleSubmit={() => {
          handleSubmit("opportunities");
          setJobPayload({ tenantId: "", cronSchedule: "* * * * *", workflowToRun: {}, configuration: {} });
        }}
        handleDataChange={handleDataChange}
      />
      <CampaignAutomationWorkflow
        handleToggle={handleToggle}
        jobPayload={jobPayload}
        open={campaignAutomationModalOpen}
        setOpen={setCampaignAutomationModalOpen}
        handleSubmit={() => {
          if ((jobPayload.configuration as CampaignAutomationConfiguration)?.updateOnly) {
            (jobPayload.workflowToRun as CampaignAutomationWorkflowToRun).pga = true;
          }
          handleSubmit("campaign-automation");
          setJobPayload({ tenantId: "", cronSchedule: "* * * * *", channel: "", workflowToRun: {}, configuration: {} });
        }}
        handleDataChange={handleDataChange}
      />
      <MpaAccountWorkflow
        handleToggle={handleToggle}
        jobPayload={{ ...jobPayload, workflowToRun: {} }}
        open={mpaAccountModalOpen}
        setOpen={setMpaAccountModalOpen}
        handleSubmit={() => {
          handleSubmit("mpa-account");
          setJobPayload({ tenantId: "", cronSchedule: "* * * * *", workflowToRun: {}, configuration: {} });
        }}
        handleDataChange={handleDataChange}
      />
      <MpaSkuWorkflow
        handleToggle={handleToggle}
        jobPayload={{ ...jobPayload, workflowToRun: {} }}
        open={mpaSkuModalOpen}
        setOpen={setMpaSkuModalOpen}
        handleSubmit={() => {
          handleSubmit("mpa-sku");
          setJobPayload({ tenantId: "", cronSchedule: "* * * * *", workflowToRun: {}, configuration: {} });
        }}
        handleDataChange={handleDataChange}
      />
      <MonitoringWorkflow
        handleToggle={handleToggle}
        jobPayload={jobPayload}
        open={monitoringModalOpen}
        setOpen={setMonitoringModalOpen}
        handleSubmit={() => {
          handleSubmit("monitoring");
          setJobPayload({ tenantId: "", cronSchedule: "* * * * *", workflowToRun: {}, configuration: {} });
        }}
        handleDataChange={handleDataChange}
      />
      <SearchTrendsWorkflow
        handleToggle={handleToggle}
        jobPayload={{ ...jobPayload, workflowToRun: {} }}
        open={searchTrendsModalOpen}
        setOpen={setSearchTrendsModalOpen}
        handleSubmit={() => {
          handleSubmit("search-trends");
          setJobPayload({ tenantId: "", cronSchedule: "* * * * *", workflowToRun: {}, configuration: {} });
        }}
        handleDataChange={handleDataChange}
      />
      <VisibilityPerformanceReportWorkflow
        handleToggle={handleToggle}
        jobPayload={jobPayload}
        open={visibilityPerformanceModalOpen}
        handleDataChange={handleDataChange}
        setOpen={onOpenVisibilityPerformanceModal}
        handleSubmit={() => {
          handleSubmit("visibility-performance-report");
          setJobPayload({
            tenantId: "",
            cronSchedule: "* * * * *",
            workflowToRun: {},
            configuration: {},
          });
          setJobPayload({
            tenantId: "",
            cronSchedule: "* * * * *",
            workflowToRun: {},
            configuration: {},
          });
        }}
      />
    </Dialog>
  );
};
