import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { GridWrapper } from "../dataManagement/DataManagement";
import { SummaryScheduledJob } from "../../api/jobscheduler";

type Props = {
  jobs: SummaryScheduledJob[];
  isLoading: boolean;
};

function OverviewTable({ jobs, isLoading }: Props): JSX.Element {
  const columns: GridColDef[] = [
    { headerName: "Account ID", field: "tenantId", width: 150 },
    { headerName: "Job Name", field: "jobName", width: 160 },
    { headerName: "Job Type", field: "jobType", width: 125 },
    { headerName: "Status", field: "status", width: 125 },
    { headerName: "Cron Schedule", field: "cronSchedule", width: 125 },
    { headerName: "Last Update", field: "latestUpdate", width: 200 },
    { headerName: "Last Update Time", field: "latestUpdateTime", width: 200 },
    { headerName: "Next Invocation", field: "nextInvocation", width: 200 },
  ];

  return (
    <GridWrapper>
      <DataGrid rows={jobs} columns={columns} loading={isLoading} getRowId={(row) => row.jobName} />
    </GridWrapper>
  );
}

export default OverviewTable;
