import NumberFormat from "react-number-format";
import styled from "styled-components";
import { uppColourPalette } from "../util/colours";
import { GetCurrencySymbol } from "../util/currency";
import { dateFormatter } from "./Chart";

export const StyledTooltip = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  overflow: auto;
`;

const StyledTitle = styled.div`
  color: white;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 1em;
  background: ${uppColourPalette.darkGreen[500]};
  padding: 16px;
`;

const StyledText = styled.div`
  display: grid;
  font-size: 1em;
  grid-template-columns: 1fr 1fr;
  &:not(:last-child) {
    border-bottom: 1px dashed ${uppColourPalette.purple[900]};
  }
  padding: 12px;
`;

export interface CircleProps {
  colour: string;
}

export const Circle = styled.div<CircleProps>`
  display: inline-block;
  height: 8px;
  width: 8px;
  margin-right: 8px;
  border-radius: 10px;
  background: ${(props) =>
    props.colour === "purple" ? uppColourPalette.purple[500] : uppColourPalette.darkGreen[500]};
`;

interface SquareProps {
  colour: string;
}

const Square = styled.div<SquareProps>`
  display: inline-block;
  height: 8px;
  width: 8px;
  margin-right: 8px;
  border: 1px dashed
    ${(props) => (props.colour === "purple" ? uppColourPalette.purple[500] : uppColourPalette.darkGreen[500])};
`;

function renderKey(object: any, colour: string) {
  switch (object.name) {
    case "targetRoas":
    case "targetSpend":
    case "targetCpa":
      return <Square colour={colour} />;
    default:
      return <Circle colour={colour} />;
  }
}

const ChartTooltip = ({ active, payload, label, left, right, keyRender }: any) => {
  function getName(name: string): string {
    return (
      "" +
      {
        roas: "ROAS",
        targetRoas: "Target ROAS",
        actualSpend: "Spend",
        targetSpend: "Target Spend",
        conversionValue: "Conversion Value",
        actualCpa: "CPA",
        targetCpa: "Target CPA",
        value: "Value",
      }[name]
    );
  }

  const newSet = new Set();

  const dedupedPayload =
    payload &&
    payload.filter((obj: any) => {
      const isPresent = newSet.has(obj.name);
      newSet.add(obj.name);
      return !isPresent;
    });

  if (active && payload) {
    return (
      <StyledTooltip>
        <StyledTitle>{dateFormatter(label)}</StyledTitle>

        {dedupedPayload.map((object: { name: string; value: number }, count: number) => {
          const prefix = object.name.toLowerCase().includes("roas") ? "" : GetCurrencySymbol();
          const colour = getColour(left, right, count, dedupedPayload.length);

          return (
            <StyledText>
              <text>
                {keyRender && renderKey(object, colour)}
                {getName(object.name)}
              </text>
              <text style={{ textAlign: "right" }}>
                <NumberFormat displayType={"text"} value={object.value} prefix={prefix} />
              </text>
            </StyledText>
          );
        })}
      </StyledTooltip>
    );
  }
  return null;
};

export default ChartTooltip;

export function getColour(left: boolean, right: boolean, count: number, total: number): string {
  if (!left) {
    return "green";
  }

  if (!right) {
    return "purple";
  }

  if (total === 4 && count < 2) {
    if (count < 2) {
      return "purple";
    }
  }

  if (total === 3) {
    if (count < 1) {
      return "purple";
    }
  }

  return "green";
}
