import { Box, Dialog, DialogContent, DialogTitle, Divider, InputLabel, TextField } from "@material-ui/core";
import { CloseRounded } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import { useState } from "react";
import styled from "styled-components";
import { updateTenantConfig } from "../../api/tenant";
import { StyledButton, StyledButtonFilled } from "../datasources/Datasources";
import { StyledCloseButton } from "../datasources/SetupConnection";
import { FreeTextConfig } from "./FreeTextConfig";
import { StyledRow } from "./TenantConfig";

interface SubAccountProps {
  data: any;
  isOpen: boolean;
  onClose: () => void;
}

const StyledSaveButton = styled(StyledButton)`
  float: right;
`;

const StyledAddButton = styled(StyledButton)`
  float: right;
  width: 150px;
`;

export const SubAccountMapping = ({ data, isOpen, onClose }: SubAccountProps) => {
  const [submapping, setSubmapping] = useState(data);
  const [region, setRegion] = useState<string>("");
  const [tenantId, setTenantId] = useState<string>("");
  const [step, setStep] = useState<number>(0);
  const { enqueueSnackbar } = useSnackbar();

  const handleClose = () => {
    onClose();
  };

  const handleDataChange = (prop: any) => (e: React.ChangeEvent<HTMLInputElement>) => {
    setSubmapping({ ...submapping, [prop]: e.target.value });
  };

  const handleAdd = () => {
    if (region !== "" && tenantId !== "") {
      setSubmapping({ ...submapping, [region]: tenantId });
      setStep(0);
    }
    enqueueSnackbar("Missing data. Please try again", {
      variant: "error",
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
    });
  };

  const handleSubmit = (e: React.SyntheticEvent) => {
    updateTenantConfig(window.USER_INFO.tenantId, [{ key: "subAccountMapping", value: JSON.stringify(submapping) }])
      .then(() => {
        enqueueSnackbar("Mappings have been updated successfully", {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        });
      })
      .catch(() => {
        enqueueSnackbar("There was an error trying to save mappings, please try again.", {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        });
      });

    handleClose();
  };

  return (
    <Dialog open={isOpen} maxWidth="sm" fullWidth onClose={handleClose}>
      {step === 0 ? (
        <>
          <DialogTitle>
            Edit SubAccount Mapping
            <StyledCloseButton onClick={handleClose}>
              <CloseRounded />
            </StyledCloseButton>
            <StyledSaveButton onClick={handleSubmit}>Save</StyledSaveButton>
          </DialogTitle>
          <Divider />
          <DialogContent>
            <StyledAddButton
              onClick={() => {
                setStep(1);
              }}
            >
              Add Account
            </StyledAddButton>

            <Box padding={2} display="flex" flexDirection="column">
              {Object.entries(submapping).map(([key, value]) => {
                return (
                  <StyledRow>
                    <InputLabel>{key}</InputLabel>
                    <TextField onChange={handleDataChange(key)} variant="outlined" defaultValue={value} />
                  </StyledRow>
                );
              })}
            </Box>
          </DialogContent>
        </>
      ) : (
        <>
          <DialogTitle>
            Add New SubAccount
            <StyledCloseButton onClick={handleClose}>
              <CloseRounded />
            </StyledCloseButton>
            <StyledSaveButton onClick={() => setStep(0)}>Back</StyledSaveButton>
          </DialogTitle>
          <Divider />
          <DialogContent>
            <Box padding={1} display="flex" flexDirection="column">
              <StyledRow>
                <FreeTextConfig placeholder={"Region"} onChange={(e) => setRegion(e.target.value)} />
              </StyledRow>
              <StyledRow>
                <FreeTextConfig placeholder={"Account Id"} onChange={(e) => setTenantId(e.target.value)} />
              </StyledRow>
            </Box>
            <StyledButtonFilled onClick={handleAdd}>Save</StyledButtonFilled>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
};
