import { Grid, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { Box } from "@material-ui/core";
import {
  CampaignMonitoring,
  getAccountCampaignMonitoring,
  getCampaignMonitoring,
  PastCampaignAutomation,
} from "../../api/campaignMonitoring";
import { StyledDashboardContainer } from "../dashboard/Dashboard";
import { Heading } from "../../components/Heading";
import { CampaignMonitoringCards } from "./CampaignMonitoringCards";
import { CampaignMonitoringErrorBox } from "./CampaignMonitoringErrorBox";
import { PGADateSelector } from "./PGADateSelector";
import { ProductsPerformanceGraph } from "./ProductsPerformanceGraph";
import { AccountOverview, getAccountOverview } from "../../api/accountsoverview";
import { getProductStats } from "../../api/product";
import { ProductsPerformance } from "../../api/performance";
import { getProductMoves, ProductMoves } from "../../api/campaignAutomation";
import { ProductMovesGraph } from "./ProductMovesGraph";

const getDiscountedImpressionsPercentageChange = (normalImpressions: number, discountedImpressions: number) => {
  if (discountedImpressions > normalImpressions) {
    const discountPercentageChange = (100 * normalImpressions) / discountedImpressions;
    return `had a ${
      Number.isNaN(discountPercentageChange) ? 0 : discountPercentageChange.toFixed(2)
    }% increase in impressions`;
  }
  if (normalImpressions < discountedImpressions) {
    const discountPercentageChange = (100 * discountedImpressions) / normalImpressions;
    return `had a ${
      Number.isNaN(discountPercentageChange) ? 0 : discountPercentageChange.toFixed(2)
    }% decrease in impressions`;
  }
  return `had no impact on impressions`;
};

const getDiscountedRevenuePercentageChange = (normalRevenue: number, discountedRevenue: number) => {
  if (discountedRevenue > normalRevenue) {
    const discountPercentageChange = (100 * normalRevenue) / discountedRevenue;
    return `had a ${
      Number.isNaN(discountPercentageChange) ? 0 : discountPercentageChange.toFixed(2)
    }% increase in revenue`;
  }
  if (normalRevenue < discountedRevenue) {
    const discountPercentageChange = (100 * discountedRevenue) / normalRevenue;
    return `had a ${
      Number.isNaN(discountPercentageChange) ? 0 : discountPercentageChange.toFixed(2)
    }% decrease in revenue`;
  }
  return `had no impact on revenue`;
};

const getProductPerformanceSummarySentence = ({
  normalProductsPerformance,
  discountedProductsPerformance,
  discountedProductCount,
  normalProductCount,
}: {
  normalProductsPerformance: ProductsPerformance[];
  discountedProductsPerformance: ProductsPerformance[];
  normalProductCount: number;
  discountedProductCount: number;
}) => {
  const totalNormalProductPerformance = normalProductsPerformance.reduce(
    (previous, current) => {
      return { impressions: previous.impressions + current.impressions, revenue: previous.revenue + current.revenue };
    },
    { impressions: 0, revenue: 0 }
  );
  const totalDiscountedProductPerformance = discountedProductsPerformance.reduce(
    (previous, current) => {
      return { impressions: previous.impressions + current.impressions, revenue: previous.revenue + current.revenue };
    },
    { impressions: 0, revenue: 0 }
  );
  const totalProductCount = discountedProductCount + normalProductCount;
  const discountedPercentage = (100 * discountedProductCount) / totalProductCount;
  return `Out of the ${totalProductCount} products, ${discountedProductCount} are in discount which represents ${
    Number.isNaN(discountedPercentage) ? 0 : discountedPercentage.toFixed(2)
  }%.
   This ${
     Number.isNaN(discountedPercentage) ? 0 : discountedPercentage.toFixed(2)
   }% ${getDiscountedImpressionsPercentageChange(
    totalNormalProductPerformance.impressions,
    totalDiscountedProductPerformance.impressions
  )} and ${getDiscountedRevenuePercentageChange(
    totalNormalProductPerformance.revenue,
    totalDiscountedProductPerformance.revenue
  )}`;
};

const CampaignMonitoringScreen = () => {
  const location = useLocation();
  const tenantId = new URLSearchParams(location.search).get("tenantId");
  const [noTenantIdError, setNoTenantIdError] = useState(false);
  const [loadingProductsPerformance, setLoadingProductsPerformance] = useState<boolean>(true);
  const [loadingPastCampaigns, setLoadingPastCampaigns] = useState<boolean>(true);
  const [loadingCampaignMonitoring, setLoadingCampaignMonitoring] = useState<boolean>(true);
  const [loadingProductMoves, setLoadingProductMoves] = useState<boolean>(true);
  const [fromDate, setFromDate] = useState<string>(new Date().toISOString());
  const [toDate, setToDate] = useState<string>(new Date().toISOString());
  const [normalProductsPerformance, setNormalProductsPerformance] = useState<ProductsPerformance[]>([]);
  const [discountedProductsPerformance, setDiscountedProductsPerformance] = useState<ProductsPerformance[]>([]);
  const [pastCampaignAutomations, setPastCampaignAutomations] = useState<PastCampaignAutomation[]>([]);
  const [campaignMonitoring, setCampaignMonitoring] = useState<CampaignMonitoring[]>([]);
  const [accountOverview, setAccountOverview] = useState<AccountOverview>();
  const [discountedProductCount, setDiscountedProductCount] = useState<number>(0);
  const [normalProductCount, setNormalProductCount] = useState<number>(0);
  const [productMoves, setProductMoves] = useState<ProductMoves>({
    source: [],
    source_labels: [],
    target: [],
    target_labels: [],
    value: [],
    colour: [],
    label: [],
  });

  useEffect(() => {
    async function fetchAccountOverview() {
      if (tenantId) {
        const accountOverviewData = await getAccountOverview(tenantId, fromDate, toDate);
        setAccountOverview(accountOverviewData);
      }
    }
    fetchAccountOverview();
  }, [tenantId, fromDate, toDate]);
  useEffect(() => {
    async function fetchProductStats() {
      if (tenantId) {
        const { discountedProductCount, normalProductCount } = await getProductStats();
        setDiscountedProductCount(discountedProductCount);
        setNormalProductCount(normalProductCount);
      }
    }
    fetchProductStats();
  }, [tenantId]);
  useEffect(() => {
    async function fetchAccountsCampaignMonitoring() {
      setLoadingPastCampaigns(true);
      if (tenantId) {
        const data = await getAccountCampaignMonitoring({ tenantId });
        setPastCampaignAutomations(data.pastCampaignAutomations);
        setFromDate(data.pastCampaignAutomations[0]?.startDate.split("T")[0]);
        setToDate(
          data.pastCampaignAutomations[0]?.endDate
            ? data.pastCampaignAutomations[0].endDate.split("T")[0]
            : new Date().toISOString().split("T")[0]
        );
        return setLoadingPastCampaigns(false);
      }
      setNoTenantIdError(true);
      setLoadingPastCampaigns(false);
    }
    fetchAccountsCampaignMonitoring();
  }, [tenantId]);
  useEffect(() => {
    async function fetchProductsPerformance() {
      setLoadingProductsPerformance(true);
      if (tenantId) {
        if (fromDate && toDate) {
          const oneWeekAfterPGA = new Date();
          oneWeekAfterPGA.setDate(new Date(fromDate).getDate() + 7);

          const normalProductPerformanceData = { results: [] };
          setNormalProductsPerformance(normalProductPerformanceData.results);

          const discountedProductPerformanceData = { results: [] };
          setDiscountedProductsPerformance(discountedProductPerformanceData.results);
        }
        return setLoadingProductsPerformance(false);
      }
      setNoTenantIdError(true);
      setLoadingProductsPerformance(false);
    }
    fetchProductsPerformance();
  }, [fromDate, toDate, tenantId]);
  useEffect(() => {
    async function fetchCampaignMonitoring() {
      setLoadingCampaignMonitoring(true);
      if (tenantId) {
        if (fromDate && toDate) {
          const data = await getCampaignMonitoring({ tenantId, from: fromDate, to: toDate });
          setCampaignMonitoring(data.campaigns);
        }
        return setLoadingCampaignMonitoring(false);
      }
      setNoTenantIdError(true);
      setLoadingCampaignMonitoring(false);
    }
    fetchCampaignMonitoring();
  }, [fromDate, toDate, tenantId]);
  useEffect(() => {
    async function fetchProductMoves() {
      setLoadingProductMoves(true);
      if (tenantId) {
        if (fromDate && toDate) {
          const data = await getProductMoves({ tenantId, from: fromDate, to: toDate });
          setProductMoves(data as ProductMoves);
        }
      }
      return setLoadingProductMoves(false);
    }
    fetchProductMoves();
  }, [fromDate, toDate, tenantId]);

  return (
    <StyledDashboardContainer>
      <Grid style={{ margin: 10 }} container spacing={2}>
        <Box
          sx={{
            padding: "2em",
            margin: "2em",
          }}
        >
          <Heading>{tenantId}</Heading>
          <Typography color="textPrimary" align="left">
            Target ROAS: {accountOverview?.targetRoas ?? 0}
          </Typography>
          <Typography color="textPrimary" align="left">
            Budget: {accountOverview?.targetSpend ?? 0}
          </Typography>
          <Typography color="textPrimary" align="left">
            {getProductPerformanceSummarySentence({
              normalProductsPerformance,
              discountedProductsPerformance,
              normalProductCount,
              discountedProductCount,
            })}
          </Typography>
        </Box>
        <br />
        {loadingPastCampaigns ? (
          <ClipLoader size={50} loading />
        ) : pastCampaignAutomations.length === 0 ? (
          <Box>{`No past pga runs for ${tenantId}}`}</Box>
        ) : (
          <Grid item xs={10} spacing={1}>
            <PGADateSelector
              pastCampaignAutomations={pastCampaignAutomations}
              setFromDate={setFromDate}
              setToDate={setToDate}
            />
          </Grid>
        )}
        {loadingProductMoves ? (
          <ClipLoader size={50} loading />
        ) : productMoves?.source?.length === 0 ? (
          <Box>{`No product moves data available`}</Box>
        ) : (
          <ProductMovesGraph productMoves={productMoves} />
        )}
        {loadingProductsPerformance ? (
          <div style={{ margin: 10 }}>
            <ClipLoader size={50} loading />
          </div>
        ) : (
          <ProductsPerformanceGraph productsPerformance={discountedProductsPerformance} />
        )}
        {loadingCampaignMonitoring ? (
          <ClipLoader size={50} loading />
        ) : campaignMonitoring.length === 0 || noTenantIdError ? (
          <CampaignMonitoringErrorBox tenantId={tenantId} />
        ) : (
          <CampaignMonitoringCards campaignMonitoring={campaignMonitoring} />
        )}
      </Grid>
    </StyledDashboardContainer>
  );
};

export default CampaignMonitoringScreen;
