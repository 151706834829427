import { Dialog, DialogContent, DialogTitle, Divider, Grid, TextField } from "@material-ui/core";
import { CloseRounded } from "@material-ui/icons";
import { ChangeEvent, Dispatch, SetStateAction } from "react";
import { StyledButtonFilled } from "../../datasources/Datasources";
import { StyledCloseButton } from "../../datasources/SetupConnection";
import { StyledRow } from "../../tenantConfig/TenantConfig";
import { CommonPayload } from "@volo-ai/upp-temporal-utils";
import { StyledInputLabel } from "../AddJobDialog";

interface VisibilityPerformanceReportWorkflowProps {
  handleToggle: (prop: any) => (e: ChangeEvent<HTMLInputElement>) => void;
  jobPayload: CommonPayload;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  handleSubmit?: React.MouseEventHandler<HTMLButtonElement>;
  handleDataChange: (prop: any) => (e: ChangeEvent<HTMLInputElement>) => void;
}

export const VisibilityPerformanceReportWorkflow = ({
  handleToggle,
  jobPayload,
  open,
  setOpen,
  handleSubmit,
  handleDataChange,
}: VisibilityPerformanceReportWorkflowProps) => {
  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>
        Visibility Performance Report Workflow
        <StyledCloseButton onClick={() => setOpen(false)}>
          <CloseRounded />
        </StyledCloseButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container>
          <Grid item>
            <p>Leave date fields empty to use standard 14 days vs 14 days date range</p>
            <StyledInputLabel>Date From (Optional)</StyledInputLabel>
            <TextField
              type="date"
              variant="outlined"
              onChange={handleDataChange("configuration.visibilityPerformanceReport.dateRange.from")}
            />
            <StyledInputLabel>Date To (Optional)</StyledInputLabel>
            <TextField
              type="date"
              variant="outlined"
              onChange={handleDataChange("configuration.visibilityPerformanceReport.dateRange.to")}
            />
            <StyledInputLabel>Compare Date From (Optional)</StyledInputLabel>
            <TextField
              type="date"
              variant="outlined"
              onChange={handleDataChange("configuration.visibilityPerformanceReport.comparisonDateRange.from")}
            />
            <StyledInputLabel>Compare Date To (Optional)</StyledInputLabel>
            <TextField
              type="date"
              variant="outlined"
              onChange={handleDataChange("configuration.visibilityPerformanceReport.comparisonDateRange.to")}
            />
          </Grid>
          <Grid container>
            <Grid item>
              <StyledInputLabel>Channel</StyledInputLabel>
              <TextField type="string" variant="outlined" onChange={handleDataChange("channel")} />
            </Grid>
          </Grid>
        </Grid>

        <StyledRow style={{ marginTop: "20px" }}>
          <StyledButtonFilled onClick={handleSubmit}>Create</StyledButtonFilled>
        </StyledRow>
      </DialogContent>
    </Dialog>
  );
};
