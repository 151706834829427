import { Box } from "@material-ui/core";
import { useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";
import { MPAHealthIndicators, getMPAHealth } from "../../../api/product";
import { Heading } from "../../../components/Heading";
import { InnerContainer } from "../../../components/InnerContainer";
import { StyledRow } from "../CampaignAutomation";

export const MPAHeathIndicators = () => {
  const [mpaHealthIndicatorsLoading, setMpaHealthIndicatorsLoading] = useState<boolean>(false);
  const [mpaHealthIndicators, setMpaHealthIndicators] = useState<MPAHealthIndicators>();

  async function getStats() {
    const result = await getMPAHealth();
    if (result) {
      setMpaHealthIndicators(result);
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      setMpaHealthIndicatorsLoading(true);
      await Promise.all([getStats()]);
      setMpaHealthIndicatorsLoading(false);
    };
    fetchData();
  }, []);

  return (
    <Box
      sx={{
        bgcolor: "white",
        padding: "2em",
        margin: "2em",
        borderRadius: "0.5%",
        border: "1px solid",
        borderColor: "#dbdbdb",
      }}
    >
      <Heading>
        MPA Health Indicators <ClipLoader loading={mpaHealthIndicatorsLoading} />
      </Heading>
      {mpaHealthIndicators && (
        <InnerContainer>
          <StyledRow>
            <strong>Total products</strong> {`: ${mpaHealthIndicators.totalProducts}`}
          </StyledRow>
          <StyledRow>
            <strong>Products missing cost of goods sold</strong> {`: ${mpaHealthIndicators.productsMissingCogs}`}
          </StyledRow>
          <StyledRow>
            <strong>Products missing target contribution margin set</strong>
            {`: ${mpaHealthIndicators.productsMissingTargetContributionMargin}`}
            <strong></strong>
          </StyledRow>
          <StyledRow>
            <strong>Products with an invalid target roas</strong>
            {`: ${mpaHealthIndicators.productsInvalidTargetRoas}`}
          </StyledRow>
          <StyledRow>
            <strong>Problematic product percentage </strong>
            {mpaHealthIndicators.totalProducts !== 0
              ? `: ${mpaHealthIndicators.combinedIssues} which is ${(
                  (100 * mpaHealthIndicators.combinedIssues) /
                  mpaHealthIndicators.totalProducts
                ).toFixed(0)}% of the whole inventory`
              : `N/A`}
          </StyledRow>
        </InnerContainer>
      )}
    </Box>
  );
};
