import styled from "@emotion/styled";
import { uppColourPalette } from "../../util/colours";

type ButtonProps = {
  styling?: string;
  disabled?: boolean;
  children: string;
  onClick?: () => void;
};

const renderBackground = (styling: string): string => {
  switch (styling) {
    case "Confirm":
      return uppColourPalette.darkGreen[500];
    case "Deny":
      return uppColourPalette.coral[500];
    case "Submit":
      return uppColourPalette.navy[500];
    default:
      return "#ffffff";
  }
};

const renderColor = (styling: string): string => {
  switch (styling) {
    case "Submit":
    case "Confirm":
    case "Deny":
      return "white";

    default:
      return uppColourPalette.navy[500];
  }
};

const StyledButton = styled.button<ButtonProps>`
  width: 100%;
  max-width: 200px;
  padding: 8px 16px;
  transition: all ease 0.25s;
  border: none;
  color: ${(props) => renderColor(props.styling!)};
  background: ${(props) => renderBackground(props.styling!)};
  font-family: Aeonik Pro;
  font-size: 1em;
  border-radius: 4px;
  border: 1px solid ${uppColourPalette.coolGrey[100]};
  &:hover {
    filter: drop-shadow(0 0 2px ${uppColourPalette.lightGreen[500]});
    cursor: pointer;
  }
  &:disabled {
    background: ${uppColourPalette.coolGrey[50]};
    color: ${uppColourPalette.coolGrey[200]};
    cursor: not-allowed;
  }
`;

const Button = ({ styling, disabled, children, onClick }: ButtonProps) => {
  return (
    <StyledButton disabled={disabled} styling={styling} onClick={onClick}>
      {children}
    </StyledButton>
  );
};

export default Button;
