import { Box, Divider } from "@material-ui/core";
import { Heading } from "../../components/Heading";
import { useAuditState } from "../../hooks/useAuditHealth";
import AuditHealthReportTable from "./AuditHealthReportTable";

const AuditHealthReport = () => {
  const { isLoading, isError, data } = useAuditState(window.USER_INFO.tenantId);

  return (
    <>
      <Box
        sx={{
          bgcolor: "white",
          padding: "2em",
          margin: "2em",
          borderRadius: "0.5%",
          border: "1px solid",
          borderColor: "#dbdbdb",
        }}
      >
        <Heading>Account Level Audit Health Report</Heading>
        <Divider />
        {isError && data !== undefined ? (
          <div> Something went wrong </div>
        ) : (
          <AuditHealthReportTable accountLevelHealthReport={data?.accountLevel ?? []} isLoading={isLoading} />
        )}
      </Box>
      <Box
        sx={{
          bgcolor: "white",
          padding: "2em",
          margin: "2em",
          borderRadius: "0.5%",
          border: "1px solid",
          borderColor: "#dbdbdb",
        }}
      >
        <Heading>Group Level Audit Health Report</Heading>
        <Divider />
        {/*<AuditHealthReportTable /> */}
      </Box>
    </>
  );
};

export default AuditHealthReport;
