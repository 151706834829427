import styled from "styled-components";

export const Heading = styled.div`
  display: flex;
  align-items: center;
  margin: 0 -16px 10px -16px;
  font-size: 2em;
  padding: 0 10px 10px 10px;
  button {
    margin-left: auto;
  }
`;
