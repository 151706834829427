import { http } from "../UppAuth";

export interface PastCampaignAutomation {
  startDate: string;
  endDate: string;
}
export interface AccountCampaignMonitoring {
  pastCampaignAutomations: PastCampaignAutomation[];
}

export interface CampaignMonitoring {
  campaignId: string;
  campaignName: string;
  date: string;
  averageRoas: number;
  targetRoas: number;
  productGroups: number;
  productGroupsAboveTargetRoas: number;
  productGroupsBelowTargetRoas: number;
  productGroupsAboveAverageRoas: number;
  productGroupsBelowAverageRoas: number;
  minimumNumberOfImpressionsPerConversion: number;
  maximumNumberOfImpressionsPerConversion: number;
  averageImpressionsPerConversion: number;
}

export async function getAccountCampaignMonitoring({
  tenantId,
}: {
  tenantId: string;
}): Promise<AccountCampaignMonitoring> {
  const result = await http.get(
    `${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/campaign-monitoring/accounts/${tenantId}`
  );

  if (result.status !== 200) {
    console.log("Failed to fetch campaign monitoring results");
  }

  return result.data;
}

export async function getCampaignMonitoring({
  tenantId,
  from,
  to,
}: {
  tenantId: string;
  from: string;
  to: string;
}): Promise<{ campaigns: CampaignMonitoring[] }> {
  const result = await http.get<{ campaigns: CampaignMonitoring[] }>(
    `${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/campaign-monitoring/accounts/${tenantId}/campaigns?from=${from}&to=${to}`
  );

  if (result.status !== 200) {
    console.log("Failed to fetch campaign monitoring results");
  }

  return result.data;
}
