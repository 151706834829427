import { DateTime } from "luxon";
import { http } from "../UppAuth";
import { getGoogleCampaignsOverview, getPGAOverview, GoogleCampaignType } from "./health";
import _ from "lodash";

export async function getAllTenants(showConfig: boolean = false): Promise<Tenant[]> {
  const query = showConfig ? "/tenants/?config=true" : "/tenants/";
  const result = await http.get<Tenant[]>(`${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}${query}`);

  if (result.status !== 200) {
    console.log("Failed to fetch Tenant Config");
    throw new Error("Failed to fetch Tenant Config");
  }

  return result.data;
}

export async function getAllLiveTenants(): Promise<Tenant[]> {
  const query = "/tenants?live=true&config=true";
  const result = await http.get<Tenant[]>(`${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}${query}`);

  if (result.status !== 200) {
    console.log("Failed to fetch Tenant Config");
    throw new Error("Failed to fetch Tenant Config");
  }
  const sortedById = _.orderBy(result.data, "id");

  return sortedById;
}

export async function getAllTenantsWithConfigAndCampaignMetadata(): Promise<OverviewDecoratedTenant[]> {
  const tenants = await getAllTenants(true);

  if (tenants.length) {
    const lastSuccessfulPGA = await getPGAOverview();
    const campaignsType = await getGoogleCampaignsOverview();

    return tenants.map((tenant) => {
      const possibleCampaignType = campaignsType.find((p) => p.tenantId === tenant.id);
      const campaignType = possibleCampaignType?.campaignType || GoogleCampaignType.Unknown;
      const pgaData = lastSuccessfulPGA.find((p: any) => p.tenantId === tenant.id);
      const lastSuccessful = pgaData?.lastSuccessfulPGA ? DateTime.fromISO(pgaData?.lastSuccessfulPGA) : null;
      const live = tenant.config["accountStatus"] === "Live";
      const campaignAutomation = tenant.config["automation.enabled"] === true;
      const mpa = campaignAutomation && tenant.config["intakeMargin_enabled"] === true;
      const autopilot = campaignAutomation && !mpa && (tenant.config["autopilot_enabled"] ?? true);
      return {
        ...tenant,
        lastSuccessfulPGA: lastSuccessful,
        campaignType: campaignType,
        campaignAutomation,
        mpa,
        autopilot,
        live,
      };
    });
  }
  return [];
}

export async function getTenantWithConfig(id: string): Promise<OverviewDecoratedTenant> {
  const result = await http.get<any>(`${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/tenants/${id}`);
  const configResult = await http.get<any>(`${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/tenants/${id}/config`);
  if (result.status !== 200) {
    console.log("Failed to fetch Tenant Config");
  }
  return { ...result.data, config: configResult.data };
}

export interface Tenant {
  id: string;
  deleted: boolean;
  name: string;
  config: { [key: string]: any };
}

export interface OverviewDecoratedTenant extends Tenant {
  lastSuccessfulPGA: DateTime | null;
  campaignType: GoogleCampaignType | null;
  campaignAutomation: boolean;
  autopilot: boolean;
  mpa: boolean;
  live: boolean;
}

export type ScalarConfigValue = string | number | boolean;
export type TenantConfig = {
  [key: string]: ScalarConfigValue;
};

export async function getTenantConfig(tenantId: string): Promise<TenantConfig> {
  const result = await http.get<any>(`${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/tenants/${tenantId}/config/`);

  if (result.status !== 200) {
    console.log("Failed to fetch Tenant Config");
  }

  return result.data;
}

export async function updateTenantConfig(tenantId: string, params: Array<TenantConfig>) {
  const result = await http.patch<any>(
    `${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/tenants/${tenantId}/config/`,
    params
  );

  if (result.status !== 200) {
    console.log("Failed to update config");
    return false;
  }

  return true;
}

export async function deleteTenant(tenantId: string) {
  const result = await http.delete<any>(`${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/tenants/${tenantId}`);

  if (result.status !== 200) {
    console.log("Failed to delete data source");
    return false;
  }

  return true;
}
