import getSymbolFromCurrency from "currency-symbol-map";
//@ts-ignore
import currencyToSymbolMap from "currency-symbol-map/map";

enum ConversionLagBucket {
  // Not specified.
  UNSPECIFIED = 0,

  // Used for return value only. Represents value unknown in this version.
  UNKNOWN = 1,

  // Conversion lag bucket from 0 to 1 day. 0 day is included, 1 day is not.
  LESS_THAN_ONE_DAY = 2,

  // Conversion lag bucket from 1 to 2 days. 1 day is included, 2 days is not.
  ONE_TO_TWO_DAYS = 3,

  // Conversion lag bucket from 2 to 3 days. 2 days is included,
  // 3 days is not.
  TWO_TO_THREE_DAYS = 4,

  // Conversion lag bucket from 3 to 4 days. 3 days is included,
  // 4 days is not.
  THREE_TO_FOUR_DAYS = 5,

  // Conversion lag bucket from 4 to 5 days. 4 days is included,
  // 5 days is not.
  FOUR_TO_FIVE_DAYS = 6,

  // Conversion lag bucket from 5 to 6 days. 5 days is included,
  // 6 days is not.
  FIVE_TO_SIX_DAYS = 7,

  // Conversion lag bucket from 6 to 7 days. 6 days is included,
  // 7 days is not.
  SIX_TO_SEVEN_DAYS = 8,

  // Conversion lag bucket from 7 to 8 days. 7 days is included,
  // 8 days is not.
  SEVEN_TO_EIGHT_DAYS = 9,

  // Conversion lag bucket from 8 to 9 days. 8 days is included,
  // 9 days is not.
  EIGHT_TO_NINE_DAYS = 10,

  // Conversion lag bucket from 9 to 10 days. 9 days is included,
  // 10 days is not.
  NINE_TO_TEN_DAYS = 11,

  // Conversion lag bucket from 10 to 11 days. 10 days is included,
  // 11 days is not.
  TEN_TO_ELEVEN_DAYS = 12,

  // Conversion lag bucket from 11 to 12 days. 11 days is included,
  // 12 days is not.
  ELEVEN_TO_TWELVE_DAYS = 13,

  // Conversion lag bucket from 12 to 13 days. 12 days is included,
  // 13 days is not.
  TWELVE_TO_THIRTEEN_DAYS = 14,

  // Conversion lag bucket from 13 to 14 days. 13 days is included,
  // 14 days is not.
  THIRTEEN_TO_FOURTEEN_DAYS = 15,

  // Conversion lag bucket from 14 to 21 days. 14 days is included,
  // 21 days is not.
  FOURTEEN_TO_TWENTY_ONE_DAYS = 16,

  // Conversion lag bucket from 21 to 30 days. 21 days is included,
  // 30 days is not.
  TWENTY_ONE_TO_THIRTY_DAYS = 17,

  // Conversion lag bucket from 30 to 45 days. 30 days is included,
  // 45 days is not.
  THIRTY_TO_FORTY_FIVE_DAYS = 18,

  // Conversion lag bucket from 45 to 60 days. 45 days is included,
  // 60 days is not.
  FORTY_FIVE_TO_SIXTY_DAYS = 19,

  // Conversion lag bucket from 60 to 90 days. 60 days is included,
  // 90 days is not.
  SIXTY_TO_NINETY_DAYS = 20,
}

export function getAllCurrencyCodes(): string[] {
  return Object.keys(currencyToSymbolMap);
}

export function getCurrencySymbol(code: any): any | undefined {
  const symbol = getSymbolFromCurrency(code);

  return symbol;
}

export function conversionLagBucketToText(conversionLagBucket: number): string {
  return ConversionLagBucket[conversionLagBucket];
}
