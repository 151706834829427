import { Grid } from "@material-ui/core";
import { CampaignMonitoring } from "../../api/campaignMonitoring";
import { CampaignMonitoringCard } from "./CampaignMonitoringCard";

interface CampaignMonitoringCardsProps {
  campaignMonitoring: CampaignMonitoring[];
}

export const CampaignMonitoringCards = ({ campaignMonitoring }: CampaignMonitoringCardsProps) => {
  return (
    <Grid style={{ marginLeft: 5 }} container spacing={1}>
      {campaignMonitoring.map(
        ({
          campaignId,
          campaignName,
          date,
          averageRoas,
          targetRoas,
          productGroups,
          productGroupsAboveAverageRoas,
          productGroupsBelowAverageRoas,
          productGroupsAboveTargetRoas,
          productGroupsBelowTargetRoas,
          minimumNumberOfImpressionsPerConversion,
          maximumNumberOfImpressionsPerConversion,
          averageImpressionsPerConversion,
        }) => {
          return (
            <CampaignMonitoringCard
              campaignId={campaignId}
              campaignName={campaignName}
              date={date}
              averageRoas={averageRoas}
              targetRoas={targetRoas}
              productGroups={productGroups}
              productGroupsAboveAverageRoas={productGroupsAboveAverageRoas}
              productGroupsBelowAverageRoas={productGroupsBelowAverageRoas}
              productGroupsAboveTargetRoas={productGroupsAboveTargetRoas}
              productGroupsBelowTargetRoas={productGroupsBelowTargetRoas}
              minimumNumberOfImpressionsPerConversion={minimumNumberOfImpressionsPerConversion}
              maximumNumberOfImpressionsPerConversion={maximumNumberOfImpressionsPerConversion}
              averageImpressionsPerConversion={averageImpressionsPerConversion}
            />
          );
        }
      )}
    </Grid>
  );
};
