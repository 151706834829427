import styled from "@emotion/styled";

interface TitleProps {
  size: string;
  children: any;
}

const StyledTitle = styled.div<TitleProps>`
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: ${({ size }) => size};
  display: flex;
  align-items: center;
  gap: 8px;
`;

const SectionTitle = ({ size, children }: TitleProps) => {
  return <StyledTitle size={size}>{children}</StyledTitle>;
};

export default SectionTitle;
