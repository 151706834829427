import styled from "styled-components";
import { uppColourPalette } from "../util/colours";

interface ButtonProps {
  active?: boolean;
  onClick?: () => void;
  disabled?: boolean;
  children: any;
  width?: number;
  className?: string;
}

const StyledButton = styled.button<ButtonProps>`
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: ${uppColourPalette.navy[800]};
  color: ${uppColourPalette.navy[800]};
  background: none;
  text-align: center;
  border-radius: 4px;
  border: 1px solid ${uppColourPalette.navy[800]};
  transition: all ease 0.5s;
  padding: 8px 16px;
  width: ${({ width }) => `${width}%`};
  cursor: pointer;
  ${({ active }) =>
    active &&
    `
background: #DBDBEE;
border: none;
`}
  &:hover {
    background: ${uppColourPalette.navy[500]};
  }
`;

const InternalButton = ({ className, active, onClick, disabled, children, width }: ButtonProps) => {
  return (
    <StyledButton className={className} active={active} onClick={onClick} disabled={disabled} width={width}>
      {children}
    </StyledButton>
  );
};

export default InternalButton;
