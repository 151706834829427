import { Dialog, DialogContent, DialogTitle, Divider, Grid, TextField } from "@material-ui/core";
import { CloseRounded } from "@material-ui/icons";
import { ChangeEvent, Dispatch, SetStateAction } from "react";
import { StyledButtonFilled } from "../../datasources/Datasources";
import { StyledCloseButton } from "../../datasources/SetupConnection";
import SwitchConfig from "../../tenantConfig/SwitchConfig";
import { StyledRow } from "../../tenantConfig/TenantConfig";
import { StyledInputLabel } from "../AddJobDialog";
import { CommonPayload, TradePackConfiguration } from "@volo-ai/upp-temporal-utils";

interface TradePackProps {
  handleToggle: (prop: any) => (e: ChangeEvent<HTMLInputElement>) => void;
  jobPayload: CommonPayload;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  handleSubmit?: React.MouseEventHandler<HTMLButtonElement>;
  handleDataChange: (prop: any) => (e: ChangeEvent<HTMLInputElement>) => void;
}

export const TradePackWorkflow = ({
  handleToggle,
  jobPayload,
  open,
  setOpen,
  handleSubmit,
  handleDataChange,
}: TradePackProps) => {
  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>
        Trade Pack Workflow
        <StyledCloseButton onClick={() => setOpen(false)}>
          <CloseRounded />
        </StyledCloseButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container>
          <Grid item>
            <StyledInputLabel>Trending Products</StyledInputLabel>
            <SwitchConfig
              onChange={handleToggle("configuration.productTrending")}
              checked={(jobPayload.configuration as TradePackConfiguration).productTrending}
            ></SwitchConfig>
            <StyledInputLabel>Products On Promotion</StyledInputLabel>
            <SwitchConfig
              onChange={handleToggle("configuration.productsOnPromotion")}
              checked={(jobPayload.configuration as TradePackConfiguration).productsOnPromotion}
            ></SwitchConfig>
          </Grid>
          <Grid container>
            <Grid item>
              <StyledInputLabel>Channel</StyledInputLabel>
              <TextField type="string" variant="outlined" onChange={handleDataChange("channel")} />
            </Grid>
          </Grid>
        </Grid>
        <StyledRow style={{ marginTop: "20px" }}>
          <StyledButtonFilled onClick={handleSubmit}>Create</StyledButtonFilled>
        </StyledRow>
      </DialogContent>
    </Dialog>
  );
};
