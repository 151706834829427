import Icon from "@material-ui/core/Icon";
import { useEffect, useState } from "react";
import styled from "styled-components";
import Card from "../../components/Card";
import FooterButton from "../../components/FooterButton";
import InternalButton from "../../components/InternalButton";
import InfluentialProductsTable from "./InfluentialProductsTable";
import {
  getInfluentialProducts,
  getInfluentialProductsCount,
  InfluentialProduct,
  InfluentialProductCounts,
} from "../../api/performance";
const Column = styled.div`
  display: grid;
  grid-auto-columns: max-content;
`;

const Content = styled.div`
  margin-top: 5em;
`;

const ChangelogHeader = styled.div`
  height: 33px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 1em;
`;

export enum InfluentialProductsType {
  COST = "cost",
  CONVERSIONS = "conversions",
  IMPRESSIONS = "impressions",
}

export enum FilterType {
  NONE = "none",
  DELETED = "deleted",
  BLOCKED = "blocked",
  UNLISTED = "unlisted",
  NOSTOCK = "nostock",
}

const InfluentialProducts = () => {
  const [page, setPage] = useState<number>(0);
  const [influentialProductsType, setInfluentialProductsType] = useState<InfluentialProductsType>(
    InfluentialProductsType.COST
  );
  const [filterType, setFilterType] = useState<FilterType>(FilterType.NONE);
  const [influentialProducts, setInfluentialProducts] = useState<InfluentialProduct[]>([]);
  const [isLastPage, setIsLastPage] = useState<boolean>(true);
  const [isCopied, setIsCopied] = useState<boolean>(false);

  const [counts, setCounts] = useState<InfluentialProductCounts>({
    cost: 0,
    conversions: 0,
    impressions: 0,
  });

  const changeFilter = (influentialProductType: InfluentialProductsType): void => {
    setPage(0);
    setInfluentialProductsType(influentialProductType);
  };

  const changeFilterType = (filterType: FilterType): void => {
    setPage(0);
    setFilterType(filterType);
  };

  useEffect(() => {
    let isMounted = true;

    async function getCounts() {
      const counts = await getInfluentialProductsCount();
      if (isMounted) setCounts(counts);
    }
    getCounts();
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let isMounted = true;

    async function getChangelogs() {
      let items = await getInfluentialProducts(20, page * 20, influentialProductsType);
      if (isMounted) {
        switch (filterType) {
          case FilterType.DELETED:
            items = items.filter((item) => item.isDeleted);
            break;
          case FilterType.BLOCKED:
            items = items.filter((item) => item.isBlocked);
            break;
          case FilterType.UNLISTED:
            items = items.filter((item) => item.unlisted);
            break;
          case FilterType.NOSTOCK:
            items = items.filter((item) => item.stock === 0);
            break;
        }
        setInfluentialProducts(items);
      }
    }
    getChangelogs();
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, [page, influentialProductsType, filterType]);

  useEffect(() => {
    let isMounted = true;
    async function getIsLastPage() {
      if (isMounted) {
        const itemCount = page * 20 + influentialProducts.length;
        setIsLastPage(itemCount >= counts[influentialProductsType]);
      }
    }
    getIsLastPage();
    return () => {
      isMounted = false;
    };
  }, [page, counts, influentialProducts, influentialProductsType]);

  return (
    <Content>
      <Column>
        <ChangelogHeader>
          <ButtonWrapper>
            <InternalButton
              active={influentialProductsType === InfluentialProductsType.COST}
              onClick={() => changeFilter(InfluentialProductsType.COST)}
            >
              {`Cost (${counts.cost})`}
            </InternalButton>
            <InternalButton
              active={influentialProductsType === InfluentialProductsType.CONVERSIONS}
              onClick={() => changeFilter(InfluentialProductsType.CONVERSIONS)}
            >
              {`Conversions (${counts.conversions})`}
            </InternalButton>
            <InternalButton
              active={influentialProductsType === InfluentialProductsType.IMPRESSIONS}
              onClick={() => changeFilter(InfluentialProductsType.IMPRESSIONS)}
            >
              {`Impressions (${counts.impressions})`}
            </InternalButton>
            <InternalButton active={filterType === FilterType.NONE} onClick={() => changeFilterType(FilterType.NONE)}>
              {`All Records`}
            </InternalButton>
            <InternalButton
              active={filterType === FilterType.DELETED}
              onClick={() => changeFilterType(FilterType.DELETED)}
            >
              {`Deleted Only`}
            </InternalButton>
            <InternalButton
              active={filterType === FilterType.BLOCKED}
              onClick={() => changeFilterType(FilterType.BLOCKED)}
            >
              {`Blocked Only`}
            </InternalButton>
            <InternalButton
              active={filterType === FilterType.UNLISTED}
              onClick={() => changeFilterType(FilterType.UNLISTED)}
            >
              {`Unlisted Only`}
            </InternalButton>
            <InternalButton
              active={filterType === FilterType.NOSTOCK}
              onClick={() => changeFilterType(FilterType.NOSTOCK)}
            >
              {`No Stock`}
            </InternalButton>
          </ButtonWrapper>
        </ChangelogHeader>
        <Card>
          <InfluentialProductsTable
            page={page}
            influentialProductType={influentialProductsType}
            influentialProducts={influentialProducts}
          >
            <FooterButton
              disabled={page < 1}
              onClick={() => {
                page > 0 && setPage(page - 1);
              }}
            >
              <Icon className="material-icons-outline">{"<<"}</Icon>
            </FooterButton>
            <>
              Current Page: {page + 1}/({Math.trunc(counts[influentialProductsType] / 20)})
            </>
            <FooterButton
              disabled={isLastPage}
              onClick={() => {
                !isLastPage && setPage(page + 1);
              }}
            >
              <Icon className="material-icons-outlined">{">>"}</Icon>
            </FooterButton>
          </InfluentialProductsTable>
        </Card>
      </Column>
    </Content>
  );
};

export default InfluentialProducts;
