import { Box, Divider, Switch } from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import styled from "styled-components";
import { AuditAccountPerformance, getAllAudits, updateAuditVisibility } from "../../api/audit";
import { Heading } from "../../components/Heading";

const GridWrapper = styled.div`
  height: 325px;
`;

function AuditTable(): JSX.Element {
  const auditQuery = useQuery(["audits"], () => getAllAudits(), { enabled: true });

  const queryClient = useQueryClient();

  const changeVisibility = useMutation(
    ({ id, visibility }: { id: string; visibility: boolean }) => {
      return updateAuditVisibility(id, visibility);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["audits"]);
      },
    }
  );

  const visibilityRenderer = (params: GridRenderCellParams) => (
    <Switch
      color="primary"
      checked={params.value}
      onChange={(e: any) => {
        changeVisibility.mutate({ id: String(params.id), visibility: e.target.checked });
      }}
    />
  );

  const dateRenderer = (params: GridRenderCellParams) => {
    const value = new Date(params.value);
    return <div>{value.toLocaleString()}</div>;
  };

  const columns: GridColDef[] = [
    { headerName: "Id", field: "_id", flex: 1 },
    { headerName: "Created On", field: "createdOn", flex: 1, renderCell: dateRenderer },
    { headerName: "Visible", field: "visible", flex: 1, renderCell: visibilityRenderer },
  ];

  return (
    <>
      <Box
        sx={{
          bgcolor: "white",
          padding: "2em",
          margin: "2em",
          borderRadius: "0.5%",
          border: "1px solid",
          borderColor: "#dbdbdb",
        }}
      >
        <Heading>Account Audits</Heading>
        <Divider />
        {!auditQuery.data || auditQuery.data.length === 0 ? (
          <div> No Data</div>
        ) : (
          <GridWrapper>
            <DataGrid
              rows={auditQuery.data as AuditAccountPerformance[]}
              columns={columns}
              getRowId={(row) => row._id}
            />
          </GridWrapper>
        )}
      </Box>
    </>
  );
}

export default AuditTable;
