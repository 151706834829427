export interface BackOfficeError {
  errorCode: string;
  message: string;
}

export enum BackOfficeErrorCode {
  NoResult = "NoResult",
  Unexpected = "Unexpected",
}

export function isBackOfficeError(result: BackOfficeError | any): result is BackOfficeError {
  return (result as BackOfficeError)?.errorCode !== undefined;
}
