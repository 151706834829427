import { Box } from "@material-ui/core";
import Chart from "react-google-charts";
import styled from "styled-components";
import { ProductMoves } from "../../api/campaignAutomation";
import { Heading } from "../datasources/Datasources";

interface ProductsMovesInterface {
  productMoves: ProductMoves;
}

export const StyledChart = styled(Chart)`
  margin: 1em;
  margin-bottom: 5em;
`;

export const ProductMovesGraph = ({ productMoves }: ProductsMovesInterface) => {
  if (productMoves.message) {
    return (
      <>
        <Box
          sx={{
            bgcolor: "white",
            padding: "2em",
            margin: "2em",
            borderRadius: "0.5%",
            border: "1px solid",
            borderColor: "#dbdbdb",
            color: "red",
          }}
        >
          No product moves data in period
        </Box>
      </>
    );
  }
  const sourceTotals: { [key: string]: number } = {};

  productMoves?.source_labels?.forEach((label, index) => {
    if (!sourceTotals[label]) {
      return (sourceTotals[label] = Number(productMoves.value[index]));
    }
    sourceTotals[label] += Number(productMoves.value[index]);
  });

  const targetTotals: { [key: string]: number } = {};

  productMoves?.target_labels?.forEach((label, index) => {
    if (!targetTotals[label]) {
      return (targetTotals[label] = Number(productMoves.value[index]));
    }
    return (targetTotals[label] += Number(productMoves.value[index]));
  });

  const sourceLabelRange = productMoves.label.length > 0 ? productMoves.label.length / 2 : 0;
  const targetLabelRange = productMoves.label.length;

  const sourceLabels = productMoves.label.slice(0, sourceLabelRange);
  const targetLabels = productMoves.label.slice(sourceLabelRange, targetLabelRange);

  const sankeyData = [
    ["From", "To", "Quantity"],
    ...productMoves?.source_labels.map((source, index) => [
      `${source} - ${sourceTotals[source]} \n products moved`,
      `${productMoves.target_labels[index]} - ${targetTotals[productMoves.target_labels[index]]} products received`,
      Number(productMoves.value[index]),
    ]),
  ];

  return (
    <>
      <StyledChart chartType="Sankey" data={sankeyData} width="80%" height="500px" />
      <Box
        sx={{
          bgcolor: "white",
          padding: "2em",
          margin: "2em",
          borderRadius: "0.5%",
          border: "1px solid",
          borderColor: "#dbdbdb",
        }}
      >
        <Heading>Total products in source clusters </Heading>
        {sourceLabels.map((label) => (
          <p>{label}</p>
        ))}
      </Box>
      <Box
        sx={{
          bgcolor: "white",
          padding: "2em",
          margin: "2em",
          borderRadius: "0.5%",
          border: "1px solid",
          borderColor: "#dbdbdb",
        }}
      >
        <Heading>Total products in target clusters </Heading>
        {targetLabels.map((label) => (
          <p>{label}</p>
        ))}
      </Box>
    </>
  );
};
