import { Box, Divider } from "@material-ui/core";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { getBlockedProducts, Product } from "../../api/product";
import { StyledDashboardContainer } from "../dashboard/Dashboard";
import styled from "styled-components";
import { RightStyledButton } from "../dashboard/TenantTable";
import UploadListDialog from "./UploadListDialog";

export const GridWrapper = styled.div`
  height: 700px;
`;

const columnDefs: GridColDef[] = [
  { headerName: "SKU", field: "sku", width: 200 },
  { headerName: "Item Id", field: "itemId", width: 200 },
  { headerName: "Title", field: "title", width: 500 },
];

const Heading = styled.div`
  display: flex;
  align-items: center;
  margin: 0 -16px 10px -16px;
  font-size: 2em;
  padding: 0 10px 10px 10px;
  button {
    margin-left: auto;
  }
`;

export function BlockedProducts() {
  const [loading, setLoading] = useState<boolean>(true);
  const [products, setProducts] = useState<Product[]>([]);
  const [uploadDialog, setUploadDialog] = useState<boolean>(false);

  useEffect(() => {
    async function fetchBlockedProducts() {
      const data = await getBlockedProducts();
      setProducts(data);
      setLoading(false);
    }
    fetchBlockedProducts();
  }, [loading]);
  return (
    <StyledDashboardContainer>
      <Box
        sx={{
          bgcolor: "white",
          padding: "2em",
          margin: "2em",
          borderRadius: "0.5%",
          border: "1px solid",
          borderColor: "#dbdbdb",
        }}
      >
        <Heading>
          Blocked Products
          <RightStyledButton onClick={() => setUploadDialog(true)}>Upload Block List</RightStyledButton>
        </Heading>
        <Divider />
        <GridWrapper>
          <DataGrid rows={products} getRowId={(product) => product.sku} columns={columnDefs} loading={loading} />
        </GridWrapper>
      </Box>
      {uploadDialog ? (
        <UploadListDialog
          isOpen={true}
          onClose={() => {
            setUploadDialog(false);
            setLoading(true);
          }}
        />
      ) : (
        ""
      )}
    </StyledDashboardContainer>
  );
}

export default BlockedProducts;
