import styled from "styled-components";
import { StyledDashboardContainer } from "../dashboard/Dashboard";
import { CampaignItem } from "./CampaignItem";
import { PerformanceMaxSplit } from "./PerformanceMaxSplit";
import { VisibilityPerformanceReports } from "./VisibilityPerformanceReport";
import { useCampaigns } from "../../hooks/useCampaigns";

const InnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 104 px 40 px;
`;
export const Campaign = () => {
  //const [campaigns, setCampaigns] = useState<ManagedCampaign[]>([]);
  const campaignsQuery = useCampaigns();

  return (
    <>
      <StyledDashboardContainer>
        <PerformanceMaxSplit />
        <InnerContainer>
          {campaignsQuery.data?.map((c) => {
            return (
              <CampaignItem
                name={c.campaignName}
                value={{
                  skuCount: c.skuCount,
                  currency: c.currency,
                  budget: c.budget,
                  strategy: c.biddingStrategyType,
                  targetRoas: c.biddingStrategyTypeSchema.targetRoas,
                  campaignId: c.campaignId,
                  conversionLagBucket: c.conversionLagBucket ?? 0,
                  primaryStatus: c.primaryStatus ?? "",
                  primaryStatusReasons: c.primaryStatusReasons,
                }}
              />
            );
          })}
        </InnerContainer>
      </StyledDashboardContainer>
      <StyledDashboardContainer style={{ marginTop: "-100px" }}>
        <VisibilityPerformanceReports />
      </StyledDashboardContainer>
    </>
  );
};

export default Campaign;
