import { AuditAccountPerformance, AuditProductGroupPerformance, Totals } from "../../api/audit";

export const defaultAudit = {
  tenantId: "",
  createdOn: new Date().toString(),
  from: new Date().toString(),
  to: new Date().toString(),
  _id: "",
};

export const defaultTotals: Totals = {
  impressions: 0,
  clicks: 0,
  cost: 0,
  conversions: 0,
  conversionValue: 0,
  revenue: 0,
  unitsSold: 0,
};

export const defaultAccountAudit: AuditAccountPerformance = {
  ...defaultAudit,
  underSpend: {
    unitsSold: 0,
    unitsSoldShare: 0,
    revenue: 0,
    revenueShare: 0,
    cost: 0,
    costShare: 0,
    percentageOfTotalInventory: 0,
  },
  overSpend: {
    unitsSold: 0,
    unitsSoldShare: 0,
    revenue: 0,
    revenueShare: 0,
    cost: 0,
    costShare: 0,
    percentageOfTotalInventory: 0,
  },
  totals: defaultTotals,
  forecastUplift: 0,
  timeSeries: {
    revenue: [0],
    cost: [0],
  },
  avgImpPerClick: 0,
  productsAchievingAvgImp: {
    percentage: 0,
    count: 0,
  },
  avgConvPerClick: 0,
  productsAchievingAvgConv: {
    percentage: 0,
    count: 0,
  },
  _id: "",
};

export const defaultProductGroupPerformance: AuditProductGroupPerformance = {
  ...defaultAudit,
  productGroupPerformanceAuditItems: [],
};
