import { Box, CircularProgress, Divider } from "@material-ui/core";
import { StyledDashboardContainer } from "../dashboard/Dashboard";
import { Heading } from "../../components/Heading";
import { useEffect, useState } from "react";
import { getAccountsConversionDataSummary } from "../../api/conversionValueAudit";
import { Tenant, getAllLiveTenants } from "../../api/tenant";
import ComparisonChart from "./ComparisonChart";
import { DateTime } from "luxon";
import * as htmlToImage from "html-to-image";
import Button from "../../components/Wizards/Button";
import JsPDF from "jspdf";

const AccountsAudit = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [conversionData, setConversionData] = useState<any[]>([]);
  const [tenants, setTenants] = useState<Tenant[]>([]);

  useEffect(() => {
    async function fetchAllTenants() {
      setLoading(true);
      const allTenants = await getAllLiveTenants();
      setTenants(allTenants);
      setLoading(false);
    }
    fetchAllTenants();
  }, []);

  useEffect(() => {
    async function fetchAccountsConversionData() {
      const data = await getAccountsConversionDataSummary(tenants);
      setConversionData(data);
      setLoading(false);
    }
    fetchAccountsConversionData();
  }, [tenants]);

  const renderComparisonChart = () => {
    return conversionData.map((tenantData) => {
      if (tenantData.data.length > 0) {
        const xData = tenantData.data.map((data: any) => {
          return DateTime.fromISO(data.date).toISODate();
        });

        const yData = tenantData.data.map((data: any) => {
          return {
            accountMetrics: data.accountMetrics.conversionValue,
            hourlyMetrics: data.hourlyMetrics.conversionValueByConversionDate,
          };
        });
        return <ComparisonChart tenantId={tenantData.tenantId} xData={xData} yData={yData} />;
      }
      return null;
    });
  };

  const downloadAllCharts = async () => {
    const charts = Array.from(document.getElementsByClassName("highcharts-container"));

    const doc = new JsPDF({
      orientation: "landscape",
      unit: "px",
      format: [700, 300],
    });
    setLoading(true);
    for (const chart of charts as HTMLElement[]) {
      const canvas = await htmlToImage.toPng(chart);
      doc.addImage(canvas, "PNG", 10, 10, 600, 200);
      if (chart !== charts[charts.length - 1]) {
        doc.addPage();
      }
    }
    doc.save("all-charts.pdf");
    setLoading(false);
  };

  return (
    <>
      <StyledDashboardContainer>
        <Box
          sx={{
            bgcolor: "white",
            padding: "2em",
            margin: "2em",
            borderRadius: "0.5%",
            border: "1px solid",
            borderColor: "#dbdbdb",
          }}
        >
          <div style={{ float: "right" }}>
            {loading && <div>Loading...</div>}
            <Button onClick={downloadAllCharts}>Download all</Button>
          </div>
          <Heading>Accounts Audit</Heading>
          <p style={{ textAlign: "left" }}>Audit conversion value over time</p>
          <Divider />
          {conversionData.length === 0 ? <LoadingSpinner /> : renderComparisonChart()}
        </Box>
      </StyledDashboardContainer>
    </>
  );
};

const LoadingSpinner = () => (
  <>
    <CircularProgress color="primary" style={{ margin: "2rem 0rem" }} size={"7rem"} />
    <p>Fetching account and performance data...</p>
  </>
);

export default AccountsAudit;
