import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import { AccountStateChange } from "../../api/accountState";
import { AccountState } from "../../api/autopilotHelper";

const columns: GridColDef[] = [
  {
    field: "tenantId",
    headerName: "Tenant ID",
    width: 200,
  },
  // { field: "previousState", headerName: "Previous State", width: 200 },
  // {
  //   field: "accountStateChange",
  //   headerName: "Change",
  //   width: 200,
  //   renderCell: (params) => {
  //     const stateChange = params.value;
  //     if (stateChange === AccountStateChange.INCREASE) {
  //       return <ArrowUpward style={{ color: "green" }} />;
  //     }
  //     if (stateChange === AccountStateChange.DECREASE) {
  //       return <ArrowDownward style={{ color: "red" }} />;
  //     }
  //     return <HorizontalRule />;
  //   },
  // },
];

interface AccountsStateInterface {
  tenants: {
    tenantId: string;
    currentState?: AccountState;
  }[];
  currentState: AccountState;
}

export const AccountsStateTable = ({ tenants, currentState }: AccountsStateInterface) => {
  return (
    <DataGridPro
      rows={(tenants || []).map((row) => {
        return {
          ...row,
          id: row.tenantId,
          accountStateChange: AccountStateChange.SAME,
        };
      })}
      columns={columns}
      pagination
      autoHeight
      hideFooter
    />
  );
};
