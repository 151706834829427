// αlpha;

import { Box, Dialog, DialogContent, DialogTitle, Divider, InputLabel, TextField, Typography } from "@material-ui/core";
import { green, red } from "@material-ui/core/colors";
import { CloseRounded } from "@material-ui/icons";
import { saveAs } from "file-saver";
import { useCallback, useState } from "react";
import { Check, X, Info } from "react-feather";
import { ClipLoader } from "react-spinners";
import styled from "styled-components";
import { checkOrderHealth, getMarginAndCostReport } from "../../api/orders";
import { Heading } from "../../components/Heading";
import { StyledDashboardContainer } from "../dashboard/Dashboard";
import { StyledButtonFilled } from "../datasources/Datasources";
import { StyledCloseButton } from "../datasources/SetupConnection";
import { StyledContainer, StyledRow } from "../tenantConfig/TenantConfig";
import AuditHealthReport from "./AuditHealthReport";
import AuditStats from "./AuditStats";
import AuditTable from "./AuditTable";
import AuditCampaigns from "./AuditCampaigns";
import AuditChangelog from "./AuditChangelog";

const ClickableInfo = styled(Info)`
  cursor: pointer;
`;
export const Audit = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [expandChecker, setExpandChecker] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState<number>(30);
  const [dataHealthy, setDataHealthy] = useState<{
    ads: { healthy: boolean; missingDates: string[] | Date[] };
    orders: { healthy: boolean; missingDates: string[] | Date[] };
  }>();
  const [groupBy, setGroupBy] = useState<string>("");
  const [missingDatesPopup, setMissingDatesPopup] = useState<boolean>(false);
  const [whichPopup, setWhichPopup] = useState<string>("");

  const handleClose = () => {
    setMissingDatesPopup(false);
  };

  const triggerOrderChecker = useCallback(async () => {
    setLoading(true);
    setExpandChecker(true);
    const result = await checkOrderHealth(window.USER_INFO.tenantId, dateRange || 30);
    setDataHealthy(result.data);
    setLoading(false);
  }, [dateRange]);

  const onDownload = () => {
    let filename;
    getMarginAndCostReport(dateRange, groupBy).then((response) => {
      filename = response.headers["content-disposition"].split("filename=")[1];

      let blob = new Blob([response.data]);
      saveAs(blob, filename.replace(/"/g, ""));
    });
  };

  return (
    <StyledDashboardContainer>
      <AuditStats />
      <AuditTable />
      {missingDatesPopup && (
        <Dialog open={missingDatesPopup} maxWidth="sm" fullWidth onClose={handleClose}>
          <DialogTitle>
            Missing Dates ({whichPopup})
            <StyledCloseButton onClick={handleClose}>
              <CloseRounded />
            </StyledCloseButton>
          </DialogTitle>
          <Divider />
          {whichPopup === "Orders" ? (
            <DialogContent>
              {dataHealthy!.orders.missingDates.length > 0 ? (
                dataHealthy!.orders.missingDates!.map((date) => <ul>{new Date(date).toLocaleDateString("en-UK")}</ul>)
              ) : (
                <Typography>No Order Data</Typography>
              )}
            </DialogContent>
          ) : (
            <DialogContent>
              {dataHealthy!.ads.missingDates.length > 0 ? (
                dataHealthy!.ads.missingDates!.map((date) => <ul>{new Date(date).toLocaleDateString("en-UK")}</ul>)
              ) : (
                <Typography>No Ads Data</Typography>
              )}
            </DialogContent>
          )}
        </Dialog>
      )}

      <AuditHealthReport />
      <Box
        sx={{
          bgcolor: "white",
          padding: "2em",
          margin: "2em",
          borderRadius: "0.5%",
          border: "1px solid",
          borderColor: "#dbdbdb",
        }}
      >
        <Heading>Audit Date Range Checker</Heading>
        <Divider />

        <StyledContainer>
          <br></br>
          <StyledRow style={{ margin: "auto", width: "60%", marginBottom: "30px", marginRight: "20px" }}>
            <InputLabel>Date Range:</InputLabel>
            <TextField
              placeholder={"Days"}
              type="number"
              value={dateRange}
              variant="outlined"
              onChange={(e: any) => setDateRange(parseInt(e.target.value))}
            />
            <InputLabel style={{ marginLeft: "50px" }}>Group By:</InputLabel>
            <TextField value={groupBy} variant="outlined" onChange={(e: any) => setGroupBy(e.target.value)} />
          </StyledRow>
          <br></br>
          <StyledButtonFilled onClick={() => triggerOrderChecker()}>Check</StyledButtonFilled>
        </StyledContainer>

        <br />
        {expandChecker && (
          <Box
            sx={{
              bgcolor: "white",
              padding: "2em",
              margin: "2em",
              borderRadius: "0.5%",
              border: "1px solid",
              borderColor: "#dbdbdb",
            }}
          >
            <Typography>
              Checking Order Date Range {loading && <ClipLoader size={10} />}{" "}
              {!loading && dataHealthy!.orders.healthy ? (
                <Check size={20} color={green[600]} />
              ) : (
                !loading && (
                  <>
                    <X size={20} color={red[600]} />
                    <ClickableInfo
                      size={18}
                      onClick={() => {
                        setWhichPopup("Orders");
                        setMissingDatesPopup(true);
                      }}
                    />
                  </>
                )
              )}
            </Typography>
            <Typography>
              Checking Ads Date Range {loading && <ClipLoader size={10} />}{" "}
              {!loading && dataHealthy!.ads.healthy ? (
                <Check size={20} color={green[600]} />
              ) : (
                !loading && (
                  <>
                    <X size={20} color={red[600]} />
                    <ClickableInfo
                      size={18}
                      onClick={() => {
                        setWhichPopup("Ads");
                        setMissingDatesPopup(true);
                      }}
                    />
                  </>
                )
              )}
            </Typography>
            <br></br>
            <StyledButtonFilled disabled={loading && dataHealthy && !dataHealthy.orders.healthy} onClick={onDownload}>
              Download
            </StyledButtonFilled>
          </Box>
        )}
      </Box>
      <AuditCampaigns />
      <AuditChangelog />
    </StyledDashboardContainer>
  );
};

export default Audit;
