import { http } from "../UppAuth";

export interface ManagedCampaign {
  tenantId: string;
  campaignId: string;
  campaignName: string;
  biddingStrategyType: string;
  biddingStrategyTypeSchema: any;
  budget: number;
  currency: string;
  isDeleted: boolean;
  isExclusion: boolean;
  isManaged: boolean;
  skuCount: number;
  source: any;
  skus: Array<string>;
  conversionLagBucket: number;
  primaryStatus: string;
  primaryStatusReasons: string[];
}

export enum GoogleCampaignType {
  Unknown = "Unknown",
  PerformanceMax = "PerformanceMax",
  SmartShoppingCampaign = "SmartShoppingCampaign",
}

export async function getUppManagedCampaigns(): Promise<Array<ManagedCampaign>> {
  const result = await http.get<any>(
    `${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/campaigns?isManaged=true&isDeleted=false`
  );

  if (result.status !== 200) {
    console.log("Failed to fetch Campaigns");
  }

  return result.data;
}
