import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { AuditAccountPerformance, ChartRender, getAccountAudit, Totals } from "../../api/audit";
import AuditCard from "./AuditCard";
import { formatValue } from "./AuditProductTable";
import { defaultTotals, defaultAccountAudit } from "./AuditDefaults";
import SectionTitle from "../../components/SectionTitle";
import { formatDate } from "../../util/date";
import { uppColourPalette } from "../../util/colours";
import { Heading } from "../../components/Heading";
import { Box, Divider } from "@mui/material";
import ProductGroupTable from "./ProductGroupTable";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import AuditChart from "./AuditChart";
import IntButton from "../../components/InternalButton";

const Grid = styled.div`
  position: relative;
  min-height: 100vh;
`;

const BodyGrid = styled.div`
  display: grid;
  grid-template-columns: 80px 1fr 80px;
  gap: 24px;
  padding-bottom: 24px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 2;
  gap: 24px;
`;

interface RowProps {
  columns: string;
}

const Row = styled.div<RowProps>`
  display: grid;
  grid-template-columns: ${({ columns }) => columns};
  gap: 24px;
`;

const SectionInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

interface ShortCardProps {
  border?: string;
  borderColor?: string;
}

const ShortCard = styled(AuditCard)<ShortCardProps>`
  gap: 8px;
  display: flex;
  flex-direction: column;
  height: 240px;
  padding: 0 16px;
  align-items: center;
  justify-content: center;
  border: black;
  border-color: black;
`;

const CardRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid;
  padding: 16px 24px;
  &(:last-child) {
    border-bottom: none;
  }
`;

const CardTitleRow = styled.div`
  display: flex;
  align-items: center;
  background: ${uppColourPalette.lightGreen[500]}
  padding: 16px 24px;
  color: white;
  font-family: Roboto, Helvetica, Arial, sans-serif;
`;

interface TitleProps {
  color?: string;
}

const CardTitle = styled.span<TitleProps>`
  font-size: regular;
  font-family: Roboto, Helvetica, Arial, sans-serif;
`;

interface DataProps {
  size?: string;
}

const CardData = styled.span<DataProps>`
  font-size: regular;
  font-family: Roboto, Helvetica, Arial, sans-serif;
`;

const AuditStats = () => {
  const [accountAudit, setAccountAudit] = useState<AuditAccountPerformance>(defaultAccountAudit);
  const [totals, setTotals] = useState<Totals>(defaultTotals);
  const [openTable, setOpenTable] = useState(false);
  const [openChart, setOpenChart] = useState(false);
  const [chartRender, setChartRender] = useState<ChartRender>(ChartRender.REVENUE);

  const handleTable = () => {
    setOpenTable(!openTable);
  };

  const handleChart = () => {
    setOpenChart(!openChart);
  };

  useEffect(() => {
    async function getAudit() {
      const audit = await getAccountAudit();
      if (audit) {
        setAccountAudit(audit);
      }
    }
    getAudit();
  }, []);

  useEffect(() => {
    setTotals(accountAudit.totals);
  }, [accountAudit]);

  return (
    <>
      <Box
        sx={{
          bgcolor: "white",
          padding: "2em",
          margin: "2em",
          borderRadius: "0.5%",
          border: "1px solid",
          borderColor: "#dbdbdb",
        }}
      >
        <Heading>Latest Audit</Heading>
        <Divider />
        <Grid>
          <div>
            Date: {formatDate(accountAudit.from)} - {formatDate(accountAudit.to)}
          </div>
          <BodyGrid>
            <Content>
              <Row columns="1fr">
                <SectionTitle size="1.5em">Overview</SectionTitle>
              </Row>
              <Row columns="1fr 1fr">
                <AuditCard border="none">
                  <CardRow>
                    <CardTitle>Impressions</CardTitle>
                    <CardData>{formatValue("impressions", totals.impressions)}</CardData>
                  </CardRow>
                  <CardRow>
                    <CardTitle>Clicks</CardTitle>
                    <CardData>{formatValue("clicks", totals.clicks)}</CardData>
                  </CardRow>
                  <CardRow>
                    <CardTitle>Spend</CardTitle>
                    <CardData>{formatValue("cost", totals.cost)}</CardData>
                  </CardRow>
                </AuditCard>
                <AuditCard border="none">
                  <CardRow>
                    <CardTitle>Conversions</CardTitle>
                    <CardData>{formatValue("conversions", totals.conversions)}</CardData>
                  </CardRow>
                  <CardRow>
                    <CardTitle>Conversion Value</CardTitle>
                    <CardData>{formatValue("conversionValue", totals.conversionValue)}</CardData>
                  </CardRow>
                  <CardRow>
                    <CardTitle>Units Sold</CardTitle>
                    <CardData>{formatValue("unitsSold", totals.unitsSold)}</CardData>
                  </CardRow>
                </AuditCard>
              </Row>
              <Row columns="repeat(3, 1fr)">
                <ShortCard>
                  <CardTitle>Total Revenue</CardTitle>
                  <CardData size="1.5em">{formatValue("revenue", totals.revenue)}</CardData>
                </ShortCard>
                <ShortCard>
                  <CardTitle>Total Spend</CardTitle>
                  <CardData size="1.5em">{formatValue("cost", totals.cost)}</CardData>
                </ShortCard>
                <ShortCard borderColor="#EF7F7B">
                  <CardTitle color="#EF7F7B">Total Overspend</CardTitle>
                  <CardData size="1.5em">{formatValue("monetary", accountAudit.overSpend.cost)}</CardData>
                </ShortCard>
              </Row>
              <SectionInfo>
                <Row columns="1fr">
                  <SectionTitle size="1.5em">Visibility</SectionTitle>
                </Row>
              </SectionInfo>
              <Row columns="1fr 1fr">
                <AuditCard border="none">
                  <CardRow>
                    <CardTitle>Average Impressions Per Click</CardTitle>
                    <CardData>{formatValue("avgImpPerClick", accountAudit.avgImpPerClick)}</CardData>
                  </CardRow>
                  <CardRow>
                    <CardTitle>Products Achieving Average Impressions Per Click</CardTitle>
                    <CardData>
                      {formatValue("productsAchievingAvgImp", accountAudit.productsAchievingAvgImp.count)}
                    </CardData>
                  </CardRow>
                  <CardRow>
                    <CardTitle>% Products Achieving Average Impressions Per Click</CardTitle>
                    <CardData>
                      {formatValue("percentProductsAchievingAvgImp", accountAudit.productsAchievingAvgImp.percentage)}
                    </CardData>
                  </CardRow>
                </AuditCard>
                <AuditCard border="none">
                  <CardRow>
                    <CardTitle>Average Clicks Per Conversion</CardTitle>
                    <CardData>{formatValue("avgConvPerClick", accountAudit.avgConvPerClick)}</CardData>
                  </CardRow>
                  <CardRow>
                    <CardTitle>Products Achieving Average Clicks Per Conversion</CardTitle>
                    <CardData>
                      {formatValue("productsAchievingAvgConv", accountAudit.productsAchievingAvgConv.count)}
                    </CardData>
                  </CardRow>
                  <CardRow>
                    <CardTitle>% Products Achieving Average Clicks Per Conversion</CardTitle>
                    <CardData>
                      {formatValue("percentProductsAchievingAvgConv", accountAudit.productsAchievingAvgConv.percentage)}
                    </CardData>
                  </CardRow>
                </AuditCard>
              </Row>
              <SectionInfo>
                <Row columns="1fr">
                  <SectionTitle size="1.5em">Inventory</SectionTitle>
                </Row>
              </SectionInfo>
              <Row columns="1fr 1fr">
                <AuditCard>
                  <CardTitleRow>Overspend</CardTitleRow>
                  <CardRow>
                    <CardTitle>Total Overspend</CardTitle>
                    <CardData>{formatValue("monetary", accountAudit.overSpend.cost)}</CardData>
                  </CardRow>
                  <CardRow>
                    <CardTitle>% of Products</CardTitle>
                    <CardData>
                      {formatValue("percentage", accountAudit.overSpend.percentageOfTotalInventory / 100)}
                    </CardData>
                  </CardRow>
                  <CardRow>
                    <CardTitle>Revenue Generated</CardTitle>
                    <CardData>{formatValue("monetary", accountAudit.overSpend.revenue)}</CardData>
                  </CardRow>
                  <CardRow>
                    <CardTitle>% of Revenue</CardTitle>
                    <CardData>{formatValue("percentage", accountAudit.overSpend.revenueShare / 100)}</CardData>
                  </CardRow>
                </AuditCard>
                <AuditCard>
                  <CardTitleRow>Underspend</CardTitleRow>
                  <CardRow>
                    <CardTitle>Total Underspend</CardTitle>
                    <CardData>{formatValue("monetary", accountAudit.underSpend.cost)}</CardData>
                  </CardRow>
                  <CardRow>
                    <CardTitle>% of Products</CardTitle>
                    <CardData>
                      {formatValue("percentage", accountAudit.underSpend.percentageOfTotalInventory / 100)}
                    </CardData>
                  </CardRow>
                  <CardRow>
                    <CardTitle>Revenue Generated</CardTitle>
                    <CardData>{formatValue("monetary", accountAudit.underSpend.revenue)}</CardData>
                  </CardRow>
                  <CardRow>
                    <CardTitle>% of Revenue</CardTitle>
                    <CardData>{formatValue("percentage", accountAudit.underSpend.revenueShare / 100)}</CardData>
                  </CardRow>
                </AuditCard>
              </Row>
            </Content>
          </BodyGrid>
        </Grid>
      </Box>
      <Box
        sx={{
          bgcolor: "white",
          padding: "2em",
          margin: "2em",
          borderRadius: "0.5%",
          border: "1px solid",
          borderColor: "#dbdbdb",
          height: openTable ? "650px;" : "30px",
        }}
      >
        <Heading>
          Product Table{" "}
          {openTable ? (
            <KeyboardArrowUpIcon onClick={handleTable} sx={{ float: "right", cursor: "pointer" }} fontSize="large" />
          ) : (
            <KeyboardArrowDownIcon onClick={handleTable} sx={{ float: "right", cursor: "pointer" }} fontSize="large" />
          )}
        </Heading>
        {openTable && <ProductGroupTable />}
      </Box>
      <Box
        sx={{
          bgcolor: "white",
          padding: "2em",
          margin: "2em",
          borderRadius: "0.5%",
          border: "1px solid",
          borderColor: "#dbdbdb",
          height: openChart ? "550px;" : "30px",
        }}
      >
        <Heading>
          Historic Revenue Chart
          {openChart ? (
            <KeyboardArrowUpIcon onClick={handleChart} sx={{ float: "right", cursor: "pointer" }} fontSize="large" />
          ) : (
            <KeyboardArrowDownIcon onClick={handleChart} sx={{ float: "right", cursor: "pointer" }} fontSize="large" />
          )}
        </Heading>
        {openChart && (
          <>
            <div style={{ margin: "20px" }}>
              <IntButton
                width={30}
                active={chartRender === ChartRender.REVENUE}
                onClick={() => setChartRender(ChartRender.REVENUE)}
              >
                Chart Revenue
              </IntButton>
              <IntButton
                width={30}
                active={chartRender === ChartRender.SPEND}
                onClick={() => setChartRender(ChartRender.SPEND)}
              >
                Chart Spend
              </IntButton>
            </div>
            <AuditChart chartRender={chartRender} auditAccountPerformance={accountAudit} />
          </>
        )}
      </Box>
    </>
  );
};

export default AuditStats;
