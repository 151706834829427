import { useAuth0 } from "@auth0/auth0-react";
import {
  FormControl,
  InputAdornment,
  InputLabel,
  ListSubheader,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import React, { useEffect } from "react";
import { getAllTenants, Tenant } from "../api/tenant";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
    fontFamily: "Montserrat",
  },
}));

export function AccountSwitcher() {
  const classes = useStyles();
  const { loginWithRedirect, user } = useAuth0();

  const [tenants, setTenants] = React.useState<Array<Tenant>>([]);
  const [selectedValue, setSelectedValue] = React.useState("");
  const [filter, setFilter] = React.useState<string>("");

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedValue(event.target.value as string);
  };

  const matchesTenant = (name: string, searchText: string) => name.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

  useEffect(() => {}, [selectedValue]);

  useEffect(() => {
    if (user && user["https://upp.ai/tenantId"]) {
      setSelectedValue(user["https://upp.ai/tenantId"]);
    }
  }, [user]);

  useEffect(() => {
    const allTenants = async () => {
      const tenants = await getAllTenants();
      const sortedTenants = tenants.sort((a, b) => {
        return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
      });
      setTenants(sortedTenants);
    };
    allTenants();
  }, []);

  if (!user) {
    return <></>;
  }

  return (
    <FormControl className={classes.formControl}>
      <InputLabel shrink id="accountLabel">
        Account
      </InputLabel>
      <Select
        labelId="accountLabel"
        id="accountLabel"
        defaultValue=""
        MenuProps={{ autoFocus: false }}
        onChange={handleChange}
        value={selectedValue}
        displayEmpty
        onClose={() => setFilter("")}
      >
        <ListSubheader disableSticky>
          <TextField
            size="small"
            autoFocus
            placeholder="Type to search..."
            fullWidth
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={(e) => setFilter(e.target.value)}
            onClick={(e) => e.stopPropagation()}
            onKeyDown={(e) => {
              if (e.key !== "Escape") {
                // Prevents autoselecting item while typing (default Select behaviour)
                e.stopPropagation();
              }
            }}
          />
        </ListSubheader>
        {tenants &&
          tenants
            .filter((option) => matchesTenant(option.name, filter))
            .map(({ id, name }) => {
              return (
                <MenuItem
                  key={id}
                  value={id}
                  disabled={id === user["https://upp.ai/tenantId"]}
                  onClick={() => loginWithRedirect({ scope: `tenant:${id}` })}
                >
                  {name} ({id})
                </MenuItem>
              );
            })}
        {tenants.filter((option) => matchesTenant(option.name, filter)).length < 1 && (
          <Typography style={{ marginLeft: "15px" }}>No Matching Tenant Found...</Typography>
        )}
      </Select>
    </FormControl>
  );
}
