import styled from "@emotion/styled";
import { Dialog, DialogTitle, Tooltip } from "@material-ui/core";
import { CloseRounded } from "@material-ui/icons";
import Check from "@material-ui/icons/Check";
import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";
import { Connection, DatasourceEntityType } from "../../api/datasources";
import { clearCollections } from "../../api/platformadmin";
import { deleteTenant } from "../../api/tenant";
import Button from "../../components/Wizards/Button";
import { useAllConnections, useCurrentDatasources } from "../../hooks/useDatasources";
import { useAccountStats } from "../../hooks/useStats";
import { uppColourPalette } from "../../util/colours";
import ConfigureConnection from "../datasources/ConfigureConnection";
import { StyledCloseButton } from "../datasources/SetupConnection";
import { Footer, WizardContext } from "./OnboardingWizard";
import { OrderSource } from "./WizardProvider";

interface DatasourceWizardProps {
  datasourcesComplete: boolean;
  setDatasourcesComplete: Dispatch<SetStateAction<boolean>>;
  setStep: Dispatch<SetStateAction<number>>;
  redirect: (key: string) => void;
  newTenant: string;
}

const Heading = styled.div`
  padding: 16px 0;
  text-align: left;
`;

const Logo = styled.img`
  height: 120px;
  width: auto;
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 24px;
`;
export type PaneProps = {
  disabled?: boolean;
};

const Pane = styled.div<PaneProps>`
  min-width: 200px;
  min-height: 400px;
  padding: 24px;
  transition: all ease 0.5s;
  background: ${(props) => (props.disabled ? uppColourPalette.coolGrey[50] : "#ffffff")};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  border: 1px solid ${uppColourPalette.coolGrey[100]};
`;

export type CheckContainerProps = {
  active: boolean | undefined;
  hidden?: boolean;
};

export const CheckContainer = styled.div<CheckContainerProps>`
  height: 24px;
  width: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => (props.active ? "white" : uppColourPalette.navy[300])};
  background: ${(props) => (props.active ? uppColourPalette.darkGreen[500] : uppColourPalette.coolGrey[50])};
  display: ${(props) => (props.hidden ? "none" : "")};
`;

export const DatasourceWizard = ({
  setStep,
  redirect,
  newTenant,
  datasourcesComplete,
  setDatasourcesComplete,
}: DatasourceWizardProps) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [selectedDatasource, setSelectedDatasource] = useState<Connection>();
  const [entityType, setEntityType] = useState<DatasourceEntityType>();
  const [isOpen, setIsOpen] = useState(false);
  const connectionQuery = useAllConnections();
  const currentDatasources = useCurrentDatasources();
  const accountStats = useAccountStats().data;
  const { updateWizardState } = useContext(WizardContext);

  async function cleardownDatasources() {
    const cleardownArray: string[] = [];
    accountStats?.forEach((stat) => {
      if (stat.count > 0) {
        cleardownArray.push(stat.name);
      }
    });
    await clearCollections(cleardownArray);
  }

  const deleteMultiTenants = () => {
    const tenantRegions = localStorage.getItem("multiRegion")?.split(/[ ,]+/);
    tenantRegions?.forEach((region: any) => {
      deleteTenant(region);
    });
  };

  const backPage = () => {
    if (visible) {
      cleardownDatasources();
      if (localStorage.getItem("multiRegion") !== null) {
        deleteMultiTenants();
      } else {
        deleteTenant(newTenant);
      }

      setStep(0);
      redirect("old");
      localStorage.clear();
    } else {
      setVisible(true);
    }
  };

  const nextPage = () => {
    updateWizardState({ datasource: { googleCombined: checkDatasource("googlecombined"), order: getOrderSource() } });
    // If multi-region, go through all datasource creation steps for each region before mapping (meh) - should we go through datasources + mapping + data download for each region first?
    if (localStorage.getItem("multiRegion") !== null) {
      const tenantRegions = localStorage.getItem("multiRegion")?.split(/[ ,]+/);
      const removeCompletedTenant = tenantRegions?.filter(
        (region: any) => region !== localStorage.getItem("onboardingTenant")
      );
      localStorage.setItem("multiRegion", removeCompletedTenant!.toLocaleString());
      if (tenantRegions!.length - 1 !== 0) {
        localStorage.setItem("onboardingTenant", removeCompletedTenant![0]);
        redirect("new");
      } else {
        setStep(2);
        localStorage.setItem("onboardingStep", String(2));
        setDatasourcesComplete(true);
      }
    }
    if (localStorage.getItem("multiRegion") === null) {
      setStep(2);
      localStorage.setItem("onboardingStep", String(2));
      setDatasourcesComplete(true);
    }
  };

  const checkShopifyConnection = () => {
    if (currentDatasources.data?.some((item) => item.partnerSystemId === "shopify")) {
      localStorage.setItem("waitingForCustomerShopify", "false");
      return true;
    }
    return false;
  };

  const checkDatasource = (datasource: string) => {
    return currentDatasources.data
      ? currentDatasources.data?.some((item) => item.partnerSystemId === datasource)
      : false;
  };

  const checkDatasourceComplete = () => {
    const combined = currentDatasources.data?.some((item) => item.entityType === DatasourceEntityType.Combined);
    const order = currentDatasources.data?.some((item) => item.entityType === DatasourceEntityType.Order);
    const ordersource = currentDatasources.data?.filter((item) => item.entityType === DatasourceEntityType.Order);
    if (combined && order && ordersource) {
      return true;
    }
    return false;
  };

  const hasOrderConnector = (datasource: string): boolean | undefined => {
    return (
      currentDatasources.data?.some((item) => item.entityType === DatasourceEntityType.Order) &&
      !currentDatasources.data?.some((item) => item.partnerSystemId === datasource)
    );
  };

  const getOrderSource = (): OrderSource | undefined => {
    return currentDatasources.data?.filter((item) => item.entityType === DatasourceEntityType.Order)?.[0]
      .partnerSystemId as OrderSource;
  };

  useEffect(() => {
    // should user have to click next or should it be automatic? Currently have to click next
    if (checkDatasourceComplete()) {
      if (datasourcesComplete) {
        nextPage();
      }
    }
    // eslint-disable-next-line
  }, [currentDatasources.data, datasourcesComplete]);

  return (
    <>
      <Heading>Connect Data Source</Heading>
      <>
        <Row>
          {connectionQuery.data?.map(
            (datasource) =>
              datasource.systemId === "googlecombined" && (
                <Pane>
                  <Tooltip title={"Google Connectors"}>
                    <Logo alt="Google Combined" src={datasource.logo} />
                  </Tooltip>
                  <CheckContainer active={checkDatasource("googlecombined")}>
                    <Check fontSize="small" />
                  </CheckContainer>
                  <Button
                    onClick={() => {
                      setSelectedDatasource(datasource);
                      setEntityType(DatasourceEntityType.Combined);
                      setIsOpen(true);
                    }}
                  >
                    Connect
                  </Button>
                </Pane>
              )
          )}

          {connectionQuery.data?.map(
            (datasource) =>
              datasource.systemId === "magento" && (
                <Pane disabled={hasOrderConnector("magento")}>
                  <Tooltip title={"Magento"}>
                    <Logo
                      onClick={() => {
                        setSelectedDatasource(datasource);
                        setEntityType(DatasourceEntityType.Order);
                        setIsOpen(true);
                      }}
                      alt="magento"
                      src={datasource.logo}
                    />
                  </Tooltip>
                  <CheckContainer active={checkDatasource("magento")} hidden={hasOrderConnector("magento")}>
                    <Check fontSize="small" />
                  </CheckContainer>
                  <Button
                    disabled={hasOrderConnector("magento")}
                    onClick={() => {
                      setSelectedDatasource(datasource);
                      setEntityType(DatasourceEntityType.Order);
                      setIsOpen(true);
                    }}
                  >
                    Connect
                  </Button>
                </Pane>
              )
          )}
          {connectionQuery.data?.map(
            (datasource) =>
              datasource.systemId === "googleanalytics" && (
                <Pane disabled={hasOrderConnector("googleanalytics")}>
                  <Tooltip title={"Google Analytics"}>
                    <Logo alt="googleanalytics" src={datasource.logo} />
                  </Tooltip>
                  <CheckContainer
                    active={checkDatasource("googleanalytics")}
                    hidden={hasOrderConnector("googleanalytics")}
                  >
                    <Check fontSize="small" />
                  </CheckContainer>
                  <Button
                    disabled={hasOrderConnector("googleanalytics")}
                    onClick={() => {
                      setSelectedDatasource(datasource);
                      setEntityType(DatasourceEntityType.Order);
                      setIsOpen(true);
                    }}
                  >
                    Connect
                  </Button>
                </Pane>
              )
          )}
          {connectionQuery.data?.map(
            (datasource) =>
              datasource.systemId === "shopify" && (
                <Pane disabled={hasOrderConnector("shopify")}>
                  <Tooltip title={"Shopify"}>
                    <Logo alt="shopify" src={datasource.logo} />
                  </Tooltip>
                  {localStorage.getItem("waitingForCustomerShopify") === "true" && !checkShopifyConnection() ? (
                    <>
                      <p>Waiting for customer</p>
                      <ClipLoader />
                    </>
                  ) : (
                    <CheckContainer active={checkDatasource("shopify")} hidden={hasOrderConnector("shopify")}>
                      <Check fontSize="small" />
                    </CheckContainer>
                  )}
                  <Button
                    disabled={hasOrderConnector("shopify")}
                    onClick={() => {
                      setSelectedDatasource(datasource);
                      setEntityType(DatasourceEntityType.Order);
                      setIsOpen(true);
                    }}
                  >
                    Connect
                  </Button>
                </Pane>
              )
          )}
        </Row>
        {!checkDatasourceComplete() && (
          <div>
            {!currentDatasources.data?.some(
              (datasource) => datasource.entityType === DatasourceEntityType.Combined
            ) && <p style={{ color: "red" }}>Required: Google Combined Datasource</p>}
            {!currentDatasources.data?.some((datasource) => datasource.entityType === DatasourceEntityType.Order) && (
              <p style={{ color: "red" }}>Required: Order Datasource</p>
            )}
          </div>
        )}
      </>
      <Dialog open={isOpen} maxWidth="md" fullWidth onClose={() => setIsOpen(false)}>
        <DialogTitle>
          {`Setup`}

          <StyledCloseButton>
            <CloseRounded onClick={() => setIsOpen(false)} />
          </StyledCloseButton>
        </DialogTitle>
        <ConfigureConnection
          system={selectedDatasource!}
          entityType={entityType!}
          onFinish={() => {
            setIsOpen(false);
          }}
          onBack={() => setIsOpen(false)}
          wizard={true}
        />
      </Dialog>
      {visible && (
        <p style={{ color: "red" }}>
          Are you sure you want to go back? This will delete the current tenant. Click 'Back' again to continue.
        </p>
      )}
      <Footer>
        <Button onClick={backPage}>Back</Button>
        <Button disabled={!checkDatasourceComplete()} onClick={nextPage}>
          Next
        </Button>
      </Footer>
    </>
  );
};

export default DatasourceWizard;
