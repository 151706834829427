import {
  DataGridPro,
  getGridStringOperators,
  GridCellParams,
  GridColDef,
  GridFilterItem,
  GridFilterOperator,
  GridValueFormatterParams,
} from "@mui/x-data-grid-pro";
import { useEffect, useState } from "react";
import { AuditProductGroupPerformance, Categorisation, getProductGroupAudit } from "../../api/audit";
import { uppColourPalette } from "../../util/colours";
import { GetCurrencySymbol } from "../../util/currency";
import { defaultProductGroupPerformance } from "./AuditDefaults";

const ProductGroupTable = () => {
  const currencySymbol = GetCurrencySymbol();
  const LIMIT = 1000000; // return all results to see how DataGrid client side pagination performs.

  const [productGroupAudit, setProductGroupAudit] =
    useState<AuditProductGroupPerformance>(defaultProductGroupPerformance);
  useEffect(() => {
    async function getAudit() {
      const audit = await getProductGroupAudit(0, LIMIT, Categorisation.ALL);
      if (audit) {
        setProductGroupAudit(audit);
      }
    }
    getAudit();
  }, []);

  const percentageValueFormatter: (params: GridValueFormatterParams<number>) => string = (
    params: GridValueFormatterParams<number>
  ) => {
    if (params.value == null) {
      return "";
    }
    const valueFormatted = Number(params.value * 100).toLocaleString("en", {
      maximumFractionDigits: 2,
    });
    return `${valueFormatted} %`;
  };

  const numberFormatter: (params: GridValueFormatterParams<number>) => string = (
    params: GridValueFormatterParams<number>
  ) => {
    if (params.value == null) {
      return "";
    }
    return Number(params.value).toLocaleString("en", {
      maximumFractionDigits: 2,
    });
  };

  const currencyValueFormatter: (params: GridValueFormatterParams<number>) => string = (
    params: GridValueFormatterParams<number>
  ) => {
    return `${currencySymbol}${numberFormatter(params)}`;
  };

  const defaultGridStringOperators = getGridStringOperators();

  const doesNotContainFilterOperator: GridFilterOperator = {
    label: "does not contain",
    value: "doesNotContain",
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (!filterItem.field || !filterItem.value || !filterItem.operator) {
        return null;
      }
      return (params: GridCellParams): boolean => {
        return !String(params.value).toLowerCase().includes(String(filterItem.value).toLowerCase());
      };
    },
    InputComponent: defaultGridStringOperators[0].InputComponent,
    InputComponentProps: { type: "string" },
  };

  const column: GridColDef = {
    field: "",
    flex: 1,
    headerAlign: "center",
    align: "center",
    type: "number",
    valueFormatter: numberFormatter,
  };

  const columns: GridColDef[] = [
    {
      ...column,
      field: "groupId",
      headerName: "Group Id",
      type: "string",
      valueFormatter: undefined,
      filterOperators: [...defaultGridStringOperators, doesNotContainFilterOperator],
    },
    {
      ...column,
      field: "title",
      headerName: "Title",
      type: "string",
      valueFormatter: undefined,
      filterOperators: [...defaultGridStringOperators, doesNotContainFilterOperator],
    },
    { ...column, field: "impressions", headerName: "Impressions" },
    { ...column, field: "clicks", headerName: "Clicks" },
    { ...column, field: "cost", headerName: "Cost", valueFormatter: currencyValueFormatter },
    {
      ...column,
      field: "costPercentile",
      headerName: "Cost Percentile",
      valueFormatter: percentageValueFormatter,
    },
    { ...column, field: "conversions", headerName: "Conversions" },
    {
      ...column,
      field: "conversionValue",
      headerName: "Conversion Value",
      valueFormatter: currencyValueFormatter,
    },
    {
      ...column,
      field: "revenue",
      headerName: "Revenue",
      flex: 1,
      valueFormatter: currencyValueFormatter,
    },
    { ...column, field: "unitsSold", headerName: "Units Sold" },
    {
      ...column,
      field: "unitsSoldPercentile",
      headerName: "Units Sold Percentile",
      valueFormatter: percentageValueFormatter,
    },
    { ...column, field: "categorisation", headerName: "Categorisation", type: "string", valueFormatter: undefined },
  ];

  const rows = productGroupAudit.productGroupPerformanceAuditItems.map((auditItem) => {
    return {
      id: auditItem.groupId,
      groupId: auditItem.groupId,
      title: auditItem.title,
      impressions: auditItem.impressions,
      clicks: auditItem.clicks,
      cost: auditItem.cost,
      conversions: auditItem.conversions,
      conversionValue: auditItem.conversionValue,
      revenue: auditItem.revenue,
      unitsSold: auditItem.unitsSold,
      costPercentile: auditItem.costPercentile,
      unitsSoldPercentile: auditItem.unitsSoldPercentile,
      categorisation: auditItem.categorisation,
    };
  });

  return (
    <div style={{ height: "600px" }}>
      <DataGridPro
        rows={rows}
        columns={columns}
        sx={{
          overflow: "scroll",
          fontFamily: "Jost, sans-serif",
          fontSize: 14,
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: uppColourPalette.navy[500],
            color: "white",
          },
          "& .MuiDataGrid-columnSeparator": {
            visibility: "hidden",
          },
        }}
      />
    </div>
  );
};

export default ProductGroupTable;
