import styled from "@emotion/styled";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { GridWrapper } from "../dataManagement/DataManagement";
import {
  CampaignAutomationStrategy,
  CampaignAutomationStrategyPlaybookItem,
  closeCampaignAutomationStrategy,
  updateCampaignAutomationStrategy,
  updateCampaignAutomationStrategyVisiblityBudget,
} from "../../api/campaignAutomation";
import Button from "../../components/Wizards/Button";
import { useState } from "react";
import { Box, Dialog, DialogTitle, Divider, TextField, Toolbar, Typography } from "@material-ui/core";
import { Field, Form } from "react-final-form";

type Props = {
  fetchCampaignStrategies: () => void;
  campaignStrategiesPlaybook: CampaignAutomationStrategyPlaybookItem[];
  strategies: CampaignAutomationStrategy[];
  isLoading: boolean;
};

const CustomMenuItem = styled.div`
  cursor: pointer;
  padding: 1rem;

  :hover {
    background-color: #ccc;
  }
`;

function AutopilotTenantsOverviewTable({
  fetchCampaignStrategies,
  campaignStrategiesPlaybook,
  strategies,
  isLoading,
}: Props): JSX.Element {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [showVisibilityTargetModal, setShowVisibilityTargetModal] = useState<boolean>(false);
  const [showChangeStrategyModal, setShowChangeStrategyModal] = useState<boolean>(false);
  const [selectedTenantId, setSelectedTenantId] = useState<string>("");
  const [selectedStrategy, setSelectedStrategy] = useState<CampaignAutomationStrategyPlaybookItem | null>(null);

  const closeStrategy = async (tenantId: number) => {
    setSubmitting(true);

    try {
      if (window.confirm("Are you sure you want to close the current strategy? (y/n)")) {
        await closeCampaignAutomationStrategy(tenantId);
        fetchCampaignStrategies();
      }
    } catch (error) {
      alert("Couldn't close strategy. Please try again later.");
    }

    setSubmitting(false);
  };

  const updateVisibilityBudget = async ({
    tenantId,
    visibilityShare,
  }: {
    tenantId: string;
    visibilityShare: number;
  }) => {
    setSubmitting(true);

    try {
      if (visibilityShare) {
        await updateCampaignAutomationStrategyVisiblityBudget(tenantId, visibilityShare);
        fetchCampaignStrategies();
      }
    } catch (error) {
      alert("Couldn't update strategy. Please try again later.");
    }

    setSubmitting(false);
  };

  const updateAutopilotStrategy = async ({
    tenantId,
    strategy,
  }: {
    tenantId: string;
    strategy: CampaignAutomationStrategyPlaybookItem | null;
  }) => {
    setSubmitting(true);

    try {
      if (strategy) {
        await updateCampaignAutomationStrategy(tenantId, strategy);
        fetchCampaignStrategies();
      }
    } catch (error) {
      alert("Couldn't update strategy. Please try again later.");
    }

    setSubmitting(false);
  };

  const columns: GridColDef[] = [
    { headerName: "Customer", field: "tenantId", width: 200 },
    { headerName: "Current Autopilot Strategy", field: "name", width: 300 },
    {
      headerName: "Current Autopilot Description",
      field: "description",
      width: 450,
      renderCell: (params) => (
        <p>
          {!params.row.expiryDate
            ? "Strategy recently cancelled - awaiting new strategy on next PGA run"
            : params.row.description}
        </p>
      ),
    },
    {
      headerName: "Close Current Strategy",
      field: "closeStrategy",
      width: 300,
      renderCell: (params) => (
        <Button
          styling="Deny"
          disabled={submitting || !params.row.expiryDate}
          onClick={() => {
            closeStrategy(params.row.tenantId);
          }}
        >
          Close Strategy
        </Button>
      ),
    },
    {
      headerName: "Override Visibility Budget",
      field: "overrideVisibility",
      width: 300,
      renderCell: (params) => (
        <Button
          styling="Submit"
          disabled={submitting}
          onClick={() => {
            setShowVisibilityTargetModal(true);
            setSelectedTenantId(params.row.tenantId);
          }}
        >
          Override
        </Button>
      ),
    },
    {
      headerName: "Change Strategy",
      field: "changeStrategy",
      width: 300,
      renderCell: (params) => (
        <Button
          styling="Submit"
          disabled={submitting || !params.row.expiryDate}
          onClick={() => {
            setShowChangeStrategyModal(true);
            setSelectedTenantId(params.row.tenantId);
          }}
        >
          Change Strategy
        </Button>
      ),
    },
  ];

  return (
    <GridWrapper>
      <DataGrid
        initialState={{
          sorting: {
            sortModel: [{ field: "tenantId", sort: "asc" }],
          },
        }}
        rows={strategies}
        columns={columns}
        loading={isLoading}
        getRowId={(row) => row.tenantId}
      />

      {showVisibilityTargetModal && (
        <Dialog
          open={showVisibilityTargetModal}
          maxWidth="md"
          fullWidth
          onClose={() => setShowVisibilityTargetModal(false)}
        >
          <DialogTitle>Override Visibility Budget Share</DialogTitle>

          <Divider />
          <Box marginY={2} marginX={2}>
            <Form
              onSubmit={async (data: any) => {
                await updateVisibilityBudget({ tenantId: selectedTenantId, visibilityShare: data.visibilityShare });
                setShowVisibilityTargetModal(false);
                setSelectedTenantId("");
              }}
              render={({ handleSubmit, submitting }) => (
                <form onSubmit={handleSubmit}>
                  <Typography variant="body1">Override Visibility Budget Share </Typography>
                  <p>Note: visibility budget share should be as decimal e.g. 10% = 0.1</p>
                  <Field
                    name="visibilityShare"
                    component={TextFieldAdapter}
                    label="Visibility budget share"
                    type="number"
                    required
                    style={{ minWidth: "20rem" }}
                    InputProps={{ inputProps: { min: 0, max: 1, step: 0.01 } }}
                  />

                  <Toolbar style={{ padding: 0 }}>
                    <Button styling="Submit" disabled={submitting} onClick={handleSubmit}>
                      Submit
                    </Button>

                    <div style={{ marginLeft: "1rem" }}>
                      <Button
                        onClick={() => {
                          setShowVisibilityTargetModal(false);
                          setSelectedTenantId("");
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </Toolbar>
                </form>
              )}
            />
          </Box>
        </Dialog>
      )}

      {showChangeStrategyModal && (
        <Dialog
          open={showChangeStrategyModal}
          maxWidth="md"
          fullWidth
          onClose={() => setShowChangeStrategyModal(false)}
        >
          <DialogTitle>Change Autopilot Strategy</DialogTitle>

          <Divider />
          <Box marginY={2} marginX={2}>
            <Form
              onSubmit={async () => {
                await updateAutopilotStrategy({ tenantId: selectedTenantId, strategy: selectedStrategy });
                setShowChangeStrategyModal(false);
                setSelectedTenantId("");
                setSelectedStrategy(null);
              }}
              render={({ handleSubmit, submitting }) => (
                <form onSubmit={handleSubmit}>
                  <Typography variant="body1" style={{ marginBottom: "1rem" }}>
                    Strategy Name
                  </Typography>

                  <div style={{ border: "1px #ccc solid", display: "flex" }}>
                    <div style={{ borderRight: "1px #ccc solid", minWidth: "20rem", flex: "0 0 2rem" }}>
                      {campaignStrategiesPlaybook.map((strategy, index) => (
                        <CustomMenuItem
                          key={`${strategy.name}-${index}`}
                          onClick={() => {
                            setSelectedStrategy(strategy);
                          }}
                          style={{
                            backgroundColor: selectedStrategy?.name === strategy.name ? "#3F3954" : "",
                            color: selectedStrategy?.name === strategy.name ? "#FFFFFF" : "#000000",
                          }}
                        >
                          {strategy.name}
                        </CustomMenuItem>
                      ))}
                    </div>
                    <div style={{ padding: "1rem" }}>{selectedStrategy && selectedStrategy.description}</div>
                  </div>

                  <Toolbar style={{ padding: 0 }}>
                    <Button styling="Submit" disabled={submitting} onClick={handleSubmit}>
                      Submit
                    </Button>

                    <div style={{ marginLeft: "1rem" }}>
                      <Button
                        onClick={() => {
                          setShowChangeStrategyModal(false);
                          setSelectedTenantId("");
                          setSelectedStrategy(null);
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </Toolbar>
                </form>
              )}
            />
          </Box>
        </Dialog>
      )}
    </GridWrapper>
  );
}

const TextFieldAdapter = ({ input, meta, ...rest }: any) => (
  <TextField {...input} {...rest} errorText={meta.touched ? meta.error : ""} margin="dense" variant="outlined" />
);

export default AutopilotTenantsOverviewTable;
