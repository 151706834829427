import styled from "styled-components";

interface NotificationBarProps {
  title?: string;
  copy?: string;
  type: "warning" | "success" | "error" | "info";
}

interface StyleBarProps {
  type: "warning" | "success" | "error" | "info";
}

const StyleBar = styled.div<StyleBarProps>`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1.5rem;
  width: 100%;

  p,
  h3 {
    text-align: left;
    margin: 0;
    padding: 0;
  }
  h3 {
    margin-bottom: 0.25rem;
  }

  ${({ type }) => {
    switch (type) {
      case "warning":
        return `background-color:#ffc400`;
      case "success":
        return `background-color:#00e676`;
      case "error":
        return `background-color:#ff1744; color:#fff`;
      case "info":
        return `background-color:#2196f3; color:#fff`;
    }
  }}
`;

const NotificationBar = ({ title, copy, type }: NotificationBarProps) => {
  return (
    <StyleBar type={type}>
      {title && <h3>{title}</h3>}
      {copy && <p>{copy}</p>}
    </StyleBar>
  );
};

export default NotificationBar;
