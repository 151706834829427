import { Box } from "@material-ui/core";
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { AccountPerformanceTrend, getAccountPerformanceTrend } from "../../../api/performance";
import { Heading } from "../../../components/Heading";
import { InnerContainer } from "../../../components/InnerContainer";

interface AccountPerformanceTrendView extends Omit<AccountPerformanceTrend, "percentageDifference"> {
  percentageDifference: string;
}

export const MPAPerformance = () => {
  const [isLoadingProducts, setIsLoadingProducts] = useState<boolean>(false);

  const [startDate] = useState<string>(
    DateTime.now().setZone("utc").minus({ weeks: 4 }).startOf("week").toISO().split("T")[0]
  );
  const [endDate] = useState<string>(
    DateTime.now().setZone("utc").minus({ weeks: 1 }).endOf("week").toISO().split("T")[0]
  );
  const [accountPerformanceTrend, setAccountPerformanceTrend] = useState<AccountPerformanceTrendView[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoadingProducts(true);
      try {
        const result = await getAccountPerformanceTrend();
        setAccountPerformanceTrend(makeHumanReadable(result));
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoadingProducts(false);
      }
    };
    fetchData();
  }, []);

  const GridContent = () => {
    if (isLoadingProducts) {
      return <div>Loading...</div>;
    }
    if (accountPerformanceTrend.length === 0) {
      return <div>No Account Performance data available - Review the Scheduler or contact the 🦒 team</div>;
    }

    const columns = Object.keys(accountPerformanceTrend[0]).map((index) => ({
      headerName: index,
      field: index,
      width: 150,
    }));
    return (
      <DataGridPro
        rows={accountPerformanceTrend}
        columns={columns}
        loading={isLoadingProducts}
        getRowId={(product: AccountPerformanceTrendView) => product.index}
        autoHeight
        slots={{
          toolbar: GridToolbar,
        }}
      />
    );
  };

  return (
    <Box
      sx={{
        bgcolor: "white",
        padding: "2em",
        margin: "2em",
        borderRadius: "0.5%",
        border: "1px solid",
        borderColor: "#dbdbdb",
      }}
    >
      <Heading>{`Account Performance: ${startDate} - ${endDate}`}</Heading>
      <InnerContainer>
        <GridContent />
      </InnerContainer>
    </Box>
  );
};

function makeHumanReadable(result: AccountPerformanceTrend[]): AccountPerformanceTrendView[] {
  return result.map((item) => ({
    index: item.index,
    after: Number(item.after.toFixed(2)),
    before: Number(item.before.toFixed(2)),
    difference: Number(item.difference.toFixed(2)),
    percentageDifference: (item.percentageDifference * 100).toFixed(0) + "%",
  }));
}
