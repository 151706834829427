import { http } from "../UppAuth";

export async function getCollectionStats(): Promise<CollectionStatsResponse> {
  const result = await http.get<any>(`${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/platformadmin/data/stats`);

  if (result.status !== 200) {
    console.log("Failed to fetch Tenant Config");
  }

  return result.data;
}

export async function clearCollections(collections: string[]): Promise<any[]> {
  const result = await http.post<any>(`${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/platformadmin/data/cleardown`, {
    collections,
  });

  if (result.status !== 204) {
    console.log("Failed to fetch Tenant Config");
  }
  return result.data;
}

export async function getTenants(): Promise<TenantInfo[]> {
  const result = await http.get(`${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/platformadmin/tenants`);

  if (result.status !== 200) {
    console.log("Failed to fetch Tenant Config");
  }

  return result.data;
}

export async function createTenant(createTenantRequest: CreateTenantRequest): Promise<CreateTenantResponse> {
  const result = await http.post<CreateTenantRequest>(
    `${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/platformadmin/tenants`,
    { name: createTenantRequest.name, tenantId: createTenantRequest.tenantId }
  );

  if (result.status !== 200) {
    console.log("Failed to fetch Tenant Config");
  }

  return result.data as CreateTenantResponse;
}

export async function publishSlackNotification(payload: { channel: string; attachments: any }): Promise<any> {
  const result = await http.post(`${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/internal-notification/slack`, payload);

  if (result.status !== 200) {
    console.log("Failed to fetch Tenant Config");
  }

  return result.data;
}

export async function getCollectionCount(collection: string): Promise<number> {
  const result = await http.get<any>(
    `${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/platformadmin/data/stats?collection=${collection}`
  );

  if (result.status !== 200) {
    console.log(`Unable to fetch count for ${collection}`);
    return 0;
  }
  if (result.data.length) {
    return result.data[0].count;
  }
  return 0;
}

export interface CreateTenantRequest {
  tenantId: string;
  name: string;
  email?: string;
  regions?: string;
}

export interface CreateTenantResponse {
  tenantId: string;
  name: string;
  key: string;
}

export interface TenantInfo {
  id: string;
  name: string;
  key: string;
}

export type CollectionStats = {
  [key: string]: any;
};

export type CollectionStatsResponse = CollectionStats[];
