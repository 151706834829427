import { Box, Card, Divider } from "@material-ui/core";
import { DataGrid, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import { useState } from "react";
import styled from "styled-components";
import { OverviewDecoratedTenant } from "../../api/tenant";
import { TableCheckCell } from "../../components/Table/TableCheckCell";
import { TableAgeCell } from "../../components/Table/TableDateCell";
import { StyledButton } from "../datasources/Datasources";
import { CreateTenant } from "./CreateTenant";

const ButtonContainer = styled.div`
  display: flex;
`;

export const RightStyledButton = styled(StyledButton)`
  padding: 0.5em !important;
  margin: 1em 1em 0.5em auto !important;
  border: 1px solid !important;
`;

export const GridWrapper = styled.div`
  height: 500px;
`;

export const TenantTable = (props: { tenants: OverviewDecoratedTenant[]; isLoading: boolean }) => {
  const [showAddTenant, setShowAddTenant] = useState<boolean>(false);

  const rows: GridRowsProp = props.tenants
    .sort((a, b) => {
      return a.id.localeCompare(b.id);
    })
    .map((tenant) => ({
      id: `${tenant.id} ${tenant.name}`,
      customer: `${tenant.id} ${tenant.name}`,
      live: tenant.live,
      campaignAutomation: tenant.campaignAutomation,
      autopilot: tenant.autopilot,
      mpa: tenant.mpa,
      checker: tenant.config["automation_checkerOff"],
      pgaLastRun: tenant.lastSuccessfulPGA,
      campaignType: tenant.campaignType,
    }));

  const columns: GridColDef[] = [
    { field: "customer", headerName: "Customer", flex: 1 },
    { field: "live", headerName: "Live", renderCell: TableCheckCell, flex: 1 },
    { field: "campaignAutomation", headerName: "Campaign Automation", renderCell: TableCheckCell, flex: 1 },
    { field: "autopilot", headerName: "Autopilot", renderCell: TableCheckCell, flex: 1 },
    { field: "mpa", headerName: "MPA", renderCell: TableCheckCell, flex: 1 },
    { field: "checker", headerName: "Checker Off", renderCell: TableCheckCell, flex: 1 },
    { field: "pgaLastRun", headerName: "PGA Last Run", renderCell: TableAgeCell, flex: 1 },
    { field: "campaignType", headerName: "Campaign Type", flex: 1 },
  ];

  return (
    <Card>
      <Divider />
      <Box>
        <ButtonContainer>
          <RightStyledButton
            variant="outlined"
            onClick={() => {
              setShowAddTenant(true);
            }}
          >
            Create Tenant
          </RightStyledButton>

          {showAddTenant ? (
            <CreateTenant
              isOpen={showAddTenant}
              onClose={() => setShowAddTenant(false)}
              existingTenantIds={props.tenants?.map((t) => t.id) || []}
            />
          ) : (
            ""
          )}
        </ButtonContainer>

        <GridWrapper>
          <DataGrid rows={rows} columns={columns} loading={props.isLoading} />
        </GridWrapper>
      </Box>
    </Card>
  );
};
