import { Box } from "@material-ui/core";
import { DateTime } from "luxon";
import styled from "styled-components";
import { StyledButton } from "../datasources/Datasources";

const StyledSourceCard = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
  border: 1px solid #bcbcbc;
  border-radius: 5px;
  height: 80px;
  width: 50%%;
  margin: 10px;
  cursor: pointer;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const SourceInfo = styled(Box)`
  display: flex !important;
  align-items: center !important;
`;

const ButtonContainer = styled.div`
  display: flex !important;
  justify-content: flex-end !important;
  align-items: center !important;
  padding-right: 16px !important;
`;

interface JobCardProps {
  onClick?: () => void;
  onExpand?: (e: any) => void;
  status?: string;
  id: string;
  nextInvocation: string;
}

export const JobCard = ({ onClick, onExpand, id, status, nextInvocation }: JobCardProps) => {
  return (
    <StyledSourceCard className="job-card" onClick={onExpand}>
      <SourceInfo>{id}</SourceInfo>
      <SourceInfo />
      <SourceInfo>{`${status!.charAt(0).toUpperCase() + status!.slice(1)}`}</SourceInfo>
      <SourceInfo>
        {status === "scheduled" && DateTime.fromISO(nextInvocation).toLocaleString(DateTime.DATETIME_SHORT)}
      </SourceInfo>
      <ButtonContainer>
        <StyledButton variant="outlined" onClick={onClick}>
          Delete
        </StyledButton>
      </ButtonContainer>
    </StyledSourceCard>
  );
};
