import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { useState } from "react";
import { PastCampaignAutomation } from "../../api/campaignMonitoring";

interface PGASelectorInterface {
  pastCampaignAutomations: PastCampaignAutomation[];
  setFromDate: (fromDate: string) => void;
  setToDate: (toDate: string) => void;
}

const getPgaDateRangeString = (pastCampaignAutomation: PastCampaignAutomation) => {
  const endDate = pastCampaignAutomation.endDate ? pastCampaignAutomation.endDate : new Date().toISOString();
  return `${pastCampaignAutomation.startDate.split("T")[0]} to ${endDate.split("T")[0]}`;
};

export const PGADateSelector = ({ pastCampaignAutomations, setFromDate, setToDate }: PGASelectorInterface) => {
  const [selectedPastCampaign, setSelectedPastCampaign] = useState(pastCampaignAutomations[0]);

  const handleCampaignChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const dateRange = (event.target.value as string).split("to");
    const startDate = dateRange[0].trim();
    const endDate = dateRange[1].trim();
    setSelectedPastCampaign({ endDate, startDate });
    setFromDate(startDate);
    setToDate(endDate);
  };
  return (
    <FormControl fullWidth>
      <InputLabel id="pga-selector-input-label">Choose PGA Run</InputLabel>
      <Select
        labelId="pga-selector-label"
        id="pga-selected"
        value={getPgaDateRangeString(selectedPastCampaign)}
        label="Chose PGA run"
        onChange={handleCampaignChange}
      >
        {pastCampaignAutomations.map((campaign, index) => {
          return (
            <MenuItem key={index} value={getPgaDateRangeString(campaign)}>
              {getPgaDateRangeString(campaign)}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
