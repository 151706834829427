import { Box, Divider } from "@material-ui/core";
import { useState } from "react";
import { ClipLoader } from "react-spinners";
import { ClustersResponse, getNumberClustersAPI } from "../../api/campaignAutomation";
import { Heading } from "../../components/Heading";
import { InnerContainer } from "../../components/InnerContainer";
import { StyledButtonFilled } from "../../components/StyledButton";

export const OptimalNumberOfClusters = () => {
  const [optimalNumberOfClusters, setOptimalNumberOfClusters] = useState<number>();
  const [clustersError, setClustersError] = useState<string>("");
  const [loadingOptimalNumberOfClusters, setLoadingOptimalNumberOfClusters] = useState<boolean>(false);

  async function fetchOptimalNumberOfClusters() {
    try {
      setLoadingOptimalNumberOfClusters(true);
      const numberOfClustersResponse = (await getNumberClustersAPI()) as ClustersResponse;
      setOptimalNumberOfClusters(numberOfClustersResponse.n_clusters);
    } catch (err) {
      setClustersError((err as Error).message);
    }
    setLoadingOptimalNumberOfClusters(false);
  }

  return (
    <Box
      sx={{
        bgcolor: "white",
        padding: "2em",
        margin: "2em",
        borderRadius: "0.5%",
        border: "1px solid",
        borderColor: "#dbdbdb",
      }}
    >
      {loadingOptimalNumberOfClusters ? (
        <ClipLoader />
      ) : clustersError ? (
        <InnerContainer>
          <div style={{ color: "red" }}>Could not retrieve optimal number of clusters</div>
        </InnerContainer>
      ) : optimalNumberOfClusters ? (
        <>
          <Heading>The optimal number of clusters is {optimalNumberOfClusters} </Heading>
          <Divider />
        </>
      ) : (
        <StyledButtonFilled onClick={() => fetchOptimalNumberOfClusters()}>
          Get optimal number of clusters
        </StyledButtonFilled>
      )}
    </Box>
  );
};
