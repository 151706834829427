import { useQuery } from "@tanstack/react-query";
import { AuditHealth, getAuditHealthReport } from "../api/health";
import { getGoogleAdsAudit } from "../api/audit";

export function useAuditState(tenantId: string) {
  return useQuery<AuditHealth, Error>(["auditHealth", tenantId], () => getAuditHealthReport(tenantId));
}

export function useGoogleAdsDirectAudit(tenantId: string) {
  return useQuery<GoogleAdsDirectAudit, Error>(["googleAdsAudit", tenantId], () => getGoogleAdsAudit(tenantId));
}

export interface GoogleAdsDirectAudit {
  campaigns: AuditCampaign[];
  changelog: any;
  twelveMonthPerformance: any;
  threeMonthPerformance: any;
}

export interface AuditCampaign {
  id: string;
  name: string;
  budget: number;
  targetRoas: number;
}
