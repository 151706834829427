import { Button, DialogActions, DialogContent, MenuItem, Select } from "@material-ui/core";
import { useGoogleLogin } from "@react-oauth/google";
import { JSONSchema7 } from "json-schema";
import { flatten } from "lodash";
import { TextField } from "mui-rff";
import { useSnackbar } from "notistack";
import { useEffect, useRef, useState } from "react";
import { Field, useForm } from "react-final-form";
import styled from "styled-components";
import {
  AvailableConnectionOauthUrl,
  Connection,
  DatasourceEntityType,
  getAvailableConnectionOauthUrl,
} from "../../api/datasources";
import { StyledButton } from "./Datasources";
import { DialogContentSection } from "./DialogSection";
import { iso31661 } from "iso-3166";
import ShopifyWizard from "../onboardingWizard/ShopifyWizard";
import { useCurrentDatasources } from "../../hooks/useDatasources";

const DialogForm = styled.form`
  display: flex;
  max-height: calc(100vh - 128px);
  flex-direction: column;
  background-color: #f2f2f7;
`;

const SystemLogo = styled.img`
  position: absolute;
  height: 40px;
  width: 40px;
  top: 15px;
  right: 80px;
`;

const StyledTextField = styled(TextField)`
  width: 100% !important;
`;

const GOOGLE = "google";
const OAUTH2 = "oauth2";

export interface ConnectionFormProps {
  system: Connection;
  schema: JSONSchema7;
  onSubmit: () => void;
  onBack: () => void;
  wizard?: boolean;
}

const availableScopes: { [key: string]: string } = {
  googlemerchantcenter: "https://www.googleapis.com/auth/content",
  googleanalytics: "https://www.googleapis.com/auth/analytics.readonly",
  googleadwords: "https://www.googleapis.com/auth/adwords",
};

export const ConnectionForm = ({ system, schema, onSubmit, onBack, wizard }: ConnectionFormProps) => {
  const form = useForm();
  const { enqueueSnackbar } = useSnackbar();
  const [authInProgress, setAuthInProgress] = useState<boolean>(false);
  const [oAuthData, setOAuthData] = useState<AvailableConnectionOauthUrl | undefined>();
  const countries = useRef(iso31661.sort((a, b) => a.name.localeCompare(b.name)));
  const currentDatasources = useCurrentDatasources();

  useEffect(() => {
    console.log(form.getFieldState("merchant.center.id"));
    oAuthDetails();
    // eslint-disable-next-line
  }, []);

  const SelectAdapter = ({ input, ...rest }: any) => (
    <Select {...input} {...rest} searchable>
      {countries.current.map((country) => (
        <MenuItem key={country.alpha2} value={country.alpha2}>
          {country.name}
        </MenuItem>
      ))}
    </Select>
  );

  const scope = availableScopes[system.systemId];
  if (!scope) {
    console.log(`Unknown system: ${system.systemId}`);
  }

  const googleLogin = useGoogleLogin({
    scope,
    onSuccess: (response) => {
      form.submit();
    },
    onError: (error) => {
      console.log("OAUTH ERROR:", JSON.stringify(error));
      setAuthInProgress(false);

      enqueueSnackbar(error.error_description, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    },
  });

  async function oAuthDetails() {
    if (system.authType !== OAUTH2) {
      return;
    }

    const params: { [key: string]: string | any } = {
      siteAddress: "",
      ...flatten(form.getState().values?.connection || {}),
    };

    if (!system.systemId.startsWith(GOOGLE)) {
      params.callBackUrl = `${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/datasources/oauth/callback`;
    }

    const data = await getAvailableConnectionOauthUrl(system.systemId, params);
    setOAuthData(data);
  }

  let windowObjectReference: Window | null = null;
  let previousUrl: string | null = null;

  const handleAuthorizationCodeReceived = (event: MessageEvent) => {
    if (event.data.code) {
      setAuthInProgress(true);
      form.mutators.setGoogleAuthCode(event.data.code);
      form.submit();
    }
  };

  const openAuthorizationWindow = (url: string, name = "OAuth") => {
    // remove any existing event listeners
    window.removeEventListener("message", handleAuthorizationCodeReceived);

    // window features
    const strWindowFeatures = "toolbar=no, menubar=no, width=600, height=700, top=100, left=100";

    if (windowObjectReference === null || windowObjectReference.closed) {
      /* if the pointer to the window object in memory does not exist
		or if such pointer exists but the window was closed */
      windowObjectReference = window.open(url, name, strWindowFeatures);
    } else if (previousUrl !== url) {
      /* if the resource to load is different,
		then we load it in the already opened secondary window and then
		we bring such window back on top/in front of its parent window. */
      windowObjectReference = window.open(url, name, strWindowFeatures);
      if (windowObjectReference) {
        windowObjectReference.focus();
      }
    } else {
      /* else the window reference must exist and the window
		is not closed; therefore, we can bring it back on top of any other
		window with the focus() method. There would be no need to re-create
		the window or to reload the referenced resource. */
      windowObjectReference.focus();
    }

    // add the listener for receiving a message from the popup
    window.addEventListener("message", handleAuthorizationCodeReceived, false);
    // assign the previous URL
    previousUrl = url;
  };
  // eslint-disable-next-line
  useEffect(() => {
    if (!authInProgress) {
      return;
    }

    if (!oAuthData) {
      return;
    }

    if (system.authType === OAUTH2 && !system.systemId.startsWith(GOOGLE)) {
      setAuthInProgress(false);
      openAuthorizationWindow(oAuthData.oauthUrl);
    }
    // eslint-disable-next-line
  }, [oAuthData]);

  return (
    <DialogForm onSubmit={onSubmit}>
      {system.logo && <SystemLogo src={system.logo} />}
      {system.systemId !== "shopify" || !wizard ? (
        <>
          <DialogContent>
            {schema &&
              Object.entries(schema.properties!).map(([key, property]) => {
                if (!schema.required?.includes(key) || key === "oauth.authorization.code") {
                  return false;
                }

                if (typeof property === "boolean") {
                  return false;
                }

                return key !== "target.country" || !wizard ? (
                  <DialogContentSection title={property.title ?? key} infoText={property.description}>
                    <StyledTextField key={key} name={`connection.${key}`} variant="outlined" hiddenLabel />
                  </DialogContentSection>
                ) : (
                  <DialogContentSection title={property.title ?? key} infoText={property.description}>
                    <Field
                      name={`connection.${key}`}
                      component={SelectAdapter}
                      variant="outlined"
                      style={{ width: "100%" }}
                    />
                  </DialogContentSection>
                );
              })}
          </DialogContent>
          <DialogActions>
            <Button onClick={onBack} id="connections__back">
              Back
            </Button>

            {system.authType !== OAUTH2 && (
              <StyledButton type="submit" id="connections__connect" variant="outlined" color="primary">
                Submit
              </StyledButton>
            )}

            {system.authType === OAUTH2 && !system.systemId.startsWith(GOOGLE) && (
              <StyledButton
                variant="outlined"
                color="primary"
                disabled={authInProgress}
                onClick={() => {
                  setAuthInProgress(true);
                  oAuthDetails();
                }}
              >
                Submit
              </StyledButton>
            )}

            {system.authType === OAUTH2 && system.systemId.startsWith(GOOGLE) && oAuthData && (
              <StyledButton onClick={() => googleLogin()}>Submit</StyledButton>
            )}
          </DialogActions>
        </>
      ) : (
        <DialogContent>
          <ShopifyWizard
            hasCombined={
              currentDatasources.data?.some((datasource) => datasource.entityType === DatasourceEntityType.Combined) ||
              false
            }
            page={1}
          />
        </DialogContent>
      )}
    </DialogForm>
  );
};
