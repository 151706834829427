import { green, red } from "@material-ui/core/colors";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { Check, X } from "react-feather";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export function TableCheckCell(params: GridRenderCellParams<boolean>) {
  return <Container>{params.value ? <Check color={green[600]} /> : <X color={red[600]} />}</Container>;
}
