import { http } from "../UppAuth";
import { displayCognismCompanyJSON, displayCognismContactJSON } from "../screens/marketing/displayCognismJson";
import { IntegratorResponse } from "../screens/marketing/models/IntegratorResponse";
import { CognismContactRedeem } from "../screens/marketing/models/cognismContactRedeem";

export async function idSearch(id: string) {
  const result = await http.get<CognismContactRedeem>(
    `${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/marketing-leads/search/${id}`
  );
  if (result.status !== 200) {
    console.log("Failed to gather Cognism information");
    return "Failed to gather Cognism information";
  }

  if (id.length > 40) {
    return displayCognismContactJSON(result.data);
  } else {
    return displayCognismCompanyJSON(result.data);
  }
}

export async function addCompany(companyName: string) {
  const removedSpace = companyName.replace(/\s+/g, "");
  const result = await http.post<any>(
    `${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/marketing-leads/fill/${removedSpace}`
  );
  if (result.status !== 200) {
    console.log("Failed to add company");
    return "Failed to add company";
  }
  return companyName + " added to HubSpot";
}

export async function deleteCompany(companyName: string) {
  const removedSpace = companyName.replace(/\s+/g, "");
  alert(removedSpace);
  const result = await http.delete<any>(
    `${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/marketing-leads/delete/${removedSpace}`
  );
  if (result.status !== 200) {
    console.log("Failed to delete company");
  }
  return companyName + " has been deleted!";
}

export async function cognismMigrationToHubspot(
  queryParams: URLSearchParams
): Promise<{ error?: string; integratorResponse?: IntegratorResponse }> {
  const result = await http.post<IntegratorResponse>(
    `${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/marketing-leads/migrate?${queryParams.toString()}`,
    {}
  );

  if (result.status !== 202) {
    if (result.status === 429) {
      return { error: "Too many requests" };
    }
    return { error: "Something went wrong" };
  }

  return result.data.error ? { error: result.data.error } : { integratorResponse: result.data };
}
