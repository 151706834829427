import styled from "styled-components";
import { uppColourPalette } from "../util/colours";

const StyledCard = styled.div`
  min-height: calc(100vh - 108px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 24px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid ${uppColourPalette.coolGrey[100]};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  font-family: Aeonik Pro;
`;

const Card = ({ className, children }: any) => {
  return <StyledCard className={className}>{children}</StyledCard>;
};

export default Card;
