import { CloseRounded } from "@material-ui/icons";
import { Box, Checkbox, List, ListItem, ListItemText, Modal, Typography } from "@mui/material";
import { useState } from "react";
import styled from "styled-components";
import { TenantConfig } from "../../api/tenant";
import { uppColourPalette } from "../../util/colours";
import { StyledCloseButton } from "../datasources/SetupConnection";
import { useSnackbar } from "notistack";
import useHandleToggleFeature from "../../api/productFeature";

const StyledModal = styled(Modal)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  margin-top: 1em;
  max-height: 80vh;
  overflow-x: scroll;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 1em;
  margin-top: 1em;
  & > button {
    flex: 1;
    margin: 0.7em;
  }
`;

const StyledAddButton = styled.button`
  justify-content: center;
  cursor: pointer;
  border-radius: 8px;
  color: none;
  padding: 0.4em;
  color: white;
  background-color: ${uppColourPalette.navy[500]};
  &:hover {
    background: ${uppColourPalette.navy[800]};
  }
`;

const StyledRemoveButton = styled.button`
  justify-content: center;
  cursor: pointer;
  border-radius: 8px;
  width: 40%;
  border: 2px solid;
  padding: 0.4em;
  background-color: ${uppColourPalette.navy[500]};
  color: white;
  &:hover {
    background: ${uppColourPalette.navy[800]};
  }
`;

const ProductModal = ({
  open,
  handleClose,
  tenantIds,
  allFeatures,
  handleRefreshAllLiveTenants,
}: FeatureModalProps) => {
  const [enableBulkFeatures, setEnableBulkFeatures] = useState<Set<string>>(new Set());
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const useToggleHook = useHandleToggleFeature();

  const handleCheckBoxInBulk = async (feature: string, isChecked: boolean) => {
    setEnableBulkFeatures((prevState) => {
      const newSet = new Set(prevState);
      if (isChecked) {
        newSet.add(feature);
      } else {
        newSet.delete(feature);
      }
      return newSet;
    });
  };

  const createTenantConfigs = (value: boolean): TenantConfig[] => {
    return Array.from(enableBulkFeatures).map((feature: string) => {
      return {
        key: feature,
        value: value,
      };
    });
  };

  const addFeatureBulk = async () => {
    if (enableBulkFeatures.size === 0) {
      alert("Please select at least one feature");
      return;
    }
    const features = Array.from(enableBulkFeatures);
    setIsLoading(true);

    try {
      for (const tenantID of tenantIds) {
        for (const feature of features) {
          await useToggleHook.mutateAsync({
            tenantId: tenantID,
            feature: feature,
            value: true,
          });
        }
      }
      enqueueSnackbar("Feature added to all selected tenants", {
        variant: "success",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
    } catch (error) {
      console.error("Failed to update some tenants", error);
      alert("Failed to update some tenants");
    } finally {
      setIsLoading(false);
      setEnableBulkFeatures(new Set());
      handleRefreshAllLiveTenants();
      handleClose();
    }
  };

  const removeFeatureBulk = async () => {
    if (enableBulkFeatures.size === 0) {
      alert("Please select at least one feature");
      return;
    }
    const features = Array.from(enableBulkFeatures);
    setIsLoading(true);

    try {
      for (const tenantID of tenantIds) {
        for (const feature of features) {
          await useToggleHook.mutateAsync({
            tenantId: tenantID,
            feature: feature,
            value: false,
          });
        }
      }
      enqueueSnackbar("Feature removed from all selected tenants", {
        variant: "success",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
    } catch (error) {
      console.error("Failed to update some tenants", error);
      alert("Failed to update some tenants");
    } finally {
      setIsLoading(false);
      setEnableBulkFeatures(new Set());
      handleRefreshAllLiveTenants();
      handleClose();
    }
  };

  const sortFeaturesAlphabetically = (features: string[]) => {
    return features.sort((a, b) => a.localeCompare(b));
  };
  const renameFeatureFlags = (features: string[]) => {
    const map = new Map<string, string>();
    features.forEach((feature) => map.set(feature.replace(/featureFlag[_.]/, ""), feature));
    return map;
  };
  const sortedAndRenamedFeatures = renameFeatureFlags(sortFeaturesAlphabetically(allFeatures));

  const handleSelectAllinBulk = (isChecked: boolean) => {
    if (!isChecked) {
      setEnableBulkFeatures(new Set());
    } else {
      const allFeaturesSet = new Set(allFeatures.map((feature) => feature));
      setEnableBulkFeatures(allFeaturesSet);
    }
  };

  const allFeaturesSelected = Array.from(sortedAndRenamedFeatures.keys()).every((feature) =>
    enableBulkFeatures.has(sortedAndRenamedFeatures.get(feature) as string)
  );
  const selectAllCheckbox = (
    <Checkbox
      style={{ color: "grey" }}
      checked={allFeaturesSelected && sortedAndRenamedFeatures.size > 0}
      onChange={(e) => handleSelectAllinBulk(e.target.checked)}
    />
  );
  return (
    <StyledModal open={open} onClose={handleClose}>
      <Box sx={{ bgcolor: "white", padding: "2em", margin: "2em", border: "1px solid", borderColor: "#dbdbdb" }}>
        <StyledCloseButton onClick={handleClose}>
          <CloseRounded />
        </StyledCloseButton>
        <Typography>Edit Features for Selected Tenants</Typography>
        <List>
          <ListContainer>
            <ListItem key="selectAll">
              <ListItemText primary="Select All Features" primaryTypographyProps={{ style: { fontWeight: "bold" } }} />
              {selectAllCheckbox}
            </ListItem>
            {Array.from(sortedAndRenamedFeatures.keys()).map((feature) => {
              return (
                <ListItem key={feature}>
                  <ListItemText primary={feature} />
                  <Checkbox
                    style={{ color: "grey" }}
                    checked={enableBulkFeatures.has(sortedAndRenamedFeatures.get(feature) as string)}
                    onChange={(e) =>
                      handleCheckBoxInBulk(sortedAndRenamedFeatures.get(feature) as string, e.target.checked)
                    }
                  />
                </ListItem>
              );
            })}
            {isLoading && (
              <Typography>
                <b>Updating...</b>
              </Typography>
            )}
            <ButtonContainer>
              <StyledAddButton onClick={addFeatureBulk}>Add Features</StyledAddButton>
              <StyledRemoveButton onClick={removeFeatureBulk}>Remove Features</StyledRemoveButton>
            </ButtonContainer>
          </ListContainer>
        </List>
      </Box>
    </StyledModal>
  );
};
interface FeatureModalProps {
  open: boolean;
  handleClose: () => void;
  tenantIds: Set<string>;
  allFeatures: string[];
  handleRefreshAllLiveTenants: () => void;
}

export default ProductModal;
