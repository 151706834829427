import { Box, InputAdornment, InputLabel, Tooltip, Typography } from "@material-ui/core";
import Settings from "@material-ui/icons/Settings";
import { useConfirm } from "material-ui-confirm";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { TenantConfig, deleteTenant, getTenantConfig, updateTenantConfig } from "../../api/tenant";
import { OutlinedDiv } from "../../components/OutlinedDiv";
import { getAllCurrencyCodes } from "../../services/utils";
import { StyledDashboardContainer } from "../dashboard/Dashboard";
import { StyledButton, StyledButtonFilled } from "../datasources/Datasources";
import { AdvancedOptions } from "./AdvancedOptions";
import DropdownConfig from "./DropdownConfig";
import { FreeTextConfig } from "./FreeTextConfig";
import { SubAccountMapping } from "./SubAccountMapping";
import SwitchConfig from "./SwitchConfig";
import { createJob } from "../../api/jobscheduler";
import { getCustomLabelExclusionOptions } from "../../api/product";

export const StyledContainer = styled.div`
  .MuiListItem-root {
    margin: 0.5em;
  }
`;

export const StyledRow = styled.div`
  display: inline-flex;
  width: 100%;
  margin-bottom: 10px;
  .MuiFormLabel-root {
    margin-top: 10px !important;
    margin: 5px;
  }

  div {
    margin-right: 5px;
  }
`;

export const DeleteButton = styled(StyledButtonFilled)`
  background-color: red !important;
`;

const OptionsButton = styled(StyledButton)`
  float: right;

  .MuiSvgIcon-root {
    margin-right: 3px;
  }
`;

export enum AccountStatus {
  Initialised = "Initialised",
  Audit = "Audit",
  Live = "Live",
}

Object.values(AccountStatus).map((status) => {
  return { key: status, value: status };
});

export const TenantConfiguration = () => {
  const [tenantConfig, setTenantConfig] = useState<TenantConfig>({});
  const [updatedKeys, setUpdatedKeys] = useState<Array<any>>([]);
  const [updatedConfig, setUpdatedConfig] = useState<any>({});
  const [showSubMapping, setShowSubMapping] = useState<boolean>(false);
  const [showAdvancedOptions, setShowAdvancedOptions] = useState<boolean>(false);
  const [ignoreProductsCustomLabelMapping, setIgnoreProductsCustomLabelMapping] = useState<any>([]);
  const [customLabelExclusionOptions, setCustomLabelExclusionOptions] = useState<any>([]);

  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();

  const handleDataChange = (prop: any) => (e: React.ChangeEvent<HTMLInputElement>) => {
    setTenantConfig({
      ...tenantConfig,
      [prop]: e.target.type === "number" ? parseFloat(e.target.value) : e.target.value,
    });
    setUpdatedKeys(Array.from(new Set([...updatedKeys, prop])));
  };

  const handleIgnoreCustomLabelMappingLocalUpdate = (index: number, targetKey: string, value: string) => {
    const modified = [...ignoreProductsCustomLabelMapping];
    modified[index] = {
      ...modified[index],
      [targetKey]: value,
    };

    const validRows = modified.filter((row: any) => row.customLabelValue && row.customLabelValue !== "");

    setTenantConfig({
      ...tenantConfig,
      ignoreProductsCustomLabelMapping: JSON.stringify(validRows),
    });
    setIgnoreProductsCustomLabelMapping(modified);
    setUpdatedKeys(Array.from(new Set([...updatedKeys, "ignoreProductsCustomLabelMapping"])));
    upsertSetIgnoreProductsCustomLabelMapping(validRows);
  };

  const upsertSetIgnoreProductsCustomLabelMapping = (customLabelMappingOverride?: any) => {
    const validRows = customLabelMappingOverride
      ? customLabelMappingOverride.filter((row: any) => row.customLabelValue && row.customLabelValue !== "")
      : ignoreProductsCustomLabelMapping.filter((row: any) => row.customLabelValue && row.customLabelValue !== "");

    setTenantConfig({
      ...tenantConfig,
      ignoreProductsCustomLabelMapping: validRows,
    });

    updateTenantConfig(window.USER_INFO.tenantId, [
      {
        key: "ignoreProductsCustomLabelMapping",
        value: JSON.stringify(validRows),
      },
    ]);
  };

  const toggleBooleanConfig = (configKey: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    setTenantConfig({ ...tenantConfig, [configKey]: e.target.checked });
    updateTenantConfig(window.USER_INFO.tenantId, [{ key: configKey, value: e.target.checked }]);
  };

  const setConfigNumberValue = (configKey: string) => (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const numberValue = parseInt(e.target?.value);
    if (numberValue) {
      setTenantConfig({ ...tenantConfig, [configKey]: numberValue });
      updateTenantConfig(window.USER_INFO.tenantId, [{ key: configKey, value: numberValue }]);
    }
  };

  const setTextValue =
    (configKey: string) =>
    (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement> | React.ChangeEvent<HTMLInputElement>) => {
      const textValue = e.target?.value;
      if (textValue) {
        setTenantConfig({ ...tenantConfig, [configKey]: textValue });
        updateTenantConfig(window.USER_INFO.tenantId, [{ key: configKey, value: textValue }]);
      }
    };

  const confirmMessage = <Typography component={"span"}>Are you sure you want to delete this tenant?</Typography>;

  const handleDelete = () => {
    confirm({ title: "Confirm", description: confirmMessage })
      .then(() => {
        deleteTenant(window.USER_INFO.tenantId)
          .then((response) => {
            if (response === true) {
              enqueueSnackbar("Account has been deleted successfully", {
                variant: "success",
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "center",
                },
              });
            } else {
              enqueueSnackbar("Account failed to be deleted", {
                variant: "error",
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "center",
                },
              });
            }
          })
          .finally(() => {});
      })
      .catch(() => {
        // TODO: needed to handle 'cancel' gracefully
      });
  };

  useEffect(() => {
    const getParams = () => {
      const data = updatedKeys.map((item) => {
        return { key: item, value: tenantConfig[item] };
      });
      setUpdatedConfig(data);
    };
    getParams();
  }, [updatedKeys, tenantConfig]);

  const handleSubmit = (e: React.SyntheticEvent) => {
    updateTenantConfig(window.USER_INFO.tenantId, updatedConfig)
      .then(() => {
        enqueueSnackbar("Account configurations have been saved successfully", {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        });
      })
      .catch(() => {
        enqueueSnackbar("There was an error trying to save tenant configurations, please try again.", {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        });
      });

    setUpdatedKeys([]);
    setUpdatedConfig({});
  };

  const handleStartAnomolydetection = (e: React.SyntheticEvent) => {
    updateTenantConfig(window.USER_INFO.tenantId, updatedConfig)
      .then(() => {
        createJob(
          {
            tenantId: window.USER_INFO.tenantId,
            cronSchedule: "* * * * *",
            workflowToRun: {
              anomalyDetection: true,
            },
            configuration: {},
          },
          "monitoring"
        )
          .then((response) => {
            const workflowAlreadyStarted =
              response.data?.message === "Failed to start workflow - Workflow execution already started";
            if (response.data.wasSuccessful === true || workflowAlreadyStarted) {
              enqueueSnackbar(`${workflowAlreadyStarted ? "Anomaly detection running." : response.data.message}`, {
                variant: "success",
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "center",
                },
              });
            } else {
              enqueueSnackbar(`${response.data.message}`, {
                variant: "error",
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "center",
                },
              });
            }
          })
          .catch(() => {
            enqueueSnackbar("There was an error trying to start the anomaly detection, please try again.", {
              variant: "error",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
            });
          });
      })
      .catch(() => {
        enqueueSnackbar("There was an error trying to save tenant configurations, please try again.", {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        });
      });
    setUpdatedKeys([]);
    setUpdatedConfig({});
  };

  useEffect(() => {
    async function fetchTenantConfig() {
      const config = await getTenantConfig(window.USER_INFO.tenantId);
      setTenantConfig(config);
      setIgnoreProductsCustomLabelMapping(
        config["ignoreProductsCustomLabelMapping"]
          ? JSON.parse(config["ignoreProductsCustomLabelMapping"] as string)
          : []
      );
    }
    fetchTenantConfig();
  }, []);

  useEffect(() => {
    async function fetchCustomLabelExclusionOptions() {
      const customLabelOptions = await getCustomLabelExclusionOptions();
      setCustomLabelExclusionOptions(customLabelOptions);
    }

    fetchCustomLabelExclusionOptions();
  }, []);

  return (
    <StyledDashboardContainer>
      <h1>Account Configuration</h1>
      <Box
        sx={{
          bgcolor: "white",
          minHeight: "100%",
          padding: "3em",
          margin: "2em",
          borderRadius: "0.5%",
          border: "1px solid",
          borderColor: "#dbdbdb",
        }}
      >
        <StyledContainer>
          <OutlinedDiv label={"Account"}>
            <StyledRow key="accountStatus">
              <Tooltip title="Initialised = Account is in onboarding state for customer to add datasources, Audit = only the audit page is visible, Live = Customer is live and can see all elements of the dashboard.">
                <InputLabel>Account Status ℹ️</InputLabel>
              </Tooltip>
              <DropdownConfig
                name="accountStatus"
                data={Object.values(AccountStatus).map((status) => {
                  return { key: status, value: status };
                })}
                value={tenantConfig["accountStatus"] ? tenantConfig["accountStatus"] : AccountStatus.Initialised}
                handleChange={(e) => {
                  handleDataChange("accountStatus")(e);
                  setTextValue("accountStatus")(e);
                }}
              />
            </StyledRow>
            <StyledRow>
              <InputLabel>Spend Reporting (Hourly)</InputLabel>
              <SwitchConfig
                onChange={toggleBooleanConfig("spendReportingEnabled")}
                checked={tenantConfig["spendReportingEnabled"] ? tenantConfig["spendReportingEnabled"] : false}
              />
            </StyledRow>
            <StyledRow>
              <InputLabel>ROAS Reporting (Hourly)</InputLabel>
              <SwitchConfig
                onChange={toggleBooleanConfig("revenueRoasReportingEnabled")}
                checked={
                  tenantConfig["revenueRoasReportingEnabled"] ? tenantConfig["revenueRoasReportingEnabled"] : false
                }
              />
            </StyledRow>
            <StyledRow>
              <Tooltip title="Customer Start date. This should be ISO date format e.g. 2023-03-01">
                <InputLabel>Contract Start Date ℹ️</InputLabel>
              </Tooltip>

              <FreeTextConfig
                onBlur={setTextValue("contractStartDate")}
                onChange={handleDataChange("contractStartDate")}
                // TODO: type="date"
                value={tenantConfig["contractStartDate"] ?? ""}
              />
            </StyledRow>
            <StyledRow>
              <Tooltip title="Yearly monetary amount in GBP">
                <InputLabel>Annual Contract Value ℹ️</InputLabel>
              </Tooltip>

              <FreeTextConfig
                onChange={handleDataChange("annualContractValue")}
                type="number"
                onBlur={setConfigNumberValue("annualContractValue")}
                value={tenantConfig["annualContractValue"] ? tenantConfig["annualContractValue"] : 0}
                inputProps={{
                  startAdornment: <InputAdornment position="start">£</InputAdornment>,
                }}
              />
            </StyledRow>
          </OutlinedDiv>

          <OutlinedDiv label={"Ads Account Anomaly Detection"}>
            <StyledRow>
              <Tooltip title="Account Anomaly Detector alerts the advertiser whenever a Google Ads account is suddenly behaving too differently from what's historically observed.">
                <InputLabel>Ads Account Anomaly Detection ℹ️</InputLabel>
              </Tooltip>
              <SwitchConfig
                onChange={toggleBooleanConfig("anomalyDetectionEnabled")}
                checked={tenantConfig["anomalyDetectionEnabled"] ? tenantConfig["anomalyDetectionEnabled"] : false}
              />
            </StyledRow>
            <StyledRow>
              <Tooltip title="Number of weeks to look back at">
                <InputLabel>Number of Weeks to Look Back at ℹ️</InputLabel>
              </Tooltip>
              <FreeTextConfig
                onChange={handleDataChange("anomalyDetectionWweeksToLookAt")}
                type="number"
                onBlur={setConfigNumberValue("anomalyDetectionweeksToLookAt")}
                value={
                  tenantConfig["anomalyDetectionWeeksToLookAt"] ? tenantConfig["anomalyDetectionWeeksToLookAt"] : 26
                }
                disabled={tenantConfig["anomalyDetectionEnabled"] ? !tenantConfig["anomalyDetectionEnabled"] : true}
              />
            </StyledRow>
            <StyledRow>
              <Tooltip title="% of historical average">
                <InputLabel>Impressions Threshold ℹ️</InputLabel>
              </Tooltip>
              <FreeTextConfig
                onChange={handleDataChange("anomalyDetectionImpressionsThreshold")}
                type="number"
                onBlur={setConfigNumberValue("anomalyDetectionImpressionsThreshold")}
                value={
                  tenantConfig["anomalyDetectionIimpressionsThreshold"]
                    ? tenantConfig["anomalyDetectionImpressionsThreshold"]
                    : 80
                }
                inputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
                disabled={tenantConfig["anomalyDetectionEnabled"] ? !tenantConfig["anomalyDetectionEnabled"] : true}
              />
            </StyledRow>
            <StyledRow>
              <Tooltip title="% of historical average">
                <InputLabel>Clicks Threshold ℹ️</InputLabel>
              </Tooltip>
              <FreeTextConfig
                onChange={handleDataChange("anomalyDetectionClicksThreshold")}
                type="number"
                onBlur={setConfigNumberValue("anomalyDetectionClicksThreshold")}
                value={
                  tenantConfig["anomalyDetectionClicksThreshold"] ? tenantConfig["anomalyDetectionClicksThreshold"] : 50
                }
                inputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
                disabled={tenantConfig["anomalyDetectionEnabled"] ? !tenantConfig["anomalyDetectionEnabled"] : true}
              />
            </StyledRow>
            <StyledRow>
              <Tooltip title="% of historical average">
                <InputLabel>Conversions Threshold ℹ️</InputLabel>
              </Tooltip>
              <FreeTextConfig
                onChange={handleDataChange("anomalyDetectionConversionsThreshold")}
                type="number"
                onBlur={setConfigNumberValue("anomalyDetectionConversionsThreshold")}
                value={
                  tenantConfig["anomalyDetectionConversionsThreshold"]
                    ? tenantConfig["anomalyDetectionConversionsThreshold"]
                    : 50
                }
                inputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
                disabled={tenantConfig["anomalyDetectionEnabled"] ? !tenantConfig["anomalyDetectionEnabled"] : true}
              />
            </StyledRow>
            <StyledRow>
              <Tooltip title="% of historical average">
                <InputLabel>Cost Threshold ℹ️</InputLabel>
              </Tooltip>
              <FreeTextConfig
                onChange={handleDataChange("anomalyDetectionCostThreshold")}
                type="number"
                onBlur={setConfigNumberValue("anomalyDetectionCostThreshold")}
                value={
                  tenantConfig["anomalyDetectionCostThreshold"] ? tenantConfig["anomalyDetectionCostThreshold"] : 150
                }
                inputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
                disabled={tenantConfig["anomalyDetectionEnabled"] ? !tenantConfig["anomalyDetectionEnabled"] : true}
              />
            </StyledRow>
            <StyledRow>
              <Tooltip title="Slack channel that the anomaly detection will send alerts to">
                <InputLabel>Slack Channel ℹ️</InputLabel>
              </Tooltip>
              <FreeTextConfig
                onChange={handleDataChange("anomalyDetectionSlackChannel")}
                type="string"
                onBlur={setTextValue("anomalyDetectionSlackChannel")}
                value={
                  tenantConfig["anomalyDetectionSlackChannel"]
                    ? tenantConfig["anomalyDetectionSlackChannel"]
                    : "alert-noisy"
                }
                disabled={tenantConfig["anomalyDetectionEnabled"] ? !tenantConfig["anomalyDetectionEnabled"] : true}
              />
            </StyledRow>
            <StyledRow>
              <Tooltip title="Reporting interval specifies how often the anomaly detection will run">
                <InputLabel>Report Every ℹ️</InputLabel>
              </Tooltip>
              <DropdownConfig
                name="anomalyDetectionSchedule"
                data={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}
                value={tenantConfig["anomalyDetectionSchedule"] ? tenantConfig["anomalyDetectionSchedule"] : 1}
                handleChange={(e) => {
                  handleDataChange("anomalyDetectionSchedule")(e);
                  setTextValue("anomalyDetectionSchedule");
                }}
              />
              <InputLabel>Hour(s)</InputLabel>
            </StyledRow>

            <StyledButtonFilled
              onClick={handleStartAnomolydetection}
              disabled={tenantConfig["anomalyDetectionEnabled"] ? !tenantConfig["anomalyDetectionEnabled"] : true}
            >
              Start Anomaly Detection
            </StyledButtonFilled>
          </OutlinedDiv>
          <OutlinedDiv label={"Targets"} colour="red">
            <StyledRow>
              <InputLabel>Target Spend</InputLabel>
              <FreeTextConfig
                onChange={handleDataChange("targetSpend")}
                value={tenantConfig["targetSpend"] ? tenantConfig["targetSpend"] : 0}
              />
            </StyledRow>
            <StyledRow>
              <InputLabel>Target ROAS</InputLabel>
              <FreeTextConfig
                onChange={handleDataChange("targetRoas")}
                value={tenantConfig["targetRoas"] ? tenantConfig["targetRoas"] : 0}
              />
            </StyledRow>
            <StyledRow>
              <InputLabel>Target CPA</InputLabel>
              <FreeTextConfig
                onChange={handleDataChange("targetCpa")}
                value={tenantConfig["targetCpa"] ? tenantConfig["targetCpa"] : 0}
              />
            </StyledRow>
            <StyledButtonFilled onClick={handleSubmit}>Save</StyledButtonFilled>
          </OutlinedDiv>

          <OutlinedDiv label={"Localisation"}>
            <StyledRow>
              <InputLabel>Currency</InputLabel>
              <DropdownConfig
                name="currency"
                data={getAllCurrencyCodes()}
                value={tenantConfig["currency"] ? tenantConfig["currency"] : "GBP"}
                handleChange={(e) => {
                  handleDataChange("currency")(e);
                  setTextValue("currency")(e);
                }}
              />
            </StyledRow>
            <StyledRow>
              <InputLabel>Umbrella Account</InputLabel>
              <SwitchConfig
                onChange={toggleBooleanConfig("umbrellaAccount")}
                checked={!!tenantConfig["umbrellaAccount"]}
              />
            </StyledRow>
            <StyledRow>
              <InputLabel>Sub Account Mapping</InputLabel>
              <StyledButton
                disabled={(tenantConfig["subAccountMapping"] as string) ? false : true}
                onClick={() => {
                  setShowSubMapping(true);
                }}
              >
                Edit Mapping
              </StyledButton>
              {showSubMapping && (
                <SubAccountMapping
                  isOpen={true}
                  onClose={() => {
                    setShowSubMapping(false);
                  }}
                  data={JSON.parse(tenantConfig["subAccountMapping"] as string)}
                />
              )}
            </StyledRow>
          </OutlinedDiv>

          <OutlinedDiv label={"Campaign Automation"}>
            <StyledRow>
              <SwitchConfig
                onChange={toggleBooleanConfig("automation.enabled")}
                checked={!!tenantConfig["automation.enabled"]}
              />
              <InputLabel>Live</InputLabel>
            </StyledRow>

            <StyledRow>
              <InputLabel>Number of clusters</InputLabel>
              <FreeTextConfig
                onChange={handleDataChange("automation.numberClusters")}
                onBlur={setConfigNumberValue("automation.numberClusters")}
                type="number"
                value={tenantConfig["automation.numberClusters"]}
              />
            </StyledRow>
          </OutlinedDiv>
          {customLabelExclusionOptions && Object.keys(customLabelExclusionOptions).length > 0 && (
            <OutlinedDiv label={"Products to Exclude"}>
              <StyledRow>
                <InputLabel>Products to exclude - custom label mapping </InputLabel>
              </StyledRow>
              {ignoreProductsCustomLabelMapping.map((row: any, index: number) => {
                return (
                  <StyledRow id={`customLabel_${index}`}>
                    <DropdownConfig
                      name={`customLabel_${index}`}
                      data={Object.keys(customLabelExclusionOptions).map((key) => key) || []}
                      value={row.customLabelKey}
                      handleChange={(e) => {
                        handleIgnoreCustomLabelMappingLocalUpdate(index, "customLabelKey", e.target.value);
                      }}
                    />
                    <DropdownConfig
                      name={`customLabel_${index}`}
                      data={customLabelExclusionOptions[row.customLabelKey] || []}
                      value={row.customLabelValue}
                      handleChange={(e) => {
                        handleIgnoreCustomLabelMappingLocalUpdate(index, "customLabelValue", e.target.value);
                      }}
                    />
                    <DeleteButton
                      onClick={() => {
                        if (window.confirm("Are you sure you wish to delete this row?")) {
                          const cloned = [...ignoreProductsCustomLabelMapping];
                          cloned.splice(index, 1);
                          setIgnoreProductsCustomLabelMapping(cloned);
                          upsertSetIgnoreProductsCustomLabelMapping(cloned);
                        }
                      }}
                    >
                      Delete Row
                    </DeleteButton>
                  </StyledRow>
                );
              })}
              <StyledButtonFilled
                onClick={() => {
                  setIgnoreProductsCustomLabelMapping([
                    ...ignoreProductsCustomLabelMapping,
                    { customLabelKey: "customLabel0", customLabelValue: "" },
                  ]);
                }}
              >
                Add row
              </StyledButtonFilled>
            </OutlinedDiv>
          )}
          <OutlinedDiv label={"Notifications"}>
            <StyledRow>
              <InputLabel>Slack Channel</InputLabel>
              <FreeTextConfig
                onChange={handleDataChange("customerSlackChannel")}
                onBlur={setTextValue("customerSlackChannel")}
                value={tenantConfig["customerSlackChannel"] ?? ""}
              />
            </StyledRow>
            <StyledRow>
              <InputLabel>Target Updated Emails</InputLabel>
              <SwitchConfig
                onChange={toggleBooleanConfig("targetUpdatedMailEnabled")}
                checked={!!tenantConfig["targetUpdatedMailEnabled"]}
              />
            </StyledRow>
            <StyledRow>
              <InputLabel>Weekly Account Report Disabled</InputLabel>
              <SwitchConfig
                onChange={toggleBooleanConfig("accountReportMailDisabled")}
                checked={!!tenantConfig["accountReportMailDisabled"]}
              />
            </StyledRow>
          </OutlinedDiv>
          <OutlinedDiv label="" colour="red">
            <DeleteButton onClick={handleDelete}>Delete Tenant</DeleteButton>
          </OutlinedDiv>

          <OptionsButton
            onClick={() => {
              setShowAdvancedOptions(true);
            }}
          >
            <Settings /> Advanced Options
          </OptionsButton>
          {showAdvancedOptions && (
            <AdvancedOptions
              isOpen={true}
              onClose={() => {
                setShowAdvancedOptions(false);
              }}
              data={tenantConfig}
            />
          )}
        </StyledContainer>
      </Box>
    </StyledDashboardContainer>
  );
};

export default TenantConfiguration;
