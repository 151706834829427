import { Box, Typography, Chip, Switch, FormControlLabel } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { useSnackbar } from "notistack";
import { useState } from "react";
import styled from "styled-components";
import { useDeleteUser, getUsers, UsersResponse } from "../../api/userManagement";
import { StyledDashboardContainer } from "../dashboard/Dashboard";
import { StyledButtonFilled } from "../datasources/Datasources";
import { ConfirmationModal } from "./ConfirmationModal";
import { CreateUserModal } from "./CreateUserModal";
import { EditUserWrapper } from "./EditUserWrapper";

export const StyledEdit = styled(Typography)`
  color: #4054b4;
  cursor: pointer;
`;

export const StyledDelete = styled(Typography)`
  color: red;
  cursor: pointer;
`;

const GridWrapper = styled.div`
  height: 700px;
`;

const Heading = styled.div`
  display: flex;
  align-items: center;
  margin: 0 -16px 10px -16px;
  font-size: 2em;
  padding: 0 10px 10px 10px;
  button {
    margin-left: auto;
  }
`;

function UserManagement() {
  const [page, setPage] = useState(1);
  const [tenantId, setTenantId] = useState<string | undefined>();
  const { data, isLoading } = useQuery(["users", page, tenantId], () => getUsers(page, tenantId), {
    enabled: true,
    keepPreviousData: true,
  });
  const queryClient = useQueryClient();

  const [editUserId, setEditUserId] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [createUserModal, setCreateUserModal] = useState(false);
  const [deleteUserModal, setDeleteUserModal] = useState(false);
  const deleteUser = useDeleteUser();

  const { enqueueSnackbar } = useSnackbar();

  const handleEditUserClick = (userId: string) => {
    setEditUserId(userId);
    setEditModalOpen(true);
  };
  const handleDeleteUserClick = (userId: string, email: string) => {
    setEditUserId(userId);
    setEmail(email);
    setDeleteUserModal(true);
  };

  const handleTenantToggle = (_event: any, checked: boolean) => {
    if (checked) {
      setTenantId(window.TENANT_ID);
    } else {
      setTenantId(undefined);
    }
  };

  const handleDeleteUser = (userId: string) => {
    deleteUser.mutate(userId, {
      onSuccess: () => {
        queryClient.setQueryData<UsersResponse>(["users", page], (oldData) => {
          return {
            ...oldData!,
            totalUsers: (oldData?.totalUsers! - 1) | 0,
            users: oldData?.users.filter((user) => user.user_id !== userId) || [],
          };
        });
        enqueueSnackbar(`Successfully deleted user!`, {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        });
      },
      onError: (error) => {
        enqueueSnackbar(`Failed to delete user : ${(error as Error).message}`, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        });
      },
    });
    setDeleteUserModal(false);
  };

  const editCell = (id: string) => {
    return <StyledEdit onClick={() => handleEditUserClick(id)}>Edit</StyledEdit>;
  };

  const deleteCell = (id: string, email: string) => {
    return <StyledDelete onClick={() => handleDeleteUserClick(id, email)}>Delete</StyledDelete>;
  };

  const emailVerifiedCell = (verified: boolean) => {
    if (verified) {
      return <Chip size="small" label="Verified" color="success" />;
    }
    return <Chip size="small" label="Unverified" color="warning" />;
  };

  const columns: GridColDef[] = [
    { headerName: "Email", field: "email", flex: 1 },
    { headerName: "Name", field: "name", flex: 1 },
    {
      headerName: "Connection",
      field: "connection",
      valueGetter: (params) => params.row.identities?.[0].connection || "",
      flex: 1,
    },
    {
      headerName: "Last login",
      field: "last_login",
      flex: 1,
      valueFormatter: (params) =>
        params.value ? DateTime.fromISO(params.value as string).toLocaleString(DateTime.DATETIME_MED) : "Never",
    },
    {
      headerName: "Verified",
      field: "verified",
      flex: 1,
      renderCell: (params) => emailVerifiedCell(params.row.email_verified),
    },
    {
      headerName: "",
      field: "edit",
      renderCell: (params) => editCell(params.id as string),
      width: 75,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      headerName: "",
      field: "delete",
      renderCell: (params) => deleteCell(params.id as string, params.row.email as string),
      width: 75,
      disableColumnMenu: true,
      sortable: false,
    },
  ];

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (data) {
    return (
      <StyledDashboardContainer>
        <Box
          sx={{
            bgcolor: "white",
            padding: "2em",
            margin: "2em",
            borderRadius: "0.5%",
            border: "1px solid",
            borderColor: "#dbdbdb",
          }}
        >
          <Heading>
            User Management
            <StyledButtonFilled onClick={() => setCreateUserModal(true)}>Add User</StyledButtonFilled>
          </Heading>
          <FormControlLabel
            control={
              <Switch
                edge="end"
                color="primary"
                size="medium"
                defaultChecked={false}
                onChange={handleTenantToggle}
                // checked={true}
                inputProps={{
                  "aria-labelledby": "switch-list-label-wifi",
                }}
                disabled={isLoading}
              />
            }
            label="Tenant Users Only"
          />
          <GridWrapper>
            <DataGrid
              sx={{
                "& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator": {
                  display: "none",
                },
              }}
              paginationMode={"server"}
              rowCount={data.totalUsers}
              onPageChange={(page) => setPage(page + 1)}
              rows={data.users}
              columns={columns}
              loading={isLoading}
              getRowId={(row) => row.user_id}
              disableSelectionOnClick
              rowsPerPageOptions={[100]}
            />
          </GridWrapper>
        </Box>
        <CreateUserModal
          isOpen={createUserModal}
          onClose={() => {
            setCreateUserModal(false);
          }}
          page={page}
        ></CreateUserModal>
        <EditUserWrapper
          isOpen={editModalOpen}
          userId={editUserId}
          onClose={() => setEditModalOpen(false)}
          page={page}
        />
        <ConfirmationModal
          open={deleteUserModal}
          userEmail={email}
          onClose={() => {
            setDeleteUserModal(false);
          }}
          onConfirm={() => {
            handleDeleteUser(editUserId);
          }}
          disableButtons={false}
        />
      </StyledDashboardContainer>
    );
  }

  return null;
}

export default UserManagement;
