import { Box, Divider } from "@material-ui/core";
import { useEffect, useState } from "react";
import { Heading } from "../../components/Heading";
import { StyledDashboardContainer } from "../dashboard/Dashboard";
import OverviewTable from "./OverviewTable";
import { SummaryScheduledJob, getAllScheduledJobs } from "../../api/jobscheduler";

const JobSchedulerOverview = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [jobs, setJobs] = useState<SummaryScheduledJob[]>([]);

  useEffect(() => {
    async function fetchScheduledJobs() {
      const data = await getAllScheduledJobs();
      setJobs(data);
      setLoading(false);
    }
    fetchScheduledJobs();
  }, []);

  return (
    <StyledDashboardContainer>
      <Box
        sx={{
          bgcolor: "white",
          padding: "2em",
          margin: "2em",
          borderRadius: "0.5%",
          border: "1px solid",
          borderColor: "#dbdbdb",
        }}
      >
        <Heading>Job Scheduler Overview</Heading>
        <Divider />
        <OverviewTable jobs={jobs} isLoading={loading} />
      </Box>
    </StyledDashboardContainer>
  );
};

export default JobSchedulerOverview;
