import styled from "@emotion/styled";
import { InputAdornment, InputLabel } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { TenantConfig, getTenantConfig, updateTenantConfig } from "../../api/tenant";
import { OutlinedDiv } from "../../components/OutlinedDiv";
import Button from "../../components/Wizards/Button";
import { getAllCurrencyCodes, getCurrencySymbol } from "../../services/utils";
import DropdownConfig from "../tenantConfig/DropdownConfig";
import { FreeTextConfig } from "../tenantConfig/FreeTextConfig";
import { StyledRow } from "../tenantConfig/TenantConfig";
import { Footer, Steps, WizardContext } from "./OnboardingWizard";

const Heading = styled.div`
  display: flex;
  padding: 16px 0;
`;

export const TargetsAndCurrencyWizard = ({ setStep }: { setStep: Dispatch<SetStateAction<number>> }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [currency, setCurrency] = useState<string>("GBP");
  const [targetSpend, setTargetSpend] = useState<number>(0);
  const [targetRoas, setTargetRoas] = useState<number>(0);
  const [tenantConfig, setTenantConfig] = useState<TenantConfig>();
  const [slackChannel, setSlackChannel] = useState<string>("");
  const { updateWizardState } = useContext(WizardContext);

  useEffect(() => {
    async function fetchTenantConfig() {
      const config = await getTenantConfig(localStorage.getItem("onboardingTenant") as string);
      setTenantConfig(config);
      if (config.currency) {
        setCurrency(config.currency as string);
      }
      if (config.customerSlackChannel) {
        setSlackChannel(config.customerSlackChannel as string);
      }
      if (config.targetSpend) {
        setTargetSpend(config.targetSpend as number);
      }
      if (config.targetRoas) {
        setTargetRoas(config.targetRoas as number);
      }
    }
    fetchTenantConfig();
  }, []);

  function onSave() {
    if (
      currency !== tenantConfig?.currency ||
      targetSpend !== tenantConfig?.targetSpend ||
      targetRoas !== tenantConfig?.targetRoas ||
      slackChannel !== tenantConfig?.customerSlackChannel
    ) {
      updateTenantConfig(localStorage.getItem("onboardingTenant") as string, [
        { key: "currency", value: currency },
        { key: "customerSlackChannel", value: slackChannel },
        { key: "targetSpend", value: targetSpend },
        { key: "targetRoas", value: targetRoas },
      ])
        .then(() => {
          enqueueSnackbar("Tenant configurations have been saved successfully", {
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
          });
        })
        .catch(() => {
          enqueueSnackbar("There was an error trying to save tenant configurations, please try again.", {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
          });
        });
    }
  }

  const backPage = () => {
    localStorage.setItem("onboardingStep", String(2));

    onSave();
    setStep(Steps.MAPPING);
  };
  const nextPage = () => {
    localStorage.setItem("onboardingStep", String(4));
    onSave();
    updateWizardState({ currency, targetSpend, targetRoas, slackChannel });
    setStep(Steps.DATA_DOWNLOAD);
  };
  return (
    <>
      <Heading>Targets and Config</Heading>
      <div>
        <OutlinedDiv label={"Account Config"}>
          <StyledRow>
            <InputLabel>{"Currency :"}</InputLabel>
            <DropdownConfig
              name="currency"
              data={getAllCurrencyCodes()}
              value={currency}
              handleChange={(e) => setCurrency(e.target.value)}
            />
          </StyledRow>
          <StyledRow>
            <InputLabel>{"Slack Channel :"}</InputLabel>
            <FreeTextConfig
              onChange={(e) => setSlackChannel(e.target.value as unknown as string)}
              type="text"
              value={slackChannel}
            />
          </StyledRow>
        </OutlinedDiv>
        <OutlinedDiv label={"Targets"}>
          <StyledRow>
            <InputLabel>{"ROAS :"}</InputLabel>
            <FreeTextConfig
              onChange={(e) => setTargetRoas(e.target.value as unknown as number)}
              type="number"
              value={targetRoas}
            />
          </StyledRow>
          <StyledRow>
            <InputLabel>{"Daily Spend :"}</InputLabel>
            <FreeTextConfig
              onChange={(e) => setTargetSpend(e.target.value as unknown as number)}
              type="number"
              value={targetSpend}
              inputProps={{
                startAdornment: <InputAdornment position="start">{getCurrencySymbol(currency)}</InputAdornment>,
              }}
            />
          </StyledRow>
        </OutlinedDiv>
      </div>

      <Footer>
        <Button onClick={backPage}>Back</Button>
        <Button onClick={nextPage}>Next</Button>
      </Footer>
    </>
  );
};
