import { Box, Tooltip, Typography } from "@material-ui/core";
import styled from "styled-components";
import { StyledButton } from "../datasources/Datasources";
import SettingsIcon from "@material-ui/icons/Settings";
import CheckIcon from "@material-ui/icons/CheckCircle";
import CrossIcon from "@material-ui/icons/Cancel";
import { DateTime } from "luxon";

const StyledSourceCard = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
  border: 1px solid #bcbcbc;
  border-radius: 5px;
  height: 100%;
  margin: 10px;
  cursor: pointer;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const SourceInfo = styled(Box)`
  margin-left: 20px;
  display: flex !important;
  align-items: center !important;
  padding: 15px;
`;

const ButtonContainer = styled.div`
  display: flex !important;
  justify-content: flex-end !important;
  align-items: center !important;
  padding-right: 16px !important;
`;

const StyledCheckIcon = styled(CheckIcon)`
  color: green;
`;

const StyledCrossIcon = styled(CrossIcon)`
  color: red;
`;

interface StepProps {
  update: string;
  status: string;
  latestUpdateTime: string;
  results?: any;
  onClick: () => void;
}
export const StepCard = ({ update, status, results, latestUpdateTime, onClick }: StepProps) => {
  return (
    <Tooltip
      title={
        status === "failed"
          ? update
          : "Latest update time: " +
            DateTime.fromISO(latestUpdateTime).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)
      }
    >
      <StyledSourceCard className="step-card">
        <SourceInfo>{update}</SourceInfo>
        <SourceInfo />
        <SourceInfo>
          {status === "running" ? <SettingsIcon /> : status === "failed" ? <StyledCrossIcon /> : <StyledCheckIcon />}
          <Typography>{status!.charAt(0).toUpperCase() + status!.slice(1)}</Typography>
        </SourceInfo>

        <SourceInfo />
        {results && (
          <ButtonContainer>
            <StyledButton onClick={onClick}>Results</StyledButton>
          </ButtonContainer>
        )}
      </StyledSourceCard>
    </Tooltip>
  );
};
