import { Button } from "@material-ui/core";
import { useState } from "react";
import { ClipLoader } from "react-spinners";
import { CreateUserModal } from "../userManagement/CreateUserModal";

interface ShopifyWizardProps {
  hasCombined: boolean;
  page: number;
}

export const ShopifyWizard = ({ hasCombined, page }: ShopifyWizardProps) => {
  // TODO: sendgrid email with instructions on how to add shopify connector - should be sent out if customer needs to add shopify connector
  // AND they've already verified their email on Auth0.
  const [step, setStep] = useState(0);

  const updateStep = () => {
    setStep(step + 1);
  };

  const goBack = () => {
    setStep(step - 1);
  };

  return (
    <div>
      {step === 0 && (
        <>
          {!hasCombined ? (
            <h1>Please connect google datasources before continuing</h1>
          ) : (
            <div>
              <h2>
                Customers will need to add their shopify connector themselves. They will do so via our App. To get
                access to our App we must first create an account for them. then an email will be sent out, inviting
                them to verify their account and then instructing them on how to add their shopify connector.
              </h2>
              <h2>Please click next to continue with account creation</h2>
              <Button onClick={() => updateStep()}>Next</Button>
            </div>
          )}
        </>
      )}
      {step === 1 && (
        <>
          <h1>Account creation</h1>
          {
            // TODO: Should auto populate tenantId for user, but still allow adding more / user to change
          }
          <CreateUserModal
            onClose={() => updateStep()}
            isOpen={true}
            wizard={true}
            onComplete={() => updateStep()}
            page={page}
          />
          <Button onClick={() => goBack()}>Back</Button>
        </>
      )}
      {step === 2 && (
        <>
          <p>Waiting for customer to add their shopify connector</p>

          {localStorage.getItem("waitingForCustomerShopify") === "true" && <ClipLoader />}
          <Button onClick={() => goBack()}>Back</Button>
        </>
      )}
    </div>
    // TODO: Explain what is required for the shopify route (self-onboarding via Upp's App).
    // Customer needs an AUTH0 account created for them (user management routes). Once account has been created they will be invited to verify their email and acces the Upp App to add their shopify account
    // NOTE: Currently default sign up email does not mention shopify - has to be changed manually. Need a way to have a separate email template for just shopify customers.
  );
};

export default ShopifyWizard;
