import { Box, Button, List, Typography } from "@material-ui/core";
import { useEffect } from "react";
import {
  Activity as ActivityIcon,
  BarChart as BarChartIcon,
  Box as BoxIcon,
  Calendar as CalendarIcon,
  Clipboard as ClipboardIcon,
  Clock as ClockIcon,
  CloudOff as CloudOffIcon,
  CreditCard as CreditCardIcon,
  Icon,
  Link as LinkIcon,
  Settings as SettingsIcon,
  ShoppingBag as ShoppingBagIcon,
  Truck as TruckIcon,
  Users as UsersIcon,
  User as UserIcon,
  Star as StarIcon,
  Mail as MailIcon,
  BookOpen as BookOpenIcon,
  MessageSquare,
  ToggleRight,
} from "react-feather";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { AccountSwitcher } from "../AccountSwitcher";
import NavItem from "./LeftNavItem";

const StyledMenuList = styled(List)`
  text-align: left;
`;

enum NavigationSubheader {
  Account = "Account",
  Global = "Global",
}
interface NavigationItems {
  href: string;
  icon: Icon;
  title: string;
  subheader: NavigationSubheader;
}

const items: NavigationItems[] = [
  {
    href: "/TenantConfiguration",
    icon: BarChartIcon,
    title: "Account Configuration",
    subheader: NavigationSubheader.Account,
  },
  {
    href: "/AccountState",
    icon: StarIcon,
    title: "Account State",
    subheader: NavigationSubheader.Account,
  },
  {
    href: "/Campaigns",
    icon: BoxIcon,
    title: "Campaigns",
    subheader: NavigationSubheader.Account,
  },
  {
    href: "/Datasources",
    icon: ShoppingBagIcon,
    title: "Datasources",
    subheader: NavigationSubheader.Account,
  },
  {
    href: "/Audit",
    icon: ClipboardIcon,
    title: "Audit",
    subheader: NavigationSubheader.Account,
  },
  {
    href: "/DataManagement",
    icon: SettingsIcon,
    title: "Data Management",
    subheader: NavigationSubheader.Account,
  },
  {
    href: "/JobScheduler",
    icon: CalendarIcon,
    title: "Job Scheduler",
    subheader: NavigationSubheader.Account,
  },
  {
    href: "/CampaignAutomation",
    icon: TruckIcon,
    title: "Campaign Automation",
    subheader: NavigationSubheader.Account,
  },
  {
    href: "/AccountsOverview",
    icon: ActivityIcon,
    title: "Accounts Overview",
    subheader: NavigationSubheader.Global,
  },
  {
    href: "/AccountsAudit",
    icon: ClipboardIcon,
    title: "Accounts Audit",
    subheader: NavigationSubheader.Global,
  },
  {
    href: "/ActionsEmailsMetrics",
    icon: MailIcon,
    title: "Actions Emails Metrics",
    subheader: NavigationSubheader.Global,
  },
  {
    href: "/BlockedProducts",
    icon: CloudOffIcon,
    title: "Blocked Products",
    subheader: NavigationSubheader.Account,
  },
  {
    href: "/InfluentialProducts",
    icon: CreditCardIcon,
    title: "Influential Products",
    subheader: NavigationSubheader.Account,
  },
  {
    href: "/Dashboard",
    icon: UsersIcon,
    title: "Accounts",
    subheader: NavigationSubheader.Global,
  },
  {
    href: "/OnboardingWizard",
    icon: UserIcon,
    title: "Onboarding Wizard",
    subheader: NavigationSubheader.Global,
  },
  {
    href: "/UserManagment",
    icon: UserIcon,
    title: "User Management",
    subheader: NavigationSubheader.Global,
  },
  {
    href: "/UsefulLinks",
    icon: LinkIcon,
    title: "Useful Links",
    subheader: NavigationSubheader.Global,
  },
  {
    href: "/Changelog",
    icon: LinkIcon,
    title: "Changelog",
    subheader: NavigationSubheader.Account,
  },
  {
    href: "/JobSchedulerOverview",
    icon: ClockIcon,
    title: "Job Scheduler Overview",
    subheader: NavigationSubheader.Global,
  },
  {
    href: "/Marketing",
    icon: BookOpenIcon,
    title: "Marketing",
    subheader: NavigationSubheader.Global,
  },
  {
    href: "/ChatbotHistory",
    icon: MessageSquare,
    title: "Chatbot History",
    subheader: NavigationSubheader.Account,
  },
  {
    href: "/ProductFeatures",
    icon: ToggleRight,
    title: "Product Features",
    subheader: NavigationSubheader.Global,
  },
];

const LeftNavBar = () => {
  const location = useLocation();
  useEffect(() => {}, [location.pathname]);

  const globalMenuItems = items
    .filter((item) => item.subheader === NavigationSubheader.Global)
    .map(function (item, idx) {
      return (
        <li key={idx}>
          <NavItem href={item.href} key={item.title} title={item.title} icon={item.icon} />
        </li>
      );
    });

  const accountMenuItems = items
    .filter((item) => item.subheader === NavigationSubheader.Account)
    .map(function (item, idx) {
      return (
        <li key={idx}>
          <NavItem href={item.href} key={item.title} title={item.title} icon={item.icon} />
        </li>
      );
    });

  return (
    <Box
      sx={{
        marginTop: "50px",
        padding: "1em",
        bgcolor: "white",
        border: "1px solid",
        borderColor: "#dbdbdb",
      }}
    >
      <StyledMenuList>
        <h3>Global</h3> {globalMenuItems}
      </StyledMenuList>
      <StyledMenuList>
        <h3>Account</h3> {accountMenuItems}
      </StyledMenuList>

      <AccountSwitcher />

      <Typography align="center" variant="body2">
        Missing something? Checkout the Upp Internal Documentation on Google Drive
      </Typography>

      <Button
        color="primary"
        component="a"
        href="https://drive.google.com/drive/u/2/folders/0AEa-iC9UpGc_Uk9PVA"
        variant="contained"
      >
        Upp Google Drive
      </Button>
    </Box>
  );
};

export default LeftNavBar;
