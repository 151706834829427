import { Button, Dialog, DialogTitle } from "@material-ui/core";
import { useState } from "react";
import styled from "styled-components";
import { Connection, DatasourceEntityType } from "../../api/datasources";
import { ConfigureConnection } from "./ConfigureConnection";
import { ListAvailableConnections } from "./ListAvailableConnections";
import { CheckConnection } from "./CheckConnection";
import { CloseRounded } from "@material-ui/icons";

export const StyledCloseButton = styled(Button)`
  border-radius: 5px !important;
  float: right !important;
  color: #9e9e9e !important;
`;
interface SetupProps {
  isOpen: boolean;
  onClose: () => void;
}

export const SetupConnection = ({ isOpen, onClose }: SetupProps) => {
  const [step, setStep] = useState(0);
  const [system, setSystem] = useState<Connection | null>(null);
  const [connectionId, setConnectionId] = useState("");
  const [entityType, setEntityType] = useState<DatasourceEntityType | null>(null);

  const handleClose = () => {
    onClose();
    setStep(0);
    setSystem(null);
    setConnectionId("");
  };

  return (
    <Dialog open={isOpen} maxWidth="md" fullWidth onClose={handleClose}>
      <DialogTitle>
        {`Setup Connection${system ? ` - ${system.name}` : ""}`}

        <StyledCloseButton onClick={handleClose}>
          <CloseRounded />
        </StyledCloseButton>
      </DialogTitle>
      {step === 0 && (
        <ListAvailableConnections
          onClick={(item, entityType) => {
            setSystem(item);
            setEntityType(entityType);
            setStep(1);
          }}
        />
      )}

      {step === 1 && (
        <ConfigureConnection
          system={system as Connection}
          entityType={entityType!}
          onBack={() => {
            setStep(0);
            setSystem(null);
          }}
          onFinish={(connectionId) => {
            setConnectionId(connectionId);
            setStep(2);
            setSystem(null);
          }}
        />
      )}
      {step === 2 && <CheckConnection system={system!} connectionId={connectionId} onClose={handleClose} />}
    </Dialog>
  );
};

export default SetupConnection;
