import {
  Box,
  Button,
  Checkbox,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { Tenant, getAllLiveTenants, updateTenantConfig } from "../../api/tenant";
import { uppColourPalette } from "../../util/colours";
import { StyledDashboardContainer } from "../dashboard/Dashboard";
import FeatureModal from "./ProductModal";
import Tippy from "@tippyjs/react";
import useHandleToggleFeature from "../../api/productFeature";

export const StyledSwitch = styled(Switch)`
  scale: 0.75;

  .MuiSwitch-switchBase.Mui-checked {
    color: ${uppColourPalette.darkGreen[500]};
  }
  .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    background-color: ${uppColourPalette.darkGreen[500]};
  }

  .MuiSwitch-switchBase {
    color: ${uppColourPalette.navy[400]};
  }
`;

interface StyledButtonProps {
  isTenantSelected: boolean;
}
const SwitchContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledButton = styled(Button)<StyledButtonProps>`
  margin: 1em;
  cursor: pointer;
  border-radius: 50%;
  border: 2px solid;
  width: 18%;
  background-color: ${(props) => (props.isTenantSelected ? uppColourPalette.grey[300] : null)} !important;
`;
const StyledTextField = styled(TextField)`
  margin: 1em;
  width: 50%;
  float: right;
`;

const StyledTableCell = styled(TableCell)`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledDirection = styled.span`
  margin-left: 4px;
`;

const StyledTooltip = styled(Tippy)`
  background-color: ${uppColourPalette.coolGrey[400]};
  color: white;
  width: 300px;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 5px 10px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 10px 10px 0 10px;
    border-style: solid;
    border-color: ${uppColourPalette.coolGrey[400]} transparent transparent transparent;
  }
`;

const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StickyTableCell = styled(TableCell)<{ left: number }>`
  position: sticky;
  left: ${(props) => props.left}px;
  background: white;
  z-index: 1;
`;

const ProductFeatures = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [tenants, setTenants] = useState<Tenant[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [allFeatures, setAllFeatures] = useState<string[]>([]);
  const [selectedTenants, setSelectedTenants] = useState<Set<string>>(new Set());
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [sortFeature, setSortFeature] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const useToggleHook = useHandleToggleFeature();

  const handleOpenModal = () => {
    if (selectedTenants.size === 0) {
      alert("Please select at least one tenant");
      return;
    }

    setOpenModal(true);
  };
  const closeModal = () => {
    setOpenModal(false);
    setSelectedTenants(new Set()); //reset selected tenants
  };

  const handleTenantSelection = (tenantId: string, isChecked: boolean) => {
    setSelectedTenants((prevSelected) => {
      const newSelected = new Set(prevSelected);
      if (isChecked) {
        newSelected.add(tenantId);
      } else {
        newSelected.delete(tenantId);
      }
      return newSelected;
    });
  };

  const isTenantSelected = (tenantId: string) => selectedTenants.has(tenantId);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredTenants = tenants.filter((tenant) => {
    return tenant.name.toLowerCase().includes(searchTerm.toLowerCase());
  });

  useEffect(() => {
    async function fetchAllLiveTenants() {
      setIsLoading(true);
      handleRefreshAllLiveTenants();
      setIsLoading(false);
    }
    fetchAllLiveTenants();
  }, []);

  // need to use here to be able to pass to modal
  const handleRefreshAllLiveTenants = async () => {
    const allLiveTenants = await getAllLiveTenants();
    setTenants(allLiveTenants);
  };

  useEffect(() => {
    const features = new Set<string>();
    tenants.forEach((tenant) => {
      Object.keys(tenant.config)
        //need to filter out the random and experimental features (tests ones)
        .filter(
          (key) =>
            key.startsWith("featureFlag") &&
            !key.endsWith("random") &&
            !key.endsWith("experimental") &&
            !key.endsWith(".category")
        )
        .forEach((feature) => features.add(feature));
    });
    setAllFeatures(Array.from(features));
  }, [tenants]);

  const handleToggleFeature = async (tenant: Tenant, feature: string) => {
    const updatedConfig = {
      key: feature,
      value: !tenant.config[feature],
    };
    try {
      setTenants(
        tenants.map((t) =>
          t.id === tenant.id
            ? {
                ...t,
                config: {
                  ...t.config,
                  [feature]: !t.config[feature],
                },
              }
            : t
        )
      );
      await updateTenantConfig(tenant.id, [updatedConfig]);
    } catch (error) {
      console.error("Error trying to update", error);
    }
  };

  const handleColumnClick = (feature: string) => {
    if (feature === sortFeature) {
      setSortDirection((prevDirection) => (prevDirection === "asc" ? "desc" : "asc"));
    } else {
      setSortFeature(feature);
      setSortDirection("asc");
    }
  };

  //sorts the tenants by the feature selected
  const sortedTenants = () => {
    let dataToSort = filteredTenants;
    if (sortFeature) {
      dataToSort = [...dataToSort].sort((a, b) => {
        const aEnabled = a.config[sortFeature] === true;
        const bEnabled = b.config[sortFeature] === true;

        if (aEnabled === bEnabled) return 0;
        return sortDirection === "asc" ? (aEnabled ? -1 : 1) : aEnabled ? 1 : -1;
      });
    }

    // Calculate the pagination
    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    return dataToSort.slice(startIndex, endIndex);
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const sortFeaturesAlphabetically = (features: string[]) => {
    return features.sort((a, b) => a.localeCompare(b));
  };

  const renameFeatureFlags = (features: string[]) => {
    return features.map((feature) => feature.replace(/featureFlag[_.]/, ""));
  };

  const createFeatureMapping = (features: string[]) => {
    const mapping: { [key: string]: string } = {};
    features.forEach((feature) => {
      const renamedFeature = feature.replace(/featureFlag[_.]/, "");
      mapping[renamedFeature] = feature;
    });
    return mapping;
  };

  const getTooltipContent = (headerName: string) => {
    switch (headerName) {
      case "disableAccountBudgetSetting":
        return "Removes the ability for customer users to edit Daily Budget from the dashboard";
      case "disableAccountRoasSetting":
        return "Removes the ability for customer users to edit Target ROAS from the dashboard";
      default:
        return "";
    }
  };

  const sortedAndRenamedFeatures = renameFeatureFlags(sortFeaturesAlphabetically(allFeatures));
  const featureMapping = createFeatureMapping(allFeatures);

  const selectAllCheckbox = (
    <Checkbox
      size="small"
      style={{ color: "grey" }}
      checked={sortedTenants().length > 0 && selectedTenants.size === sortedTenants().length}
      onChange={(e) => {
        if (e.target.checked) {
          const currentPageTenantIds = new Set(sortedTenants().map((tenant) => tenant.id));
          setSelectedTenants(currentPageTenantIds);
        } else {
          setSelectedTenants(new Set());
        }
      }}
    />
  );

  return (
    <StyledDashboardContainer>
      <Box
        sx={{
          bgcolor: "white",
          padding: "2em",
          margin: "2em",
          borderRadius: "0.5%",
          border: "1px solid",
          borderColor: "#dbdbdb",
        }}
      >
        <TopContainer>
          <StyledTooltip content="Add and Remove features in bulk.">
            <StyledButton onClick={handleOpenModal} isTenantSelected={selectedTenants.size > 0}>
              Edit Multiple Tenants
            </StyledButton>
          </StyledTooltip>
          <StyledTextField
            id="outlined-basic"
            label="Search"
            variant="outlined"
            value={searchTerm}
            onChange={handleSearch}
            margin="normal"
          />
        </TopContainer>
        <FeatureModal
          open={openModal}
          handleClose={closeModal}
          tenantIds={selectedTenants}
          allFeatures={allFeatures}
          handleRefreshAllLiveTenants={handleRefreshAllLiveTenants}
        />

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <StickyTableCell left={0}>
                  Select
                  <Tooltip title="Select all tenants" placement="top" arrow interactive>
                    {selectAllCheckbox}
                  </Tooltip>
                </StickyTableCell>
                <StickyTableCell left={70}>Tenants</StickyTableCell>
                <StickyTableCell left={180}>ID</StickyTableCell>
                {sortedAndRenamedFeatures.map((feature) => (
                  <Tooltip
                    key={sortedAndRenamedFeatures.indexOf(feature)}
                    title={getTooltipContent(feature)}
                    placement="top"
                  >
                    <StyledTableCell key={feature} onClick={() => handleColumnClick(featureMapping[feature])}>
                      {feature}
                      {sortFeature === featureMapping[feature] && (
                        <StyledDirection>{sortDirection === "asc" ? "↑" : "↓"}</StyledDirection>
                      )}
                    </StyledTableCell>
                  </Tooltip>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell>Loading...</TableCell>
                </TableRow>
              ) : (
                sortedTenants().map((tenant: Tenant) => (
                  <TableRow key={tenant.id}>
                    <StickyTableCell left={0}>
                      <Checkbox
                        style={{ color: "grey" }}
                        checked={isTenantSelected(tenant.id)}
                        onChange={(e) => handleTenantSelection(tenant.id, e.target.checked)}
                      />
                    </StickyTableCell>
                    <StickyTableCell left={70}>{tenant.name}</StickyTableCell>
                    <StickyTableCell left={180}>{tenant.id}</StickyTableCell>
                    {allFeatures.map((feature) => (
                      <TableCell key={feature}>
                        <SwitchContainer>
                          <StyledSwitch
                            checked={tenant.config[feature]}
                            onChange={() => handleToggleFeature(tenant, feature)}
                          />
                        </SwitchContainer>
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={filteredTenants.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </StyledDashboardContainer>
  );
};

export default ProductFeatures;
