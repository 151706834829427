import { Tooltip } from "@material-ui/core";
import { ArrowRight } from "react-feather";
import styled from "styled-components";
import { calculatePercentageIncrease } from "../../util/percentageUtil";

const formatter = Intl.NumberFormat("en-GB", {
  signDisplay: "exceptZero",
  maximumFractionDigits: 1,
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Percentage = styled.div`
  font-size: 1.25em;
`;

const Values = styled.div`
  font-size: 0.75em;
  display: flex;
  justify-content: space-between;
  column-gap: 5px;
  align-items: center;
`;

const ArrowRightScaled = styled(ArrowRight)`
  height: 1.25em;
`;

type Props = {
  comparison: number;
  target: number;
};

function TablePercentageCell({ comparison, target }: Props): JSX.Element {
  let average = calculatePercentageIncrease(comparison, target);

  return (
    <Container>
      <Percentage style={{ color: average > 0 ? "green" : "red" }}>{formatter.format(average)}%</Percentage>
      <Values>
        <Tooltip title="Comparison week">
          <div>{comparison}</div>
        </Tooltip>
        <ArrowRightScaled />
        <Tooltip title="Target week">
          <div>{target}</div>
        </Tooltip>
      </Values>
    </Container>
  );
}

export default TablePercentageCell;
