import { useQuery } from "@tanstack/react-query";
import { getSkuMapping } from "../api/datasources";

export function useSkuMapping() {
  return useQuery<SkuMappingResult, Error>(["skuMapping"], () => getSkuMapping());
}

export interface SkuMappingResult {
  productMapping: string;
  exampleOrderSku?: string;
  exampleProductDetails: {
    offerId?: string;
    mpn?: string;
    gtin?: string;
    sku?: string;
  };
  usesShopifyProductVariantSku?: boolean;
}
