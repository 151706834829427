import { InputLabel, TextField } from "@material-ui/core";
import { ChangeEvent } from "react";
import SwitchConfig from "../../tenantConfig/SwitchConfig";
import { StyledRow } from "../../tenantConfig/TenantConfig";
import { StyledInputLabel } from "../AddJobDialog";
import { CommonDownloadConfiguration, CommonPayload } from "@volo-ai/upp-temporal-utils";

interface ScheduleProps {
  handleToggle: (prop: any) => (e: ChangeEvent<HTMLInputElement>) => void;
  jobPayload: CommonPayload;
  handleDataChange: (prop: any) => (e: ChangeEvent<HTMLInputElement>) => void;
}

export const Schedule = ({ handleToggle, jobPayload, handleDataChange }: ScheduleProps) => {
  return (
    <>
      <StyledRow>
        <InputLabel>Cron Schedule</InputLabel>
        <StyledInputLabel>* * * * * immediate</StyledInputLabel>
        <StyledInputLabel>auto automatic</StyledInputLabel>
        <TextField
          onChange={handleDataChange("cronSchedule")}
          value={jobPayload.cronSchedule}
          variant="outlined"
        ></TextField>
      </StyledRow>
      <StyledRow>
        <StyledInputLabel>Use auto for nightly download jobs only</StyledInputLabel>
        <a href="http://crontab.guru" target="_blank" rel="noreferrer">
          Use this link for help with cron schedules
        </a>
      </StyledRow>
      <StyledRow>
        <InputLabel>Initial Download</InputLabel>
        <SwitchConfig
          onChange={handleToggle("initialDownload")}
          checked={(jobPayload.configuration as CommonDownloadConfiguration).initialDownload}
        ></SwitchConfig>
      </StyledRow>
    </>
  );
};
