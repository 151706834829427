import styled from "@emotion/styled";
import { Box, Divider } from "@material-ui/core";
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, TooltipProps, XAxis, YAxis } from "recharts";
import { Heading } from "../../components/Heading";
import { useGoogleAdsDirectAudit } from "../../hooks/useAuditHealth";

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin: 1em;
`;

const AuditChangelog = () => {
  const tenantId = window.USER_INFO.tenantId;
  let { data, error, isLoading } = useGoogleAdsDirectAudit(tenantId);
  const chartData = data?.changelog ? mapToDataPoints(data?.changelog) : [];

  return (
    <Box
      sx={{
        bgcolor: "white",
        padding: "2em",
        margin: "2em",
        borderRadius: "0.5%",
        border: "1px solid",
        borderColor: "#dbdbdb",
      }}
    >
      <Heading>Changelog Activity</Heading>
      <Divider />
      {isLoading && <div>Loading...</div>}
      <CardContainer>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart
            width={500}
            height={300}
            data={chartData}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" label={{ value: "Date", position: "insideBottom" }} />
            <YAxis label={{ value: "# of changes", angle: -90, position: "insideLeft" }} />
            <Tooltip content={<CustomTooltip />} />
            <Line type="monotone" dataKey="changes" stroke="#8884d8" activeDot={{ r: 8 }} />
          </LineChart>
        </ResponsiveContainer>
      </CardContainer>
      {error && <div>Error: Unable to get campaigns ({error.message})</div>}
    </Box>
  );
};

export default AuditChangelog;

const CustomTooltip = ({ active, payload }: TooltipProps<number, string>) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip" style={{ backgroundColor: "#f5f5f5", padding: "10px", border: "1px solid #ccc" }}>
        <p>{`Date: ${payload[0].payload.date}`}</p>
        <p>{`Changes: ${payload[0].value}`}</p>
        <p>{`Emails: ${payload[0].payload.emails.join(", ")}`}</p>
      </div>
    );
  }

  return null;
};

interface DataPoint {
  date: string; // Date of change
  changes: number; // Number of changes
  emails: string[]; // userEmails for the tooltips
}

interface ChangeLogEntry {
  changeDateTime: string;
  userEmail: string;
}

function mapToDataPoints(changelog: ChangeLogEntry[]): DataPoint[] {
  const dataPointsMap: Map<string, DataPoint> = new Map();

  changelog.forEach((entry) => {
    const date = entry.changeDateTime.split(" ")[0];
    if (!dataPointsMap.has(date)) {
      dataPointsMap.set(date, { date, changes: 0, emails: [] });
    }
    const dataPoint = dataPointsMap.get(date)!;
    dataPoint.changes += 1;
    dataPoint.emails.push(entry.userEmail);
  });

  return Array.from(dataPointsMap.values());
}
