import { Box, Button, Dialog, DialogContent, DialogTitle, Divider, TextField, Typography } from "@material-ui/core";
import { CloseRounded } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";
import styled from "styled-components";
import { addIntegrationDetails, Datasource, getDatasource, IntegrationDetails } from "../../api/datasources";
import { StyledButton } from "./Datasources";
import { DialogContentSection } from "./DialogSection";
import { StyledCloseButton } from "./SetupConnection";

interface IntegrationDetailsProps {
  isOpen: boolean;
  onClose: () => void;
  data: Datasource | undefined;
}

const StyledDialogContent = styled(DialogContent)`
  background-color: #f2f2f7 !important;
`;

const StyledDialogContentSection = styled(DialogContentSection)`
  width: 100px !important;
`;

const IntegrationButton = styled(Button)`
  position: relative;
  margin: 5px !important;
  width: 400px;
  left: 25%;
  padding: 6px 16px !important;
  color: black !important;
  font-weight: 500 !important;
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
  background-color: #fff !important;
  border-radius: 4px !important;
`;

const SubmitButton = styled(Button)`
  padding: 6px 16px !important;
  color: white !important;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%);
  font-weight: 500 !important;
  background-color: #4054b4 !important;
  border-radius: 4px !important;
  z-index: 150;
  width: 100px;
  margin: 10px !important;
  float: right;
  position: absolute;
  background-color: #fff !important;
  color: black !important;
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
  box-shadow: none;
`;

const StyledTextField = styled(TextField)`
  width: 100%;
`;

export const IntegrationForm = ({ isOpen, onClose, data }: IntegrationDetailsProps) => {
  const [integrationData, setIntegrationData] = useState<IntegrationDetails>();
  const [mapping, setMapping] = useState<{ [key: string]: string }>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [step, setStep] = useState<number>(0);
  const [showNewIntegration, setShowNewIntegration] = useState<boolean>(false);
  const [chosenIntegration, setChosenIntegration] = useState<string>("");
  const [productVariant, setProductVariant] = useState({
    "order.product.identifier.source": "productAndVariant",
    "order.product.country.identifier": "",
  });
  const [countryOfSale, setCountryOfSale] = useState({
    "order.country.of.sale": "",
  });
  const { enqueueSnackbar } = useSnackbar();
  const variantError = productVariant["order.product.country.identifier"] === "";
  const orderError = countryOfSale["order.country.of.sale"] === "";

  const handleClose = () => {
    onClose();
    setStep(0);
  };

  const handleAddIntegration = () => {
    setStep(1);
  };

  const onChange = (prop: any) => (e: any) => {
    if (prop === "order.product.country.identifier") {
      setProductVariant((prevState: any) => {
        return {
          ...prevState,
          "order.product.country.identifier": e.target.value,
          "order.product.identifier.source": productVariant["order.product.identifier.source"],
        };
      });
    } else {
      setCountryOfSale((prevState: any) => {
        return {
          ...prevState,
          "order.country.of.sale": e.target.value,
        };
      });
    }
  };

  const handleSubmit = () => {
    if (chosenIntegration === "productVariant") {
      if (productVariant["order.product.country.identifier"] !== "") {
        addIntegrationDetails(data!.id, productVariant);

        enqueueSnackbar("Integration has been added successfully", {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        });
      } else {
        enqueueSnackbar("There was an error trying to add the integration, please try again.", {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        });
      }
    } else {
      if (countryOfSale["order.country.of.sale"] !== "") {
        addIntegrationDetails(data!.id, countryOfSale);

        enqueueSnackbar("Integration has been added successfully", {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        });
      } else {
        enqueueSnackbar("There was an error trying to add the integration, please try again.", {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        });
      }
    }
    setShowNewIntegration(false);
    handleClose();
  };

  useEffect(() => {
    async function getData() {
      setIsLoading(true);
      const datasource = await getDatasource(data!.id);
      if (datasource.integrationDetails.mapping) {
        // rome-ignore lint/performance/noDelete: <explanation>
        delete datasource.integrationDetails.mapping;
      }
      setIntegrationData(datasource.integrationDetails);
      setMapping(datasource.mapping);
      setIsLoading(false);
    }
    getData();
  }, [data]);

  return (
    <Dialog open={isOpen} maxWidth="md" fullWidth onClose={handleClose}>
      <StyledDialogContent>
        <DialogContentSection title="mapping">
          <pre>{JSON.stringify(mapping, null, 2)}</pre>
        </DialogContentSection>
      </StyledDialogContent>
      <DialogTitle>
        Integration Details
        <StyledCloseButton onClick={handleClose}>
          <CloseRounded />
        </StyledCloseButton>
        {step === 0 ? (
          <StyledButton style={{ float: "right" }} onClick={handleAddIntegration}>
            Add Detail
          </StyledButton>
        ) : (
          ""
        )}
      </DialogTitle>
      <Divider />
      {isLoading ? (
        <DialogContent>
          <Box paddingY={8} display="flex" flexDirection="column">
            <Box display="flex" justifyContent="center">
              <ClipLoader />
            </Box>

            <Typography variant="h6" align="center">
              Loading
            </Typography>
          </Box>
        </DialogContent>
      ) : (
        <div>
          {integrationData! && step === 0 && Object.entries(integrationData).length !== 0 ? (
            Object.entries(integrationData!).map(([key, property]) => {
              return (
                <StyledDialogContent>
                  <DialogContentSection title={key}>{property}</DialogContentSection>
                </StyledDialogContent>
              );
            })
          ) : step === 0 ? (
            <StyledDialogContent>
              <Box padding={14} display="flex" flexDirection="column">
                <Typography variant="h6" align="center">
                  No integration details available
                </Typography>
              </Box>
            </StyledDialogContent>
          ) : step === 1 ? (
            <StyledDialogContent>
              {integrationData! && !showNewIntegration ? (
                <Box padding={10} display="flex" flexDirection="column" justifyContent="center">
                  <IntegrationButton
                    onClick={() => {
                      setChosenIntegration("productVariant");
                      setShowNewIntegration(true);
                    }}
                  >
                    Add productAndVariant
                  </IntegrationButton>
                  <IntegrationButton
                    onClick={() => {
                      setChosenIntegration("countryOfSale");
                      setShowNewIntegration(true);
                    }}
                  >
                    Add Country of Sale
                  </IntegrationButton>
                </Box>
              ) : (
                <Box padding={4}>
                  <StyledDialogContentSection title={"Country Identifier"}>
                    {chosenIntegration === "productVariant" ? (
                      <StyledTextField
                        variant="outlined"
                        value={productVariant["order.product.country.identifier"]}
                        onChange={onChange("order.product.country.identifier")}
                        error={variantError}
                        helperText={variantError ? "Required." : ""}
                      />
                    ) : (
                      <StyledTextField
                        variant="outlined"
                        value={countryOfSale["order.country.of.sale"]}
                        onChange={onChange("order.country.of.sale")}
                        error={orderError}
                        helperText={orderError ? "Required." : ""}
                      />
                    )}
                  </StyledDialogContentSection>
                </Box>
              )}

              {showNewIntegration ? (
                <SubmitButton type="submit" onClick={handleSubmit}>
                  Submit
                </SubmitButton>
              ) : (
                ""
              )}
            </StyledDialogContent>
          ) : (
            ""
          )}
        </div>
      )}
    </Dialog>
  );
};
