import { Switch } from "@material-ui/core";
import React from "react";

type Props = {
  name?: string;
  checked?: any;
  defaultValue?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isLoading?: boolean;
};

export const SwitchConfig = ({ name, checked, onChange, defaultValue, isLoading = false }: Props) => {
  return (
    <div>
      <Switch
        edge="end"
        color="primary"
        size="medium"
        aria-label={name}
        defaultChecked={defaultValue}
        onChange={onChange}
        checked={checked}
        inputProps={{
          "aria-labelledby": "switch-list-label-wifi",
        }}
        disabled={isLoading}
      />
    </div>
  );
};

export default SwitchConfig;
