import { Box, Button, DialogActions, DialogContent, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";
import { Connection, DatasourceStatus, getStatus } from "../../api/datasources";

interface CheckConnectionProps {
  connectionId: string;
  system: Connection;
  onClose: () => void;
}

export const CheckConnection = ({ connectionId, system, onClose }: CheckConnectionProps) => {
  const [status, setStatus] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    async function fetchStatus() {
      const data = await getStatus(connectionId);
      setStatus(data);
      setIsLoading(false);
    }

    fetchStatus();
  }, [connectionId]);

  let screen = <div />;

  if (status === DatasourceStatus.Pending) {
    screen = (
      <Box padding={14} display="flex" flexDirection="column">
        <Box display="flex" justifyContent="center">
          <ClipLoader />
        </Box>

        <br />

        <Typography variant="h6" align="center">
          The connection configuration is complete. We're checking if we can connect to it in the background, however,
          you don't have to wait for this to finish.
        </Typography>
      </Box>
    );
  }

  if (status === DatasourceStatus.Success || status === DatasourceStatus.Inactive) {
    screen = (
      <Box padding={14} display="flex" flexDirection="column">
        <Typography variant="h6" align="center">
          The connection was successful!
        </Typography>
      </Box>
    );
  }

  if (status === DatasourceStatus.Error) {
    screen = (
      <Box padding={14} display="flex" flexDirection="column">
        <Typography variant="h6" align="center">
          The connection failed!
        </Typography>
      </Box>
    );
  }

  if (isLoading) {
    screen = (
      <Box padding={14} display="flex" flexDirection="column">
        <Box display="flex" justifyContent="center">
          <ClipLoader />
        </Box>
      </Box>
    );
  }

  return (
    <>
      <DialogContent>{screen}</DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" color="primary" id="connections__close">
          Close
        </Button>
      </DialogActions>
    </>
  );
};
