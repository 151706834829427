import { Box } from "@mui/material";
import Chart from "react-google-charts";

import { ProductsPerformance } from "../../api/performance";

interface ProductsPerformanceGraphInterface {
  productsPerformance: ProductsPerformance[];
}

export const ImpressionsRoasChart = ({ productsPerformance }: ProductsPerformanceGraphInterface) => {
  const data = [
    ["Day", "Impressions", "Roas"],
    ...productsPerformance
      .filter(({ impressions, roas }) => impressions !== 0 && roas !== 0)
      .map(({ date, impressions, roas }) => {
        const dateWithoutTime: string = date.split("T")[0];
        const day = dateWithoutTime.slice(-2);
        return [day, impressions, roas];
      }),
  ];
  return (
    <Chart
      data={data}
      width={"90%"}
      chartType="Line"
      options={{
        chart: {
          title: "Discounted product performance - impressions",
        },
        height: 500,
        series: {
          0: { axis: "Impressions" },
          1: { axis: "Roas" },
        },
        axes: {
          y: {
            Impressions: { label: "Impressions" },
            Roas: { label: "Roas" },
          },
        },
      }}
      style={{ margin: "10px", marginBottom: "20px" }}
    />
  );
};

export const UnitsSoldRoasChart = ({ productsPerformance }: ProductsPerformanceGraphInterface) => {
  const data = [
    ["Day", "Units sold", "Roas"],
    ...productsPerformance
      .filter(({ unitsSold, roas }) => unitsSold !== 0 && roas !== 0)
      .map(({ date, unitsSold, roas }) => {
        const dateWithoutTime: string = date.split("T")[0];
        const day = dateWithoutTime.slice(-2);
        return [day, unitsSold, roas];
      }),
  ];
  return (
    <Chart
      data={data}
      width={"90%"}
      chartType="Line"
      options={{
        chart: {
          title: "Discounted product performance - units sold",
        },
        height: 500,
        series: {
          0: { axis: "Units sold", color: "green" },
          1: { axis: "Roas", color: "blue" },
        },
        axes: {
          y: {
            UnitsSold: { label: "Units sold" },
            Roas: { label: "Roas" },
          },
        },
      }}
      style={{ margin: "10px", marginBottom: "20px" }}
    />
  );
};

export const ProductsPerformanceGraph = ({ productsPerformance }: ProductsPerformanceGraphInterface) => {
  if (productsPerformance.length > 0) {
    return (
      <>
        <ImpressionsRoasChart productsPerformance={productsPerformance} />;
        <UnitsSoldRoasChart productsPerformance={productsPerformance} />
      </>
    );
  }

  return (
    <Box
      sx={{
        bgcolor: "white",
        padding: "2em",
        margin: "2em",
        borderRadius: "0.5%",
        border: "1px solid",
        borderColor: "#dbdbdb",
        color: "red",
      }}
    >
      No data for products performance graph
    </Box>
  );
};
