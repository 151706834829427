import { Box, Dialog, DialogContent, DialogTitle, Divider, Snackbar, TextField, Typography } from "@material-ui/core";
import { CloseRounded } from "@material-ui/icons";
import { useEffect, useState } from "react";
import { StyledCloseButton } from "../datasources/SetupConnection";
import { DataGrid, GridCellEditCommitParams, GridColDef } from "@mui/x-data-grid";
import { GridWrapper } from "../dataManagement/DataManagement";
import { StyledButton } from "../datasources/Datasources";
import { StyledRow } from "./TenantConfig";
import { updateTenantConfig } from "../../api/tenant";
import Alert, { AlertColor } from "@mui/material/Alert";

import styled from "styled-components";

export const StyledActionButtons = styled(StyledButton)`
  margin-left: 485px !important;
`;
interface AdvancedOptionsProps {
  data: any;
  isOpen: boolean;
  onClose: () => void;
}

export const AdvancedOptions = ({ data, isOpen, onClose }: AdvancedOptionsProps) => {
  const [rows, setRows] = useState<Array<object>>([]);
  const [step, setStep] = useState<number>(0);
  const [key, setKey] = useState<string>("");
  const [value, setValue] = useState<any>();
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertConfig, setAlertConfig] = useState<{ message: string; severity: AlertColor }>({
    message: "test",
    severity: "success",
  });

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    let arr: any = [];
    let obj = {};

    Object.entries(data).map((row) => {
      obj = { key: row[0], value: row[1] };
      arr.push(obj);
      return arr;
    });
    setRows(arr);
  }, [data, showAlert]);

  const handleNewConfig = () => {
    setStep(1);
  };

  const addNewConfig = () => {
    let typeValue: any;
    if (!isNaN(Number(value))) {
      typeValue = Number(value);
    } else if (value === "true" || value === "false") {
      const isTrue = value === "true";
      typeValue = isTrue;
    } else {
      typeValue = value as string;
    }

    updateTenantConfig(window.USER_INFO.tenantId, [{ key: key, value: typeValue }])
      .then((response) => {
        if (response === true) {
          setAlertConfig!({ message: "Successfully added configuration", severity: "success" });
        } else {
          setAlertConfig!({
            message: "Something went wrong!",
            severity: "error",
          });
        }
      })
      .finally(() => {
        setShowAlert!(true);
        setRows([...rows, { key: key, value: typeValue }]);
      });

    setStep(0);
    setKey("");
    setValue(null);
  };

  const handleEditRow = (e: GridCellEditCommitParams) => {
    if (!isNaN(Number(e.value))) {
      updateTenantConfig(window.USER_INFO.tenantId, [{ key: e.id, value: Number(e.value) }]);
    } else if (e.value === "true" || e.value === "false") {
      const isTrue = e.value === "true";
      updateTenantConfig(window.USER_INFO.tenantId, [{ key: e.id, value: isTrue }]);
    } else {
      updateTenantConfig(window.USER_INFO.tenantId, [{ key: e.id, value: e.value as string }]);
    }
  };

  const columns: GridColDef[] = [
    { headerName: "Key", field: "key", editable: true, width: 400 },
    { headerName: "Value", field: "value", editable: true, width: 400 },
  ];

  return (
    <Dialog open={isOpen} maxWidth="md" fullWidth onClose={handleClose}>
      <DialogTitle>
        Advanced Options
        <StyledCloseButton onClick={handleClose}>
          <CloseRounded />
        </StyledCloseButton>
      </DialogTitle>
      <Divider />
      {step === 0 ? (
        <DialogContent>
          <StyledRow>
            <Typography>Existing Configurations</Typography>
            <StyledActionButtons onClick={handleNewConfig}>Add New Configuration</StyledActionButtons>
          </StyledRow>

          <Box padding={2} display="flex" flexDirection="column">
            <GridWrapper>
              <DataGrid columns={columns} rows={rows} getRowId={(row) => row.key} onCellEditCommit={handleEditRow} />
            </GridWrapper>
          </Box>
        </DialogContent>
      ) : (
        <DialogContent>
          <Typography>Add New Configuration</Typography>
          <br />
          <StyledRow>
            <TextField
              placeholder="Key"
              variant="outlined"
              value={key}
              onChange={(e) => setKey(e.target.value)}
            ></TextField>
          </StyledRow>

          <StyledRow>
            <TextField
              placeholder="Value"
              variant="outlined"
              value={value}
              onChange={(e) => setValue(e.target.value)}
            ></TextField>
          </StyledRow>

          <StyledRow>
            <StyledButton onClick={addNewConfig}>Submit</StyledButton>
            <StyledButton onClick={() => setStep(0)}>Back</StyledButton>
          </StyledRow>
        </DialogContent>
      )}
      <Snackbar open={showAlert} autoHideDuration={3000} onClose={() => setShowAlert(false)}>
        <Alert severity={alertConfig?.severity} variant="filled">
          {alertConfig?.message}
        </Alert>
      </Snackbar>
    </Dialog>
  );
};
