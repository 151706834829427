import styled from "styled-components";

export const MetricsContainer = styled.div`
  border: 1px #dbdbdb solid;
  display: flex;
  width: 100%;

  div {
    border-right: 1px #dbdbdb solid;
    padding: 1rem;
    width: 50%;

    &:last-child {
      border-right: none;
    }

    h3 {
      font-size: 1rem;
      font-weight: 400;
      margin: 0;
      margin-bottom: 1rem;
    }

    span {
      font-size: 2.5rem;
      font-weight: 700;
    }
  }
`;

export const DateContainer = styled.div`
  margin-bottom: 2rem;
  text-align: left;

  h2 {
    font-size: 1rem;
    font-weight: 400;
  }
`;
