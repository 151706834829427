import { CloseRounded } from "@mui/icons-material";
import { Autocomplete, Dialog, DialogContent, DialogTitle, Divider, Stack, TextField } from "@mui/material";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { getAllTenants, Tenant } from "../../api/tenant";
import { getUser, useUpdateUser, UserRequest, UsersResponse } from "../../api/userManagement";
import { StyledButton, StyledButtonFilled } from "../datasources/Datasources";
import { StyledCloseButton } from "../datasources/SetupConnection";
import { ClipLoader } from "react-spinners";
import { useSnackbar } from "notistack";

export type Props = {
  isOpen: boolean;
  userId: string;
  onClose: () => void;
  page: number;
};

// Modal wrapper that grabs data first

export function EditUserWrapper({ isOpen, userId, onClose, page }: Props) {
  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth>
      <Modal userId={userId} isOpen={isOpen} onClose={onClose} page={page} />
    </Dialog>
  );
}

function Modal({ userId, isOpen, onClose, page }: Props) {
  const userQuery = useQuery(["user", userId], () => getUser(userId), { enabled: isOpen });
  const tenantQuery = useQuery(["tenants"], () => getAllTenants(), { enabled: isOpen });
  const queryClient = useQueryClient();
  const updateUser = useUpdateUser();
  const [email, setEmail] = useState<string>();
  const [name, setName] = useState<string>();
  const [tenantIds, setTenantIds] = useState<Tenant[]>();
  const [loading, setLoading] = useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();

  const originalTenants = tenantQuery.data?.filter((tenant: Tenant) =>
    userQuery.data?.app_metadata?.allowedTenants.includes(tenant.id)
  );

  const handleSubmit = async () => {
    setLoading(true);
    console.log(
      `Updating user ${userId} with email ${
        email ? email : (userQuery.data?.email as string)
      } and name ${name} and tenantIds ${JSON.stringify(tenantIds)}`
    );
    updateUser.mutate(
      {
        email: email ? email : userQuery.data?.email,
        name: name ? name : userQuery.data?.name,
        tenantIds: tenantIds ? tenantIds?.map((tenant) => tenant.id) : userQuery.data?.app_metadata?.allowedTenants,
        user_id: userQuery.data?.user_id,
      },
      {
        onSuccess: (user: UserRequest) => {
          queryClient.setQueryData<UsersResponse>(["users", page], (oldData) => {
            return {
              ...oldData!,
              users: [...oldData!.users.filter((user) => user.user_id !== userId), user],
            };
          });
          enqueueSnackbar(`Successfully updated user: ${userQuery.data?.email}`, {
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
          });

          onClose();
        },
        onError: (error) => {
          enqueueSnackbar(`${error}`, {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
          });
          setLoading(false);
          onClose();
        },
      }
    );
  };

  if (userQuery.data && tenantQuery.data) {
    return (
      <>
        <DialogTitle>
          Edit User
          <StyledCloseButton onClick={onClose}>
            <CloseRounded />
          </StyledCloseButton>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Stack spacing={2} sx={{ width: 500 }}>
            {!loading ? (
              <>
                <TextField
                  label="Email"
                  defaultValue={userQuery.data?.email}
                  onChange={(e) => setEmail(e.target.value)}
                  variant="outlined"
                />
                <TextField
                  label="Name"
                  defaultValue={userQuery.data?.name}
                  onChange={(e) => setName(e.target.value)}
                  variant="outlined"
                />
                <Autocomplete
                  multiple
                  id="tenants"
                  options={tenantQuery.data}
                  value={tenantIds ? tenantIds : originalTenants}
                  onChange={(e, newValue) => {
                    //@ts-ignore
                    setTenantIds(newValue);
                  }}
                  getOptionLabel={(option: Tenant) => option.name}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="Accounts" placeholder="Select accounts..." />
                  )}
                />
                <StyledButtonFilled onClick={handleSubmit}>Update</StyledButtonFilled>
                <StyledButton onClick={onClose}>Cancel</StyledButton>
              </>
            ) : (
              <div style={{ marginLeft: "50%" }}>
                <ClipLoader size={20} />
              </div>
            )}
          </Stack>
        </DialogContent>
      </>
    );
  }
  return null;
}
