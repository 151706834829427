import { NavLink as RouterLink, matchPath, useLocation } from "react-router-dom";
import { Button, ListItem, StylesProvider } from "@material-ui/core";
import { Icon } from "react-feather";
import styled from "styled-components";
import { uppColourPalette } from "../../util/colours";

const NavItem = (props: { href: string; icon: Icon; title: string }) => {
  const location = useLocation();
  const active = !!matchPath(props.href, location.pathname);

  const StyledIcon = styled(props.icon)`
    margin-right: 0.5em;
    size: 20;
  `;

  type ButtonProps = {
    component: any;
    to: any;
    active: boolean;
  };

  const StyledButton = styled(Button)<ButtonProps>`
    color: ${(props) => (props.active ? uppColourPalette.navy[500] : uppColourPalette.coolGrey[500])}!important;
    font-weight: normal;
    justify-content: flex-start !important;
    letter-spacing: 0;
    text-transform: none;
    width: 100%;
  `;

  return (
    <ListItem disableGutters>
      <StylesProvider injectFirst>
        <StyledButton component={RouterLink} to={props.href} active={active}>
          <StyledIcon />
          <span>{props.title}</span>
        </StyledButton>
      </StylesProvider>
    </ListItem>
  );
};

export default NavItem;
