import styled from "styled-components";
import { uppColourPalette } from "../../util/colours";
import { InfluentialProduct } from "../../api/performance";
import { InfluentialProductsType } from "./InfluentialProducts";

export const Cell = styled.div`
  display: flex;
  padding: 8px;
  align-items: center;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 1em;
`;

export const CellContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 2px;
  padding: 1px;
  width: 100%;
`;

export const TableWrapper = styled.div`
  display: grid;
  grid-template-rows: 60px 390px 60px;
  @media screen and (min-width: 1800px) {
    grid-template-rows: 60px 850px 60px;
  }
`;

export const Header = styled.div`
  padding: 0 8px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  background: ${uppColourPalette.darkGreen[500]};
  font-family: Jost;
  color: white;
`;

export const RowWrapper = styled.div`
  overflow: auto;
  max-height: 100%;
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-bottom: 1px solid ${uppColourPalette.darkGreen[500]};
`;

export const Footer = styled.div`
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  font-family: Jost;
  background: ${uppColourPalette.darkGreen[500]};
  box-shadow: 0 -2px 2px rgba(123, 119, 238, 0.1);
  border-radius: 0 0 4px 4px;
`;

const StyledRow = styled(Row)`
  grid-template-columns: 0.5fr 1fr 1fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr;
`;

const StyledHeader = styled(Header)`
  grid-template-columns: 0.5fr 1fr 1fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr;
`;

const StyledFooter = styled(Footer)`
  justify-content: center;
  gap: 12px;
`;

const StyledWrapper = styled(TableWrapper)`
  @media screen and (min-width: 1024px) {
    grid-template-rows: 60px 400px 60px;
  }
  @media screen and (min-width: 1400px) {
    grid-template-rows: 60px 800px 60px;
  }
  @media screen and (min-width: 2000px) {
    grid-template-rows: 60p 1000px 60px;
  }
`;

const InfluentialProductsTable = (props: {
  children: any;
  page: number;
  influentialProductType: InfluentialProductsType;
  influentialProducts: InfluentialProduct[];
}) => {
  return (
    <StyledWrapper>
      <StyledHeader>
        <Cell>Rank</Cell>
        <Cell>Sku</Cell>
        <Cell>Title</Cell>
        <Cell>Contribution</Cell>
        <Cell>Deleted</Cell>
        <Cell>Blocked</Cell>
        <Cell>Unlisted</Cell>
        <Cell>Stock</Cell>
      </StyledHeader>
      <RowWrapper>
        {props.influentialProducts.map((influentialProduct) => (
          <StyledRow key={props.influentialProducts.indexOf(influentialProduct)}>
            <Cell>
              <CellContent>{influentialProduct.rank}</CellContent>
            </Cell>
            <Cell>
              <CellContent>{influentialProduct.sku}</CellContent>
            </Cell>
            <Cell>
              <CellContent>{influentialProduct.title.slice(0, 40)}</CellContent>
            </Cell>
            <Cell>
              <CellContent>{Math.trunc(influentialProduct.contribution)}</CellContent>
            </Cell>
            <Cell>
              <CellContent>{influentialProduct.isDeleted ? "Y" : "N"}</CellContent>
            </Cell>
            <Cell>
              <CellContent>{influentialProduct.isBlocked ? "Y" : "N"}</CellContent>
            </Cell>
            <Cell>
              <CellContent>{influentialProduct.unlisted ? "Y" : "N"}</CellContent>
            </Cell>
            <Cell>
              <CellContent>{influentialProduct.stock}</CellContent>
            </Cell>
          </StyledRow>
        ))}
      </RowWrapper>

      <StyledFooter>{props.children}</StyledFooter>
    </StyledWrapper>
  );
};

export default InfluentialProductsTable;
