import { Box, Button, Snackbar, Typography } from "@material-ui/core";
import Alert, { AlertColor } from "@mui/material/Alert";
import { useEffect, useState } from "react";
import styled from "styled-components";
import {
  activateDatasource,
  Connection,
  Datasource,
  deleteDatasource,
  getAllDatasources,
  getAvailableConnections,
} from "../../api/datasources";
import { StyledDashboardContainer } from "../dashboard/Dashboard";
import { ConnectionCard } from "./ConnectionCard";
import { Headers } from "./Headers";
import ClipLoader from "react-spinners/ClipLoader";
import { SetupConnection } from "./SetupConnection";
import { IntegrationForm } from "./IntegrationForm";
import SkuMapping from "./SkuMapping";
import { getCollectionCount } from "../../api/platformadmin";

export const Heading = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #bcbcbc;
  margin: 0 -16px 10px -16px;
  padding: 0 10px 10px 10px;
  button {
    margin-left: auto;
  }
`;

export const StyledButtonFilled = styled(Button)`
  position: relative;
  padding: 6px 16px !important;
  color: white !important;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%);
  font-weight: 500 !important;
  background-color: #4054b4 !important;
  border-radius: 4px !important;
  z-index: 150;

  &:disabled {
    border: 1px solid #bfbfbf !important;
    color: #bfbfbf !important;
  }
`;

export const StyledButton = styled(StyledButtonFilled)`
  position: absolute;
  background-color: #fff !important;
  color: black !important;
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
  box-shadow: none;

  &:disabled {
    border: 1px solid #bfbfbf !important;
    color: #bfbfbf !important;
  }
`;

export const DatasourceConfiguration = () => {
  const [datasources, setDatasources] = useState<Datasource[]>([]);
  const [availableConnections, setAvailableConnections] = useState<Connection[]>([]);
  const [productCount, setProductCount] = useState(0);
  const [orderCount, setOrderCount] = useState(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isActivating, setIsActivating] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [showAddConnection, setShowAddConnection] = useState<boolean>(false);
  const [showSkuMapping, setShowSkuMapping] = useState<boolean>(false);
  const [expandDatasource, setExpandDatasource] = useState<boolean>(false);
  const [data, setData] = useState<Datasource>();
  const [alertConfig, setAlertConfig] = useState<{ message: string; severity: AlertColor }>({
    message: "test",
    severity: "success",
  });

  useEffect(() => {
    async function fetchAllData() {
      setIsLoading(true);
      const ds = await getAllDatasources();
      setDatasources(ds);
      const ac = await getAvailableConnections();
      setAvailableConnections(ac);
      const pc = await getCollectionCount("products");
      setProductCount(pc);
      const oc = await getCollectionCount("orderhistory");
      setOrderCount(oc);
      setIsLoading(false);
    }

    fetchAllData();
  }, [showAlert, showAddConnection, showSkuMapping, isActivating]);

  async function activateDatasources(id: string) {
    setIsActivating(true);
    await activateDatasource(id);
    setIsActivating(false);
  }

  return (
    <StyledDashboardContainer>
      <Box
        sx={{
          bgcolor: "white",
          padding: "2em",
          margin: "2em",
          borderRadius: "0.5%",
          border: "1px solid",
          borderColor: "#dbdbdb",
        }}
      >
        <Heading>
          <h1>Datasources</h1>
          <StyledButton
            variant="outlined"
            onClick={() => {
              setShowSkuMapping(true);
            }}
            id="sku_mapping"
          >
            Check SKU Mapping
          </StyledButton>
          <StyledButton
            variant="outlined"
            onClick={() => {
              setShowAddConnection(true);
            }}
            id="connections__add"
          >
            Add Connection
          </StyledButton>
        </Heading>
        {isLoading ? (
          <ClipLoader />
        ) : (
          <>
            {showAddConnection ? (
              <SetupConnection
                isOpen={true}
                onClose={() => {
                  setShowAddConnection(false);
                }}
              />
            ) : (
              ""
            )}
            {showSkuMapping ? (
              <SkuMapping
                isOpen={true}
                onClose={() => {
                  setShowSkuMapping(false);
                }}
              />
            ) : (
              ""
            )}
            {expandDatasource ? (
              <IntegrationForm
                isOpen={true}
                onClose={() => {
                  setExpandDatasource(false);
                }}
                data={data}
              />
            ) : (
              ""
            )}
            {datasources.length > 0 ? (
              <>
                <Headers values={["Source", "Status", "Count", "Entity Type"]} />

                {datasources.map(function (d, idx) {
                  const system = availableConnections.find((sys) => sys.systemId === d.partnerSystemId);

                  return (
                    <ConnectionCard
                      key={d.id}
                      id={d.id}
                      buttonId={`connection__${idx}`}
                      buttonDisabled={d.status === "PENDING"}
                      item={system!}
                      buttonValue={`${d.status === "INACTIVE" ? "Activate" : "Delete"}`}
                      onClick={() => {
                        if (d.status === "INACTIVE") {
                          activateDatasources(d.id);
                        } else {
                          deleteDatasource(d.id);
                        }
                      }}
                      totalImported={
                        d.entityType === "PRODUCT" ? productCount : d.entityType === "ORDER" ? orderCount : "N/A"
                      }
                      status={d.status}
                      entityType={d.entityType}
                      onExpand={(e) => {
                        if (e.target.className !== "MuiButton-label") {
                          setData(d);
                          setExpandDatasource(true);
                        }
                      }}
                      setShowAlert={setShowAlert}
                      setAlertConfig={setAlertConfig}
                    />
                  );
                })}
              </>
            ) : (
              <Box padding={8} display="flex" alignItems="center" flexDirection="column">
                <Typography align="center">You don't have any connections setup right now.</Typography>

                <br />

                <StyledButtonFilled
                  variant="contained"
                  onClick={() => {
                    setShowAddConnection(true);
                  }}
                  color="primary"
                >
                  Add first connection
                </StyledButtonFilled>
              </Box>
            )}
          </>
        )}
      </Box>
      <Snackbar open={showAlert} autoHideDuration={3000} onClose={() => setShowAlert(false)}>
        <Alert severity={alertConfig?.severity} variant="filled">
          {alertConfig?.message}
        </Alert>
      </Snackbar>
    </StyledDashboardContainer>
  );
};

export default DatasourceConfiguration;
