import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Tenant, updateTenantConfig } from "./tenant";

export const useHandleToggleFeature = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    async (data: { tenantId: string; feature: string; value: boolean }) => {
      const tenant = await updateTenantConfig(data.tenantId, [{ key: data.feature, value: data.value }]);
      return tenant;
    },
    {
      onMutate: async (data) => {
        const previousTenants = queryClient.getQueryData<Tenant[]>(["tenants"]);
        if (previousTenants) {
          const newTenants = previousTenants.map((tenant) => {
            if (tenant.id === data.tenantId) {
              return { ...tenant, config: { ...tenant.config, [data.feature]: data.value } };
            }
            return tenant;
          });
          queryClient.setQueryData(["tenants"], newTenants);
        }
      },

      onSettled: () => {
        queryClient.invalidateQueries(["tenants"]);
      },
    }
  );

  return mutation;
};

export default useHandleToggleFeature;
