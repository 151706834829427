import { JSONSchema7 } from "json-schema";
import { SkuMappingResult } from "../hooks/useMapping";
import { http } from "../UppAuth";
export interface Datasource {
  partnerSystemId: string;
  partnerIntegrationId?: string;
  partnerIntegrationToken?: string;
  id: string;
  systemType: DatasourceSystemType;
  entityType: DatasourceEntityType;
  provider: DatasourceProvider;
  integrationDetails?: IntegrationDetails;
  vaultKey: string;
  revision: number;
  status?: DatasourceStatus;
  priority?: number;
  channel?: string;
}

export interface DataSourceLite {
  entityType: DatasourceEntityType;
  integrationDetails?: IntegrationDetails;
  partnerSystemId: string;
  externalProviderId?: string;
  partnerIntegrationId?: string;
  channel?: string;
}
export enum DatasourceStatus {
  Pending = "PENDING",
  Error = "ERROR",
  Success = "SUCCESS",
  Inactive = "INACTIVE",
}

export enum DatasourceEntityType {
  Order = "ORDER",
  Product = "PRODUCT",
  Ads = "ADS",
  Combined = "COMBINED",
}

export enum DatasourceProvider {
  Upp = "UPP",
}

export enum DatasourceSystemType {
  External = "EXTERNAL",
  Internal = "INTERNAL",
}

export type IntegrationDetails = { [key: string]: string };

export interface ManagedCampaign {
  tenantId: string;
  campaignId: string;
  campaignName: string;
}

export interface Connection {
  systemId: string;
  name: string;
  logo: string;
  authType: string;
  entityTypes: DatasourceEntityType[];
  createdAt: string;
  updatedAt: string;
  type: string;
  isSource: boolean;
  settings: unknown;
  backend: string;
  currency: string | null;
  isDestination: boolean;
  status: DatasourceStatus;
  channel: string;
}

export interface AvailableConnectionOauthUrl {
  oauthUrl: string;
  params: AvailableConnectionOauthUrlParams;
}
export interface AvailableConnectionOauthUrlParams {
  accessType: string;
  scope: string;
  responseType: string;
  redirectUri: string;
  state: string;
  prompt: string;
  client_id: string;
}

export async function getAllDatasources(): Promise<Array<Datasource>> {
  const result = await http.get<any>(`${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/datasources`);

  if (result.status !== 200) {
    console.log("Failed to get all datasources");
  }

  return result.data;
}

export async function getAvailableConnections(): Promise<Array<Connection>> {
  const result = await http.get<any>(`${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/datasources/availableConnections`);

  if (result.status !== 200) {
    console.log("Failed to get available connections");
  }

  return result.data;
}

export async function deleteDatasource(id: string): Promise<boolean> {
  const result = await http.delete<any>(`${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/datasources/${id}`);

  if (result.status !== 200) {
    console.log("Failed to delete data source");
    return false;
  }

  return true;
}

export async function getAvailableConnectionDetails(systemId: string): Promise<JSONSchema7> {
  const result = await http.get<any>(
    `${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/datasources/availableConnections/${systemId}/details`
  );

  if (result.status !== 200) {
    console.log("Failed to delete data source");
  }

  return result.data;
}

export async function createDatasource(dataSource: DataSourceLite): Promise<any> {
  return await http.post<any>(
    `${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/datasources`,
    {
      entityType: dataSource.entityType,
      integrationDetails: dataSource.integrationDetails,
      externalProviderId: dataSource.externalProviderId,
      partnerSystemId: dataSource.partnerSystemId,
      channel: dataSource.channel,
    },
    {
      headers: {
        "Api-Version": "1",
      },
    }
  );
}

export async function getStatus(id: string): Promise<string> {
  const result = await http.get<any>(`${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/datasources/${id}`);
  if (result.status !== 200) {
    console.log("Failed to get status");
  }

  return result.data.status;
}

export async function getAvailableConnectionOauthUrl(
  systemId: string,
  additionalParams: { [key: string]: string }
): Promise<AvailableConnectionOauthUrl> {
  const params = {
    systemId,
    callBackUrl: "postmessage",
    ...additionalParams,
  };
  const result = await http.get<any>(
    `${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/datasources/availableConnections/${systemId}/oauthUrl`,
    { params }
  );
  if (result.status !== 200) {
    console.log("Failed to get status");
  }

  return result.data;
}

export async function activateDatasource(id: string) {
  await http.put<any>(`${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/datasources/${id}/status`, {
    status: "SUCCESS",
  });
}

export async function getDatasource(id: string) {
  const result = await http.get<any>(`${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/datasources/${id}`);

  if (result.status !== 200) {
    console.log("Failed to get integration details");
  }

  return result.data;
}

export async function addIntegrationDetails(id: string | undefined, integrationDetails: any) {
  return await http.patch<any>(`${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/datasources/${id}/integration-details`, {
    integrationDetails,
  });
}

export async function getSkuMapping(): Promise<SkuMappingResult> {
  const result = await http.get<any>(`${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/datasources/skuMapping`);
  if (result.status !== 200) {
    console.log("Failed to get sku mapping");
    throw new Error(result.data.errorMessage);
  }

  return result.data;
}
