import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import jwtDecode, { JwtPayload } from "jwt-decode";
import { useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { useAsync } from "react-use";
import App from "./App";
import { TenantConfig, getTenantConfig } from "./api/tenant";

export const http = axios.create({
  baseURL: window.APP_CONFIG?.svcUrl,
});

interface UppJwtPayload extends JwtPayload {
  "https://upp.ai/tenantName": string;
}

function AuthRoot() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const state = JSON.parse(atob(params.get("state")!));
  return <Navigate to={`/${state.tenant}/${state.redirectTo}${location.search}`} />;
}

export function UppAuth() {
  const { isLoading, isAuthenticated, getAccessTokenSilently, getIdTokenClaims, error, loginWithRedirect } = useAuth0();

  const [axiosAuthReady, setAxiosAuthReady] = useState<boolean>(false);
  const [tenantConfig, setTenantConfig] = useState<TenantConfig>({});

  useAsync(async () => {
    if (!isAuthenticated) {
      return;
    }

    const idToken = await getIdTokenClaims();
    const accessToken = await getAccessTokenSilently();
    const accessTokenInfo = jwtDecode<UppJwtPayload>(accessToken);

    if (!axiosAuthReady) {
      // Set up an interceptor to add the token to the headers of all requests
      http.interceptors.request.use((config) => {
        // config.headers = {
        //   ...config?.headers,
        //   Authorization: `Bearer ${accessToken}`,
        // };

        config.headers.setAuthorization(`Bearer ${accessToken}`);

        return config;
      });

      setAxiosAuthReady(true);
    }

    // @TODO: Handle idToken being undefined here
    const tenantId = String(idToken!["https://upp.ai/tenantId"]);
    const tenantName = accessTokenInfo["https://upp.ai/tenantName"];
    const isStaff = idToken!["https://upp.ai/isStaff"];

    setTenantConfig(await getTenantConfig(tenantId));

    window.USER_INFO = {
      id: idToken!.sub,
      email: idToken!.email,
      tenantId,
      tenantName,
      type: isStaff ? "staff" : "user",
    };
    window.TENANT_ID = tenantId;
  }, [isAuthenticated]);

  if (isLoading) {
    return <>Loading...</>;
  }

  if (!isAuthenticated) {
    loginWithRedirect();

    return <>Loading...</>;
  }

  if (!axiosAuthReady) {
    return <>Loading...</>;
  }

  if (Object.keys(tenantConfig).length === 0) {
    return <>Loading...</>;
  }

  if (error) {
    return (
      <>
        <>Access Denied or an error occurred!</>
        <>Sorry this is all we know right now :(</>
      </>
    );
  }

  return (
    <Routes>
      <Route path="/auth/redirect" element={<AuthRoot />} />
      <Route path="/*" element={<App />} />
    </Routes>
  );
}
