import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import styled from "styled-components";
import NumberFormat from "react-number-format";
import { CampaignMonitoring } from "../../api/campaignMonitoring";

const StyledCard = styled(Card)`
  width: 400px;
  margin: 10px;
`;
export const CampaignMonitoringCard = ({
  campaignId,
  campaignName,
  date,
  averageRoas,
  targetRoas,
  productGroups,
  productGroupsAboveAverageRoas,
  productGroupsBelowAverageRoas,
  productGroupsAboveTargetRoas,
  productGroupsBelowTargetRoas,
  minimumNumberOfImpressionsPerConversion,
  maximumNumberOfImpressionsPerConversion,
  averageImpressionsPerConversion,
}: CampaignMonitoring) => {
  return (
    <StyledCard>
      <CardContent>
        <Grid item xs={10}>
          <Typography color="textPrimary" align="left">
            <strong>{campaignId}</strong>
          </Typography>
          <Typography color="textPrimary" align="left">
            <strong>{campaignName}</strong>
          </Typography>
          <Typography color="textPrimary" align="left">
            {date}
          </Typography>
          <br />
          <Typography color="textPrimary" align="left">
            Product groups: {productGroups}
          </Typography>
          <br />
          <Typography>
            <strong>
              Average ROAS <NumberFormat displayType={"text"} value={averageRoas} decimalScale={2} />
            </strong>
          </Typography>
          <Typography color="textSecondary" align="left">
            <p>Groups above: {productGroupsAboveAverageRoas}</p>
          </Typography>
          <Typography color="textSecondary" align="left">
            <p>Groups below: {productGroupsBelowAverageRoas}</p>
          </Typography>
          <Typography>
            <strong>
              Target Roas <NumberFormat displayType={"text"} value={targetRoas} decimalScale={2} />
            </strong>
          </Typography>
          <Typography color="textSecondary" align="left">
            <p>Above target roas: {productGroupsAboveTargetRoas}</p>
          </Typography>
          <Typography color="textSecondary" align="left">
            <p>Below target roas: {productGroupsBelowTargetRoas}</p>
          </Typography>
          <Typography>
            <strong>Impressions per conversion</strong>
          </Typography>
          <Typography color="textSecondary" align="left">
            <p>
              Minimum:
              <NumberFormat displayType={"text"} value={minimumNumberOfImpressionsPerConversion} decimalScale={2} />
            </p>
          </Typography>
          <Typography color="textSecondary" align="left">
            <p>
              Maximum:
              <NumberFormat displayType={"text"} value={maximumNumberOfImpressionsPerConversion} decimalScale={2} />
            </p>
          </Typography>
          <Typography color="textSecondary" align="left">
            <p>
              Average:
              <NumberFormat displayType={"text"} value={averageImpressionsPerConversion} decimalScale={2} />
            </p>
          </Typography>
        </Grid>
      </CardContent>
    </StyledCard>
  );
};
