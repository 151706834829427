import { TextField, Typography } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

type Props = {
  type?: string;
  inputProps?: any;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  value?: any;
  title?: string;
  placeholder?: string;
  disabled?: boolean;
};

const StyledTextField = styled(TextField)`
  .MuiInputBase-root {
    width: 12em;
  }
  .MuiOutlinedInput-input {
    padding: 12px 14px;
  }
`;

export const FreeTextConfig = ({
  type,
  inputProps,
  onChange,
  onBlur,
  value,
  title,
  placeholder,
  disabled = false,
}: Props) => {
  return (
    <div>
      <Typography>{title}</Typography>

      <StyledTextField
        variant="outlined"
        value={value}
        type={type}
        placeholder={placeholder}
        InputProps={inputProps}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
      ></StyledTextField>
    </div>
  );
};
