import { http } from "../UppAuth";

export async function getAccountsOverview(): Promise<AccountOverview[]> {
  const res = await http.get<AccountOverview[]>(
    `${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/health/accounts-overview`
  );

  if (res.status !== 200) {
    console.log(`Failed to get accounts overview`);
  }

  return res.data;
}

export async function getAccountOverview(tenantId: string, from: string, to: string): Promise<AccountOverview> {
  const res = await http.get<{ accountOverview: AccountOverview }>(
    `${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/health/accounts-overview/tenants/${tenantId}?from=${from}&to=${to}`
  );

  if (res.status !== 200) {
    console.log(`Failed to get accounts overview`);
  }

  return res.data.accountOverview;
}

export interface AccountOverview {
  name: string;
  tenantId: string;
  actualCpa: number;
  actualCpaChangePercent: number;
  actualSpend: number;
  actualSpendChangePercent: number;
  averageOrderValue: number;
  averageOrderValueChangePercent: number;
  clickThroughRate: number;
  clickThroughRateChangePercent: number;
  clicks: number;
  clicksChangePercent: number;
  conversionValue: number;
  conversionValueChangePercent: number;
  conversions: number;
  conversionsChangePercent: number;
  impressions: number;
  impressionsChangePercent: number;
  roas: number;
  roasChangePercent: number;
  targetRoas: number;
  targetCpa: number;
  targetSpend: number;
}
