import { Box, Divider } from "@material-ui/core";
import { useEffect, useState } from "react";
import { AccountOverview, getAccountsOverview } from "../../api/accountsoverview";
import { Heading } from "../../components/Heading";
import { StyledDashboardContainer } from "../dashboard/Dashboard";
import OverviewTable from "./OverviewTable";
import { AccountsStateGrid } from "./AccountsStateGrid";
import AutopilotState from "./AutopilotState";

const AccountsOverview = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [accounts, setAccounts] = useState<AccountOverview[]>([]);

  useEffect(() => {
    async function fetchAccountsOverview() {
      const data = await getAccountsOverview();
      setAccounts(data);
      setLoading(false);
    }
    fetchAccountsOverview();
  }, []);

  return (
    <>
      <AutopilotState />
      <AccountsStateGrid />
      <StyledDashboardContainer>
        <Box
          sx={{
            bgcolor: "white",
            padding: "2em",
            margin: "2em",
            borderRadius: "0.5%",
            border: "1px solid",
            borderColor: "#dbdbdb",
          }}
        >
          <Heading>Accounts Overview</Heading>
          <Divider />
          <OverviewTable accounts={accounts} isLoading={loading} />
        </Box>
      </StyledDashboardContainer>
    </>
  );
};

export default AccountsOverview;
