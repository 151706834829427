import { http } from "../UppAuth";
export interface ChatHistory {
  _id?: string;
  createdAt: Date;
  tenantId: string;
  userEmail: string;
  messages: Message[];
}

export interface Message {
  id?: string;
  createdAt?: Date;
  content: string;
  role: "user" | "assistant";
  feedback?: string;
}

export async function getChatHistory(tenantId: string): Promise<ChatHistory[]> {
  const result = await http.get<ChatHistory[]>(
    `${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/chatbot/history/${tenantId}`
  );
  if (result.status !== 200) {
    console.log("Failed to fetch chat history");
    return [];
  }
  return result.data;
}
