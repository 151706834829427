import { Box, Divider } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { useCallback, useState } from "react";
import { ClipLoader } from "react-spinners";
import { triggerGoogleAdsCheck } from "../../api/campaignAutomation";

import { Heading } from "../../components/Heading";
import { InnerContainer } from "../../components/InnerContainer";
import { StyledButtonFilled } from "../../components/StyledButton";
import { StyledRow, StyledUnorderedList } from "./CampaignAutomation";

export const CampaignChecker = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [isCheckingCampaigns, setIsCheckingCampaigns] = useState<boolean>(false);
  const [targetSpendGood, setTargetSpendGood] = useState<boolean | string>("");
  const [targetRoasGood, setTargetRoasGood] = useState<boolean | string>("");
  const [campaignsGood, setCampaignsGood] = useState<boolean | string>("");
  const [message, setMessage] = useState<string>("");

  const triggerCampaignCheck = useCallback(async () => {
    setIsCheckingCampaigns(true);
    try {
      const checkingResult = await triggerGoogleAdsCheck();

      if (checkingResult.success) {
        setTargetSpendGood(checkingResult.accountTargetSpendGood!);
        setTargetRoasGood(checkingResult.accountTargetRoasGood!);
        setCampaignsGood(checkingResult.campaignsGood!);
        setMessage(checkingResult?.message ?? "");
      }
    } catch (e) {
      enqueueSnackbar("Failed to check campaigns", {
        variant: "warning",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    }
    setIsCheckingCampaigns(false);
  }, [enqueueSnackbar]);

  return (
    <Box
      sx={{
        bgcolor: "white",
        padding: "2em",
        margin: "2em",
        borderRadius: "0.5%",
        border: "1px solid",
        borderColor: "#dbdbdb",
      }}
    >
      <Heading>Campaign Checker</Heading>
      <Divider />

      <InnerContainer>
        {
          <StyledRow>
            <StyledUnorderedList>
              <li>
                <strong>Target Spend OK?</strong> {targetSpendGood ? "Yes" : "No"}
                <li>
                  <strong>Target ROAS OK?</strong> {targetRoasGood ? "Yes" : "No"}
                </li>
                <li>
                  <strong>Campaigns OK?</strong> {campaignsGood ? "Yes" : "No"}
                </li>
                <li>
                  <strong>Message</strong> {message}
                </li>
              </li>
            </StyledUnorderedList>
          </StyledRow>
        }
        {
          <StyledButtonFilled disabled={isCheckingCampaigns} onClick={() => triggerCampaignCheck()}>
            Check campaigns
          </StyledButtonFilled>
        }
        {isCheckingCampaigns && <ClipLoader />}
      </InnerContainer>
    </Box>
  );
};
