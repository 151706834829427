import styled from "@emotion/styled";
import { ArrowRightAlt } from "@material-ui/icons";
import Check from "@material-ui/icons/Check";
import { Dispatch, SetStateAction, useContext } from "react";
import { ClipLoader } from "react-spinners";
import Button from "../../components/Wizards/Button";
import { useSkuMapping } from "../../hooks/useMapping";
import { uppColourPalette } from "../../util/colours";
import { CheckContainer } from "./DatasourceWizard";
import { Footer, Steps, WizardContext } from "./OnboardingWizard";

const Heading = styled.div`
  display: flex;
  padding: 16px 0;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Row = styled.div`
  padding: 24px;
  display: flex;
  gap: 24px;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid ${uppColourPalette.coolGrey[100]};
`;

const Column = styled.div`
  gap: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const IconContainer = styled.div`
  height: 48px;
  width: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${uppColourPalette.navy[300]};
  background: ${uppColourPalette.coolGrey[50]};
`;

export const MappingWizard = ({
  setStep,
  setDatasourcesComplete,
}: {
  setStep: Dispatch<SetStateAction<number>>;
  setDatasourcesComplete: Dispatch<SetStateAction<boolean>>;
}) => {
  const { updateWizardState } = useContext(WizardContext);

  const skuMappingQuery = useSkuMapping();

  const backPage = () => {
    setDatasourcesComplete(false);
    setStep(Steps.DATASOURCE);
    localStorage.setItem("onboardingStep", String(1));
  };

  const nextPage = () => {
    localStorage.setItem("onboardingStep", String(3));
    setStep(Steps.TARGETS);
    updateWizardState({ mapping: skuMappingQuery.data?.productMapping });
  };

  return (
    <>
      <Heading>Mapping Products</Heading>
      <div>
        <Content>
          <Row>
            <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
              {skuMappingQuery.isLoading ? (
                <>
                  <div>Searching for Field:</div>
                  <ClipLoader size={28} />
                </>
              ) : (
                <>
                  <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                    <div>Mapping to Field:</div>
                    <b>{skuMappingQuery.data?.productMapping}</b>
                    <CheckContainer active={true} style={{ height: "28px", width: "28px" }}>
                      <Check fontSize="small" />
                    </CheckContainer>
                  </div>

                  <b>{skuMappingQuery.error?.message}</b>
                </>
              )}
            </div>
          </Row>
          <Row>
            <Column style={{ justifyContent: "flex-start" }}>
              <div style={{ textDecoration: "underline" }}>Example Order SKU</div>
              <div style={{ color: !skuMappingQuery.isLoading ? uppColourPalette.darkGreen[500] : "" }}>
                SKU: <b>{skuMappingQuery.data?.exampleOrderSku}</b>
              </div>
            </Column>
            <Column>
              <IconContainer>
                <ArrowRightAlt fontSize="large" />
              </IconContainer>
            </Column>
            <Column>
              <div
                style={{
                  color: skuMappingQuery.data?.productMapping === "offerId" ? uppColourPalette.darkGreen[500] : "",
                }}
              >
                OFFERID: <b>{skuMappingQuery.data?.exampleProductDetails.offerId}</b>
              </div>
              <div
                style={{ color: skuMappingQuery.data?.productMapping === "mpn" ? uppColourPalette.darkGreen[500] : "" }}
              >
                MPN: <b>{skuMappingQuery.data?.exampleProductDetails.mpn}</b>
              </div>
              <div
                style={{
                  color: skuMappingQuery.data?.productMapping === "gtin" ? uppColourPalette.darkGreen[500] : "",
                }}
              >
                GTIN: <b>{skuMappingQuery.data?.exampleProductDetails.gtin}</b>
              </div>
              <div
                style={{ color: skuMappingQuery.data?.productMapping === "sku" ? uppColourPalette.darkGreen[500] : "" }}
              >
                SKU: <b>{skuMappingQuery.data?.exampleProductDetails.sku}</b>
              </div>
            </Column>
          </Row>
        </Content>
      </div>
      <Footer>
        <Button onClick={backPage}>Back</Button>
        <Button
          disabled={!(skuMappingQuery.data && skuMappingQuery.data.productMapping !== undefined)}
          onClick={nextPage}
        >
          Next
        </Button>
      </Footer>
    </>
  );
};

export default MappingWizard;
