import { Box, InputAdornment, InputLabel } from "@material-ui/core";
import { useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";
import { Heading } from "../../../components/Heading";
import { InnerContainer } from "../../../components/InnerContainer";
import { getCurrencySymbol } from "../../../services/utils";
import { FreeTextConfig } from "../../tenantConfig/FreeTextConfig";
import { StyledRow, StyledUnorderedList } from "../CampaignAutomation";
import { MarginModellingTooltip } from "../MarginModellingTooltip";

import SwitchConfig from "../../tenantConfig/SwitchConfig";
import { updateTenantConfig } from "../../../api/tenant";

export interface IntakeMargin {
  currency: string;
  intakeMargin_paymentGatewayDefaultPercentage: string;
  intakeMargin_targetContributionMarginDefaultPercentage: string;
  intakeMargin_fulfilmentFlatFee: number;
  intakeMargin_cogsDefaultPercentage: string;
  intakeMargin_enabled: boolean;
}

interface MarginModellingInterface {
  tenantConfig: IntakeMargin;
  intakeMarginEnabled: boolean;
  loadingUpdateIntakeMarginEnabled: boolean;
  updateMPAEnabled: (enabled: boolean) => {};
}

export const MPASettings = ({
  intakeMarginEnabled,
  updateMPAEnabled,
  tenantConfig,
  loadingUpdateIntakeMarginEnabled,
}: MarginModellingInterface) => {
  const {
    currency,
    intakeMargin_paymentGatewayDefaultPercentage,
    intakeMargin_fulfilmentFlatFee,
    intakeMargin_cogsDefaultPercentage,
    intakeMargin_targetContributionMarginDefaultPercentage,
  } = tenantConfig;

  const updatingTenantConfig: boolean = false;

  const [loadingUpdateTenantConfig, setLoadingUpdateTenantConfig] = useState<boolean>(updatingTenantConfig);

  const [targetContributionMarginDefaultPercentage, setTargetContributionMarginDefaultPercentage] = useState<string>(
    intakeMargin_targetContributionMarginDefaultPercentage
  );

  const [paymentGatewayDefaultPercentage, setPaymentGatewayDefaultPercentage] = useState<string>(
    intakeMargin_paymentGatewayDefaultPercentage
  );
  const [fulfilmentFlatFee, setFulfilmentFlatFee] = useState<number>(intakeMargin_fulfilmentFlatFee);
  const [cogsDefaultPercentage, setCogsDefaultPercentage] = useState<string>(intakeMargin_cogsDefaultPercentage);

  useEffect(() => {
    setTargetContributionMarginDefaultPercentage(intakeMargin_targetContributionMarginDefaultPercentage);
    setCogsDefaultPercentage(intakeMargin_cogsDefaultPercentage);
    setFulfilmentFlatFee(intakeMargin_fulfilmentFlatFee);
    setPaymentGatewayDefaultPercentage(intakeMargin_paymentGatewayDefaultPercentage);
    setLoadingUpdateTenantConfig(updatingTenantConfig);
  }, [
    intakeMargin_targetContributionMarginDefaultPercentage,
    intakeMargin_cogsDefaultPercentage,
    intakeMargin_fulfilmentFlatFee,
    intakeMargin_paymentGatewayDefaultPercentage,
    updatingTenantConfig,
  ]);

  const updateMarginModelling = async (key: string, value: string | number | boolean) => {
    setLoadingUpdateTenantConfig(true);
    await updateTenantConfig(window?.USER_INFO?.tenantId, [{ key, value }]);
    setLoadingUpdateTenantConfig(false);
  };

  return (
    <Box
      sx={{
        bgcolor: "white",
        padding: "2em",
        margin: "2em",
        borderRadius: "0.5%",
        border: "1px solid",
        borderColor: "#dbdbdb",
      }}
    >
      <Heading>
        Margin Profit Algorithm <ClipLoader loading={loadingUpdateTenantConfig} />
      </Heading>

      <InnerContainer>
        <StyledRow>
          <SwitchConfig
            name="mpa-enabled"
            key={"intakeMarginEnabled"}
            checked={!!intakeMarginEnabled}
            onChange={(event) => {
              updateMPAEnabled(event.target.checked);
            }}
            isLoading={loadingUpdateIntakeMarginEnabled}
          />
          <InputLabel>Enable MPA</InputLabel>
        </StyledRow>
        {intakeMarginEnabled && (
          <StyledRow>
            <StyledUnorderedList>
              <li>
                <strong>Target Contribution Margin Default Percentage</strong>
                <MarginModellingTooltip text="Fallback percentage value if the product doesn't have a target contribution on the SKU level. 3% is 0.03" />
                <FreeTextConfig
                  placeholder="3% is 0.03"
                  onChange={(e) => {
                    setTargetContributionMarginDefaultPercentage(e.target.value);
                  }}
                  value={targetContributionMarginDefaultPercentage}
                  onBlur={() =>
                    updateMarginModelling(
                      "intakeMargin_targetContributionMarginDefaultPercentage",
                      targetContributionMarginDefaultPercentage
                    )
                  }
                />
              </li>
              <li>
                <strong>Payment Gateway Default Percentage</strong>
                <MarginModellingTooltip text="Fallback percentage value if the product doesn't have a payment fee per transaction on the SKU level. 3% is 0.03" />
                <FreeTextConfig
                  placeholder="3% is 0.03"
                  onChange={(e) => setPaymentGatewayDefaultPercentage(e.target.value)}
                  value={paymentGatewayDefaultPercentage}
                  onBlur={() =>
                    updateMarginModelling(
                      "intakeMargin_paymentGatewayDefaultPercentage",
                      paymentGatewayDefaultPercentage
                    )
                  }
                />
              </li>
              <li>
                <strong>Fulfilment Flat Fee:</strong>
                <MarginModellingTooltip text="Fallback value if the product doesn't have a fulfilment fee per transaction on the SKU level. Shipping from the warehouse to the customer. Eg. Royal Mail, DHL" />
                <FreeTextConfig
                  type="number"
                  onChange={(e) => setFulfilmentFlatFee(Number(e.target.value))}
                  value={fulfilmentFlatFee}
                  inputProps={{
                    startAdornment: currency && (
                      <InputAdornment position="start">{getCurrencySymbol(currency)}</InputAdornment>
                    ),
                  }}
                  onBlur={() => updateMarginModelling("intakeMargin_fulfilmentFlatFee", fulfilmentFlatFee)}
                />
              </li>
              <li>
                <strong>COGs Default Percentage</strong>
                <MarginModellingTooltip text="Fallback value if the product doesn't have a COGs per product. 3% is 0.03" />
                <FreeTextConfig
                  type="number"
                  placeholder="3% is 0.03"
                  onChange={(e) => setCogsDefaultPercentage(e.target.value)}
                  value={cogsDefaultPercentage ?? "0.9"}
                  onBlur={() => updateMarginModelling("intakeMargin_cogsDefaultPercentage", cogsDefaultPercentage)}
                />
              </li>
            </StyledUnorderedList>
          </StyledRow>
        )}
      </InnerContainer>
    </Box>
  );
};
