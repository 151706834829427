import styled from "@emotion/styled";

const StyledCard = styled.div`
  position: relative;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  font-family: Montserrat;
  font-weight: 400;
  overflow: auto;
`;

const AuditCard = ({ className, children }: any) => {
  return <StyledCard className={className}>{children}</StyledCard>;
};

export default AuditCard;
