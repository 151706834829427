import { useQuery } from "@tanstack/react-query";
import { getAllJobs, getJobHistory } from "../api/jobscheduler";

export function useAllJobs() {
  return useQuery<any, Error>(["allJobs"], async () => await getAllJobs(), { refetchInterval: 5000 });
}

export function useJobHistory(jobName: string) {
  return useQuery<any, Error>(["jobHistory"], () => getJobHistory(jobName), {
    refetchInterval: 5000,
    enabled: !!jobName,
  });
}
